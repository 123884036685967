import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useTranslate } from '../../context/translate-context';
import { ReactComponent as Logo } from '../../assets/images/sportxpert-logo-light.svg';
import { UserInCircle } from '../UserInCircle';


export const HeaderPublic = () => {
  const { translate } = useTranslate();
  const { push } = useHistory();

  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__container header__container--public">
          <div className="header__logo" onClick={() => push('/login')}>
            <Logo fill="var(--SaasText01)" />
          </div>
          <div className="header__actions-public">
            <>
              <div className="header__links">
                <Link
                  className="header__link link-text"
                  to="/sign-up"
                >
                  <b>{translate('SIGN_UP')}</b>
                </Link>
                <span className="header__account-text header__link--margin-both-sides link-text">
                  {translate('OR')}
                </span>
                <Link
                  className="header__link link-text"
                  to="/login"
                >
                  <b>{translate('LOGIN')}</b>
                </Link>
              </div>
              <div className="header__user-icon">
                <UserInCircle svgStyles="user-account__icon--small" />
              </div>
            </>
          </div>
        </div>
      </div>
    </header>
  );
};
