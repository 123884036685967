import { DequeueIncident } from './DequeueIncident';
import { LiveMatchResult } from './Result';
import { Match } from './LiveMatch';
import { LiveMatchAggregatedIncident } from './LiveMatchAggregatedIncident';


export default {
  DequeueIncident,
  Match,
  AggregatedIncident: LiveMatchAggregatedIncident,
  MatchResult: LiveMatchResult,
};
