import { sectionOddType as correctScore } from './CorrectScore/MatchDetailsSideBetsCorrectScore';
import { sectionOddType as matchResult } from './MatchResult/MatchDetailsSideBetsMatchResult';
import { sectionOddType as numberOfGoals } from './NumberOfGoals/Default/MatchDetailsSideBetsNumberOfGoalsDefault';
import { sectionOddType as threeWayHandicap } from './ThreeWayHandicap/Default/MatchDetailsSideBetsThreeWayHandicapDefault';


export const sectionsConnectedToPredictions = [
  {
    oddType: correctScore,
    predictionKeys: ['correctScore', 'goalsFactor'],
  },
  {
    oddType: matchResult,
    predictionKeys: ['prediction'],
  },
  {
    oddType: numberOfGoals,
    predictionKeys: [
      'goalsPrediction',
    ],
  },
  {
    oddType: threeWayHandicap,
    predictionKeys: ['threeWayHandicap'],
  },
];
