export enum ConfigState {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

export enum SportsName {
  Soccer = 'soccer',
  Hockey = 'hockey',
}

export enum FeatureStorage {
  LocalStorage = 'local-storage',
  Auth0 = 'auth0',
}

export enum MobileNavigationItem {
  Basket = 'basket',
  Settings = 'settings'
}
