import React, { FC } from 'react';

import { MatchState } from '../../MatchList/Match/MatchState';
import { MatchDetailsSummaryWrapper } from '../MatchDetailsSummaryWrapper';

import { MatchDetailsTeamFormationPlayingField } from './MatchDetailsTeamFormationPlayingField';
import { MatchDetailsTeamLineupsController } from './MatchDetailsTeamLineupsController';


export const MatchDetailsTeamFormation: FC = () => {
  return (
    <MatchDetailsSummaryWrapper>
      {({ status }) => (
        <>
          <MatchDetailsTeamLineupsController>
            {props => (<MatchDetailsTeamFormationPlayingField {...props} />)}
          </MatchDetailsTeamLineupsController>
          <div className="match-details-summary-wrapper__state">
            <MatchState state={status} />
          </div>
        </>
      )}
    </MatchDetailsSummaryWrapper>
  );
};


