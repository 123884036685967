import React from 'react';

import { DEFAULT_LANGUAGE } from '../../constants/app';
import translate from '../../utils/translate';

import './ErrorMessage.scss';


const getError = (fieldName, errors) => {
  if (errors?.length > 0) {
    return errors.find(error => error[fieldName]);
  }
};

const ErrorMessage = ({ fieldName, value = '', errors, big = false, disableMarginFromInput = false }) => {
  const foundError = getError(fieldName, errors);
  const handleMargin = disableMarginFromInput ? '' : 'margin-top--size-s';
  const isBig = big ? 'error--big' : '';

  return (
    <>
      {foundError && (
        <span className={`error__message ${handleMargin} ${isBig}`}>
          {translate(DEFAULT_LANGUAGE, value || foundError[fieldName])}
        </span>
      )}
    </>
  );
};

export default ErrorMessage;
