import { IncidentCode } from 'types';

import { incidentConfirmedCodeMap, incidentsMapsByStatus } from './matchDetailsMatchReportIncidentsMap';


export const getIncidentAndMargin = ({ incidentCode, status }) => {
  const getIncidentsMapByStatus = incidentsMapsByStatus.get(status) || incidentConfirmedCodeMap;


  return getIncidentsMapByStatus.get(incidentCode);
};

const getSubstitutes = incidentCode => {
  if (![IncidentCode.SubIn, IncidentCode.SubOut].includes(incidentCode)) {
    return '';
  }
  const [, subDirection] = incidentCode.split('-');

  return ` (${subDirection})`;
};

export const handleDisplayPlayerNameAndSubstitute = (playerName, incidentCode) => {
  if (!playerName) {
    return '';
  }

  return `${playerName}${getSubstitutes(incidentCode)}`;
};
