const matchDetails = {
  SEASON: 'Sesong',
  STADIUM: 'Stadion',
  DRAW: 'U',
  FORM: 'Form',
  GOALS_AGAINST: 'MS',
  GOALS_DIFF: 'MF',
  GOALS_FOR: 'MI',
  LEAGUE_TABLE: 'Ligatabell',
  LOSE: 'T',
  NEXT_MATCH: 'Neste kamp',
  POINTS: 'P',
  WIN: 'W',
  STATISTICS: 'Statistikk',
  TBA: 'TBA',
  MORE_GOALS: 'Flere mål',
  H_A_D: 'Hjemme - uavgjort - borte',
  PLAYED: 'Kamper spilt',
  H_WINS: 'Hjemmeseire',
  DRAWS: 'Uavgjort',
  A_WINS: 'Borteseire',
  GOALS: 'Mål',
  NO_GOALS: 'Antall mål',
  GOALS_MATCH: 'Mål per kamp',
  GOALS_HOME: 'Mål på hjemmebane',
  GOALS_AWAY: 'Mål på bortebane',
  SUBSTITUES: 'innbytter',
  LINE_UP: 'Startoppstilling',
  ODDS: 'Odds',
  ODD: 'Odd',
  TEAM_FORMATION: 'Formasjon',
  MATCH_STATS: 'Kampstatistikk',
  MATCH_REPORT: 'Kamprapport',
  ALL_SIDE_BETS: 'Alle sidespill',
  SIDE_BETS: 'Sidespill',
  OUTCOME: 'Resultat',
  MATCH_RESULT: 'Resultat',
  HANDICAP_3W_X: 'Treveis handikap',
  TOTAL_X: 'Antall mål',
  CORRECT_SCORE: 'Riktig resultat',
  NUMBER_OF_GOALS: 'Antall mål',
  THREE_WAY_HANDICAPS: 'Treveis handikap',
  ZERO_GOALS: 'Ingen mål',
  UNDER: 'Under',
  OVER: 'Over',
  GOALS_FACTOR: 'Målfaktor',
  MATCH_DETAILS: 'Kampdetaljer',
  FULL_TIME: 'Fulltid',
  FIRST_HALF: 'Første omgang',
  SECOND_HALF: 'Andre omgang',
  XPERT: 'Xpert',
  'Attacks': 'Angrep',
  'Red cards': 'Røde kort',
  'Offsides': 'Offsides',
  'Fouls': 'Forseelser',
  'Free kicks': 'Frispark',
  'Penalties unsuccessful': 'Straffebom',
  'Shots off target': 'Skudd utenfor mål',
  'Penalties saved': 'Strafferedninger',
  'Corners': 'Cornere',
  'Throw-ins': 'Innkast',
  'Shots on target': 'Skudd på mål',
  'Ball possession percentage': 'Ballbesittelse i prosent',
  'Dangerous free kicks': 'Farlige frispark',
  'Penalties given': 'Straffer',
  'Dangerous attacks': 'Farlige angrep',
  'Shots woodwork': 'Stolpeskudd',
  'Shots blocked': 'Redninger',
  'Yellow cards': 'Gule kort',
  'Red card': 'Rødt kort',
  'Yellow card': 'Gult kort',
  'Own goals': 'Selvmål',
  'Goal kicks': 'Utspark fra mål',
  'Total shots': 'Antall skudd',
  'Formation': 'Formasjon',
  'Penalties scored': 'Scoringer på straffer',
  'Penalties missed': 'Brente straffer',
  'Substitutions': 'innbytter',
  'expensive': 'kostbar',
  'Lacking functionality': 'funksjonalitet mangler',
  'Another company': 'Et annet selskap',
  PENALTY_SAVED_BY_GOAL_KEEPER: 'straffe reddet av keeper',
  PENALTY_CANCELLED: 'straffe omgjort',
  POSSIBLE_CARD_CANCELLED: 'mulig kort kansellert',
  POSSIBLE_PENALTY: 'mulig straffe',
  PENALTY_GOAL: 'straffemål',
  POSSIBLE_CARD: 'mulig kort',
  MISSED_PENALTY: 'Brent straffe',
  POSSIBLE_GOAL: 'mulig mål',
  GOAL_CANCELLED: 'mål kansellert',
  HALF_1: 'halvtid',
  OWN_GOAL: 'selvmål',
  CORNER: 'corner',
  PENALTY: 'straffe',
  YELLOW_CARD: 'gult kort',
  RED_CARD: 'rødt kort',
  GOAL: 'mål',
  SUB_IN: 'byttet inn',
  SUB_OUT: 'byttet ut',
  CONFIRMED: 'bekreftet',
  CANCELLED: 'kansellert',
  TBD: 'tbd',
  GOALKEEPER: 'keeper',
  DEFENDER: 'forsvarer',
  MIDFIELDER: 'midtbanespiller',
  ATTACKER: 'angriper',
  REGULAR_TIME: 'ordinær tid',
  HALF_TIME: 'pause',
  HALF_TIME_LABEL: 'Pause',
  OUR_TYPE: 'Vår type',
  'Goals 76-90': 'Mål 76-90',
  'Goals 16-30': 'Mål 16-30',
  'Goals 1-15': 'Mål 1-15',
  'Goals 46-60': 'Mål 46-60',
  'Goals 31-45': 'Mål 31-45',
  'Goals 61-75': 'Mål 61-75',
  draw: 'tegne',
  home: 'hjem',
  away: 'borte',
  under: 'under',
  over: 'over',
  NO_ODDS_AVAILABLE: 'Ingen odds tilgjengelig',
  NO_PREDICTION_AVAILABLE: 'Ingen prediksjon tilgjengelig',
  FIRST_TIME_LABEL: 'Avspark',
  SECOND_HALF_LABEL: '2. omgang',
  FIRST_HALF_EXTRA_TIME_LABEL: '1. omgang ekstraomgang',
  SECOND_HALF_EXTRA_TIME_LABEL: '2. omgang ekstraomgang',
  HALF_TIME_EXTRA_TIME_LABEL: 'Halvtid ekstraomgang',
  PENALTY_LABEL: 'Straffesparkkonkurranse',
};

export default matchDetails;
