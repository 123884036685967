import  { FC, useEffect, ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { addQuery } from '../../utils/queries';


type Param = string | boolean | string[];

type Props = {
  url: string;
  isMediaQueryActive: boolean;
  children: ReactElement;
  options?: Record<string, Param>;
}

export const MediaQueriesRedirectController: FC<Props> = ({
  url,
  isMediaQueryActive,
  children,
  options = {},
}) => {
  const { search } = useLocation();
  const { push } = useHistory();

  const handleAddQuery = () => addQuery(search, options);
  const memoAddQuery = useCallback(handleAddQuery, [search]);

  useEffect(() => {
    if (isMediaQueryActive) {
      return push({
        pathname: url,
        search: memoAddQuery(),
      });
    }
  }, [isMediaQueryActive, url, push, memoAddQuery]);

  return children;
};
