const NUMBER_OF_DIGITS = {
  UNIT: '1',
  DOZENS: '2',
  HUNDREDS: '3',
  THOUSANDS: '4',
};

/*
  Create limit for match stats.
  Key in the map is builded from numberOfDigitMapKey- and key-index (max. 9).
  Multiplier sets limit by fixed number.
  When we look at this setAdditionalMapKeysBy({ numberOfDigitMapKey: NUMBER_OF_DIGITS.DOZENS, thresholdMultiplier: 10 });
  we will receive e.g
  - key in map '2-1' will have range equal to 20   (`${NUMBER_OF_DIGITS.DOZENS}-`${key}``)
  - key in map '2-2' will have range equal to 30   (when key = 1, `${NUMBER_OF_DIGITS.DOZENS}-${(key+1)*10}`)

  Since limitAsNextThreshold is created by array key + 1 * thresholdMultiplier
*/

const matchStatsMapLimits = new Map();

const setAdditionalMapKeysBy = ({ numberOfDigitMapKey, thresholdMultiplier }) => {
  return Array.from(Array(10).keys()).map(key => {
    const limitAsNextThreshold = (key + 1) * thresholdMultiplier;

    return matchStatsMapLimits.set(`${numberOfDigitMapKey}-${key}`, limitAsNextThreshold);
  });
};

const getBarMapLength = () => {
  setAdditionalMapKeysBy({ numberOfDigitMapKey: NUMBER_OF_DIGITS.UNIT, thresholdMultiplier: 1 });
  setAdditionalMapKeysBy({ numberOfDigitMapKey: NUMBER_OF_DIGITS.DOZENS, thresholdMultiplier: 10 });
  setAdditionalMapKeysBy({ numberOfDigitMapKey: NUMBER_OF_DIGITS.HUNDREDS, thresholdMultiplier: 100 });
  setAdditionalMapKeysBy({ numberOfDigitMapKey: NUMBER_OF_DIGITS.THOUSANDS, thresholdMultiplier: 1000 });

  return matchStatsMapLimits;
};

export const handleLimitByTwoValues = scores => {
  const maxValueFloor = Math.floor(Math.max(...scores));
  const maxValueAsString = String(maxValueFloor);
  const numberOfDigits = String(maxValueAsString.length);
  const barMapLength = getBarMapLength();
  const keyInTheMap = maxValueAsString[0];

  const mapKey = `${numberOfDigits}-${keyInTheMap}`;

  return barMapLength.get(mapKey);
};
