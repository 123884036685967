import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';

import ErrorMessage from '../../ErrorMessage';

import './FieldCheckbox.scss';


type Props = {
  labelText?: string;
  ariaLabel: string;
  name: string;
  handleChange: (event: { target: { value: string | number | boolean; name: string }}) => void;
  value: string | number | boolean;
  errors?: {[key: string]: string}[];
};

export const FieldCheckbox: FC<Props> = ({
  labelText,
  ariaLabel,
  name,
  handleChange,
  value,
  errors,
}) => {
  return (
    <div className="field-checkbox__wrapper">
      <div className="field-checkbox__container">
        {value ? (
          <button
            type="button"
            className="field-checkbox__mark field-checkbox--selected"
            onClick={() => handleChange({ target: { value: false, name } })}
            aria-label={ariaLabel}
          >
            <Icon
              name="checkmark"
              className="primary margin--unset"
            />
          </button>
        ) : (
          <button
            type="button"
            className="field-checkbox__mark"
            onClick={() => handleChange({ target: { value: true, name } })}
            aria-label={ariaLabel}
          />
        )}
        {labelText && (
          <label className="field-checkbox__label">
            {labelText}
          </label>
        )}
      </div>
      {errors && <ErrorMessage fieldName={name} errors={errors} />}
    </div>
  );
};
