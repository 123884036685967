import React from 'react';
import turquoiseShirt from 'assets/images/shirts/turquoisebluishShirt.png';
import whiteShirt from 'assets/images/shirts/whiteShirt.png';
import yellowShirt from 'assets/images/shirts/yellowShirt.png';
import greyShirt from 'assets/images/shirts/greyShirt.png';

import { PLAYER_POSITION, TEAM_SCORE, TEAM_FORMATION } from '../../../../constants';

import { teamFormationMap } from './matchDetailsTeamFormationMaps';

import './MatchDetailsTeamFormationPlayingField.scss';


export const MatchDetailsTeamFormationPlayingField = ({ home, away }) => {
  return (
    <div className="playing-field__wrapper">
      <div className="playing-field__background playing-field__container">
        <Team
          teamSide={TEAM_SCORE.HOME}
          team={home?.lineup}
          formation={TEAM_FORMATION.DEFAULT}
          playerShirt={whiteShirt}
          shirtNumberColor={'green'}
          goalKeeperShirt={turquoiseShirt}
        />
        <Team
          teamSide={TEAM_SCORE.AWAY}
          team={away?.lineup}
          formation={TEAM_FORMATION.DEFAULT}
          playerShirt={greyShirt}
          shirtNumberColor={'white'}
          goalKeeperShirt={yellowShirt}
        />
      </div>
    </div>
  );
};

const Team = ({ formation, teamSide, playerShirt, goalKeeperShirt, team, shirtNumberColor }) => {
  const formationMap = teamFormationMap.get(teamSide).get(formation);

  const formationWithPlayers = team.map((player, index) => {
    return {
      ...player,
      positionOnField: formationMap.get(index),
      shirtImage: player.position !== PLAYER_POSITION.GOALKEEPER ? playerShirt : goalKeeperShirt,
      shirtNumberColor: player.position !== PLAYER_POSITION.GOALKEEPER ? shirtNumberColor : 'dark',
    };
  });

  return (
    <Formation
      formationWithPlayers={formationWithPlayers}
      shirtNumberColor={shirtNumberColor}
    />
  );
};


const Formation = ({ formationWithPlayers }) => {
  return (
    <section className="playing-field__formation">
      {formationWithPlayers.map(({ id, positionOnField, shirtImage, shirt, shirtNumberColor }) => {
        return (
          <Player
            key={id}
            style={positionOnField}
            image={shirtImage}
            shirt={shirt}
            shirtNumberColor={shirtNumberColor}
          />
        );
      })}
    </section>
  );
};

const Player = ({ style, image, shirt, shirtNumberColor }) => {
  return (
    <div
      className="playing-field__shirt-position"
      style={style}
    >
      <img className="playing-field__shirt"
        src={image}
        alt={'player shirt'}
      />
      <span className={`playing-field__shirt-number playing-field__shirt-number--${shirtNumberColor}`}>
        {shirt}
      </span>
    </div>
  );
};
