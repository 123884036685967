import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useTranslate } from 'context/translate-context';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { USER_ROUTING } from 'views/User';
import { useConfig } from 'context/config-context';
import { OddsProvider } from 'types';

import { DashboardModalController } from '../../shared/DashboardModalController';
import { ModalBlockScroll } from '../../ModalBlockScroll/ModalBlockScroll';

import { MobileModalMenuTheme } from './Theme';
import { MobileModalMenuButton } from './Button';


import './MobileModalMenu.scss';


type Props = {
  customTextClass: string;
  isDisabled?: boolean;
}

export const MobileModalMenu: FC<Props> = ({ customTextClass, isDisabled }) => {
  const { translate } = useTranslate();
  const { push } = useHistory();
  const { configuration: { provider: { name } } } = useConfig();

  return (
    <DashboardModalController>
      {({
        isExpanded,
        handleCollapse,
        handleToggleExpand,
        handleClick,
        dropdownRef,
      }) => {
        return (
          <>
            {isExpanded && (
              <ModalBlockScroll>
                <section
                  className="m-modal-menu__container"
                  onClick={handleClick}
                  ref={dropdownRef}
                >
                  <header className="m-modal-menu__header">
                    <button
                      className="m-modal-menu__close-btn"
                      onClick={handleCollapse}
                    >
                      <CloseIcon />
                    </button>
                  </header>
                  <ul className="m-modal-menu__grid">
                    <li>
                      <button
                        className="m-modal-menu__grid-item m-modal-menu__btn"
                        onClick={() => {
                          push(USER_ROUTING.getUserAccountUrl());
                          handleToggleExpand();
                        }}
                      >
                        <div className="m-modal-menu__icon">
                          <User fill="var(--SaasText01)" />
                        </div>
                        <div>
                          {translate('ACCOUNT')}
                        </div>
                      </button>
                    </li>
                  </ul>
                  {name === OddsProvider.NorskTipping && (
                    <div className="m-modal-menu__theme">
                      <MobileModalMenuTheme />
                    </div>
                  )}
                </section>
              </ModalBlockScroll>
            )}
            <MobileModalMenuButton
              isDisabled={isDisabled}
              isExpanded={isExpanded}
              handleToggleExpand={handleToggleExpand}
              customTextClass={customTextClass}
              text={translate('MORE')}
            />
          </>
        );
      }}
    </DashboardModalController>
  );
};
