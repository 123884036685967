import React from 'react';
import { IncidentState, TeamScore } from 'types';
import { getMinutesFromMatch } from 'views/shared/Incident/incidentServices';
import { Popup } from 'components';

import { LINEUP_CLASSES } from './matchDetailsLineupClassStyles';
import { lineupIncidentsMapsByStatus } from './matchDetailsLineupsMap';


export const MatchDetailsTeamLineupsPlayer = ({ teamScore, shirtNumber = 0, name, shortName, incidents = [] }) => {
  return (
    <li className={LINEUP_CLASSES.PLAYER(teamScore)}>
      <PlayerName
        incidents={incidents}
        name={name}
        shortName={shortName}
        teamScore={teamScore}
        shirtNumber={shirtNumber}
      />
      <div className={LINEUP_CLASSES.SHIRT_NUMBER(teamScore)}>
        {shirtNumber}
      </div>
    </li>
  );
};

const hanldeConfirmationByStatus = ({ confirmation, code, occurredAt }) => {
  if ([IncidentState.Cancelled, IncidentState.Confirmed, IncidentState.Tbd].includes(confirmation)) {
    return (
      <div className="match-details-line-ups__incident-container">
        {lineupIncidentsMapsByStatus.get(confirmation).get(code)}
        <div>
          {getMinutesFromMatch(occurredAt)}
        </div>
      </div>
    );
  }
  return null;
};


const PlayerName = ({ incidents, name: playerFullName, shortName, teamScore }) => {
  const handleDisplayPopupPosition = TeamScore.Home === teamScore ? {} : { position: 'top right' };

  const playerIncidents = incidents
    .map(props => {
      const { id, code, occurredAt, name, confirmation = IncidentState.Cancelled } = props;

      return (
        <Popup
          key={id}
          content={name}
          trigger={
            <>
              {hanldeConfirmationByStatus({ confirmation, code, occurredAt })}
            </>
          }
        />
      );
    });

  return (
    <div className={`match-details-line-ups__player-wrapper  ${TeamScore.Away === teamScore ? 'match-details-line-ups__player-wrapper--away' : ''}`}>
      <Popup
        {...handleDisplayPopupPosition}
        content={playerFullName}
        trigger={
          <div className={LINEUP_CLASSES.PLAYER_NAME_CONTAINER}>
            <div className={LINEUP_CLASSES.PLAYER_NAME_WRAPPER(teamScore, incidents.length)}>
              {shortName}
            </div>
          </div>
        }
      />
      {playerIncidents}
    </div>
  );
};

