import React from 'react';
import { IncidentState } from 'types';

import { getCancelledIncidentsWithClassName, getConfirmedIncidentsWithClassName } from '../../../shared/Incident/incidentCodesByStatus';


export const incidentConfirmedCodeMap = new Map();

const confirmedIncidents = getConfirmedIncidentsWithClassName('match-report-incidents__incident-code');

confirmedIncidents.forEach(({ code, icon, className }) => (
  incidentConfirmedCodeMap.set(
    code,
    <div className={className}>
      {icon}
    </div>,
  )
));


export const incidentCancelledCodeMap = new Map();

const cancelledIncidents = getCancelledIncidentsWithClassName('match-report-incidents__incident-code');

cancelledIncidents.forEach(({ code, icon, className }) => (
  incidentCancelledCodeMap.set(code,
    <div className={className}>
      {icon}
    </div>,
  )
));


export const incidentsMapsByStatus = new Map();
incidentsMapsByStatus.set(IncidentState.Confirmed, incidentConfirmedCodeMap);
incidentsMapsByStatus.set(IncidentState.Cancelled, incidentCancelledCodeMap);
