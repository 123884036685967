export enum DASHBOARD_ROUTE {
  DASHBOARD = '/dashboard',
  MATCHES = '/matches',
  DASHBOARD_MATCHES = '/dashboard/matches',
  MATCHES_ROUTE = '/dashboard/matches/:device',
  TOURNAMENT_LIST = '/tournaments',
  MOBILE = '/mobile',
  MOBILE_DASHBOARD_MATCHES = '/mobile/dashboard/matches',
}

export enum MATCH_DETAILS {
  DEFAULT = '/dashboard/matches/:eventId/season/:seasonId',
  MOBILE_DEFAULT = '/mobile/dashboard/matches/:eventId/season/:seasonId',
  TEAM_FORMATION = '/team-formation',
  SIDE_BETS = '/side-bets',
  THREE_WAY_HANDICAPS = '/three-way-handicaps',
  NUMBER_OF_GOALS = '/number-of-goals',
  HANDICAPS = '/handicaps',
  MATCH_RESULT = '/match-result',
  CORRECT_SCORE = '/correct-score',
  MATCH_REPORT = '/match-report',
  MATCH_STATS = '/match-stats',
  ANALYSIS = '/analysis',
  STATISTICS = '/statistics',
}
