import { MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper } from 'views/Dashboard/MatchDetails/SideBets/MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper';

import { DASHBOARD_ROUTE, MATCH_DETAILS } from '../../../../Routing/Routing';
import { getMatchDetailsRoutes, getMatchDetailsRouting } from '../../../../shared/MatchDetails/matchDetailsRoutingServices';
import { MobileMatchDetailsSideBetsMatchResultSection } from '../MatchResult/MobileMatchDetailsSideBetsMatchResultSection';
import { MobileMatchDetailsSideBetsAll } from '../All/MobileMatchDetailsSideBetsAll';
import { MobileMatchDetailsSideBetsCorrectScoreSection } from '../CorrectScore/MobileMatchDetailsSideBetsCorrectScoreSection';
import { MobileMatchDetailsSideBetsNumberOfGoalsSection } from '../NumberOfGoals/MobileMatchDetailsSideBetsNumberOfGoalsSection';
import { MobileMatchDetailsSideBetsThreeWayHandicap } from '../ThreeWayHandicap/MobileMatchDetailsSideBetsThreeWayHandicap';
import { RouteParam } from '../../RouteParam';


const MATCH_DETAILS_ROUTE = getMatchDetailsRoutes({
  pathname: MATCH_DETAILS.MOBILE_DEFAULT,
  dashboardPath: DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES,
});

const getDesktopMatchDetailsURLByProps = getMatchDetailsRouting(DASHBOARD_ROUTE.DASHBOARD_MATCHES);

export const getMobileMatchDetailsSideBets = (params: RouteParam) => {
  return [
    {
      path: MATCH_DETAILS_ROUTE.SIDE_BETS,
      redirectURL: getDesktopMatchDetailsURLByProps.getSideBetsURL(params),
      Component: MobileMatchDetailsSideBetsAll,
    },
    {
      path: MATCH_DETAILS_ROUTE.SIDE_BETS_MATCH_RESULT,
      redirectURL: getDesktopMatchDetailsURLByProps.getMatchResultURL(params),
      Component: MobileMatchDetailsSideBetsMatchResultSection,
      OptionalWrapper: MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper,
    },
    {
      path: MATCH_DETAILS_ROUTE.SIDE_BETS_CORRECT_SCORE,
      redirectURL: getDesktopMatchDetailsURLByProps.getCorrectScoreURL(params),
      Component: MobileMatchDetailsSideBetsCorrectScoreSection,
      OptionalWrapper: MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper,
    },
    {
      path: MATCH_DETAILS_ROUTE.SIDE_BETS_THREE_WAY_HANDICAPS,
      redirectURL: getDesktopMatchDetailsURLByProps.getThreeWayHandicapsURL(params),
      Component: MobileMatchDetailsSideBetsThreeWayHandicap,
      OptionalWrapper: MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper,
    },
    {
      path: MATCH_DETAILS_ROUTE.SIDE_BETS_NUMBER_OF_GOALS,
      redirectURL: getDesktopMatchDetailsURLByProps.getNumberOfGoalsURL(params),
      Component: MobileMatchDetailsSideBetsNumberOfGoalsSection,
      OptionalWrapper: MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper,
    },
  ];
};
