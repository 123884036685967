import { useMatchList } from 'context/match-list-context';
import React, { FC } from 'react';
import { MatchFilters } from 'types';

import { MatchListHeaderBetsSection } from '../MatchListHeaderBetsSection';

import { useListWidth } from './useListWidth';
import { transformHeaderVariantByMatchSortKey } from './common';

import './MatchListHeaderOddSection.scss';


const MatchListHeaderOddSection: FC = () => {
  const { filters } = useMatchList();
  const { order } = filters as MatchFilters;
  const getKey = transformHeaderVariantByMatchSortKey[order];

  const { elementWidth } = useListWidth('.match-list');
  const width = elementWidth ? `${elementWidth}px` : '100%';

  return (
    <section
      className={`match-list-header-odd-section-wrapper-${getKey}`}
    >
      <div
        className="match-list-header-odd-section match-list-header-odd-section__container match-list-header-odd-section__grid"
        style={{ width }}
      >
        <div />
        <MatchListHeaderBetsSection />
      </div>
    </section>
  );
};

export default MatchListHeaderOddSection;
