import React, { FC, ReactNode } from 'react';

import './MobileMatchListFilterModalSection.scss';


type Props = {
  label: string;
  children: ReactNode;
}

export const MobileMatchListFilterModalSection: FC<Props> = ({ label, children }) => {
  return (
    <section
      className="m-match-list-filter-modal-section"
    >
      <div
        className="m-match-list-filter-modal-section__label"
      >
        {label}
      </div>
      {children}
    </section>
  );
};
