import React, { FC } from 'react';
import { Popup } from 'components';


type Props = {
  customClass?: string;
  displayedName: string;
};

const MAX_DISPLAYED_NAME = 22;

export const TournamentName: FC<Props> = ({ customClass = 'tournament__name', displayedName }) => {
  const styledName = (
    <div
      aria-label={displayedName}
      className={customClass}
    >
      {displayedName}
    </div>
  );

  const text = displayedName?.length > MAX_DISPLAYED_NAME ? (
    <Popup
      basic
      content={displayedName}
      trigger={styledName}
      position="bottom right"
    />
  ) : styledName;


  return text;
};

