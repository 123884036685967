import React, { FC } from 'react';
import { EmptyElementFeedback } from 'components';
import { MatchPredictionSidebBetsOdds } from 'views/Dashboard/shared';
import { useTranslate } from 'context/translate-context';
import { Match, TeamScore } from 'types';
import { matchResultsToInt } from 'views/Dashboard/shared/Match/Prediction/matchPredictionDto';
import { isGoodBet } from 'views/Dashboard/shared/Match/Prediction/matchPredictionServices';

import { MatchSideBetsXpertPredictions } from '../MatchSideBetsXpertPredictions';
import { MatchListSideBetsOddsController } from '../MatchListSideBetsOddsController';
import { MatchBets } from '../MatchBets';

import './MatchSideBetsSection.scss';


type Props = {
  match: Match;
}

export const MatchSideBetsSection: FC<Props> = ({ match }) => {
  const { prediction } = match;
  const { translate } = useTranslate();

  return (
    <MatchListSideBetsOddsController
      match={match}
    >
      {({ foundOddListGrouping, oddsQuantity, isOddInBasket, handleToggleOddInBasket }) => {
        const foundOutcomeOdds = foundOddListGrouping?.odds?.odds || {};

        const homeOdds = foundOutcomeOdds?.home?.odds || 0;
        const drawOdds = foundOutcomeOdds?.draw?.odds || 0;
        const awayOdds = foundOutcomeOdds?.away?.odds || 0;

        const predictionsOdds = {
          [TeamScore.Home]: homeOdds,
          [TeamScore.Draw]: drawOdds,
          [TeamScore.Away]: awayOdds,
        };

        const results = matchResultsToInt(match.event.result);
        const { home, away } = results;

        const toggleOddInBasket = handleToggleOddInBasket({ odd: foundOddListGrouping?.odds });
        const componentProps = [
          {
            match,
            teamScore: TeamScore.Home,
            scoreValue: homeOdds,
            isSelected: isOddInBasket(foundOutcomeOdds?.home),
            isGood: isGoodBet(homeOdds, prediction?.home),
            handleClick: toggleOddInBasket({ oddOdds: foundOutcomeOdds?.home }),
            isMatchWinner: home > away,
          },
          {
            match,
            teamScore: TeamScore.Draw,
            scoreValue: drawOdds,
            isSelected: isOddInBasket(foundOutcomeOdds?.draw),
            isGood: isGoodBet(drawOdds, prediction?.draw),
            handleClick: toggleOddInBasket({ oddOdds: foundOutcomeOdds?.draw }),
            isMatchWinner: home === away,
          },
          {
            match,
            teamScore: TeamScore.Away,
            scoreValue: awayOdds,
            isSelected: isOddInBasket(foundOutcomeOdds?.away),
            isGood: isGoodBet(awayOdds, prediction?.away),
            handleClick: toggleOddInBasket({ oddOdds: foundOutcomeOdds?.away }),
            isMatchWinner: home < away,
          },
        ];

        return (
          <>
            <div className="match-side-bets-section">
              {oddsQuantity === 0 && !Object.values(foundOutcomeOdds).some(Boolean) ?
                <EmptyElementFeedback text="NO_ODDS_AVAILABLE" />
                : (
                  <section className="match-side-bets-section__grid">
                    <div className="match-side-bets-section--mobile">
                      {translate('ODDS')}
                    </div>
                    {componentProps.map(props => {
                      const { teamScore } = props;

                      return (
                        <MatchPredictionSidebBetsOdds key={`${match.event.id} ${teamScore}`} options={props} />
                      );
                    })}
                    {oddsQuantity ?
                      <div className="match-side-bets-section__container match-side-bets-section__container--column">
                        <MatchBets
                          match={match}
                          quantity={oddsQuantity}
                        />
                      </div>
                      : null}
                  </section>
                )}
            </div>
            <MatchSideBetsXpertPredictions
              matchStatus={match.event.status}
              odds={predictionsOdds}
              predictions={prediction}
            />
          </>
        );
      }}
    </MatchListSideBetsOddsController>
  );
};
