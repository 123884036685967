import React, { memo, FC } from 'react';

import './MatchPrediction.scss';


const MISSING_PREDICTION = '0.00';

type Props = {
  customClasses?: string;
  value?: string;
}

export const MatchPrediction: FC<Props> = memo(({ value = MISSING_PREDICTION, customClasses }) => {
  return (
    <div className={customClasses || 'match-prediction__score'}>
      {value}
    </div>
  );
});
