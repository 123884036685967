import { DASHBOARD_ROUTE, MATCH_DETAILS } from '../../../../Routing/Routing';
import { getMatchDetailsRoutes, getMatchDetailsRouting } from '../../../../shared/MatchDetails/matchDetailsRoutingServices';
import { MobileMatchDetailsStatisticTeamFormation } from '../TeamFormation/MobileMatchDetailsStatisticTeamFormation';
import { MobileMatchDetailsStatisticMatchReport } from '../MatchReport/MobileMatchDetailsStatisticMatchReport';
import { MobileMatchDetailsStatisticMatchStats } from '../MatchStats/MobileMatchDetailsStatisticMatchStats';
import { RouteParam } from '../../RouteParam';


const MATCH_DETAILS_ROUTE = getMatchDetailsRoutes({
  pathname: MATCH_DETAILS.MOBILE_DEFAULT,
  dashboardPath: DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES,
});

const getDesktopMatchDetailsURLByProps = getMatchDetailsRouting(DASHBOARD_ROUTE.DASHBOARD_MATCHES);

export const getMobileMatchDetailsStatisticRouteList = (params: RouteParam) => {
  return [
    {
      path: MATCH_DETAILS_ROUTE.STATISTICS_TEAM_FORMATION,
      redirectURL: getDesktopMatchDetailsURLByProps.getTeamFormationURL(params),
      Component: MobileMatchDetailsStatisticTeamFormation,
    },
    {
      path: MATCH_DETAILS_ROUTE.STATISTICS_MATCH_STATISTICS,
      redirectURL: getDesktopMatchDetailsURLByProps.getMatchStatsURL(params),
      Component: MobileMatchDetailsStatisticMatchStats,
    },
    {
      path: MATCH_DETAILS_ROUTE.STATISTICS_MATCH_REPORT,
      redirectURL: getDesktopMatchDetailsURLByProps.getMatchReportURL(params),
      Component: MobileMatchDetailsStatisticMatchReport,
    },
  ];
};


