import React, { FC } from 'react';
import { Control, UseFormRegister } from 'react-hook-form';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { Basket, BasketItem, BasketItemGroup } from '../../../../types';
import { useTranslate } from '../../../../context/translate-context';
import { BasketItemSingle, BasketItemCombo, BasketItemSystem } from '../BasketItem';
import { Accordion } from '../../../Accordion/Accordion';
import { GroupCombinations } from '../GroupCombinations';
import { useGroupList } from '../hooks/useGroupList';
import { mediaQuery } from '../../../../constants/mediaQuery';

import './BasketItemList.scss';


type SimpleListProps = {
  control: Control<Basket>;
  register: UseFormRegister<Basket>;
  onChange:
    ((item: BasketItem, value: any, index: number) => void) |
    ((item: BasketItem, group: BasketItemGroup, value: boolean, index: number) => void);
  onRemove: (item: BasketItem, index: number) => void;
  list: (BasketItem & { index?: number })[];
  component: typeof BasketItemSingle | typeof BasketItemCombo | typeof BasketItemSystem;
};

export const SimpleList: FC<SimpleListProps> = ({
  list,
  component: Component,
  ...props
}) => {
  return (
    <ul className="basket-list">
      {list.map((field, index) => (
        <li className="basket-list__item" key={field.odd.id}>
          <Component
            item={field}
            index={field.index || index}
            {...props}
          />
        </li>
      ))}
    </ul>
  );
};

type GroupedListProps = {
  control: Control<Basket>;
  register: UseFormRegister<Basket>;
  onChange: (item: BasketItem, group: BasketItemGroup, value: boolean, index: number) => void;
  onRemove: (item: BasketItem, index: number) => void;
  list: BasketItem[];
  component: typeof BasketItemSystem;
};

export const GroupedList: FC<GroupedListProps> = ({ list, component: Component, ...props }) => {
  const { translate } = useTranslate();
  const { groupedList } = useGroupList(list);
  const isDesktop = useMediaQuery(mediaQuery.minTabletLandscape);

  return (
    <div>
      {Object.keys(groupedList).map(key => {
        if (groupedList[key].length === 0) {
          return null;
        }

        return (
          <span key={key}>
            <div className="basket-grouped-bets">
              <Accordion
                expanded
                key={key}
                header={(
                  <span>
                    {
                      Object.keys(BasketItemGroup).indexOf(key) === -1 ?
                        translate('WITHOUT_GROUP') :
                        key === BasketItemGroup.S ? `${translate('BANKER')} (${key})` : `${translate('GROUP')} ${key}`
                    }
                    {' '}
                    ({groupedList[key].length})
                  </span>
                )}
              >
                <SimpleList
                  list={groupedList[key]}
                  component={Component}
                  {...props}
                />
              </Accordion>
            </div>
            <div className="basket-grouped-bets--simple">
              <SimpleList
                list={groupedList[key]}
                component={Component}
                {...props}
              />
            </div>
          </span>
        );
      })}
      {!isDesktop && (
        <GroupCombinations
          {...props}
          list={list}
        />
      )}
    </div>
  );
};
