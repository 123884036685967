import {
  CountryLanguage,
  CountryCurrency,
  OddsProvider,
  ProviderBasketAppConfig,
} from 'types';


const lang = window.__RUNTIME_CONFIG__.CONFIG_LANG as CountryLanguage;
const currency = window.__RUNTIME_CONFIG__.CONFIG_CURRENCY as CountryCurrency;

export const basketAppConfig: ProviderBasketAppConfig = {
  provider: {
    name: OddsProvider.NorskTipping,
    currency,
  },
  lang,
};

export const defaultBasketConfig: ProviderBasketAppConfig = {
  provider: {
    currency: CountryCurrency.International,
  },
  lang: CountryLanguage.English,
};
