import React from 'react';
import { getSideBetsGridHeaders } from 'views/Dashboard/shared/MatchDetails/SideBets/matchDetailsSideBetsServices';

import { rows } from '../sectionRows.options';
import { MatchDetailsSideBetsCorrectScore } from '../MatchDetailsSideBetsCorrectScore';


export const MatchDetailsSideBetsCorrectScoreDefault = () => {
  return (
    <MatchDetailsSideBetsCorrectScore
      ColumnsLabels={getSideBetsGridHeaders(rows)}
    />
  );
};
