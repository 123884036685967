
import { SportsName } from 'types/ExternalApps';

import { OddsProvider } from '../Odd';

import { Event } from './Match';


export enum MatchSort {
  KICK_OFF_ASC = 'startDate:asc',
  KICK_OFF_DESC = 'startDate:desc',
  TOURNAMENT_ASC = 'tournament:asc',
  TOURNAMENT_DESC = 'tournament:desc',
  BET_RATIO_ASC = 'betRatio:asc',
  BET_RATIO_DESC = 'betRatio:desc',
}

export enum MatchDisplayPrediction {
  DECIMAL_FRACTION = 'DECIMAL',
  PERCENT = 'PERCENTAGE',
  RATIO = 'RATIO',
}

export enum MatchSelectedFilter {
  Favourites = 'favourites',
  NorskTipping = 'norsk_tipping',
  SvenskaSpel = 'svenskaspel',
}

export type MatchFilters = {
  order: MatchSort;
  from: string;
  to: string;
  season: string[];
  providers: OddsProvider[];
  status: Event['status'][];
  selected: MatchSelectedFilter[];
};

export type MatchQueryFilters = {
  order: MatchSort;
  from: string;
  to: string;
  season: string[];
  sportName: SportsName[];
  status: Event['status'][];
  selected: MatchSelectedFilter[];
}
