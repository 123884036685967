import React from 'react';

import { MatchDetailsSideBetsHandleWhenUpdate } from '../MatchDetailsSideBetsHandleWhenUpdate';

import { MatchDetailsSideBetsThreeWayHandicapLive } from './Live/MatchDetailsSideBetsThreeWayHandicapLive';
import { MatchDetailsSideBetsThreeWayHandicapDefault } from './Default/MatchDetailsSideBetsThreeWayHandicapDefault';


export const MatchDetailsSideBetsThreeWayHandicapSection = () => {
  return (
    <MatchDetailsSideBetsHandleWhenUpdate
      ComponentWithLiveUpdate={MatchDetailsSideBetsThreeWayHandicapLive}
    >
      <MatchDetailsSideBetsThreeWayHandicapDefault />
    </MatchDetailsSideBetsHandleWhenUpdate>
  );
};
