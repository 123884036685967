import { FC, ReactElement, useCallback, useEffect } from 'react';
import useOnBlur from 'hooks/useOnBlur';
import { Noop } from 'types';
import { useLiveTicker } from 'context/liveTicker/live-ticker-context';


type Props = {
  children(props: {
    isExpanded: boolean;
    handleCollapse: Noop;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    handleToggleExpand: Noop;
    dropdownRef: React.MutableRefObject<null>;
  }): ReactElement;
};

export const LiveControlPanelController: FC<Props> = ({ children }) => {
  const { isControlPanelOpen, audio, setIsControlPanelOpen } = useLiveTicker();
  const handleCollapse = () => setIsControlPanelOpen(false);

  const { ref: dropdownRef, handleClick } = useOnBlur(handleCollapse);
  const handleToggleExpand = () => setIsControlPanelOpen(prevState => !prevState);

  const unlockAudio = useCallback(() => {
    if (!audio) {
      return;
    }
    audio.play();
    audio.pause();
    audio.currentTime = 0;

    document.body.removeEventListener('click', unlockAudio);
    document.body.removeEventListener('touchstart', unlockAudio);
  }, [audio]);

  useEffect(() => {
    document.body.addEventListener('click', unlockAudio);
    document.body.addEventListener('touchstart', unlockAudio);
  }, [unlockAudio]);

  const props = {
    isExpanded: isControlPanelOpen,
    handleCollapse,
    handleClick,
    handleToggleExpand,
    dropdownRef,
  };

  return children(props);
};
