import { ReactElement, FC } from 'react';
import { useMatch } from 'context/match-context';
import { Prediction } from 'types';
import { isEmpty } from 'lodash';


type Props = {
  children(props: {
    predictions: Prediction;
    arePredictionsEmpty: boolean;
    isLoading: boolean;
  }): ReactElement | null;
};

export const MatchDetailsPredictionController: FC<Props> = ({ children }) => {
  const { matchPredictions: predictions, arePredictionsLoading } = useMatch();

  const {
    prediction,
    scoringPrediction,
    correctScore,
    goalsPredictions,
    threeWayHandicap,
  } = predictions || {};

  if (!predictions) {
    return null;
  }

  const arePredictionsEmpty = () => {
    return ![
      prediction,
      scoringPrediction,
      correctScore,
      goalsPredictions,
      threeWayHandicap,
    ].some(isEmpty);
  };

  const props = {
    isLoading: arePredictionsLoading,
    predictions,
    arePredictionsEmpty: arePredictionsEmpty(),
  };

  return children(props);
};
