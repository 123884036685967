import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { DASHBOARD_ROUTE } from 'views/Dashboard/Routing/Routing';

const Auth0ProviderWithHistory: FC = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    history.replace((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={window.__RUNTIME_CONFIG__.AUTH0_ISSUER_URL || ''}
      clientId={window.__RUNTIME_CONFIG__.AUTH0_CLIENT_ID_SAAS || ''}
      redirectUri={`${window.location.origin}${DASHBOARD_ROUTE.DASHBOARD_MATCHES}`}
      audience={window.__RUNTIME_CONFIG__.AUTH0_AUDIENCE_SAAS || ''}
      scope="read:all"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
