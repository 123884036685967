import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Device } from 'types';
import { MatchListLiveController } from 'views/Dashboard/MatchList/List/Controller/MatchListLiveController';
import { MatchListVariant } from 'views/Dashboard/MatchList/MatchListVariant';
import { MatchListMissingSelectedTournamentListSection } from 'views/Dashboard/MatchList/Missing/MatchListMissingSelectedTournamentListSection';
import { DashboardSocketsController } from 'views/Dashboard/shared/DashboardSocketsController';

import '../MobileMatchList.scss';


export const MobileMatchListLive = () => {
  return (
    <DashboardSocketsController>
      <div className="m-match-list">
        <MatchListLiveController>
          {props => {
            const {
              matches,
              filters,
              areMatchesLoading,
            } = props;

            return (
              <>
                {areMatchesLoading && <Loader active inline="centered" />}
                {matches.length > 0 && (
                  <MatchListVariant
                    matches={matches}
                    order={filters.order}
                    deviceVariant={Device.Mobile}
                  />
                )}
                <MatchListMissingSelectedTournamentListSection
                  matchListLength={matches.length}
                  areMatchesLoading={areMatchesLoading}
                />
              </>
            );
          }}
        </MatchListLiveController>
      </div>
    </DashboardSocketsController>
  );
};
