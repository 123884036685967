import React, { memo, FC } from 'react';
import { MatchStatus, MatchStatusName, Match } from 'types';
import { showMatchResultWhenStateIsEqualTo } from 'views/Dashboard/MatchList/matchListServices';

import { useScore } from '../../hooks/useScore';
import { handleMatchResult } from '../../../../shared/Match/matchServices';

import './MatchResult.scss';


type Props = {
  event: Match['event'];
  home: Match['home'];
  away: Match['away'];
}

const DEFAULT_MATCH_RESULT = '-';

export const MatchResult: FC<Props> = memo(props => {
  const { event, home, away } = props;
  const { startDate, status, statusName } = event;

  const {
    homeResult: homeFirstHalfScore,
    awayResult: awayFirstHalfScore,
  } = handleMatchResult(MatchStatusName['1stHalf'])({ home, away });

  const secondHalfStatusName = MatchStatusName['1stHalf'] !== statusName ?
    statusName : MatchStatusName['2ndHalf'];

  const {
    homeResult: homeRegularScore,
    awayResult: awayRegularScore,
  } = handleMatchResult(secondHalfStatusName)({ home, away });

  const { score, handleResultClassDisplay } = useScore({
    className: 'match-result',
    homeFirstHalfScore,
    awayFirstHalfScore,
    startDate,
    homeRegularScore,
    awayRegularScore,
    status,
  });

  return (
    <div>
      <div className={`match-result__result ${handleResultClassDisplay}`}>
        {
          showMatchResultWhenStateIsEqualTo([MatchStatus.Live, MatchStatus.Finished], status) ?
            score :
            DEFAULT_MATCH_RESULT
        }
      </div>
    </div>
  );
});
