import { Match } from 'types';


export const getMissingSeasonIdList = (matches: Match[], seasonIdList: string[]) => {
  const getNotFoundSeasonId = seasonIdList.map(seasonId => {
    const isFound = matches.some(({ season }) => season.id === seasonId);

    return !isFound && seasonId;
  }).filter(Boolean) as string[];

  return getNotFoundSeasonId;
};
