import React, { FC } from 'react';
import { Match } from 'types';

import { useMatchPredictionsSideBetsOdds } from './useMatchPredictionsSideBetsOdds';
import './MatchPredictionSidebBetsOdds.scss';


const neutralStylingClass = 'match-prediction-side-bets-odds__prediction match-prediction-side-bets-odds__prediction--odds-neutral';

type Props = {
  options: {
    scoreValue: number;
    isSelected: boolean;
    handleClick: () => void;
    isGood?: boolean;
    isMatchWinner?: boolean;
    match: Match;
  };
}

export const MatchPredictionSidebBetsOdds: FC<Props> = ({ options }) => {
  const { scoreValue, isSelected, handleClick, isGood, isMatchWinner, match } = options;

  const {
    handleAllowOnClick,
    handleHasOddChanged,
    allowAction,
    isFinished,
    handleRecommendedBet,
  } = useMatchPredictionsSideBetsOdds({ scoreValue, handleClick, match, isGood });

  const displayedValue = (scoreValue || 0).toFixed(2);

  return (
    <div
      className="match-prediction-side-bets-odds__container match-prediction-side-bets-odds__container--column"
    >
      <button
        {...handleAllowOnClick}
        disabled={!allowAction}
        className={`
          ${neutralStylingClass}
          ${isSelected ? 'match-prediction-side-bets-odds__prediction--selected' : ''}
          ${handleRecommendedBet ? 'match-prediction-side-bets-odds__prediction--good' : ''}
          ${handleHasOddChanged}
          ${isMatchWinner && isFinished ? 'match-prediction-side-bets-odds__prediction--ended' : ''}
        `}
      >
        {displayedValue}
      </button>
    </div>
  );
};
