const apiErrors = {
  'ACCESS_TOKEN_ALREADY_EXISTS': 'Åtkomsttoken finns redan.',
  'ACCESS_TOKEN_NOT_EXISTS': 'Åtkomsttoken finns inte.',
  'ACTIVATION_CODE_DOESNT_EXIST': 'Koden finns inte.',
  'BIRTH_DATE_IS_IN_FUTURE': 'Kan ikke være i fremtiden',
  'BIRTH_DATE_IS_NOT_A_DATE': 'Godkänt värde är inte ett datum',
  'BIRTH_DATE_TO_FAR_IN_PAST': 'Är för långt tidigare',
  'CREDIT_CARD_NUMBER_NOT_PROVIDED': 'Kreditkortsuppgifter tillhandahålls inte',
  'DATE_IS_OUT_OF_THE_RANGE': 'Datum är utanför intervallet.',
  'DOMAIN_ALREADY_EXIST': 'Den här domänen är redan vitlistad',
  'EMAIL_REQUIRED': 'E -post krävs.',
  'FETCH_AREAS_FAILED': 'Det gick inte att hämta områden.',
  'FETCH_PACKAGES_FAILED': 'Det gick inte att hämta paket.',
  'INVALID_DOMAIN_DATA': 'Ogiltig domändata.',
  'INVALID_DOMAIN_LENGTH': 'Domännamnet är för kort eller för långt.',
  'INVALID_DOMAIN_NAME': 'Ogiltigt domänformat.',
  'INVALID_FORMAT_EXPECTED_STRING': 'Ogiltigt format, data bör tillhandahållas som en sträng',
  'INVALID_PASSWORDS_DATA': 'Ogiltiga lösenordsdata.',
  'INVALID_USER_DATA': 'Ogiltig användardata.',
  'INVALID_USER_EMAIL_FORMAT': 'Ogiltigt e -postformat.',
  'INVALID_USER_EMAIL_OR_PASSWORD': 'Ogiltigt mejl eller lösenord.',
  'INVALID_USER_EMAIL': 'Ogiltigt e -postformat.',
  'INVALID_USER_FIELD_LENGTH': 'Värdet är för långt eller för kort.',
  'INVALID_USER_PASSWORD_LENGTH': 'Lösenordet måste vara 8 - 255 tecken långt.',
  'IP_NOT_AUTHORIZED': 'Ip var inte auktoriserad.',
  'IP_NOT_DELETED': 'IP raderades inte.',
  'IP_NOT_FOUND': 'Ip finns inte.',
  'IS_REQUIRED': 'Detta fält är obligatoriskt.',
  'MISSING_EVENT_IDS': 'Event -id saknas.',
  'MISSING_NEW_PASSWORD': 'Nytt lösenord kan inte vara tomt.',
  'MISSING_ODDS_PROVIDER': 'Oddsleverantör saknas.',
  'MISSING_OLD_PASSWORD': 'Gammalt lösenord kan inte vara tomt.',
  'MISSING_PERMISSIONS': 'Behörigheter saknas för begärd data.',
  'MISSING_REPEAT_NEW_PASSWORD': 'Du måste skriva in nytt lösenord igen.',
  'MISSING_TOKEN': 'Token krävs för att kunna använda programmet.',
  'MODE_DOESNT_EXIST': 'Läget finns inte',
  'MODE_NOT_AVAILABLE': 'Läget är inte tillgängligt. Det finns inget tillräckligt antal insatser som tillhandahålls ',
  'NEW_PASSWORD_TOO_SHORT': 'Nytt lösenord måste vara minst åtta tecken långt.',
};

export default apiErrors;
