import { isEmpty } from 'lodash';
import { FC, ReactElement, memo } from 'react';
import { Match } from 'types';
import { useMatchList } from 'context/match-list-context';

import { useMatchesDefaultParams } from '../../matchListHooks';


type Props = {
  children(props: {
    matches: Match[];
    filters: Record<string, any>;
    areMatchesLoading: boolean;
  }): ReactElement;
}

export const MatchListController: FC<Props> = memo(({ children }) => {
  const {
    matches,
    isLoading,
    filters,
  } = useMatchList();

  useMatchesDefaultParams();

  if (isEmpty(filters)) {
    return null;
  }

  const props = {
    matches,
    filters,
    areMatchesLoading: isLoading,
  };

  return children(props);
});
