import React from 'react';
import { Icon } from 'semantic-ui-react';

import { useTranslate } from '../../../context/translate-context';

import './TournamentDetailsHistoricalSeasons.scss';


export const TournamentDetailsHistoricalSeasons = ({
  buttonsState,
  handleNextSeason,
  handlePreviousSeason,
  selectedHistoricalSeason,
}) => {
  const { translate } = useTranslate();
  const { isPrevDisabled, isNextDisabled } = buttonsState;

  return (
    <div className="historical-seasons-switch__container">
      <div className="historical-seasons-switch__actions">
        <button
          aria-label={translate('PREV_SEASON')}
          className="historical-seasons-switch__button"
          disabled={isPrevDisabled}
          onClick={handlePreviousSeason}
        >
          <Icon
            name="chevron left"
            className="white margin--unset"
          />
        </button>
        <div className="historical-seasons-switch__year">
          <div className="margin-right--size-s">
            {translate('SEASON')}
          </div>
          <div>
            {selectedHistoricalSeason?.year}
          </div>
        </div>
        <button
          aria-label={translate('NEXT_SEASON')}
          className="historical-seasons-switch__button"
          disabled={isNextDisabled}
          onClick={handleNextSeason}
        >
          <Icon
            name="chevron right"
            className="white margin--unset"
          />
        </button>
      </div>
    </div>
  );
};
