import { useBasketConfig } from 'context/basket/basket-config-context';
import React from 'react';
import NumberFormat from 'react-number-format';

import ErrorMessage from '../../ErrorMessage';

import './BasketFieldCurrencyInput.scss';


export const BasketFieldCurrencyInput = ({
  labelText,
  ariaLabel,
  name,
  onChange,
  value,
  type = 'text',
  errors = [],
  ...props
}) => {
  const { configuration: { provider: { currency } } } = useBasketConfig();
  let currValue = value;

  return (
    <div className="field-text-input__container">
      {labelText && <label className="field-text-input__label">{labelText}</label>}
      <NumberFormat
        thousandSeparator
        className="field-text-input__input"
        aria-label={ariaLabel}
        name={name}
        onChange={() => {
          onChange(currValue);
        }}
        onValueChange={value => {
          currValue = value.floatValue;
        }}
        value={value}
        type={type}
        suffix={` ${currency}`}
        {...props}
      />
      <ErrorMessage fieldName={name} errors={errors} />
    </div>
  );
};
