import React, { FC } from 'react';
import { Season } from 'types';

import { MatchVariantKickOffCountry } from '../../MatchVariantKickOffCountry';
import { TOURNAMENT_ROUTING } from '../../../../Tournament/Routing/TournamentRouting';

import { MatchListMissingSelectedTournamentFeedback } from './Feedback';

import './MatchListMissingSelectedTournament.scss';
import './MatchListMissingSelectedTournamentSortVariantKickOff.scss';


type Props = {
  season: Season;
}

export const MatchListMissingSelectedTournamentSortVariantKickOff: FC<Props> = ({ season }) => {
  return (
    <li className="match-list-missing-selected-tournament__container match-list-missing-selected-tournament-variant-kick-off__container">
      <MatchVariantKickOffCountry
        season={season}
        getRedirectURL={TOURNAMENT_ROUTING.getDefaultTournamentURL}
      />
      <MatchListMissingSelectedTournamentFeedback />
    </li>
  );
};
