
import { FC, ReactElement, useCallback } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import isNumber from 'lodash/isNumber';

import { Basket, BasketItem } from '../../../../../types';
import { useBasket } from '../../../../../context/basket/basket-context';


type Props = {
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  children(props: {
    onItemBetUpdate: (item: BasketItem, value: any, index: number) => void;
  }): ReactElement;
}

export const SingleModeTabController: FC<Props> = ({ children, setValue, fields }) => {
  const { updateItem } = useBasket();

  const onItemBetUpdate = useCallback((item, value = 0, index) => {
    const newValue = isNumber(value) ? Math.abs(value) : 0;

    if (newValue !== fields.stakePerBet) {
      setValue('stakePerBet', '-');
    }

    updateItem<{ betValue: BasketItem['betValue'] }>(item.odd.id, {
      betValue: newValue,
    });

    if (value < 0) {
      setValue(`items.${index}.betValue` as 'items.0.betValue', newValue.toFixed(2));
    }
  }, [fields.stakePerBet, setValue, updateItem]);

  const props = {
    onItemBetUpdate,
  };

  return children(props);
};
