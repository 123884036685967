import { OddGroupingType, BasketItem } from 'types';

import { FindElementProps } from './FindElement';

export const updateBasketItem = (findElement: FindElementProps) => (oddList: OddGroupingType[]) => (item: BasketItem): BasketItem => {
  const {
    foundEvent,
    foundOddOdd,
  } = findElement(oddList, item);

  if (foundEvent) {
    const {
      event: {
        id: eventId,
        statusName: eventStatusName,
        status: eventStatus,
        name: eventName,
      },
    } = foundEvent;

    return {
      ...item,
      eventId,
      eventName,
      eventStatus,
      eventStatusName,
      odd: foundOddOdd || item.odd,
    };
  }

  return item;
};
