import React, { FC } from 'react';
import { BasketItem, BasketMode } from 'types';

import { useTranslate } from '../../../../context/translate-context';

import { handleDisplayBetName, handleDisplayOddType } from './services';


type Props = {
  basketItem: BasketItem;
  mode?: BasketMode;
};

export const BasketItemOddInfo: FC<Props> = ({ basketItem, mode }) => {
  const { translate } = useTranslate();

  const handleModifierWhenSystemModeIsPassed = mode === BasketMode.System ? 'basket-item__header-section--system' : '';

  return (
    <>
      <div className={`basket-item__header-section ${handleModifierWhenSystemModeIsPassed}`}>
        <div className="basket-item__odd-type">
          {handleDisplayOddType(translate)(basketItem)}
        </div>
        <div className="basket-item__bet-name">
          {handleDisplayBetName(basketItem)}
        </div>
      </div>
    </>
  );
};
