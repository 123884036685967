import { getMatchDetailsRouting } from '../../../shared/MatchDetails/matchDetailsRoutingServices';
import { DASHBOARD_ROUTE } from '../../../Routing/Routing';
import { RouteParam } from '../RouteParam';
import { MATCH_DETAILS_SECTION_KEY } from '../../../../../constants';
import { TabLinkListProps } from '../../../../shared/Routing/TabLink';


type Props = ({ hasAnyLineup }: { hasAnyLineup: boolean}) => TabLinkListProps<RouteParam>;

const MATCH_DETAILS_ROUTING = getMatchDetailsRouting(DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES);

export const getMatchDetailsStatisticTabLinkList: Props = ({ hasAnyLineup }) => params => ({ translate, search }) => ([
  {
    text: translate(MATCH_DETAILS_SECTION_KEY.TEAM_FORMATION),
    to: `${MATCH_DETAILS_ROUTING.getTeamFormationURL(params)}${search}`,
    exact: true,
    isDisabled: !hasAnyLineup,
  },
  {
    text: translate(MATCH_DETAILS_SECTION_KEY.MATCH_STATS),
    to: `${MATCH_DETAILS_ROUTING.getMatchStatsURL(params)}${search}`,
    exact: true,
  },
  {
    text: translate(MATCH_DETAILS_SECTION_KEY.MATCH_REPORT),
    to: `${MATCH_DETAILS_ROUTING.getMatchReportURL(params)}${search}`,
    exact: true,
  },
]);
