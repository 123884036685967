import { MatchStatus } from 'types';

import { MatchListLiveUpdate } from './LiveUpdate/MatchListLiveUpdate';
import { MatchListStatic } from './Static/MatchListStatic';


export const matchListMap = new Map();

matchListMap.set(MatchStatus.Live, MatchListLiveUpdate);
matchListMap.set(MatchStatus.Scheduled, MatchListStatic);
