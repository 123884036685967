const liveTicker = {
  ARIA_DECREMENT_TIME_BTN: 'Knapp för att minska tiden',
  ARIA_INCREMENT_TIME_BTN: 'Öka tidsknapp',
  SET_DISPLAY_TIME: 'Ställ in visningstid',
  MATCH_STATUS: 'Matchstatus',
  CARDS: 'Kort',
  INCIDENTS_IN_QUEUE: 'Incidenter i kö',
  LIVE_TICKER_SETTINGS: 'Live ticker-inställningar',
};

export default liveTicker;
