export const isPreciseGoalEqualtoGoalsFactor = (preciseGoal, goalsFactor) => {
  return goalsFactor.some(({ home, away }) => preciseGoal.home === home && preciseGoal.away === away);
};

const uniqueGoalsFactor = goals => {
  const [firstPreciseGoal, secondPreciseGoal] = goals;

  if (firstPreciseGoal.home === secondPreciseGoal.home && firstPreciseGoal.away === secondPreciseGoal.away) {
    return [firstPreciseGoal];
  }
  return goals;
};

export const goalsFactorToCorrectScore = (goalsFactor = {}) => {
  const { home, away } = goalsFactor;

  const preciseGoals = [
    { home: Math.round(home), away: Math.round(away) },
    { home: Math.trunc(home), away: Math.trunc(away) },
  ];

  return uniqueGoalsFactor(preciseGoals);
};


