import React from 'react';

import { MatchDetailsSideBetsHandleWhenUpdate } from '../MatchDetailsSideBetsHandleWhenUpdate';

import { MatchDetailsSideBetsCorrectScoreDefault } from './Default/MatchDetailsSideBetsCorrectScoreDefault';
import { MatchDetailsSideBetsCorrectScoreLive } from './Live/MatchDetailsSideBetsCorrectScoreLive';


export const MatchDetailsSideBetsCorrectScoreSection = () => {
  return (
    <MatchDetailsSideBetsHandleWhenUpdate
      ComponentWithLiveUpdate={MatchDetailsSideBetsCorrectScoreLive}
    >
      <MatchDetailsSideBetsCorrectScoreDefault />
    </MatchDetailsSideBetsHandleWhenUpdate>
  );
};
