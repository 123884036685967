import { Popup } from 'components';
import React, { memo, FC } from 'react';
import { TeamDetails, TeamScore } from 'types';
import { Badge } from 'views/Dashboard/shared/Badge/Badge';


import './LiveMatchTeam.scss';


type Props = {
  teamDetails: TeamDetails;
  whoPlays: string;
}

const NAME_MAX_LENGTH = 20;

export const LiveMatchTeam: FC<Props> = memo(({ teamDetails = {}, whoPlays = TeamScore.Home }) => {
  const { badgeUrl: badgeURL, name = '', displayedName = '', shortName = '' } = teamDetails;
  const isHome = whoPlays === TeamScore.Home;
  const handleDisplayPopupPosition = isHome ? {} : { position: 'top right' };

  const handleDisplayName = displayedName || shortName;

  const Name = (
    <span className={'live-match-team__short-name live-match-team--tournament-variant'}>
      {displayedName || shortName}
    </span>
  );

  return (
    <div className={`live-match-team live-match-team--${whoPlays}`}>
      <Badge
        customStyles={`live-match-team__badge live-match-team--${whoPlays}`}
        badgeURL={badgeURL}
        alt={`${name} badge`}
      />
      {handleDisplayName.length > NAME_MAX_LENGTH ? (
        <Popup
          {...handleDisplayPopupPosition}
          content={handleDisplayName}
          trigger={Name}
        />
      ) : Name}
    </div>
  );
});
