import React, { FC, memo } from 'react';

import './LiveTickerProgressBar.scss';


type Props = {
  liveTickerDisplayLimit: number;
  progress: number;
}

export const LiveTickerProgressBar: FC<Props> = memo(({ progress, liveTickerDisplayLimit }) => {
  return (
    <div className="live-ticker-progress-bar">
      <div
        className="live-ticker-progress-bar__container"
        style={{ width: `${(progress / liveTickerDisplayLimit) * 100}%` }}
      />
    </div>
  );
});
