import { MatchStatus } from 'types';

import { MobileMatchListLive } from './Live/MobileMatchListLive';
import { MobileMatchListStatic } from './Static/MobileMatchListStatic';


export const mobileMatchListMap = new Map();

mobileMatchListMap.set(MatchStatus.Live, MobileMatchListLive);
mobileMatchListMap.set(MatchStatus.Scheduled, MobileMatchListStatic);
