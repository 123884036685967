import { FC, ReactElement } from 'react';
import { useArea } from 'context/area-context';
import { Area } from 'types';


type Props = {
  children(props: {
    areaList: Area[];
  }): ReactElement;
}

export const TournamentAreaFavouriteListController: FC<Props> = ({ children }) => {
  const { areas } = useArea();
  const flattenAreas = areas.flatMap(area => {
    const areaChildren = area?.areas || [];

    return [area, ...areaChildren];
  });

  const props = {
    areaList: flattenAreas,
  };

  return children(props);
};
