import React, { FC, ReactElement } from 'react';
import { Area, Maybe } from 'types';

import { TournamentArea } from './TournamentArea';


type Props = {
  handleSelectArea: (area: Area) => void;
  areas: Area[];
  selectedArea: Maybe<Area>;
  children?: ReactElement;
};

export const TournamentAreaList: FC<Props> = ({
  areas,
  handleSelectArea,
  selectedArea,
  children,
}) => {
  return (
    <ul
      className="tournament-list"
    >
      <>
        {areas.map(area => {
          const { id, name, badgeUrl } = area;
          const props = { id, name, area, badgeUrl };

          return (
            <TournamentArea
              {...props}
              key={`country ${props.id}`}
              handleSelectArea={handleSelectArea}
              selectedArea={selectedArea}
            >
              {selectedArea?.id === props.id && children}
            </TournamentArea>
          );
        })}
      </>
    </ul>
  );
};
