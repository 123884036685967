import React from 'react';

import { MatchDetailsSideBetsNumberOfGoalsDefault } from '../../../../MatchDetails/SideBets/NumberOfGoals/Default/MatchDetailsSideBetsNumberOfGoalsDefault';


export const MobileMatchDetailsSideBetsNumberOfGoalsSection = () => {
  return (
    <MatchDetailsSideBetsNumberOfGoalsDefault />
  );
};
