import React, { FC } from 'react';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Basket, BasketItem } from 'types';

import { BasketItemSingle } from '../../BasketItem';
import { SimpleList } from '../../BasketItemList';

import { SingleModeTabController } from './SingleModeTabController';


type Props = {
  control: Control<Basket>;
  register: UseFormRegister<Basket>;
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  onItemRemove: (item: BasketItem, index: number) => void;
}

export const SingleModeTab: FC<Props> = ({
  control,
  register,
  setValue,
  fields,
  onItemRemove,
}) => {
  return (
    <SingleModeTabController
      setValue={setValue}
      fields={fields}
    >
      {({ onItemBetUpdate }) => (
        <SimpleList
          list={fields.items}
          control={control}
          register={register}
          component={BasketItemSingle}
          onChange={onItemBetUpdate}
          onRemove={onItemRemove}
        />
      )}
    </SingleModeTabController>
  );
};
