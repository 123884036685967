import React, { FC } from 'react';
import { useTranslate } from 'context/translate-context';

import './LiveTickerOverlay.scss';


type Props = {
  incidentsRemaining: number;
}

export const LiveTickerOverlayQueueInfo: FC<Props>  = ({ incidentsRemaining }) => {
  const { translate } = useTranslate();

  return (
    <div className="live-ticker-overlay">
      <div className="live-ticker-overlay__container live-ticker-overlay__container--default">
        <div className="live-ticker-overlay__text">
          {translate('INCIDENTS_IN_QUEUE')}
        </div>
        <div className="live-ticker-overlay__counter">
          {incidentsRemaining}
        </div>
      </div>
    </div>
  );
};
