import { Popup } from 'components';
import React, { memo, FC } from 'react';
import {  GameTeam, GameTeamPosition } from 'types';
import { useTranslate } from 'context/translate-context';

import { Badge } from '../../../shared/Badge/Badge';

import './MatchTeam.scss';


type Props = {
  team: GameTeam;
  type: GameTeam['type'];
}

export const MatchTeam: FC<Props> = memo(({ team, type }) => {
  const { translate } = useTranslate();
  const { teamDetails } = team;
  const { badgeUrl: badgeURL = '', name = '', displayedName = '', shortName = '' } = teamDetails || {};
  const handleDisplayPopupPosition = type === GameTeamPosition.Home ? {} :
    { position: 'top right' };

  return (
    <div className={`match-team match-team--${type}`}>
      <Badge
        customStyles={`match-team__badge match-team--${type}`}
        badgeURL={badgeURL}
        alt={`${name} badge`}
      />
      <Popup
        {...handleDisplayPopupPosition}
        content={name}
        trigger={
          <span className={'match-team__short-name match-team--tournament-variant'}>
            {displayedName || shortName || translate('TBA')}
          </span>
        }
      />
    </div>
  );
});
