import React from 'react';
import { formatDate, getToday, getYesterday } from 'utils';
import { MatchStatus } from 'types';
import { EmptyListFeedback } from 'components';
import ErrorBoundaryWithRollbar from 'views/ErrorBoundaryWithRollbar';

import { useMatchesFilters } from '../useMatchesFilters';

import { matchListMap } from './matchListMap';

import './MatchList.scss';


export const MatchList = () => {
  const { filters: { from } } = useMatchesFilters();

  const today = getToday();
  const yesterday = getYesterday();

  const handleListDisplayBySettingStatus = [yesterday, today].includes(formatDate(from)) ? MatchStatus.Live : MatchStatus.Scheduled;
  const List = matchListMap.get(handleListDisplayBySettingStatus);

  return (
    <ErrorBoundaryWithRollbar
      FallbackComponent={
        <EmptyListFeedback text="COULD_NOT_LOAD_CONTENT" />
      }
    >
      <List />
    </ErrorBoundaryWithRollbar>
  );
};
