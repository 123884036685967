import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useMatch } from 'context/match-context';
import { Match, Maybe, Noop, GameTeamPosition } from 'types';
import { addQuery } from 'utils';
import MatchTeam from 'views/Dashboard/MatchList/Match/Team';
import { ReactComponent as Shirts } from 'assets/icons/shirts.svg';

import { MatchVariantKickOffCountry } from '../../../../MatchList/MatchVariantKickOffCountry';
import { MatchVariantKickOffMatchInfo } from '../../../../MatchList/MatchVariantKickOffMatchInfo';
import { MatchSideBetsSection } from '../../../../MatchList/Match/MatchSideBetsSection';
import { MOBILE_DASHBOARD_ROUTING } from '../../../../Routing/MobileDashboardRouting';
import { MOBILE_TOURNAMENT_ROUTING } from '../../../../../Tournament/Mobile/Routing/MobileTournamentRouting';

import './MatchVariantKickOff.scss';
import '../../MatchListElement.scss';


type Props = {
  match: Match;
  handleScrollRef?: Maybe<Noop>;
};

export const MobileMatchVariantKickOff: FC<Props> = ({ match, handleScrollRef = null }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { setSelectedMatch } = useMatch();

  const { event, season, home, away } = match;

  return (
    <li
      ref={handleScrollRef}
      key={event.id}
      className="match-variant-kick-off match-list-element__wrapper match-list-element__bg match-variant-kick-off__wrapper match-variant-kick-off__container--border"
    >
      <div className="match-variant-kick-off__grid">
        <MatchVariantKickOffCountry
          season={season}
          getRedirectURL={MOBILE_TOURNAMENT_ROUTING.getDefaultTournamentURL}
        />
        <div
          className="match-variant-kick-off__match-details-grid"
          onClick={() => {
            setSelectedMatch(match);
            push({
              pathname: MOBILE_DASHBOARD_ROUTING.getTeamFormationURL({
                eventId: event.id, seasonId: season.id,
              }),
              search: addQuery(search, {}),
            });
          }}
        >
          <MatchVariantKickOffMatchInfo event={event} />
          <section className="match-list-element__team-grid">
            <MatchTeam.Mobile
              team={home}
              statusName={event.statusName}
              status={event.status}
              type={GameTeamPosition.Home}
            />
            <MatchTeam.Mobile
              team={away}
              statusName={event.statusName}
              status={event.status}
              type={GameTeamPosition.Away}
            />
          </section>
          <div className="match-variant-kick-off__shirt-container">
            <div className="match-variant-kick-off__icon-shirt">
              <Shirts fill="var(--SaasText02)" width="20px" height="16px" />
            </div>
          </div>
        </div>
        <div className="match-list-element__bets">
          <MatchSideBetsSection match={match} />
        </div>
      </div>
    </li>
  );
};
