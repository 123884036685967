export enum Gender {
  Male = 'male',
  Female = 'female',
}
export type User = {
  birthDate: string;
  email: string;
  givenName: string;
  gender: Gender;
  googleUser: boolean;
  id: number;
  familyName: string;
  verified: boolean;
};
