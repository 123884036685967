import React, { FC, ReactElement, useMemo } from 'react';
import { Basket, BasketMode } from 'types';

import { SingleModeFooterResultsController } from './SingleModeFooterResultsController';
import { ComboModeFooterResultsController } from './ComboModeFooterResultsController';
import { SystemModeFooterResultsController } from './SystemModeFooterResultsController';


type ChildrenProps = {
  minPrize: string;
  maxPrize: string;
  totalOdds: string;
};


type Props = {
  fields: Basket;
  children(props: ChildrenProps): ReactElement;
};


export const ModeFooterResults: FC<Props> = ({ fields, children }) => {
  const Controller = useMemo(() => {
    const controllers = {
      [BasketMode.Single]: SingleModeFooterResultsController,
      [BasketMode.Combo]: ComboModeFooterResultsController,
      [BasketMode.System]: SystemModeFooterResultsController,
    };

    return controllers[fields.mode];
  }, [fields.mode]);


  return (
    <Controller
      fields={fields}
    >
      {(props: ChildrenProps) => children(props)}
    </Controller>
  );
};
