const common = {
  FOOTBALL: 'Fotball',
  COULD_NOT_LOAD_CONTENT: 'Kunne ikke laste innholdet',
  SHOW_ALL: 'Vis alt',
  NO_LIVE_MATCHES_AVAILABLE: 'Ingen live kamper tilgjengelig',
  NO_LIVE_INCIDENTS_AVAILABLE: 'Ingen live kamphendelser er tilgjengelige',
  SETTINGS: 'Innstillinger',
  LIVE_TICKER: 'Live ticker',
  MORE: 'Mer',
  LIGHT_THEME: 'Lyst tema',
  DARK_THEME: 'Mørkt tema',
};

export default common;
