import React from 'react';

import ErrorMessage from '../../ErrorMessage';

import './FieldTextInput.scss';


export const FieldTextInput = ({
  labelText,
  ariaLabel,
  name,
  onChange,
  value,
  type = 'text',
  errors,
}) => {
  return (
    <div className="field-text-input__container">
      <label className="field-text-input__label">{labelText}</label>
      <input
        className="field-text-input__input"
        aria-label={ariaLabel}
        name={name}
        onChange={onChange}
        value={value}
        type={type}
      />
      <ErrorMessage fieldName={name} errors={errors} />
    </div>
  );
};
