import React from 'react';

import { useMobileNavigationList } from './Link/useMobileNavigationList';


import './MobileNavigation.scss';


export const MobileNavigation = () => {
  const navigationLinkList = useMobileNavigationList();

  return (
    <nav className="m-navigation">
      <div className="m-navigation__container">
        <ul className={`m-navigation__list m-navigation__grid m-navigation__grid--col-${navigationLinkList.length}`}>
          {navigationLinkList.map(({ Component, customStyleClass }, index) => {
            return (
              <li key={`Mobile navigation element ${index}`}className={customStyleClass}>
                <Component />
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};
