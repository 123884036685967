import { sectionsConnectedToPredictions } from '../matchDetailsSideBetsSectionsOddTypes';
import { Prediction, Maybe, OddsType } from '../../../../../types';


export const getUniqueOddTypes = (prediction: Maybe<Prediction>): OddsType[] => {
  const allOddsTypes = Object.values(OddsType) as OddsType[];

  if (!prediction) {
    return allOddsTypes;
  }
  const matchPredictionKeys = Object.keys(prediction);

  const getAvailablePredictions = sectionsConnectedToPredictions.filter(({ predictionKeys }) => {
    return predictionKeys.some(key => matchPredictionKeys.includes(key));
  }).map(({ oddType }) => oddType);

  const unique = allOddsTypes.filter(odd => !getAvailablePredictions.includes(odd));

  return unique;
};
