
import { FC, ReactElement, useMemo, useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import Big from 'big.js';

import { Maybe, Basket, BasketItem } from '../../../../../types';
import { useBasket } from '../../../../../context/basket/basket-context';


type Props = {
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  children(props: {
    onStakePerBetChange: (value: number | string) => void;
    onTotalStakeChange: (value: number | string) => void;
  }): ReactElement;
}

export const SingleModeFooterController: FC<Props> = ({ children, setValue, fields }) => {
  const { items: basketItems, setItems } = useBasket();

  const stakePerBet = useMemo(() => {
    let isSameEverywhere = true;
    let value: Maybe<number> = 0;

    fields.items.forEach((item: BasketItem) => {
      const { betValue } = item;
      const handleBetValue = typeof betValue === 'number' ? betValue : parseFloat(betValue);

      if (!value) {
        value = handleBetValue;
      } else {
        if (value !== handleBetValue) {
          isSameEverywhere = false;
        }
      }
    });

    return isSameEverywhere ? value?.toFixed(2) : '-';
  }, [fields.items]);

  const totalStake = useMemo(() => {
    const totalStake = fields.items.reduce((acc, i) => acc.add(i?.betValue || 0), new Big(0)).toNumber();

    return totalStake;
  }, [fields.items]);

  const updateAllBetValues = useCallback((betValue: number) => {
    // Update field inputs
    fields.items.forEach((i, idx) => {
      setValue(`items.${idx}.betValue` as 'items.0.betValue', betValue.toFixed(2));
    });

    // Update list in context
    const newItems = basketItems.map(item => ({
      ...item,
      betValue: betValue.toFixed(2),
    }));
    setItems(newItems);
  }, [basketItems, fields.items, setValue, setItems]);


  const onStakePerBetChange = useCallback((value = 0) => {
    if (value === undefined) {
      return;
    }
    const betValue = new Big(value || 0).abs();

    updateAllBetValues(betValue.toNumber());

    // Remove - from input
    if (value < 0) {
      setValue('stakePerBet', betValue.toFixed(2));
    }
  }, [setValue, updateAllBetValues]);

  const onTotalStakeChange = useCallback((value = 0) => {
    if (value === undefined) {
      return;
    }

    const totalValue = new Big(value).abs();
    const betValue =  totalValue.div(fields.items.length);

    updateAllBetValues(betValue.toNumber());

    // Remove - from input
    if (value < 0) {
      setValue('totalStake', totalValue.toFixed(2));
    }
  }, [fields.items, setValue, updateAllBetValues]);

  useEffect(() => {
    setValue(
      'stakePerBet',
      stakePerBet,
    );
  }, [setValue, stakePerBet]);

  useEffect(() => {
    setValue(
      'totalStake',
      totalStake.toFixed(2),
    );
  }, [setValue, totalStake]);

  const props = {
    onStakePerBetChange,
    onTotalStakeChange,
  };

  return children(props);
};
