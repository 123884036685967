import { FC, ReactElement, ReactNode, useEffect, memo } from 'react';

import { useMatchList } from '../../../context/match-list-context';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';


type Props = {
  children(props: {
    isLoading: boolean;
    handleScrollRef: ReactNode;
  }): ReactElement;
}

export const MatchListScrollController: FC<Props> = memo(({ children }) => {
  const {
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useMatchList();

  const { handleScrollRef, canFetch, setCanFetch } = useInfiniteScroll(hasNextPage, isFetching);

  useEffect(() => {
    if (!isFetching && canFetch) {
      setCanFetch(false);
      fetchNextPage();
    }
  }, [fetchNextPage, canFetch, setCanFetch, isFetching]);

  useEffect(() => {
    setCanFetch(!hasNextPage);
  }, [setCanFetch, hasNextPage]);

  const props = {
    isLoading: isFetching,
    handleScrollRef,
  };

  return children(props);
});
