import { useMatchList } from 'context/match-list-context';
import { FC, ReactElement } from 'react';
import { MatchDisplayPrediction, Noop } from 'types';


type Props = {
  handleCollapse?: Noop;
  children(props: {
    displayPrediction: MatchDisplayPrediction;
    handleSetDisplay: (label: MatchDisplayPrediction) => void;
    availableLabels: {label: MatchDisplayPrediction}[];
    list: {label: MatchDisplayPrediction}[];
  }): ReactElement;
}

const displayLabels = [
  { label: MatchDisplayPrediction.DECIMAL_FRACTION },
  { label: MatchDisplayPrediction.PERCENT },
  { label: MatchDisplayPrediction.RATIO },
];

export const MatchListFilterPredictionDisplayController: FC<Props> = ({ handleCollapse, children }) => {
  const {
    displayPrediction,
    setDisplayPrediction,
  } = useMatchList();

  const handleSetDisplay = (label: MatchDisplayPrediction) => {
    setDisplayPrediction(label);
    handleCollapse && handleCollapse();
  };

  const availableLabels = displayLabels.filter(({ label }) => label !== displayPrediction);

  const props = {
    displayPrediction,
    handleSetDisplay,
    availableLabels,
    list: displayLabels,
  };

  return children(props);
};
