import { TEAM_SCORE, TEAM_FORMATION } from '../../../../constants';


export const defaultHomeFormationMap = new Map();
// GOALKEEPER
defaultHomeFormationMap.set(0, { left: '22%', top: '21%' });
// DEFENDER
defaultHomeFormationMap.set(1, { left: '48%', top: '0%' });
defaultHomeFormationMap.set(2, { left: '44%', top: '20%' });
defaultHomeFormationMap.set(3, { left: '41%', top: '37%' });
defaultHomeFormationMap.set(4, { left: '37%', top: '57%' });
// MIDFIELDER
defaultHomeFormationMap.set(5, { left: '66%', top: '0%' });
defaultHomeFormationMap.set(6, { left: '64%', top: '19%' });
defaultHomeFormationMap.set(7, { left: '62%', top: '37%' });
defaultHomeFormationMap.set(8, { left: '60%', top: '57%' });
// ATTACKER
defaultHomeFormationMap.set(9, { left: '83%', top: '12%' });
defaultHomeFormationMap.set(10, { left: '82%', top: '36%' });

export const defaulAwayFormationMap = new Map();
// GOALKEEPER
defaulAwayFormationMap.set(0, { right: '22%', top: '21%' });
// DEFENDER
defaulAwayFormationMap.set(1, { right: '48%', top: '0%' });
defaulAwayFormationMap.set(2, { right: '44%', top: '20%' });
defaulAwayFormationMap.set(3, { right: '41%', top: '37%' });
defaulAwayFormationMap.set(4, { right: '37%', top: '57%' });
// MIDFIELDER
defaulAwayFormationMap.set(5, { right: '66%', top: '0%' });
defaulAwayFormationMap.set(6, { right: '64%', top: '19%' });
defaulAwayFormationMap.set(7, { right: '62%', top: '37%' });
defaulAwayFormationMap.set(8, { right: '60%', top: '57%' });
// ATTACKER
defaulAwayFormationMap.set(9, { right: '83%', top: '12%' });
defaulAwayFormationMap.set(10, { right: '82%', top: '36%' });


const homeFormationMap = new Map();
homeFormationMap.set(TEAM_FORMATION.DEFAULT, defaultHomeFormationMap);

const awayFormationMap = new Map();
awayFormationMap.set(TEAM_FORMATION.DEFAULT, defaulAwayFormationMap);


export const teamFormationMap = new Map();
teamFormationMap.set(TEAM_SCORE.HOME, homeFormationMap);
teamFormationMap.set(TEAM_SCORE.AWAY, awayFormationMap);

