import { differenceBy } from 'lodash';
import { Match } from 'types';

import { usePrevious } from '../../hooks/usePrevious';

import { useUpdateLiveIncidentList } from './useHandleUpdate';
import { getFlattenedIncidentList } from './services';
import { useLiveMatchListContext } from './live-match-list-context';


export const useCompareLiveMatchListFromSockets = (matchesSockets: Match[]) => {
  const previousProps = usePrevious(matchesSockets);

  const { handleIncidentListInLiveFeed } = useLiveMatchListContext();
  const { handleUpdate } = useUpdateLiveIncidentList();

  const prevState = getFlattenedIncidentList(previousProps);
  const latestState = getFlattenedIncidentList(matchesSockets);

  const isNewIncidentReceived = Boolean(differenceBy(latestState, prevState, 'id').length);

  if (isNewIncidentReceived) {
    return handleIncidentListInLiveFeed(latestState);
  }

  return handleUpdate({ prevState, latestState, stateShouldBeUpdated: isNewIncidentReceived });
};
