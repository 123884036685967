import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useConfig } from 'context/config-context';
import { isEmpty, omitBy } from 'lodash';
import { useFavouriteList } from 'context/favourite/favourites-context';
import { MatchSelectedFilter } from 'types';

import { useMatch } from '../../../context/match-context';
import { addQuery, parseQuery } from '../../../utils';
import { MATCH_DETAILS_ROUTING } from '../MatchDetails/Routing/MatchDetailsRouting';
import { handleNewList } from '../shared/services';
import { DASHBOARD_ROUTING } from '..';

import { useMatchesFilters } from './useMatchesFilters';


export const useMatchesDefaultParams = () => {
  const { search } = useLocation();
  const { filters, DEFAULT_FILTERS } = useMatchesFilters();
  const { push } = useHistory();
  const { configuration } = useConfig();
  const { filters: configFilters } = configuration;

  // load default filters
  const filtersToLoadIntoURL = { ...DEFAULT_FILTERS, ...configFilters, ...omitBy(filters, isEmpty) };

  const loadDefaultFiltersToURL = () => {
    return push({ search: addQuery(search, filtersToLoadIntoURL) });
  };

  const memoLoadDefaultFilters = useCallback(loadDefaultFiltersToURL, []);

  useEffect(() => {
    memoLoadDefaultFilters();
  }, [memoLoadDefaultFilters]);

  /*
    handle setting selected favourites
    When there's a flag with favourites in selected filters then get all seasonIds
    and if they exist then add seasonsIds into the URL
  */

  const parsedQuery = parseQuery(search);
  const { selected = [] } = parsedQuery;

  const { favourites } = useFavouriteList();
  const seasonsIds = favourites.map(favourite => favourite.seasonId);

  const isFavouritesSelectedInURL = selected.includes(MatchSelectedFilter.Favourites) && seasonsIds.length;

  const loadDefaultFilter = () => {
    if (filters.selected.includes(MatchSelectedFilter.Favourites) && seasonsIds.length) {
      return push({
        search: addQuery(search, {
          ...filters,
          season: seasonsIds,
        }),
      });
    }
  };

  const memoLoadDefaultFavouriteFilterMemo = useCallback(loadDefaultFilter, [isFavouritesSelectedInURL]);

  useEffect(() => {
    memoLoadDefaultFavouriteFilterMemo();
  }, [memoLoadDefaultFavouriteFilterMemo]);
};


export const useMatchesHandlers = () => {
  const {
    selectedMatch,
    setSelectedMatch,
  } = useMatch();
  const { push } = useHistory();
  const { search } = useLocation();

  const handleOddsSideBets = match => {
    const {
      event: { id: eventId },
      season: { id: seasonId },
    } = match;

    push({
      pathname: MATCH_DETAILS_ROUTING.getSideBetsURL({ eventId, seasonId }),
      search: addQuery(search),
    });

    return setSelectedMatch(match);
  };

  const handleSetStatus = statusList => {
    const parsedQuery = parseQuery(search);
    const { status: queryStatus = [] } = parsedQuery;

    const getStatusList = handleNewList({ oldList: queryStatus, list: statusList });

    return push({
      search: addQuery(search, { status: getStatusList }),
    });
  };

  const isSelectedStatusActive = status => {
    const parsedQuery = parseQuery(search);
    const { status: queryStatus = [] } = parsedQuery;

    if (status.length === 0) {
      return false;
    }

    return queryStatus.includes(status);
  };

  const handleSelectedMatch = match => {
    const { event: selectedMatchEvent } = selectedMatch || {};
    const {
      event: { id: eventId = '' },
      season: { id: seasonId = '' },
    } = match || {};

    if (selectedMatchEvent?.id !== eventId) {
      push({
        pathname: MATCH_DETAILS_ROUTING.getTeamFormationURL({ eventId, seasonId }),
        search: addQuery(search),
      });

      return setSelectedMatch(match);
    }

    push({
      pathname: DASHBOARD_ROUTING.getDefaultDashboardURL(),
      search: addQuery(search),
    });

    return setSelectedMatch({});
  };

  return {
    handleSelectedMatch,
    handleOddsSideBets,
    handleSetStatus,
    isSelectedStatusActive,
  };
};
