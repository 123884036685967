import React, { ReactElement, FC } from 'react';

import './TournamentList.scss';


type Props = {
  children: ReactElement;
}

export const TournamentList: FC<Props> = ({ children }) => {
  return (
    <ul className="tournament-list">
      {children}
    </ul>
  );
};
