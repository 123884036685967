import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Match } from 'types';
import { useMatch } from 'context/match-context';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { mediaQuery } from '../../../constants/mediaQuery';
import { MOBILE_DASHBOARD_ROUTING } from '../Routing/MobileDashboardRouting';
import { addQuery } from '../../../utils/queries';

import { useMatchesHandlers } from './matchListHooks';

import './MatchBets.scss';


type Props = {
  quantity: number;
  match: Match;
  isLive?: boolean;
}

export const MatchBets: FC<Props> = ({ quantity, match, isLive }) => {
  const { handleOddsSideBets } = useMatchesHandlers();
  const { push } = useHistory();
  const { search } = useLocation();
  const { setSelectedMatch } = useMatch();
  const { event: { id: eventId }, season: { id: seasonId } } = match;

  const isDesktop = useMediaQuery(mediaQuery.minDesktopSmall);
  const handleBetsQuantityStateClass = isLive ? 'bets__quantity--live' : 'bets__quantity--side';

  const handleMQHandlers = isDesktop ? () => handleOddsSideBets(match) : () => {
    setSelectedMatch(match);
    push({
      pathname: MOBILE_DASHBOARD_ROUTING.getSideBetsURL({ eventId, seasonId }),
      search: addQuery(search, {}),
    });
  };

  return (
    <>
      {quantity > 0 ? (
        <button
          className="bets__container clickable"
          onClick={handleMQHandlers}
        >
          <span className={`bets__quantity ${handleBetsQuantityStateClass}`}>{`+${quantity}`}</span>
        </button>
      ) : (
        <div className="bets__container">
          <span className={`bets__quantity ${handleBetsQuantityStateClass}`}>{quantity}</span>
        </div>
      )}
    </>
  );
};
