import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { LiveDashboard } from '../LiveDashboard';

import { LIVE_DASHBOARD_ROUTE } from './Routing';


export const LiveDashboardRouting = () => {
  return (
    <Switch>
      <Route exact path="" component={LiveDashboard} />
    </Switch>
  );
};


const getDefaultLiveDashboardURL = () => {
  return LIVE_DASHBOARD_ROUTE.DEFAULT;
};

export const LIVE_DASHBOARD_ROUTING = {
  getDefaultLiveDashboardURL,
};
