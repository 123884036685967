import { BasketErrorMessageLabel } from 'types';

import { createBasketErrorMessage } from '../errors/createBasketErrorMessage';


export const checkIfEventIdIsDuplicated = (betPerEvent: Record<string, number>) => {
  const isDuplicated = Object.values(betPerEvent).some(count => count > 1);

  if (!isDuplicated) {
    return;
  }

  return createBasketErrorMessage({
    title: BasketErrorMessageLabel.CANT_BET,
    message: BasketErrorMessageLabel.CANT_MULTI_BET,
  });
};

