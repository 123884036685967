import React from 'react';
import { ReactComponent as Setting } from 'assets/icons/setting.svg';
import { useTranslate } from 'context/translate-context';
import { LiveControlPanel } from 'components/Live/Control/Panel/LiveControlPanel';

import './LiveDashboardNavigationHeaderActions.scss';


export const LiveDashboardNavigationHeaderActions = () => {
  const { translate } = useTranslate();

  return (
    <div className="live-dashboard-nav-header-actions">
      <LiveControlPanel
        canMuteSound
        canControlTime
        position="bottom"
      >
        {({ handleToggleExpand }) => {
          return (
            <button
              className="live-dashboard-nav-header-actions__button"
              onClick={handleToggleExpand}
            >
              <div className="live-dashboard-nav-header-actions__label margin-right--size-s">
                {translate('SETTINGS')}
              </div>
              <Setting className="live-dashboard-nav-header-actions__icon" />
            </button>
          );
        }}
      </LiveControlPanel>
    </div>
  );
};
