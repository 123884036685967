export enum MatchSection {
  TeamFormation = 'TEAM_FORMATION',
  MatchStats = 'MATCH_STATS',
  MatchReport = 'MATCH_REPORT',
  AllSideBets = 'ALL_SIDE_BETS',
  SideBets = 'SIDE_BETS',
  MatchResult = 'MATCH_RESULT',
  CorrectScore = 'CORRECT_SCORE',
  NumberOfGoals = 'NUMBER_OF_GOALS',
  ThreeWayHandicaps = 'THREE_WAY_HANDICAPS',
  GenericOdds = 'GENERIC_ODDS',
}
