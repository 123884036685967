import React from 'react';
import { useTranslate } from 'context/translate-context';
import { DISPLAY_PREDICTION_HANDLER } from 'views/Dashboard/MatchList/matchListPredictionSectionServices';

import { MobileMatchListFilterModalSection } from '../MobileMatchListFilterModalSection';
import { MatchListFilterPredictionDisplayController } from '../../../../../MatchList/Filter/MatchListFilterPredictionDisplayController';
import { MobileMatchListFilterModalButton } from '../../Button/MobileMatchListFilterModalButton';


const DISPLAYED_PREDICTION_EXAMPLE = 0.58;
const ODD_EXAMPLE = 1.20;


export const MobileMatchListFilterModalSectionPrediction = () => {
  const { translate } = useTranslate();

  return (
    <MatchListFilterPredictionDisplayController>
      {({ list, displayPrediction, handleSetDisplay }) => (
        <MobileMatchListFilterModalSection label={`${translate('SHOW_XPERT')}:`}>
          <div className="m-match-list-filter-modal-section--preview">
            <div className="m-match-list-filter-modal-section__container m-match-list-filter-modal-section--row">
              <div>
                {translate('XPERT')}
              </div>
              <div className="m-match-list-filter-modal-section--bold">
                {DISPLAY_PREDICTION_HANDLER[displayPrediction](DISPLAYED_PREDICTION_EXAMPLE, ODD_EXAMPLE)}
              </div>
            </div>
            <div className="m-match-list-filter-modal-section__container">
              <ul className="m-match-list-filter-modal-section__list m-match-list-filter-modal-section--grid-2">
                {list.map(({ label }) => {
                  return (
                    <li key={label}>
                      <MobileMatchListFilterModalButton
                        text={translate(label) as string}
                        isSelected={displayPrediction === label}
                        handleClick={() => handleSetDisplay(label)}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </MobileMatchListFilterModalSection>
      )}
    </MatchListFilterPredictionDisplayController>
  );
};
