import React, { FC } from 'react';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { Noop } from 'types';

import './MobileModalMenuButton.scss';


type Props = {
  isExpanded: boolean;
  handleToggleExpand: Noop;
  customTextClass: string;
  text: string;
  isDisabled?: boolean;
}

export const MobileModalMenuButton: FC<Props> = ({ isExpanded, handleToggleExpand, customTextClass, text, isDisabled = false }) => {
  return (
    <div className={`m-modal-menu-btn__container ${isExpanded ? 'm-modal-menu-btn--active' : ''}`}>
      <button
        disabled={isDisabled}
        className={`m-modal-menu-btn ${isExpanded ? 'm-modal-menu-btn--active' : ''}`}
        onClick={handleToggleExpand}
      >
        <div className="m-modal-menu-btn__wrapper">
          <MoreIcon fill="var(--SaasText01)" />
          <div className={`${customTextClass} margin-top--size-s`}>
            {text}
          </div>
        </div>
      </button>
    </div>
  );
};
