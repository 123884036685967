import React, { useContext, useState, useMemo, FC } from 'react';
import { useQuery } from 'react-query';
import { noop } from 'lodash';

import {
  EventStatistic,
  Match,
  GameTeam,
  Incident,
  PlayerInLineup,
  Maybe,
  Event,
  Prediction,
} from '../types';
import MatchClient from '../api/MatchClient';


type Props = {
  arePredictionsEmpty: boolean;
  arePredictionsLoading: boolean;
  areStatsLoading: boolean;
  hasAnyLineup: boolean;
  matchPredictions: Maybe<Prediction>;
  matchStatistics: Maybe<EventStatistic>;
  selectAway?: GameTeam;
  selectAwayIncidents: Incident[];
  selectAwayLineups: PlayerInLineup[];
  selectedMatch: Maybe<Match>;
  selectEvent?: Maybe<Event>;
  selectHome?: GameTeam;
  selectHomeIncidents: Incident[];
  selectHomeLineups: PlayerInLineup[];
  setSelectedMatch: (match: Maybe<Match>) => void;
};

const MatchContext = React.createContext<Props>({
  arePredictionsEmpty: false,
  arePredictionsLoading: false,
  areStatsLoading: false,
  hasAnyLineup: false,
  matchPredictions: null,
  matchStatistics: null,
  selectAwayIncidents: [],
  selectAwayLineups: [],
  selectedMatch: null,
  selectHomeIncidents: [],
  selectHomeLineups: [],
  setSelectedMatch: noop,
});


const MatchProvider: FC = props => {
  // match
  const [selectedMatch, setSelectedMatch] = useState<Maybe<Match>>(null);
  const [arePredictionsEmpty] = useState(false);
  const eventId = selectedMatch?.event?.id || '';

  const {
    isLoading: areStatsLoading,
    data: matchStatisticsData,
  } = useQuery(['matchDetailsStatistics', eventId], () => MatchClient.getStatistics({ eventId }),
    { enabled: Boolean(eventId) },
  );

  const matchStatistics: Maybe<EventStatistic> = useMemo(() => {
    return matchStatisticsData?.data?.statistics || null;
  }, [matchStatisticsData]);

  const {
    isLoading: arePredictionsLoading,
    data: matchPredictionsData,
  } = useQuery(['matchDetailsPredictions', eventId], () => MatchClient.postPredictions({ eventsIds: [eventId] }),
    { enabled: Boolean(eventId) },
  );

  const matchPredictions: Maybe<Prediction> = useMemo(() => {
    return matchPredictionsData?.data?.predictions[0] || null;
  }, [matchPredictionsData]);

  const selectHome = selectedMatch?.home;
  const selectAway = selectedMatch?.away;
  const selectEvent = selectedMatch?.event;
  const selectHomeIncidents = selectHome?.incidents || [];
  const selectAwayIncidents = selectAway?.incidents || [];
  const selectHomeLineups = selectHome?.lineup || [];
  const selectAwayLineups = selectAway?.lineup || [];

  const hasAnyLineup = useMemo(
    () => !!((selectHome?.lineup || []).length || (selectAway?.lineup || []).length),
    [selectHome, selectAway],
  );

  const values = {
    arePredictionsEmpty,
    arePredictionsLoading,
    areStatsLoading,
    hasAnyLineup,
    matchPredictions,
    matchStatistics,
    selectAway,
    selectAwayIncidents,
    selectAwayLineups,
    selectedMatch,
    selectEvent,
    selectHome,
    selectHomeIncidents,
    selectHomeLineups,
    setSelectedMatch,
  };

  return (
    <MatchContext.Provider value={values} {...props} />
  );
};


const useMatch = () => {
  const context = useContext(MatchContext);

  if(context === undefined) {
    throw new Error('useMatch must be used within a MatchProvider');
  }

  return context;
};


export {
  MatchProvider,
  useMatch,
};
