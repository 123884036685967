import { useConfig } from 'context/config-context';
import React from 'react';
import { OddsProvider } from 'types';
import { MobileModalMenu } from 'views/Dashboard/Mobile/ModalMenu/MobileModalMenu';


export const MobileNavigationLinkModalMenu = () => {
  const { configuration: { provider: { name } } } = useConfig();

  return (
    <MobileModalMenu
      isDisabled={name !== OddsProvider.NorskTipping}
      customTextClass="m-navigation__element-text"
    />
  );
};
