import React, { FC } from 'react';
import { FavouriteController } from 'views/Dashboard/shared/FavouriteController';

import { TournamentController } from '../TournamentController';
import { TournamentFavouriteStar } from '../TournamentFavouriteStar';

import { Props } from './Props';

import './Tournament.scss';


export const TournamentVariantMobile: FC<Props> = ({ tournamentId, tournamentName, DisplayedContent: TournamentName, seasonId, areaCode, areaSort }) => {
  return (
    <TournamentController>
      {({ mobileHandleSelect }) => {
        return (
          <li
            key={`${tournamentName} ${tournamentId}`}
            className="tournament"
          >
            <div className="tournament__wrapper tournament__wrapper--2-el-grid">
              <div className="margin-right--size-s">
                <FavouriteController
                  tournamentId={tournamentId}
                  tournamentName={tournamentName}
                  seasonId={seasonId}
                  areaCode={areaCode}
                  areaSort={areaSort}
                >
                  {props => <TournamentFavouriteStar {...props} />}
                </FavouriteController>
              </div>
              <button
                className="tournament__button"
                onClick={() => mobileHandleSelect(seasonId)}
              >
                {TournamentName}
              </button>
            </div>
          </li>
        );
      }}
    </TournamentController>
  );
};
