import React, { useCallback, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import translate from './../utils/translate';
import { DEFAULT_LANGUAGE } from './../constants/app';
import { useConfig } from './config-context';

const TranslateContext = React.createContext();

const TranslateProvider = props => {
  const { configuration: { lang } } = useConfig();
  const [language, setLanguage] = useState(lang || DEFAULT_LANGUAGE);
  const [interceptorId, setInterceptorId] = useState(null);
  const contextState = {
    translate: phrase => translate(language, phrase),
    setLanguage,
    language,
  };

  const handleAxiosError = useCallback(error => {
    if (error && error.response) {
      if (error.response.data && error.response.data.errors?.length > 0) {
        const { errors } = error.response.data;

        errors.forEach(error => {
          const [, errorMessage] = Object.entries(error)[0];

          toast.error(contextState.translate(errorMessage));
        });
      }
    }

    return Promise.reject(error);
  }, [contextState]);

  useEffect(() => {
    if (typeof interceptorId === 'number') {
      axios.interceptors.response.eject(interceptorId);
    }

    setInterceptorId(axios.interceptors.response.use(
      response => response,
      handleAxiosError,
    ));

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <TranslateContext.Provider value={{ ...contextState }} {...props} />
  );
};

const useTranslate = () => {
  const context = useContext(TranslateContext);

  if (context === undefined) {
    throw new Error('useTranslate must be used within a TranslateProvider');
  }

  return context;
};

export {
  TranslateProvider,
  useTranslate,
};
