import { AxiosRequestConfig } from 'axios';

const url = `${window.__RUNTIME_CONFIG__.API_URL}/tournaments/seasons`;

export const getSeasons: AxiosRequestConfig = { method: 'GET', url };


const urlStatistics = `${window.__RUNTIME_CONFIG__.API_URL}/statistics`;

export const getSeasonStatistics: AxiosRequestConfig = { method: 'GET', url: `${urlStatistics}/season` };
export const getMatchStatistics: AxiosRequestConfig = { method: 'GET', url: `${urlStatistics}/event` };
