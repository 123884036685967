import { FC, ReactElement, useMemo } from 'react';
import Big from 'big.js';
import countBy from 'lodash/countBy';
import { Basket } from 'types';


type Props = {
  fields: Basket;
  children(props: {
    minPrize: string;
    maxPrize: string;
    totalOdds: string;
  }): ReactElement;
}

export const ComboModeFooterResultsController: FC<Props> = ({ children, fields }) => {
  const betCount = useMemo(() => {
    const betPerEvent = countBy(fields.items, 'eventId');
    const validBets = Object.values(betPerEvent).reduce((acc, count) => {
      if (count > 1) {
        return acc;
      }

      return acc.add(count);
    }, new Big(0));

    return {
      betPerEvent,
      validBets: validBets.toNumber(),
    };
  }, [fields.items]);


  const totalOdds = useMemo(() => {
    if(betCount.validBets === 0) {
      return 0;
    }

    if (fields.items.length === 0) {
      return 0;
    }

    const initialOddValue = new Big(1);

    const sum = fields.items.reduce((acc, curr) => {
      if (betCount.betPerEvent[curr.eventId] > 1) {
        return acc;
      }

      return acc.times(curr.odd.odds);
    }, initialOddValue);

    return sum.toNumber();
  }, [betCount, fields.items]);

  const { totalStake = 0 } = fields || {};

  const maxPrize = useMemo(() => {
    return new Big(totalOdds || 0).times(totalStake).toNumber();
  }, [totalOdds, totalStake]);


  const props = {
    minPrize: '0.00',
    maxPrize: maxPrize.toFixed(2),
    totalOdds: totalOdds.toFixed(2),
  };

  return children(props);
};
