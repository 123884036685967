import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { UserInCircle, NavigationHeader } from 'components';

import { UserAccountFormController } from './UserAccountFormController';
import { UserAccountForm } from './UserAccountForm';

import './UserAccount.scss';


export const UserAccount = () => {
  const { goBack } = useHistory();
  const { user } = useAuth0();
  const metadata = user[`${window.__RUNTIME_CONFIG__.AUTH0_AUDIENCE_SAAS}/user_metadata`];

  return (
    <UserAccountFormController initialValues={{ ...user, ...metadata }}>
      {props => (
        <>
          <NavigationHeader
            callback={goBack}
          />
          <section className="user-account__container">
            <div className="user-account__section-container">
              <div className="user-account__icon-bar">
                <div className="user-account__icon-bar-wrapper">
                  <UserInCircle
                    containerStyles="user-account__icon-container"
                    svgStyles="user-account__icon"
                  />
                </div>
              </div>
              <UserAccountForm {...props} />
            </div>
          </section>
        </>
      )}
    </UserAccountFormController>
  );
};
