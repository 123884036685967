const matchDetails = {
  SEASON: 'Säsong',
  STADIUM: 'Stadion',
  DRAW: 'O',
  FORM: 'Form',
  GOALS_AGAINST: 'IM',
  GOALS_DIFF: 'M',
  GOALS_FOR: 'GM',
  LEAGUE_TABLE: 'Ligatabell',
  LOSE: 'F',
  NEXT_MATCH: 'Nästa match',
  POINTS: 'P',
  WIN: 'W',
  STATISTICS: 'Statistik',
  TBA: 'TBA',
  MORE_GOALS: 'Mål mer än',
  H_A_D: 'Hemma - Oavgjort - Borta',
  PLAYED: 'Spelade matcher',
  H_WINS: 'Hemmavinster',
  DRAWS: 'Oavgjorts',
  A_WINS: 'Bortavinster',
  GOALS: 'Mål',
  NO_GOALS: 'Inga mål',
  GOALS_MATCH: 'Mål per match',
  GOALS_HOME: 'Mål gjorda på hemmaplan',
  GOALS_AWAY: 'Mål gjorda på bortaplan',
  SUBSTITUTES: 'Avbytare',
  LINE_UP: 'Startuppställning',
  ODDS: 'Odds',
  ODD: 'Odd',
  TEAM_FORMATION: 'Laguppställning',
  MATCH_STATS: 'Matchstatistik',
  MATCH_REPORT: 'Matchrapport',
  ALL_SIDE_BETS: 'Alla',
  SIDE_BETS: 'Sidobet',
  MATCH_RESULT: 'Matchresultat',
  HANDICAP_3W_X: 'Trevägshandikapp',
  TOTAL_X: 'Antal mål',
  OUTCOME: 'Matchresultat',
  CORRECT_SCORE: 'Rätt resultat',
  NUMBER_OF_GOALS: 'Antal mål',
  THREE_WAY_HANDICAPS: 'Trevägshandikapp',
  ZERO_GOALS: 'Inga mål',
  UNDER: 'Under',
  OVER: 'Över',
  GOALS_FACTOR: 'Målfaktor',
  MATCH_DETAILS: 'Matchdetaljer',
  FULL_TIME: 'Full tid',
  FIRST_HALF: 'Första halvlek',
  SECOND_HALF: 'Andra halvlek',
  XPERT: 'Xpert',
  'Attacks': 'Anfall',
  'Red cards': 'Röda kort',
  'Offsides': 'Offsides',
  'Fouls': 'Fouls',
  'Free kicks': 'Frisparkar',
  'Penalties unsuccessful': 'Missade straffar',
  'Shots off target': 'Skott utanför mål',
  'Penalties saved': 'Räddade straffar',
  'Corners': 'Hörnor',
  'Throw-ins': 'Inkast',
  'Shots on target': 'Skott på mål',
  'Ball possession percentage': 'Bollinnehav procent',
  'Dangerous free kicks': 'Farliga frisparkar',
  'Penalties given': 'Erhållna straffar',
  'Dangerous attacks': 'Farliga anfall',
  'Shots woodwork': 'Skott i målramen',
  'Shots blocked': 'Blockerade skott',
  'Yellow cards': 'Gula kort',
  'Red card': 'Rött kort',
  'Yellow card': 'Gult kort',
  'Own goals': 'Självmål',
  'Goal kicks': 'Skott på mål',
  'Total shots': 'Totala skott',
  'Formation': 'Uppställning',
  'Penalties scored': 'Straffmål',
  'Penalties missed': 'Missade straffar',
  'Substitutions': 'Byten',
  'extensive': 'Omfattande',
  'Lacking functionality': 'Eftersom jag skulle vilja ha ytterligare funktioner',
  'Another company': 'Ett annat företag erbjuder samma funktionalitet',
  'Goals 1-15': 'Mål 1-15',
  'Goals 16-30': 'Mål 16-30',
  'Goals 31-45': 'Mål 31-45',
  'Goals 46-60': 'Mål 46-60',
  'Goals 61-75': 'Mål 61-75',
  'Goals 76-90': 'Mål 76-90',
  PENALTY_SAVED_BY_GOAL_KEEPER: 'Straffräddningar',
  PENALTY_CANCELLED: 'Ingen straff',
  POSSIBLE_CARD_CANCELLED: 'eventuellt kort avbrutet',
  POSSIBLE_PENALTY: 'eventuella straffar',
  PENALTY_GOAL: 'Straffmål',
  POSSIBLE_CARD: 'Möjliga kort',
  MISSED_PENALTY: 'Missad straff',
  POSSIBLE_GOAL: 'Möjligt mål',
  GOAL_CANCELLED: 'Mål bortdömt',
  HALF_1: 'Första halvlek',
  OWN_GOAL: 'Självmål',
  CORNER: 'Hörna',
  STRAFF: 'Straff',
  YELLOW_CARD: 'Gult kort',
  RED_CARD: 'Rött kort',
  GOAL: 'Mål',
  SUB_IN: 'Inbytta',
  SUB_OUT: 'Utbytta',
  CONFIRMED: 'Bekräftad',
  CANCELLED: 'Avbruten',
  TBD: 'tbd',
  GOALKEEPER: 'Målvakt',
  DEFENDER: 'Försvarare',
  MIDFIELDER: 'Mittfältare',
  ATTACKER: 'Anfallare',
  REGULAR_TIME: 'Ordinarie tid',
  HALF_TIME: 'Halvtid',
  HALF_TIME_LABEL: 'Halvtid',
  OUR_TYPE: 'Vår typ',
  draw: 'oavgjort',
  home: 'hemma',
  away: 'borta',
  under: 'under',
  over: 'över',
  NO_ODDS_AVAILABLE: 'Inga odds tillgängliga',
  NO_PREDICTION_AVAILABLE: 'Inga förutsägelser tillgängliga',
  FIRST_TIME_LABEL: 'Matchstart',
  SECOND_HALF_LABEL: '2:a halvlek förlängning',
  FIRST_HALF_EXTRA_TIME_LABEL: 'Förlängning i 1:a halvlek',
  SECOND_HALF_EXTRA_TIME_LABEL: '2:a halvlek förlängning',
  HALF_TIME_EXTRA_TIME_LABEL: 'Halvtid förlängning',
  PENALTY_LABEL: 'Straffläggning',
};

export default matchDetails;
