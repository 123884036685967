import React, { FC, ReactElement } from 'react';
import { Loader } from 'semantic-ui-react';
import { Tournament } from 'types';

import { TournamentList } from '../shared/TournamentList';

import { TournamentListController } from './TournamentListController';

type Props = {
  children(props: {
    tournaments: Tournament[];
  }): ReactElement;
}

export const TournamentListSection: FC<Props> = ({ children }) => {
  return (
    <TournamentListController>
      {({ tournaments, areTournamentsLoading }) => (
        <>
          {areTournamentsLoading && (
            <div className="tournament__loader">
              <Loader active inline size="small" />
            </div>
          )}
          {!areTournamentsLoading && tournaments.length > 0 && (
            <TournamentList>
              {children({ tournaments })}
            </TournamentList>
          )}
        </>
      )}
    </TournamentListController>
  );
};
