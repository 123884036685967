import React from 'react';
import { LiveTickerShortcut } from 'components/Live/Ticker/Shortcut/LiveTickerShortcut';


export const MobileNavigationLinkMatchListLive = () => {
  return (
    <LiveTickerShortcut
      customLinkStyle="m-navigation__link"
      customTextClass="m-navigation__element-text"
    />
  );
};
