import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ReactComponent as Icon } from 'assets/icons/live-ticker.svg';
import { useTranslate } from 'context/translate-context';
import { LIVE_DASHBOARD_ROUTING } from 'views/LiveDashboard/Routing/LiveDashboardRouting';

import { LiveTickerShortcutController } from './LiveTickerShortcutController';

import './LiveTickerShortcut.scss';


type Props = {
  customTextClass: string;
  customLinkStyle: string;
}

export const LiveTickerShortcut: FC<Props> = ({ customTextClass, customLinkStyle }) => {
  const { search } = useLocation();
  const { translate } = useTranslate();
  const link = `${LIVE_DASHBOARD_ROUTING.getDefaultLiveDashboardURL()}${search}`;

  return (
    <LiveTickerShortcutController>
      {({ incidentsRemaining }) => {
        return (
          <NavLink
            to={link}
            className={`${customLinkStyle} live-ticker-shortcut__container`}
          >
            <div className="live-ticker-shortcut__wrapper">
              <div className="live-ticker-shortcut__count">
                {incidentsRemaining}
              </div>
              <Icon className="live-ticker-shortcut__icon" />
              <div className={`${customTextClass} margin-top--size-s`}>
                {translate('LIVE_TICKER')}
              </div>
            </div>
          </NavLink>
        );
      }}
    </LiveTickerShortcutController>
  );
};
