import { useMatchList } from 'context/match-list-context';
import { FC, ReactElement, useMemo } from 'react';
import { Season, MatchSort } from 'types';
import SeasonClient  from 'api/SeasonClient';
import { useQuery } from 'react-query';
import { sortItemsAsc, sortItemsDesc } from 'utils/sort';

import { getMissingSeasonIdList } from './matchListMissSelectedTournamentList.services';


type Props = {
  children(props: {
    seasonList: Season[];
    order: MatchSort;
    isLoading: boolean;
  }): ReactElement;
}

const sortByOrderMap = new Map();
sortByOrderMap.set(MatchSort.KICK_OFF_ASC, sortItemsAsc);
sortByOrderMap.set(MatchSort.KICK_OFF_DESC, sortItemsDesc);
sortByOrderMap.set(MatchSort.TOURNAMENT_ASC, sortItemsAsc);
sortByOrderMap.set(MatchSort.TOURNAMENT_DESC, sortItemsDesc);

export const MatchListMissingSelectedTournamentListController: FC<Props> = ({ children }) => {
  const { matches, filters } = useMatchList();
  const { season: seasonsIds = [] } = filters;
  const castedSeasonsIds = seasonsIds as string[];

  const missingSeasonIdList = getMissingSeasonIdList(matches, castedSeasonsIds);

  const {
    data: seasonListData,
    isLoading,
  } = useQuery(['missingSelectedTournamentList', missingSeasonIdList],
    () => SeasonClient.getSeasonList({ seasonIdList: missingSeasonIdList }),
    { enabled: Boolean(missingSeasonIdList.length) });


  const seasonList: Season[] = useMemo(() => {
    return seasonListData?.data?.seasons || [];
  }, [seasonListData]);

  const seasonListSorted = seasonList
    .sort((a, b) => {
      const { name: firstElementName } = a;
      const { name: secondElementName } = b;

      return sortByOrderMap.get(filters.order)(firstElementName, secondElementName);
    });

  const props = {
    seasonList: seasonListSorted,
    order: filters.order,
    isLoading,
  };

  return children(props);
};
