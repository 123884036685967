import { useBasket } from 'context/basket/basket-context';
import { useCallback, useEffect } from 'react';
import { Basket, BasketErrorMessage } from 'types';

import { useBetPerEvent } from '../useBetPerEvent';
import { basketValidationListByMode } from '../validation';


export type Props = {
  fields: Basket;
  shouldUpdate?: boolean;
}

export const useBasketError = ({ fields, shouldUpdate }: Props) => {
  const { setErrors } = useBasket();
  const { betPerEvent } = useBetPerEvent();

  const getValidationResults = basketValidationListByMode({
    basketItemList: fields.items,
    betPerEvent,
  })[fields.mode]();

  const getErrors = () => {
    const result = getValidationResults.filter(Boolean) as BasketErrorMessage[];

    setErrors(result);
  };

  const memoErrors = useCallback(getErrors, [fields.items.length, fields.mode, shouldUpdate]);

  useEffect(() => {
    memoErrors();
  }, [memoErrors]);
};
