const apiErrosEN = {
  'ACCESS_TOKEN_ALREADY_EXISTS': 'Access token already exist.',
  'ACCESS_TOKEN_NOT_EXISTS': 'Access token does not exist.',
  'ACTIVATION_CODE_DOESNT_EXIST': 'Code doesn\'t exist.',
  'BIRTH_DATE_IS_IN_FUTURE': 'Can\'t be in future',
  'BIRTH_DATE_IS_NOT_A_DATE': 'Passed value isn\'t a date',
  'BIRTH_DATE_TO_FAR_IN_PAST': 'Is to far in the past',
  'CREDIT_CARD_NUMBER_NOT_PROVIDED': 'Credit card data is not provided',
  'DATE_IS_OUT_OF_THE_RANGE': 'Date is out of the range.',
  'DOMAIN_ALREADY_EXIST': 'This domain is already whitelisted',
  'EMAIL_REQUIRED': 'Email is required.',
  'FETCH_AREAS_FAILED': 'Fetching areas failed.',
  'FETCH_PACKAGES_FAILED': 'Fetching packages failed.',
  'INVALID_DOMAIN_DATA': 'Invalid domain data.',
  'INVALID_DOMAIN_LENGTH': 'Domain name is too short or too long.',
  'INVALID_DOMAIN_NAME': 'Not valid domain format.',
  'INVALID_FORMAT_EXPECTED_STRING': 'Invalid format, data should be provided as a string',
  'INVALID_PASSWORDS_DATA': 'Invalid passwords data.',
  'INVALID_USER_DATA': 'Invalid user data.',
  'INVALID_USER_EMAIL_FORMAT': 'Invalid email format.',
  'INVALID_USER_EMAIL_OR_PASSWORD': 'Invalid email or password.',
  'INVALID_USER_EMAIL': 'Invalid email format.',
  'INVALID_USER_FIELD_LENGTH': 'Value is too long or too short.',
  'INVALID_USER_PASSWORD_LENGTH': 'Password must be 8 - 255 characters long.',
  'IP_NOT_AUTHORIZED': 'Ip was not authorized.',
  'IP_NOT_DELETED': 'Ip was not deleted.',
  'IP_NOT_FOUND': 'Ip does not exist.',
  'IS_REQUIRED': 'This field is required.',
  'ITEM_NOT_IN_ONE_GROUP': 'Each item can be only in one group.',
  'MISSING_EVENT_IDS': 'Missing event ids.',
  'MISSING_NEW_PASSWORD': 'New password cannot be empty.',
  'MISSING_ODDS_PROVIDER': 'Missing odds provider.',
  'MISSING_OLD_PASSWORD': 'Old password cannot be empty.',
  'MISSING_PERMISSIONS': 'Missing permissions for requested data.',
  'MISSING_REPEAT_NEW_PASSWORD': 'You must type new password again.',
  'MISSING_REQUEST_PARAMETER': 'Missing request parameter',
  'MISSING_TOKEN': 'Token is required to use the application.',
  'MODE_DOESNT_EXIST': "Mode doesn't exist",
  'MODE_NOT_AVAILABLE': 'Mode isn\'t available. There\'s no sufficient number of the bets provided',
  'NEW_PASSWORD_TOO_SHORT': 'New password must be at least 8 characters long.',
  'NOT_SELECTED_COMBINATIONS': 'Combination was not selected',
  'PASSWORD_RESET_LINK_INVALID_OR_EXPIRED': 'Link is invalid or expired',
  'PASSWORDS_DONT_MATCH': "Passwords don't match.",
  'TOKEN_NOT_DELETED': 'Token was not deleted.',
  'TOKEN_NOT_FOUND': 'Token does not exist.',
  'UNKNOWN_API_ERROR': 'Unknown error occurred. Please try again in next few seconds.',
  'USER_DID_NOT_CONNECT_CARD': 'Please connect your credit card or your access will be revoked after trial period.',
  'USER_DID_NOT_PAY': 'There was a problem with your last payment. Please contact support for the details.',
  'USER_DOESNT_EXIST': 'User doesn\'t exist.',
  'USER_EXISTS': 'User already exist.',
  'USER_IS_ALREADY_ACTIVE': 'User is already active.',
  'USER_IS_UNDERAGED': 'User is underaged.',
  'USER_NOT_AUTHORIZED': 'User not logged in',
  'USER_NOT_EXISTS': 'User does not exist.',
  'USER_NOT_IN_DB': 'User does not exist.',
  'USER_NOT_VERIFIED': 'User is not verified.',
  'USER_ON_TRIAL': 'You are on trial period. Please add a credit card to start subscription and keep access to SimpleReceipts.',
  'USER_WAS_NOT_UPDATED': 'User was not updated',
  'WRONG_OLD_PASSWORD': 'Old password is invalid.',
};

export default apiErrosEN;
