import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Device } from 'types';
import { MatchListVariant } from 'views/Dashboard/MatchList/MatchListVariant';
import { MatchListMissingSelectedTournamentListSection } from 'views/Dashboard/MatchList/Missing/MatchListMissingSelectedTournamentListSection';

import { MatchListController } from '../../../MatchList/List/Controller/MatchListController';

import '../MobileMatchList.scss';


export const MobileMatchListStatic = () => {
  return (
    <div className="m-match-list">
      <MatchListController>
        {props => {
          const {
            matches,
            filters,
            areMatchesLoading,
          } = props;

          return (
            <>
              {areMatchesLoading && <Loader active inline="centered" />}
              {matches.length > 0 && (
                <MatchListVariant
                  matches={matches}
                  order={filters.order}
                  deviceVariant={Device.Mobile}
                />
              )}
              <MatchListMissingSelectedTournamentListSection
                matchListLength={matches.length}
                areMatchesLoading={areMatchesLoading}
              />
            </>
          );
        }}
      </MatchListController>
    </div>
  );
};
