import React, { FC } from 'react';
import { noop } from 'lodash';
import { Control, UseFormRegister } from 'react-hook-form';
import { Basket, BasketItem } from 'types';

import { BasketItemCombo } from '../../BasketItem';
import { SimpleList } from '../../BasketItemList';

type Props = {
  control: Control<Basket>;
  register: UseFormRegister<Basket>;
  fields: Basket;
  onItemRemove: (item: BasketItem, index: number) => void;
}

export const ComboModeTab: FC<Props> = ({
  control,
  register,
  fields,
  onItemRemove,
}) => {
  return (
    <SimpleList
      list={fields.items}
      control={control}
      register={register}
      component={BasketItemCombo}
      onChange={noop}
      onRemove={onItemRemove}
    />
  );
};
