import React, { FC, ReactElement } from 'react';
import { OddsType } from 'types';
import { isEmpty } from 'lodash';
import { Maybe } from 'types';
import { useTranslate } from 'context/translate-context';

import { MATCH_DETAILS_SECTION_KEY  } from '../../../../../constants';
import { MatchDetailsPredictionController } from '../../Prediction/MatchDetailsPredictionController';
import { MatchDetailsSideBetsOddsLabel } from '../MatchDetailsSideBetsOddsLabel';
import { activeTabToOddsPeriod } from '../matchDetailsSideBetsServices';
import { MatchDetailsPredictionsSection } from '../../Prediction/MatchDetailsPredictionsSection';
import { MatchDetailsPredictionsColumnsGrid } from '../../Prediction/MatchDetailsPredictionsColumnsGrid';
import { MatchDetailsPredictionRow } from '../../Prediction/MatchDetailsPredictionRow';
import { MatchDetailsSideBetsOddsProviderContainer } from '../MatchDetailsSideBetsOddsProviderContainer';


const defaultPredictions = {
  home: 0,
  draw: 0,
  away: 0,
};

type Props = {
  ColumnsLabels: FC<{oddsLabel: Maybe<ReactElement>}>;
}

export const sectionOddType = OddsType.Outcome;

export const MatchDetailsSideBetsMatchResult: FC<Props> = ({ ColumnsLabels }) => {
  const { translate } = useTranslate();

  return (
    <MatchDetailsSideBetsOddsProviderContainer
      oddType={[sectionOddType]}
    >
      {({ oddsByView, isOddInBasket, handleToggleOddInBasket, match }) => {
        return (
          <MatchDetailsPredictionController>
            {({ predictions }) => {
              return (
                <MatchDetailsPredictionsSection
                  tabsActive
                  label={translate(MATCH_DETAILS_SECTION_KEY.MATCH_RESULT)}
                >
                  {({ activePredictionTab }) => {
                    const prediction = predictions.prediction?.[activePredictionTab];

                    const handlePrediction = !isEmpty(prediction) ? prediction : defaultPredictions;

                    const sideBets = oddsByView
                      .find(odd => odd.typePeriod === activeTabToOddsPeriod.get(activePredictionTab));
                    const displayedOdds = sideBets?.odds || {};

                    const oddsLabel = !isEmpty(displayedOdds) ? <MatchDetailsSideBetsOddsLabel /> : null;

                    return (
                      <MatchDetailsPredictionsColumnsGrid columnVariant="3">
                        <ColumnsLabels
                          oddsLabel={oddsLabel}
                        />
                        <MatchDetailsPredictionRow
                          label="1"
                          match={match}
                          prediction={handlePrediction.home}
                          odd={displayedOdds?.home}
                          onOddClick={handleToggleOddInBasket({ odd: sideBets })}
                          isOddInBasket={isOddInBasket}
                        />

                        <MatchDetailsPredictionRow
                          label="X"
                          match={match}
                          prediction={handlePrediction.draw}
                          odd={displayedOdds?.draw}
                          onOddClick={handleToggleOddInBasket({ odd: sideBets })}
                          isOddInBasket={isOddInBasket}
                        />
                        <MatchDetailsPredictionRow
                          label="2"
                          match={match}
                          prediction={handlePrediction.away}
                          odd={displayedOdds?.away}
                          onOddClick={handleToggleOddInBasket({ odd: sideBets })}
                          isOddInBasket={isOddInBasket}
                        />
                      </MatchDetailsPredictionsColumnsGrid>
                    );
                  }}
                </MatchDetailsPredictionsSection>
              );
            }}
          </MatchDetailsPredictionController>
        );
      }}
    </MatchDetailsSideBetsOddsProviderContainer>
  );
};
