export const useHighlightHighestValue = (values, activeClass) => {
  const heighestPrediction = Math.max(...values);

  const handleHighlightElementWith = highestPrediction => prediction => {
    if (!highestPrediction) {
      return '';
    }
    return highestPrediction === prediction ? activeClass : '';
  };

  const handleHighlightWhenHighestValueIsEqualTo = handleHighlightElementWith(heighestPrediction);

  return {
    handleHighlightWhenHighestValueIsEqualTo,
  };
};
