import React, { FC, useMemo } from 'react';
import { Control, UseFormRegister } from 'react-hook-form';
import { Basket, BasketItem, BasketItemGroup } from 'types';

import { useGroupList } from '../hooks/useGroupList';
import { GroupBets } from '../GroupBets';

import './GroupCombinations.scss';


type Props = {
  list: BasketItem[];
  control: Control<Basket>;
  register: UseFormRegister<Basket>;
}

export const GroupCombinations: FC<Props> = ({ list, ...props }) => {
  const { groupedList } = useGroupList(list);
  const canChooseBets = useMemo(() => list.some(item => {
    return item.groups.length > 0 && item.groups[0] !== BasketItemGroup.S;
  }), [list]);

  return (
    canChooseBets ?
      <div className="group-combinations">
        {Object.keys(groupedList).map(key => {
          if (
            Object.keys(BasketItemGroup).indexOf(key) === -1 ||
            key === BasketItemGroup.S
          ) {
            return null;
          }

          return (
            <GroupBets
              key={key}
              group={key as BasketItemGroup}
              count={groupedList[key].length}
              {...props}
            />
          );
        })}
      </div> :
      null
  );
};
