import React, { FC, useContext, useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io-client/build/typed-events';
import { noop } from 'lodash';
import { Match, SocketLiveMatch } from 'types';
import socketsService from 'utils/sockets';

import { useCompareLiveMatchListFromSockets } from '../liveTicker/useCompareLiveMatchListFromSockets';


type Props = {
  matchesSockets: Match[];
  setMatchesSockets: (matchList: Match[]) => void;
}

const SocketsLiveMatchListContext = React.createContext<Props>({
  matchesSockets: [],
  setMatchesSockets: noop,
});

const SocketsLiveMatchListProvider: FC = props => {
  const [matchesSockets, setMatchesSockets] = useState<Match[]>([]);
  const socket = socketsService.getSocket() as Socket<DefaultEventsMap, DefaultEventsMap>;

  useCompareLiveMatchListFromSockets(matchesSockets);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket connected');
    });

    socket.on('live_events', ({ liveMatchList = [] }: SocketLiveMatch) => {
      setMatchesSockets(liveMatchList);
    });

    socket.on('errors', errors => {
      console.log(errors);
    });

    socket.on('disconnect', reason => {
      console.error(`Socket client disconnected: ${reason}`, reason);
    });

    return () => {
      socket.disconnect();
      socket.off();
    };
  }, []);

  const values = {
    matchesSockets,
    setMatchesSockets,
  };

  return (
    <SocketsLiveMatchListContext.Provider value={values} {...props} />
  );
};

const useSocketsLiveMatchList = () => {
  const context = useContext(SocketsLiveMatchListContext);

  if(context === undefined) {
    throw new Error('useSocketsLiveMatchList must be used within a SocketsLiveMatchListProvider');
  }

  return context;
};

export {
  SocketsLiveMatchListProvider,
  useSocketsLiveMatchList,
};
