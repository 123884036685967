import React, { FC, Fragment } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useBasketConfig } from 'context/basket/basket-config-context';

import { BasketFieldCurrencyInput } from '../../../..';
import { Basket } from '../../../../../types';
import { useTranslate } from '../../../../../context/translate-context';

import { SystemModeFooterController } from './SystemModeFooterController';


type Props = {
  control: Control<Basket>;
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  minPrize: string;
  maxPrize: string;
}

export const SystemModeFooter: FC<Props> = ({
  control,
  setValue,
  fields,
  minPrize,
  maxPrize,
}) => {
  const { translate } = useTranslate();
  const { configuration: { provider: { currency } } } = useBasketConfig();

  return (
    <SystemModeFooterController
      setValue={setValue}
      fields={fields}
    >
      {({
        selectedCombinationsCount,
        onStakePerComboChange,
        onTotalStakeChange,
      }) => (
        <Fragment>
          <div className="basket-footer__fields">
            <Controller
              control={control}
              name="stakePerCombo"
              render={({
                field: { onBlur, onChange, value, name },
              }) => (
                <BasketFieldCurrencyInput
                  labelText={`${translate('STAKE_PER_COMBO')} (${selectedCombinationsCount})`}
                  ariaLabel={translate('STAKE_PER_COMBO')}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={(value: number | string) => {
                    onChange(value);
                    onStakePerComboChange(value);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="totalStake"
              render={({
                field: { onBlur, onChange, value, name },
              }) => (
                <BasketFieldCurrencyInput
                  labelText={translate('TOTAL_STAKE')}
                  ariaLabel={translate('TOTAL_STAKE')}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={(value: number | string) => {
                    onChange(value);
                    onTotalStakeChange(value);
                  }}
                />
              )}
            />
          </div>
          <div className="basket-footer__values__wrapper">
            <div className="basket-footer__values">
              <span className="basket-footer__values-title">{translate('MIN_WIN')}</span>
              <span className="basket-footer__values-value">
                <NumberFormat
                  isNumericString
                  thousandSeparator
                  displayType="text"
                  value={minPrize}
                  suffix={` ${currency}`}
                />
              </span>
            </div>
            <div className="basket-footer__values">
              <span className="basket-footer__values-title">{translate('MAX_WIN')}</span>
              <span className="basket-footer__values-value">
                <NumberFormat
                  isNumericString
                  thousandSeparator
                  displayType="text"
                  value={maxPrize}
                  suffix={` ${currency}`}
                />
              </span>
            </div>
          </div>
        </Fragment>
      )}
    </SystemModeFooterController>
  );
};
