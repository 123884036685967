import React, { FC } from 'react';
import { useTranslate } from 'context/translate-context';
import { Venue } from 'types';

import './MatchDetailsVenue.scss';

type Props = {
  venue: Venue;
}

export const MatchDetailsVenue: FC<Props> = ({ venue }) => {
  const { translate } = useTranslate();
  const { name = '' } = venue;

  return (
    <section className="match-details-venue__container">
      <p className="match-details-venue__label">
        {translate('STADIUM')}
      </p>
      <div className="match-details-venue__name">
        {name}
      </div>
    </section>
  );
};
