import { FC, ReactElement } from 'react';
import { Device, Season } from 'types';

import { TOURNAMENT_ROUTING } from '../../Tournament/Routing/TournamentRouting';
import { MOBILE_TOURNAMENT_ROUTING } from '../../Tournament/Mobile/Routing/MobileTournamentRouting';


const linksForDevices = {
  [Device.Desktop]: TOURNAMENT_ROUTING.getDefaultTournamentURL,
  [Device.Mobile]: MOBILE_TOURNAMENT_ROUTING.getDefaultTournamentURL,
};

type Props = {
  season: Season;
  deviceVariant?: Device;
  children(props: {
    link: string;
    name: string;
    areaBadgeUrl?: string;
    competitionBadgeUrl?: string;
  }): ReactElement;
}

export const TournamentRedirectController: FC<Props> = ({ season, deviceVariant = Device.Desktop, children }) => {
  const {
    competition: { id, name, badgeUrl: competitionBadgeUrl },
    area: { badgeUrl: areaBadgeUrl },
  } = season;

  const link = linksForDevices[deviceVariant](id);

  const props = {
    link,
    name,
    areaBadgeUrl,
    competitionBadgeUrl,
  };

  return children(props);
};
