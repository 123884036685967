import { useTranslate } from 'context/translate-context';
import { isToday } from 'date-fns';
import React from 'react';
import { MatchStatus } from 'types';

import { MatchListFilterSectionController } from '../../MatchListFilterSectionController';

import { MatchListFilterButton } from './MatchListFilterButton';


export const MatchListFilterState = () => {
  const { translate } = useTranslate();
  const label = translate('LIVE');

  return (
    <MatchListFilterSectionController>
      {({ filters, handleSetStatus, isSelectedStatusActive }) => {
        return (
          <MatchListFilterButton
            ariaLabel={label}
            isSelected={isSelectedStatusActive(MatchStatus.Live)}
            callback={() => handleSetStatus([MatchStatus.Live])}
            isDisabled={!isToday(new Date(filters.from))}
          >
            <div className="match-list-filter__text">
              {label}
            </div>
          </MatchListFilterButton>
        );
      }}
    </MatchListFilterSectionController>
  );
};
