import React, { FC, ReactElement } from 'react';
import { Loader } from 'semantic-ui-react';
import { Tournament } from 'types';

import { TournamentListController } from './TournamentListController';


type Props = {
  children(props: {
    tournaments: Tournament[];
  }): ReactElement;
}

export const TournamentAreaContinentListSection: FC<Props> = ({ children }) => {
  return (
    <TournamentListController>
      {({ continentTournaments, areContinentTournamentsLoading }) => (
        <>
          {areContinentTournamentsLoading && (
            <div className="tournament__loader">
              <Loader active inline size="small" />
            </div>
          )}
          {!areContinentTournamentsLoading && continentTournaments.length > 0 && children({ tournaments: continentTournaments })}
        </>
      )}
    </TournamentListController>
  );
};
