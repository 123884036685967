import { Gender } from 'types';

import { Area } from '../Area';
import { Maybe } from '../Util';
import { TournamentType } from '../Tournament';

import { MatchPrediction } from './MatchPredictions';
import { MatchStatus, MatchStatusName } from './MatchState.enum';
import { Incident } from './Incident';


export type SeasonTournament = {
  id: string;
  abbrev: string;
  name: string;
  shortName: string;
  areaType: string;
  gender: Gender;
  predictionImportance: number;
  predictionLevel: number;
  type: TournamentType;
  badgeThumbUrl?: string;
  badgeUrl?: string;
};

export type Season = {
  id: string;
  area: Area;
  competition: SeasonTournament;
  from: Date;
  name: string;
  to: Date;
  year: string;
};

export type PlayerInLineup = {
  bench: boolean;
  id: string;
  name: string;
  position: string;
  shirt: number;
  shortName: string;
};

export type TeamDetails = {
  id: string;
  acronym: string;
  name: string;
  shortName: string;
  displayedName: string;
  founded: string;
  website: string;
  badgeUrl: string;
};

export enum GameTeamPosition {
  Home = 'home_team',
  Away = 'away_team',
}

export type GameTeam = {
  id: string;
  incidents: Incident[];
  lineup: PlayerInLineup[];
  name: string;
  firstHalfScore: number;
  regularTimeScore: number;
  overtimeScore: number;
  penaltyScore: number;
  shortName: string;
  teamDetails: TeamDetails;
  type: GameTeamPosition;
  updatedAt: Date;
};

export enum MatchScoreKeys {
  FirstHalf = 'firstHalfScore',
  RegularTime = 'regularTimeScore',
  OverTime = 'overtimeScore',
  Penalty = 'penaltyScore',
}

export type EventDetail = {
  id: string;
  name: string;
  value: string;
};

export type EventStatisticTeam = {
  id: string;
  name: string;
  shortName: string;
  statistics: {name: string; value: number}[];
}

export type EventCommonStatistic = {
  name: string;
  home: number;
  away: number;
}

export type EventStatistic = {
  away: EventStatisticTeam;
  commonStatistics: EventCommonStatistic[];
  home: EventStatisticTeam;
}

export type Event = {
  betCards: string;
  betCorners: string;
  betStatus: string;
  coverageType: string;
  day: string;
  details: EventDetail[];
  ftOnly: string;
  id: string;
  itemStatus: string;
  name: string;
  neutralVenue: boolean;
  relationStatus: string;
  scheduledStartedAt: string;
  createdBy: string;
  result: string;
  startDate: string;
  scoutsfeed: boolean;
  statsLvl: string;
  statscoreUt: number;
  status: MatchStatus;
  statusName: MatchStatusName;
  verifiedResult: boolean;
  clockTime: Maybe<number>;
};

export type Venue = {
  city?: string;
  country?: string;
  lat?: string;
  lng?: string;
  name?: string;
  opened?: string;
  shortName?: string;
  status?: string;
  url?: string;
  uuid?: string;
};

export type Match = {
  away: GameTeam;
  home: GameTeam;
  event: Event;
  prediction: MatchPrediction;
  season: Season;
  venue: Venue;
};


