import React, { FC } from 'react';
import { Size, Theme } from 'types';
import { useTheme } from 'context/theme-context';
import { useTranslate } from 'context/translate-context';
import { ReactComponent as LogoDarkVariant } from 'assets/images/sportxpert-logo-mark-dark.svg';
import { ReactComponent as LogoLightVariant } from 'assets/images/sportxpert-logo-mark-light.svg';

import './EmptyElementFeedback.scss';


type Props = {
  text: string;
  customClass?: string;
  canSeeTextOnly?: boolean;
  size?: Size;
}


const textSizeMap = new Map();
textSizeMap.set('tiny', 'empty-element-feedback--tiny');
textSizeMap.set('small', 'empty-element-feedback--small');
textSizeMap.set('normal', 'empty-element-feedback--normal');
textSizeMap.set('big', 'empty-element-feedback--big');

const logoVariant = new Map();
logoVariant.set(Theme.DARK, LogoDarkVariant);
logoVariant.set(Theme.LIGHT, LogoLightVariant);

export const EmptyElementFeedback: FC<Props> = ({
  text,
  customClass = '',
  canSeeTextOnly = false,
  size,
}) => {
  const { translate } = useTranslate();
  const { theme } = useTheme();
  const Logo = logoVariant.get(theme);

  const textSizeClass = size ? textSizeMap.get(size) : '';
  const translatedText = translate(text);

  return (
    <div className={`empty-element-feedback__container ${customClass}`}>
      {!canSeeTextOnly && (
        <div className="empty-element-feedback__content">
          <div className="empty-element-feedback__logo">
            <Logo viewBox="0 0 150 142" />
          </div>
          <div className={`empty-element-feedback__content-text ${textSizeClass}`}>
            {translatedText}
          </div>
        </div>
      )}

      {canSeeTextOnly && (
        <div className={`empty-element-feedback__text ${textSizeClass}`}>
          {translatedText}
        </div>
      )}
    </div>
  );
};
