import { Theme, ThemeHTML } from 'types';

import { setStorageItem, getStorageItem } from '../../storage/localStorage.js';


class ThemeService  {
  private htmlElement = document.querySelector('html') || null;
  private themeInLocalStorage = getStorageItem(ThemeHTML.KEY) as Theme | undefined;

  private setThemeInLocalStorage(theme: Theme) {
    setStorageItem(ThemeHTML.KEY, theme);
  }

  constructor() {
    this.setTheme = this.setTheme.bind(this);
    this.getTheme = this.getTheme.bind(this);
  }

  setTheme(theme: Theme) {
    if (this.htmlElement) {
      this.setThemeInLocalStorage(theme);
      this.htmlElement.className = theme;
    }
  }

  getTheme(): Theme {
    return this.themeInLocalStorage || this.htmlElement?.className as Theme;
  }
}

export default new ThemeService();
