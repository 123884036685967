import React from 'react';
import { Loader } from 'semantic-ui-react';

import './MatchListLoader.scss';


export const MatchListLoader = () => (
  <li className="match-list-loader">
    <Loader active inline />
  </li>
);
