export enum MatchStatus {
  Cancelled = 'cancelled',
  Scheduled = 'scheduled',
  Interrupted = 'interrupted',
  Live = 'live',
  Finished = 'finished',
}

export enum MatchHalfName {
  RegularTime = 'Regular time',
  FirstHalf = '1st half',
  SecondHalf = '2nd half',
  HalfTime = 'Halftime',
}

export enum MatchStatusName {
  Postponed = 'Postponed',
  Cancelled = 'Cancelled',
  NotStarted = 'Not started',
  Finished = 'Finished',
  FinishedAfterPenalties = 'Finished after penalties',
  FinishedAfterExtratime = 'Finished after extratime',
  ToFinish = 'To finish',
  '2ndHalf' = '2nd half',
  Abandoned = 'Abandoned',
  '1stHalf' = '1st half',
  Halftime = 'Halftime',
  FinishedAwardedWin = 'Finished awarded win',
  Extratime1stHalf = 'Extratime 1st half',
  Extratime2ndHalf = 'Extratime 2nd half',
  ExtratimeHalftime = 'Extratime halftime',
  WaitingForExtratime = 'Waiting for extratime',
  Extratime = 'Waiting for extratime',
  PenaltyShootout = 'Penalty shootout',
}

export enum TeamScore {
  Home = 'home',
  Away = 'away',
  Draw = 'draw',
}
