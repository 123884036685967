import React, { FC } from 'react';
import { Popup } from 'components';
import { Link } from 'react-router-dom';
import { Season, Size } from 'types';
import { Badge } from 'views/Dashboard/shared/Badge/Badge';
import { TournamentRedirectController } from 'views/shared/Tournament/TournamentRedirectController';

import './LiveMatchTournament.scss';


type Props = {
  season: Season;
  badgeURL?: string;
}

const NAME_MAX_LENGTH = 20;

export const LiveMatchTournament: FC<Props> = ({ season }) => {
  return (
    <TournamentRedirectController season={season}>
      {({ link, name, competitionBadgeUrl }) => {
        const Name = (
          <span className="live-match-tournament__text">
            {name}
          </span>
        );
        return (
          <div className="live-match-tournament">
            <Link
              to={link}
              className="live-match-tournament__link"
            >
              <Badge
                alt={`${name} badge`}
                size={Size.Tiny}
                customStyles={competitionBadgeUrl ?
                  'live-match-tournament__badge margin-right--size-s' :
                  'margin-right--size-s'}
                badgeURL={competitionBadgeUrl}
              />
              {name.length > NAME_MAX_LENGTH ? (
                <Popup
                  position="top left"
                  content={name}
                  trigger={Name}
                />
              ) : (
                Name
              )}
            </Link>
          </div>
        );
      }}
    </TournamentRedirectController>
  );
};
