import React from 'react';

import { DashboardModalController } from '../shared';

import { MatchesFiltersSorting } from './Sort/MatchListSort';
import { MatchListFilterPrediction } from './Filter/MatchListFilterPrediction';
import { MatchListFilterPredictionDisplayController } from './Filter/MatchListFilterPredictionDisplayController';
import { MatchListFilterSectionController } from './MatchListFilterSectionController';
import {
  MatchListFilterCalendar,
  MatchListFilterState,
  MatchListFilterFavourite,
  MatchListFilterOddsen,
} from './Filter';

import './MatchListFilterSection.scss';


export const MatchListFilterSection = () => {
  return (
    <section className="match-list-filter-section">
      <div className="match-list-filter-section__container">
        <MatchListFilterOddsen />
        <MatchListFilterFavourite />
        <MatchListFilterState />
        <MatchListFilterCalendar  />
      </div>
      <div className="match-list-filter-section__sorting">
        <MatchListFilterSectionController>
          {filtersProps => (
            <>
              <DashboardModalController>
                {props => (
                  <MatchesFiltersSorting {...props} {...filtersProps} />
                )}
              </DashboardModalController>
              <DashboardModalController>
                {handlerProps => {
                  const { handleCollapse } = handlerProps;

                  return (
                    <MatchListFilterPredictionDisplayController handleCollapse={handleCollapse}>
                      {props => (<MatchListFilterPrediction {...props} {...handlerProps} />)}
                    </MatchListFilterPredictionDisplayController>
                  );
                }}
              </DashboardModalController>
            </>
          )}
        </MatchListFilterSectionController>
      </div>
    </section>
  );
};
