import { useCallback, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { Basket, BasketItem } from 'types';
import { UseFormSetValue } from 'react-hook-form';
import { useBasket } from 'context/basket/basket-context';
import { useSocketsLiveOddList } from 'context/sockets/sockets-live-odd-list-context';
import { useBasketConfig } from 'context/basket/basket-config-context';

import { handleGetUpdateList } from '../shared/handleGetUpdateList';


type Props = {
  setFormValue: UseFormSetValue<Basket>;
}

export const useUpdateBasketWithLiveOddList = ({ setFormValue }: Props) => {
  const { items, setItems } = useBasket();
  const { configuration } = useBasketConfig();
  const { provider: { name: providerName } } = configuration;

  const { liveOddListSocket } = useSocketsLiveOddList();

  const syncedList = () => {
    const getUpdateList = handleGetUpdateList(providerName);

    if (getUpdateList) {
      return getUpdateList(liveOddListSocket, items);
    }
    return [];
  };

  const memoSyncedList = useMemo(syncedList, [liveOddListSocket, items, providerName]);

  const addNewItems = (items: BasketItem[]) => setItems(items);
  const memoAddNewItems = useCallback(addNewItems, []);

  const shouldListUpdate = useMemo(() => {
    return items.some((basketItem, index) => {
      const notEqual = !isEqual(basketItem, memoSyncedList[index]);

      return notEqual;
    });
  }, [items, memoSyncedList]);

  useEffect(() => {
    if (shouldListUpdate) {
      memoAddNewItems(memoSyncedList);
      setFormValue('items', memoSyncedList);
    }
  }, [memoSyncedList, memoAddNewItems, shouldListUpdate, setFormValue]);
};
