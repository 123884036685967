import { GetUser, UpdateUserProps, UserChangePasswordProps, UserResetPasswordProps } from 'api/UserClient/types';
import { AxiosPromise, AxiosRequestConfig } from 'axios';

import api from '../services/FetchService';

import * as query from './queries';


export class UserClient {
  updateUser(props: UpdateUserProps): AxiosPromise<GetUser> {
    const options: AxiosRequestConfig = {
      ...query.updateLoggedUser,
      data: {
        ...props,
      },
    };

    return api.request(options);
  }

  changePassword(props: UserChangePasswordProps): AxiosPromise<GetUser> {
    const options: AxiosRequestConfig = {
      ...query.changePassword,
      data: {
        ...props,
      },
    };

    return api.request(options);
  }

  resetPassword(props: UserResetPasswordProps): AxiosPromise<void> {
    const options: AxiosRequestConfig = {
      ...query.resetPassword,
      data: {
        ...props,
      },
    };

    return api.request(options);
  }
}
