const formFields = {
  ACTIVATION_CODE: 'Activation code',
  BIRTH_DATE: 'Birthdate',
  EMAIL: 'Email',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  PASSWORD: 'Password',
  REPEATED_PASSWORD: 'Repeated password',
  TERMS_AND_POLICY: 'Terms of Service and Privacy Policy',
  CREATE_YOUR_ACCOUNT: 'Create your account',
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  LOGIN_WITH_FACEBOOK: 'Login with Facebook',
  LOGIN_WITH_GOOGLE: 'Login with Google',
  SIGN_UP_WITH_FACEBOOK: 'Sign up with Facebook',
  SIGN_UP_WITH_GOOGLE: 'Sign up with Google',
  SIGN_UP_TO_UNLOCK: 'Sign up to unlock prediction',
  LOGIN: 'Login',
  OR: 'or',
  SIGN_UP: 'Sign up',
  FORGOT_PASSWORD_TITLE: 'Forgot your password? Don\'t worry!',
  RESET_MESSAGE_CONFIRMATION: 'A message has been sent to you be email with instructions how to reset your password.',
  RESET_MESSAGE_HINT: `Type the address linked to your account. We'll email you instructions to reset your password.
        They might end up in your spam folder, so please check there as well.`,
  RETURN_TO_LOGIN: 'Return to login',
  RESET_PASSWORD: 'Reset Password',
  CHECK_YOUR_EMAIL: 'Check the email we just sent to ',
  FOR_ACTIVATION: ' to activate your account',
  CREATING_ACCOUNT: 'Creating an  account means you’re okay with our ',
  TERMS_OF_SERVICE: 'Terms of Service',
  OUR: 'and our ',
  PRIVACY_POLICY: 'Privacy Policy',
  USER_HAVE_AN_ACCOUNT: 'Already have an account?',
  NEW_PASSWORD: 'New password',
  REPEAT_PASSWORD: 'Repeat password',
  CHANGE_PASSWORD: 'Change password',
  ACTIVE_NOW: 'Your account is active now', // Activation
  EMAIL_ADDRESS: 'Email address',
  FEMALE: 'Female',
  GENDER: 'Gender',
  LOGOUT: 'Logout',
  MALE: 'Male',
  SAVE_CHANGES: 'Save changes',
  UNSPECIFIED: 'Unspecified',
  DATA_CHANGED: 'Account data has been changed!', // User
  OTHER: 'other',
  GO_MAIN: 'Go to main page', // Tournament
  NO_DATA: 'No data available', // empty feedback
  GOT_IT: 'Got it!',
};

export default formFields;
