import React, { ReactElement } from 'react';
import { Odd, OddOdd, OddsOverUnder, Match } from 'types';

import { MatchDetailsPredictionRow } from '../../Prediction/MatchDetailsPredictionRow';


type TranslateProps = (toTranslate: string) => string;

export const displayLessThanGoalsLabel = (translate: TranslateProps) => (index: number) => {
  if (index === 0) {
    return translate('ZERO_GOALS');
  }

  return `${translate('UNDER')} ${index + 0.5}`;
};

export const getLessThanGoalsTypeValue = (index: number) => {
  return `${index + 0.5}`;
};


export const displayMoreThanGoalsLabel = (translate: TranslateProps) => (index: number) => {
  if (index === 0) {
    return translate('ZERO_GOALS');
  }

  return `${translate('OVER')} ${index - 0.5}`;
};


export const getMoreThanGoalsTypeValue = (index: number) => {
  return `${index - 0.5}`;
};


type SetRenderRowsProps = {
  isOddInBasket: (odd: OddOdd) => boolean;
  match: Match;
  handleToggleOddInBasket: ({ odd }: { odd?: Odd }) => ({ oddOdds }: { oddOdds: OddOdd }) => () => void;
  getOdd: (label: string) => Odd | undefined;
};

type RenderProps = {
  list: number[];
  getLabel: (index: number) => string;
  oddType: OddsOverUnder;
  getTypeValueLabel: (index: number) => string;
}

export const setRenderRowsProps = ({ isOddInBasket, handleToggleOddInBasket, getOdd, match }: SetRenderRowsProps) =>
  ({ list, getLabel, getTypeValueLabel, oddType }: RenderProps): ReactElement => {
    return (
      <>
        {list.map((goalPrediction, index) => {
          const label = getLabel(index);
          const typeValue = getTypeValueLabel(index);
          const foundOdd = getOdd(typeValue);
          const oddByOddType = foundOdd?.odds?.[oddType];

          return (
            <MatchDetailsPredictionRow
              match={match}
              key={label}
              label={label}
              prediction={goalPrediction}
              odd={oddByOddType}
              onOddClick={handleToggleOddInBasket({ odd: foundOdd })}
              isOddInBasket={isOddInBasket}
            />
          );
        })}
      </>
    );
  };
