import { useEffect, useCallback } from 'react';
import { BasketItem } from 'types';

import { BasketMessage } from './BasketMessage.type';


export const useSendUpdatedState = (items: BasketItem[]) => {
  const sendUpdatedState = () => {
    const event = new CustomEvent(BasketMessage.State, { detail: items });

    window.dispatchEvent(event);
  };

  const memoSendUpdatedState = useCallback(sendUpdatedState, [items.length]);

  useEffect(() => {
    memoSendUpdatedState();
  }, [memoSendUpdatedState]);
};
