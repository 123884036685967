import React, { FC } from 'react';

import { Event } from '../../../types';

import { MatchStatusWithTime } from './Match/MatchStatusWithTime';

import './MatchVariantKickOffMatchInfo.scss';


type Props = {
  event: Event;
};

export const MatchVariantKickOffMatchInfo: FC<Props> = ({ event }) => {
  const { startDate, status, statusName, clockTime = null } = event;

  return (
    <div className="match-variant-kick-off-match-info__container">
      <div className="match-variant-kick-off-match-info__time">
        <MatchStatusWithTime
          startDate={startDate}
          status={status}
          statusName={statusName}
          clockTime={clockTime}
        />
      </div>
    </div>
  );
};
