import { endOfDay, format, formatISO, startOfDay, subDays } from 'date-fns';
import { sv, nn, enGB } from 'date-fns/locale';

export const dateTranslations = new Map()
  .set('sv', sv)
  .set('no', nn)
  .set('en', enGB);

export const YEAR_MONTH_DAY = 'yyyy-MM-dd';
export const YEAR_MONTH_DAY_TIME = 'yyyy-MM-dd HH:mm';
export const TIME_HOUR_MINUTES = 'HH:mm';
export const DAY_OF_THE_WEEK_WITH_MONTH = 'cccc dd MMMM';
export const MONTH_YEAR = 'MMMM yyyy';
export const DAY = 'dd';


export const getToday = () => {
  return format(new Date(), YEAR_MONTH_DAY);
};

export const getYesterday = () => {
  return format(subDays(new Date(), 1), YEAR_MONTH_DAY);
};

export const formatDate = (date, options = {}) => format(new Date(date), YEAR_MONTH_DAY, options);

export const formatDateWithTime = (date, options = {}) => format(new Date(date), YEAR_MONTH_DAY_TIME, options);

export const getTimeFromDate = date => format(new Date(date), TIME_HOUR_MINUTES);

export const formatDateWithDayOfTheWeekAndMonth = (date, options = {}) => format(new Date(date), DAY_OF_THE_WEEK_WITH_MONTH, options);

export const formatMonthAndYear = (date, options = {}) => format(new Date(date), MONTH_YEAR, options);

export const formatDay = date => format(new Date(date), DAY);

export const getMinutesForOngoingMatch = seconds => Math.trunc(seconds / 60);

export const isClockTime = clockTime => clockTime >= 0;

export const formatDateToISO = date => formatISO(new Date(date));

export const startOfSelectedDay = date => {
  return startOfDay(date);
};
export const endOfSelectedDay = date => {
  return endOfDay(date);
};
