import React from 'react';

import { ReactComponent as Soccer } from '../../../assets/icons/sportsBar/soccer.svg';
import { ReactComponent as Hockey } from '../../../assets/icons/sportsBar/hockey.svg';
import { SportsName } from '../../../types';


export const sportsBarIconsMap = new Map<SportsName, (props?: Record<string, unknown>) => React.ReactNode>();

sportsBarIconsMap.set(SportsName.Soccer, props => <Soccer {...props} />);
sportsBarIconsMap.set(SportsName.Hockey, props => <Hockey {...props} />);
