import React, { FC, useMemo } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Big from 'big.js';
import { Basket as TBasket, BasketMode, BasketItemGroup, BasketItem, BasketCheckoutItem } from 'types';
import { Modal } from 'semantic-ui-react';

import { useTranslate } from '../../context/translate-context';
import { Tabs } from '..';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';

import { BasketController } from './BasketController';
import { ModeTab } from './components/ModeTab';
import { ModeFooter } from './components/ModeFooter';
import { GeneratedBets } from './components/GeneratedBets';
import { GroupCombinations } from './components/GroupCombinations';
import { BasketErrorsController } from './errors/BasketErrorsController';

import './Basket.scss';


type Props = {
  tabs: { name: BasketMode; label: any }[];
  basketItems: BasketItem[];
  generatedBets: BasketCheckoutItem[];
}

export const ExpandedBasket: FC<Props> = ({ tabs, basketItems, generatedBets }) => {
  const { translate } = useTranslate();

  const { control, register, watch, setValue, reset, handleSubmit } = useForm<TBasket>({
    defaultValues: {
      mode: BasketMode.Single,
      items: basketItems,
      stakePerBet: '-',
      stakePerCombo: '-',
      totalStake: basketItems.reduce((acc, i) => acc.add(i.betValue), new Big(0)).toFixed(2),
      groupBetValues: Object.keys(BasketItemGroup).reduce((o, key) => Object.assign(o, { [key]: [] }), {}),
    },
  });

  const { fields, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const { mode, stakePerBet, stakePerCombo, totalStake, groupBetValues } = watch();

  const formFields = useMemo(() => ({
    mode,
    // Necessary because fields is not updated after mount and items only keeps instantiated form fields
    items: fields.map((f, index) => ({ ...f, ...basketItems[index] })),
    stakePerBet,
    stakePerCombo,
    totalStake,
    groupBetValues,
  }), [mode, fields, basketItems, stakePerBet, stakePerCombo, totalStake, groupBetValues]);


  return (
    <BasketController
      setValue={setValue}
      remove={remove}
      reset={reset}
      fields={formFields}
    >
      {({
        isOpen,
        closeBasket,
        submitBasket,
        clearItems,
        onItemRemove,
        isSystemBetsGenerated,
      }) => (
        <Modal
          open={isOpen}
          onClose={closeBasket}
          className="basket-modal"
          style={{ margin: 'unset' }}
        >
          <div
            className="basket"
          >
            <div className="basket__close-wrapper">
              <div className="basket__close" onClick={closeBasket} role="button">
                <span className="basket__close--label">
                  {translate('CLOSE')}
                </span>
                <CloseIcon className="icon-color--first" />
              </div>
            </div>
            <form id="basket-form" onSubmit={handleSubmit(submitBasket)} className="basket__wrapper">
              <BasketErrorsController fields={formFields}>
                <>
                  <div className={`
                  basket__header
                  ${isSystemBetsGenerated ? 'basket__header--generated' : ''}
                `}
                  >
                    <div className="basket__actions">
                      <Controller
                        control={control}
                        name="mode"
                        render={({
                          field: { onChange, value },
                        }) => (
                          <Tabs
                            active={value}
                            tabs={tabs}
                            onTabClick={onChange}
                          />
                        )}
                      />
                      <button type="button" className="basket__clear" onClick={clearItems}>
                        <TrashIcon className="icon-color--third" />
                      </button>
                    </div>
                    <ModeTab
                      setValue={setValue}
                      control={control}
                      register={register}
                      onItemRemove={onItemRemove}
                      fields={formFields}
                    />
                  </div>
                  {isSystemBetsGenerated ? (
                    <GeneratedBets basket={generatedBets} />
                  ) : (
                    <div className="basket__footer">
                      {mode === BasketMode.System && (
                        <GroupCombinations
                          control={control}
                          register={register}
                          list={formFields.items}
                        />
                      )}
                      <ModeFooter
                        control={control}
                        setValue={setValue}
                        fields={formFields}
                      />
                    </div>
                  )}
                </>
              </BasketErrorsController>
            </form>
          </div>

        </Modal>
      )}
    </BasketController>
  );
};
