import { useMatch } from 'context/match-context';
import React, { FC, memo, ReactElement } from 'react';
import { MatchStatus } from 'types';


type Props = {
  ComponentWithLiveUpdate: FC;
  children: ReactElement;
}

export const MatchDetailsSideBetsHandleWhenUpdate: FC<Props> = memo(({ ComponentWithLiveUpdate, children }) => {
  const { selectedMatch } = useMatch();
  const { status } = selectedMatch?.event || {};

  const isMatchLive = status === MatchStatus.Live;

  if (isMatchLive) {
    return <ComponentWithLiveUpdate />;
  }
  return children;
});
