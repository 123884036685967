import React from 'react';
import { useConfig } from 'context/config-context';
import { ReactComponent as OddsenLogo } from 'assets/images/odds-label-norsk.svg';
import { ReactComponent as OddsenLogoSvenska } from 'assets/images/odds-label-svenska.svg';
import { OddsProvider } from 'types';
import { useTranslate } from 'context/translate-context';

import { MatchListFilterButton } from './MatchListFilterButton';
import { MatchListFilterOddsenController } from './MatchListFilterOddsenController';

import './MatchListFilter.scss';

const oddsButtonVariantMap = new Map();
oddsButtonVariantMap.set(OddsProvider.NorskTipping, OddsenLogo);
oddsButtonVariantMap.set(OddsProvider.SvenskaSpel, OddsenLogoSvenska);


export const MatchListFilterOddsen = () => {
  const { configuration } = useConfig();
  const { translate } = useTranslate();
  const providerName = configuration.provider?.name;

  if (!providerName) {
    return null;
  }

  const OddsLabel = oddsButtonVariantMap.get(providerName);

  return (
    <MatchListFilterOddsenController>
      {({ isSelected, handleRedirect }) => (
        <MatchListFilterButton
          ariaLabel={translate('ODDS_FILTER')}
          isSelected={isSelected()}
          callback={() => handleRedirect()}
        >
          <div className="match-list-filter__wrapper">
            <div className="match-list-filter__icon match-list-filter__provider">
              <OddsLabel className="match-list-filter__provider" fill="var(--SaasText01)" />
            </div>
          </div>
        </MatchListFilterButton>
      )}
    </MatchListFilterOddsenController>
  );
};
