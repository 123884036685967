import { AxiosRequestConfig } from 'axios';

const url = `${window.__RUNTIME_CONFIG__.API_URL}/users/favourites`;

export const getFavourites: AxiosRequestConfig = {
  method: 'GET',
  url,
};
export const createFavourite: AxiosRequestConfig = {
  method: 'POST',
  url,
};
export const deleteFavourite: AxiosRequestConfig = {
  method: 'DELETE',
  url,
};
