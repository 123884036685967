import React from 'react';
import { useTranslate } from 'context/translate-context';

import './MatchDetailsSideBetsOddsLabel.scss';


export const MatchDetailsSideBetsOddsLabel = () => {
  const { translate } = useTranslate();

  return (
    <div className="match-details-side-bets-odds__label">
      {translate('ODDS')}
    </div>
  );
};
