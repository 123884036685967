import { FC, ReactElement } from 'react';

import { useTournaments } from '../../../../context/tournament-list-context';
import { Tournament } from '../../../../types';


type Props = {
  children(props: {
    tournaments: Tournament[];
    areTournamentsLoading: boolean;
    continentTournaments: Tournament[];
    areContinentTournamentsLoading: boolean;
  }): ReactElement;
};

export const TournamentListController: FC<Props> = ({ children }) => {
  const {
    tournaments,
    continentTournaments,
    areTournamentsLoading,
    areContinentTournamentsLoading,
  } = useTournaments();

  const props = {
    tournaments,
    areTournamentsLoading,
    continentTournaments,
    areContinentTournamentsLoading,
  };

  return children(props);
};
