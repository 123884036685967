import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { Season } from '../../../types';
import { PlacholderFlag, Popup } from '../../../components';

import './MatchVariantKickOffCountry.scss';


type Props = {
  season: Season;
  getRedirectURL: (id: string) => string;
}

export const handleDisplayLongText = ({ text, maxLength }: { text: string; maxLength: number }) => {
  const isNameLengthMoreThanMaxLength = text.length >= maxLength;

  const handleDisplay = isNameLengthMoreThanMaxLength
    ? (
      <Popup
        content={text}
        trigger={
          <div
            className="match-variant-kick-off-country__text"
          >
            {`${text.slice(0, maxLength)}...`}
          </div>
        }
      />
    )
    : <div className="match-variant-kick-off-country__text">{text}</div>;

  return handleDisplay;
};

export const MatchVariantKickOffCountry: FC<Props> = ({ season, getRedirectURL }) => {
  const {
    area: { badgeUrl: areaBadgeUrl, name: areaName },
    competition: { id, name: competitionName, shortName },
  } = season;

  return (
    <div className="match-variant-kick-off-country__container">
      <PlacholderFlag
        areaBadgeUrl={areaBadgeUrl}
      />
      <div className="margin-left--size-s">
        {handleDisplayLongText({ text: areaName, maxLength: 20 })}
      </div>
      <div className="margin-left--size-s margin-right--size-s">
        <Icon
          style={{ fontSize: '9px' }}
          name="chevron right"
          className="margin--unset no-hover"
        />
      </div>
      <Link className="match-variant-kick-off-country__link"
        to={getRedirectURL(id)}
      >
        <div
          className="match-variant-kick-off-country__text"
        >
          {shortName || competitionName}
        </div>
      </Link>
    </div>
  );
};
