import React, { FC } from 'react';
import { Theme } from 'types';

import { useTheme } from '../../context/theme-context';
import { ReactComponent as Sun } from '../../assets/icons/sun.svg';
import { ReactComponent as Moon } from '../../assets/icons/moon.svg';

import './ThemeMode.scss';


type Props = {
  customClass?: string;
}

const isActive = (currentTheme: Theme, mode: Theme) => {
  return currentTheme === mode ? 'theme-mode--active' : 'theme-mode--inactive';
};

export const ThemeMode: FC<Props> = ({ customClass }) => {
  const { handleChangeTheme, theme } = useTheme();

  return (
    <div className={`theme-mode__container ${customClass}`}>
      <button className="theme-mode__switch" onClick={handleChangeTheme} aria-label="color theme switch">
        <div
          className={`theme-mode__element theme-mode--dark ${theme ? isActive(theme, Theme.LIGHT) : ''}`}
          style={{ transform: `translateX(${theme !== Theme.LIGHT ? '5px' : '0px'})` }}
        >
          <Sun />
        </div>
        <div
          className={`theme-mode__element theme-mode--light ${theme ? isActive(theme, Theme.DARK) : ''}`}
          style={{ transform: `translateX(${theme !== Theme.DARK ? '-5px' : '0px'})` }}
        >
          <Moon />
        </div>
      </button>
    </div>
  );
};
