import React, { FC, ReactElement } from 'react';
import { Icon } from 'semantic-ui-react';
import { Favourite } from 'types';
import { noop } from 'lodash';
import { isEmpty } from 'lodash';

import { DashboardDropdownHandlerController } from '../../DashboardDropdownHandlerController';
import { TournamentCategory } from '../shared/TournamentCategory';
import { FavouriteListController } from '../../shared/FavouriteListController';


type Props = {
  children(props: {
    favourites: Favourite[];
  }): ReactElement;
}

export const TournamentFavouriteListSection: FC<Props> = ({ children }) => {
  return (
    <FavouriteListController>
      {({ favourites, handleRedirect }) => {
        return (
          <DashboardDropdownHandlerController initialState={Boolean(favourites.length)}>
            {({ isOpen, toggleDropdown }) => (
              <>
                <TournamentCategory
                  icon={<Icon name="star" className="margin--unset" />}
                  label="FAVOURITES"
                  listLength={favourites.length}
                  handleClick={!isEmpty(favourites) ? toggleDropdown : noop}
                  isOpen={isOpen}
                  handleSelect={() => handleRedirect()}
                />
                {isOpen && children({ favourites })}
              </>
            )}
          </DashboardDropdownHandlerController>
        );
      }}
    </FavouriteListController>
  );
};
