import React, { FC } from 'react';

import { useTranslate } from '../../../../context/translate-context';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error.svg';

import './InvalidBasketFeedback.scss';

type Props = {
  title: string;
  text: string;
};

export const InvalidBasketFeedback: FC<Props> = ({ title = '', text = 'NO_DATA' }) => {
  const { translate } = useTranslate();

  return (
    <section className="invalid-basket-feedback__container">
      <div className="invalid-basket-feedback__content">
        <div className="invalid-basket-feedback__icon">
          <ErrorIcon />
        </div>
        <span className="invalid-basket-feedback__title">{translate(title)}</span>
        <span className="invalid-basket-feedback__text">{translate(text)}</span>
      </div>
    </section>
  );
};
