import React from 'react';
import { useTranslate } from 'context/translate-context';

import './MatchListMissingSelectedTournamentFeedback.scss';


export const MatchListMissingSelectedTournamentFeedback = () => {
  const { translate } = useTranslate();

  return (
    <div className="match-list-missing-selected-tournament-feedback__container">
      {translate('NO_MATCHES_AVAILABLE')}
    </div>
  );
};
