import React from 'react';

import { useTranslate } from '../../context/translate-context';
import { ReactComponent as Logo } from '../../assets/images/sportxpert-logo-brand-mark.svg';
import Popup from '../Popup';

import './XpertType.scss';


export const XpertType = () => {
  const { translate } = useTranslate();

  return (
    <Popup
      content={translate('The most possible score by Xpert')}
      trigger={
        <div className="xpert-type__container">
          <div className="xpert-type__logo-container">
            <Logo />
          </div>
          <div className="xpert-type__label">{translate('OUR_TYPE')}</div>
        </div>
      }
    />
  );
};
