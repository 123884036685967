import { FC, ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { addQuery } from 'utils/queries';
import { Noop } from 'types';
import { useMatch } from 'context/match-context';
import { useTranslate } from 'context/translate-context';

import { useMatchesFilters } from '../useMatchesFilters';
import { DASHBOARD_ROUTING } from '../../Routing/DashboardRouting';


type Props = {
  children(props: {
    handleResetTournamentList: Noop;
  }): ReactElement;
};

export const MatchListFilterClearTournamentListController: FC<Props> = ({ children }) => {
  const { push } = useHistory();
  const { translate } = useTranslate();
  const { pathname, search } = useLocation();
  const { setSelectedMatch } = useMatch();
  const { filters } = useMatchesFilters();
  const { season } = filters;
  const defaultURL = DASHBOARD_ROUTING.getDefaultDashboardURL();

  const handleResetTournamentList = () => {
    if (season?.length > 0) {
      push({
        pathname: defaultURL,
        search: addQuery(search, {
          season: [],
        }),
      });
      setSelectedMatch(null);

      return toast.success(translate('TOURNAMENT_LIST_CLEARED'));
    }

    return !pathname.includes(defaultURL) ?
      push({ pathname: defaultURL }) :
      toast.warning(translate('FILTERS_NOT_CHANGED'));
  };

  const props = { handleResetTournamentList };

  return children(props);
};
