import { MatchDisplayPrediction, OddOdd } from 'types';


export type DisplayPredictionRegularProps = (prediction: number) => string;
export type DisplayPredictionRatioProps = (prediction: number, odd: OddOdd['odds']) => string;

export const getPercent: DisplayPredictionRegularProps = value => `${Math.round(value * 100)}%`;

const getDecimalFraction: DisplayPredictionRegularProps = number => {
  if (!number) {
    return (0).toFixed(2);
  }
  return (1 / number).toFixed(2);
};

const getRatio: DisplayPredictionRatioProps = (prediction, odd) => {
  const ratio = prediction * odd;

  return ratio.toFixed(2);
};

export const DISPLAY_PREDICTION_HANDLER = {
  [MatchDisplayPrediction.PERCENT]: getPercent,
  [MatchDisplayPrediction.DECIMAL_FRACTION]: getDecimalFraction,
  [MatchDisplayPrediction.RATIO]: getRatio,
};


