import { isEmpty } from 'lodash';
import { OddsTypePeriod, Prediction, Odd, OddOdd, MatchTime } from 'types';


export const createScoreName = ({ home, away }: { home: number; away: number }) => `score__${home}_${away}`;

export const limitTeamScoresByRange = (limitInstruction: { start: number; end: number }) =>
  ({ home, away }: { home: number; away: number }) => {
    const { start, end } = limitInstruction;

    return (start >= home || home <= end) && (start >= away || away <= end);
  };

export const limitTeamScoresByValues = (limitInstruction: { start: number; end: number }) =>
  ({ home, away }: { home: number; away: number }) => {
    const { start, end } = limitInstruction;
    const limit = [start, end];

    return limit.includes(home) && limit.includes(away);
  };

export const isPredictionObjectAvailable = (specificPredictions: string[]) => (predictions: Prediction) => {
  return specificPredictions.some(predictionKey => isEmpty(predictions?.[predictionKey as keyof Prediction] || {}));
};

export const findOddFrom = (list: Odd[]) =>
  (column: MatchTime, label: string) =>
    list.find(({ odds }) => {
      return odds[column].name === label;
    });

export const overUnderFindOddByTypeValue = (list: Odd[], oddTypePeriod: OddsTypePeriod) =>
  (label: string) => {
    const foundOdd = list
      .filter(odd => odd.typePeriod === oddTypePeriod)
      .find(odd => {
        return odd.typeValue === label;
      });

    return foundOdd;
  };

export const findOddFromCorrectScore = (list: Odd[]) => (oddTimePeriod: Odd['typePeriod'], label: string) => {
  const foundByOddTimePeriod = list.find(odd => {
    const { typePeriod } = odd;

    return typePeriod === oddTimePeriod;
  });

  if (foundByOddTimePeriod) {
    const foundByName = Object.entries(foundByOddTimePeriod.odds).find(([, odd]) => {
      return odd.name === label;
    }) || [];

    const [, odd] = foundByName;

    return odd as OddOdd;
  }
};


export const activeTabToOddsPeriod = new Map();
activeTabToOddsPeriod.set(MatchTime.RegularTime, OddsTypePeriod.Fulltime);
activeTabToOddsPeriod.set(MatchTime.FirstHalf, OddsTypePeriod.FirstHalf);
activeTabToOddsPeriod.set(MatchTime.SecondHalf, OddsTypePeriod.SecondHalf);
