import React from 'react';
import { useHighlightHighestValue } from 'views/Dashboard/shared/hooks/useHighlightHighestValue';

import { TEAM_SCORE } from '../../../../constants';

import { handleLimitByTwoValues } from './matchDetailsMatchStatsListRowServices';

import './MatchDetailsMatchStatsList.scss';


export const MatchDetailsMatchStatsListRow = ({ homeValue, label, awayValue }) => {
  const scores = [homeValue, awayValue];
  const limit = handleLimitByTwoValues(scores);
  const { handleHighlightWhenHighestValueIsEqualTo } = useHighlightHighestValue(scores, 'match-details-stats__progress--highlighted');

  return (
    <li className="match-details-stats__stat-container match-details-stats__element-grid">
      <Home homeHigher statValue={homeValue} limit={limit} highlightClass={handleHighlightWhenHighestValueIsEqualTo(homeValue)} />
      <div className="match-details-stats__label"> {label} </div>
      <Away statValue={awayValue} limit={limit}  highlightClass={handleHighlightWhenHighestValueIsEqualTo(awayValue)} />
    </li>
  );
};

const Home = ({ statValue, limit, highlightClass }) => {
  const isHighlighted = Boolean(highlightClass) ? 'match-details-stats__bar--highlighted' : '';

  return (
    <div className={`match-details-stats__bar match-details-stats__bar--home ${isHighlighted}`}>
      <div className="match-details-stats__stat-value match-details-stats__stat-value--home">
        {statValue}
      </div>
      <ProgressBar statValue={statValue} limit={limit} team={TEAM_SCORE.HOME} highlightClass={highlightClass} />
    </div>
  );
};

const Away = ({ statValue, limit, highlightClass }) => {
  const isHighlighted = Boolean(highlightClass) ? 'match-details-stats__bar--highlighted' : '';

  return (
    <div className={`match-details-stats__bar match-details-stats__bar--away ${isHighlighted}`}>
      <div className="match-details-stats__stat-value match-details-stats__stat-value--away">
        {statValue}
      </div>
      <ProgressBar statValue={statValue} limit={limit} team={TEAM_SCORE.AWAY} highlightClass={highlightClass} />
    </div>
  );
};


const ProgressBar = ({ statValue, limit, team = TEAM_SCORE.HOME, highlightClass }) => {
  const progress = `${(Math.floor(statValue) / limit) * 100}%`;
  const handleClassByTeam = team === TEAM_SCORE.HOME ?
    'match-details-stats__progress--home' :
    'match-details-stats__progress--away';

  return (
    <div
      className={`match-details-stats__progress ${handleClassByTeam} ${highlightClass}`}
      style={{ width: progress }}
    />
  );
};
