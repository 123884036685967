import { useConfig } from 'context/config-context';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  MatchSort,
  MatchQueryFilters,
  SportsName,
  Event,
  MatchSelectedFilter,
} from 'types';
import { formatDate, parseQuery } from 'utils';


export const useMatchesFilters = () => {
  const query = useLocation().search.slice(1);
  const { configFilters } = useConfig();

  const filters: MatchQueryFilters = useMemo(() => {
    const parsedQuery = parseQuery(query);
    const {
      from = formatDate(new Date()),
      order = MatchSort.TOURNAMENT_ASC,
      season = [],
      sportName = [],
      status = [],
      to = formatDate(new Date()),
      selected = [],
    } = parsedQuery || {};

    const seasonCasted = season as string[];
    const orderCasted = order as MatchSort;
    const fromCasted = from as string;
    const toCasted = to as string;
    const sportCasted = sportName as SportsName[];
    const statusCasted = status as Event['status'][];
    const selectedCasted = selected as MatchSelectedFilter[];

    return {
      season: seasonCasted,
      order: orderCasted,
      from: fromCasted,
      to: toCasted,
      sportName: sportCasted,
      status: statusCasted,
      selected: selectedCasted,
    };
  }, [query]);


  const defaultSportName = configFilters?.sportName || [SportsName.Soccer];
  const DEFAULT_FILTERS: MatchQueryFilters = {
    order: MatchSort.TOURNAMENT_ASC,
    from: formatDate(new Date()),
    to: formatDate(new Date()),
    season: [],
    sportName: defaultSportName,
    status: [],
    selected: [],
  };

  return {
    DEFAULT_FILTERS,
    filters,
  };
};
