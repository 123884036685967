import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Dashboard } from '../Dashboard';

import { DASHBOARD_ROUTE } from './Routing';


export const DashboardRouting = () => {
  return (
    <Switch>
      <Route path={DASHBOARD_ROUTE.DASHBOARD_MATCHES} component={Dashboard} />
    </Switch>
  );
};


const getDefaultDashboardURL = () => {
  return DASHBOARD_ROUTE.DASHBOARD_MATCHES;
};


export const DASHBOARD_ROUTING = {
  getDefaultDashboardURL,
};
