import { MatchLiveIncident, IncidentCode, IncidentState } from 'types';

import { useLiveTicker } from './live-ticker-context';

const codeList = [
  IncidentCode.Goal,
  IncidentCode.GoalCancelled,
  IncidentCode.OwnGoal,
  IncidentCode.PenaltyGoal,
];

const confirmation = [IncidentState.Cancelled];

 type Props = {
    recentlyUpdatedLiveIncidentList: MatchLiveIncident[];
    latestState: MatchLiveIncident[];
 };

export const useAddUpdatedIncidentListToLiveTicker = () => {
  const { setIncidentsDisplayQueue } = useLiveTicker();

  const updateLiveTickerWithSpecificIncident = ({
    recentlyUpdatedLiveIncidentList,
    latestState,
  }: Props) => {
    const liveIncidentIdList = recentlyUpdatedLiveIncidentList.map(({ id }) => id);

    const verifyProperties = (incident: MatchLiveIncident) => {
      return codeList.includes(incident.code) && incident?.confirmation && confirmation.includes(incident?.confirmation);
    };

    const getIncidentListUpdateToBeQueued = () =>
      latestState.filter(incident => liveIncidentIdList.includes(incident.id) && verifyProperties(incident));

    setIncidentsDisplayQueue(prevState => [...prevState, ...getIncidentListUpdateToBeQueued()]);
  };

  return {
    updateLiveTickerWithSpecificIncident,
  };
};
