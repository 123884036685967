import { useMatch } from 'context/match-context';

import { MATCH_DETAILS_SECTION_KEY } from '../../../../constants';
import { isPredictionObjectAvailable } from '../SideBets/matchDetailsSideBetsServices';


const PREDICTION_KEY = {
  CORRECT_SCORE: 'correctScore',
  GOALS_PREDICTIONS: 'goalsPredictions',
  PREDICTION: 'prediction',
  SCORING_PREDICTION: 'scoringPrediction',
  THREE_WAY_HANDICAPS: 'threeWayHandicap',
};

const availablePredictionsServicesMap = new Map();

availablePredictionsServicesMap.set(MATCH_DETAILS_SECTION_KEY.MATCH_RESULT,
  isPredictionObjectAvailable([PREDICTION_KEY.PREDICTION]));
availablePredictionsServicesMap.set(MATCH_DETAILS_SECTION_KEY.NUMBER_OF_GOALS,
  isPredictionObjectAvailable([PREDICTION_KEY.GOALS_PREDICTIONS, PREDICTION_KEY.GOALS_PREDICTIONS]));
availablePredictionsServicesMap.set(MATCH_DETAILS_SECTION_KEY.CORRECT_SCORE,
  isPredictionObjectAvailable([PREDICTION_KEY.CORRECT_SCORE]));
availablePredictionsServicesMap.set(MATCH_DETAILS_SECTION_KEY.THREE_WAY_HANDICAPS,
  isPredictionObjectAvailable([PREDICTION_KEY.THREE_WAY_HANDICAPS]));


export const MatchDetailsPredictionDisplayController = ({ sectionNameToCheck, children }) => {
  const isPredictionAvailable = availablePredictionsServicesMap.get(sectionNameToCheck);

  const { matchPredictions: predictions } = useMatch();

  if (isPredictionAvailable(predictions)) {
    return null;
  }

  return children;
};
