import { useState } from 'react';
import { FC, ReactElement } from 'react';

import useOnBlur from '../../../hooks/useOnBlur';
import { Noop } from '../../../types/Util';


type Props = {
  children(props: {
    isExpanded: boolean;
    handleCollapse: Noop;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    handleToggleExpand: Noop;
    dropdownRef: React.MutableRefObject<null>;
  }): ReactElement;
};

export const DashboardModalController: FC<Props> = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleCollapse = () => setIsExpanded(false);

  const { ref: dropdownRef, handleClick } = useOnBlur(handleCollapse);

  const handleToggleExpand = () => setIsExpanded(prevState => !prevState);

  const props = {
    isExpanded,
    handleCollapse,
    handleClick,
    handleToggleExpand,
    dropdownRef,
  };

  return children(props);
};
