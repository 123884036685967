import React from 'react';

import { MatchDetailsSideBets } from './MatchDetailsSideBets';


export const MatchDetailsSideBetsContainer = () => {
  return (
    <MatchDetailsSideBets />
  );
};
