import { useState, useCallback, useEffect } from 'react';
import { Maybe } from 'types';


const TICK_TIME = 1000;
const INCREMENT_VALUE = 1;

type Props = {
  initialSeconds: Maybe<number>;
};

export const useMatchTimeMinuteCounter = ({ initialSeconds }: Props) => {
  let intervalId: Maybe<NodeJS.Timer> = null;

  const [seconds, setSeconds] = useState<number>(initialSeconds || 0);

  const handleAddSecond = () => setSeconds(prevState => prevState + INCREMENT_VALUE);

  const count = () => {
    if (!intervalId) {
      intervalId = setInterval(() => {
        return handleAddSecond();
      }, TICK_TIME);
    }
  };

  const memoCountDownToDequeue = useCallback(count, []);

  useEffect(() => {
    if (initialSeconds) {
      setSeconds(initialSeconds);
    }
  }, [initialSeconds]);

  useEffect(() => {
    memoCountDownToDequeue();

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [intervalId, memoCountDownToDequeue]);

  return {
    seconds,
  };
};
