import { AxiosPromise, AxiosRequestConfig } from 'axios';

import api from '../services/FetchService';

import {
  GetTournamentList,
  GetTournament,
} from './types';
import * as query from './queries';


export class TournamentClient {
  constructor() {
    this.getTournaments = this.getTournaments.bind(this);
    this.getTournament = this.getTournament.bind(this);
  }

  getTournaments({ areaCode }: { areaCode: string }): AxiosPromise<GetTournamentList> {
    const options: AxiosRequestConfig = {
      ...query.getAreaTournaments,
      url: `${query.getAreaTournaments.url}/${areaCode}`,
      data: { areaCode },
    };

    return api.request(options);
  }

  getTournament({ tournamentId: competitionId }: { tournamentId: string }): AxiosPromise<GetTournament> {
    const options: AxiosRequestConfig = {
      ...query.getSelectedTournaments,
      url: `${query.getSelectedTournaments.url}/${competitionId}`,
      data: {
        competitionId,
      },
    };

    return api.request(options);
  }
}
