import React, { FC } from 'react';

import './HeadingH2.scss';


type Props = {
  title: string;
  isTextDark?: boolean;
  center?: boolean;
}

export const HeadingH2: FC<Props> = ({ title, isTextDark, center }) => {
  const isTextDarkClassActive = isTextDark ? 'heading-h2--dark-text' : '';
  const justifyCenterTitle = center ? 'heading-h2--center' : '';

  return (
    <div className={`heading-h2__container ${justifyCenterTitle}`}>
      <h2 className={`heading-h2__title ${isTextDarkClassActive}`}>
        {title}
      </h2>
    </div>
  );
};
