import { DEFAULT_BET_VALUE } from 'constants/basket';

import { FC, ReactElement, useCallback, useMemo } from 'react';
import { Match, Odd, OddOdd, OddsProvider, OddListGroupingType  } from 'types';
import { useConfig } from 'context/config-context';
import { useMatchListSideBetsOdds } from 'context/matchListSideBets/match-list-side-bets-odds-context';
import { useBasketDashboard } from 'context/basket/basket-dashboard-context';


type Props = {
  match: Match;
  children(props: {
    foundOddListGrouping?: OddListGroupingType;
    oddsQuantity: number;
    selectedOdds: Odd[];
    handleToggleOddInBasket: ({ odd }: { odd?: Odd }) => ({ oddOdds }: { oddOdds: OddOdd }) => () => void;
    isOddInBasket: (odd: OddOdd) => boolean;
  }): ReactElement | null;
};


export const MatchListSideBetsOddsController: FC<Props> = ({ match, children }) => {
  const {
    oddList,
  } = useMatchListSideBetsOdds();
  const { configuration } = useConfig();
  const { event, home, away } = match;
  const { id: eventId, name: eventName, statusName: eventStatusName, status: eventStatus } = event;
  const { provider: { name: providerName } } = configuration;
  const providers = [providerName] as OddsProvider[];

  const { isOddInBasket, sendBasketItem } = useBasketDashboard();

  const foundOddListGrouping = oddList.find(({ event }) => event.id === eventId);

  const sideBetsOdds = foundOddListGrouping?.odds;
  const sideBetsOddOdds = sideBetsOdds?.odds;

  const selectedOdds = useMemo(() => {
    const selectedList: Odd[] = [];

    if (sideBetsOdds && sideBetsOddOdds) {
      Object.keys(sideBetsOdds).forEach(key => {
        if (!sideBetsOdds) {
          return;
        }

        if (isOddInBasket(sideBetsOddOdds[key])) {
          selectedList.push(sideBetsOdds);
        }
      });
    }
    return selectedList;
  }, [isOddInBasket, sideBetsOdds, sideBetsOddOdds]);

  const handleToggleOddInBasket = ({ odd }: { odd?: Odd }) => ({ oddOdds }: { oddOdds: OddOdd }) => () => {
    if (odd) {
      sendBasketItem({
        eventId,
        eventName,
        eventStatus,
        eventStatusName,
        homeShortName: home?.shortName || home.name,
        awayShortName: away?.shortName || away.name,
        providers,
        odd: oddOdds,
        oddType: odd.type,
        oddTypePeriod: odd.typePeriod,
        externalType: odd.externalType,
        oddId: odd.id,
        betValue: DEFAULT_BET_VALUE,
        groups: [],
      });
    }
  };

  const handleToggleOddInBasketMemoized = useCallback(handleToggleOddInBasket, [eventId, eventName, sideBetsOdds, sendBasketItem]);
  const oddsQuantity = foundOddListGrouping?.oddListLength || 0;

  const props = {
    foundOddListGrouping,
    oddsQuantity,
    selectedOdds,
    handleToggleOddInBasket: handleToggleOddInBasketMemoized,
    isOddInBasket,
  };

  return children(props);
};
