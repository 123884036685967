import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Provider as RollbarProvider } from '@rollbar/react';
import { ConfigController } from 'ConfigController';
import { rollbarConfig } from 'rollbar/rollbarConfig';
import { dashboardAppConfig } from 'config/dashboardAppConfig';

import { Loading } from './components';
import api from './api/services/FetchService';
import { AuthProviders } from './context/AuthProviders';
import AuthenticatedApp from './views/auth';


function App() {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    api.setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return <Loading resources="App" />;
  }

  return (
    <RollbarProvider config={rollbarConfig}>
      <AuthProviders>
        <ConfigController configuration={dashboardAppConfig}>
          <AuthenticatedApp />
        </ConfigController>
      </AuthProviders>
    </RollbarProvider>
  );
}

export default App;
