/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { EmptyElementFeedback, PlacholderFlag } from 'components';
import Tournament from 'views/Dashboard/Tournament/shared/Tournament';
import ErrorBoundaryWithRollbar from 'views/ErrorBoundaryWithRollbar';

import { TournamentAreaFavouriteListController } from '../../../Tournament/Favourite/TournamentAreaFavouriteListController';
import { TournamentFavouriteListSection } from '../../../Tournament/Favourite/TournamentFavouriteListSection';
import { TournamentList } from '../../../Tournament/shared/TournamentList';
import TournamentName  from '../../../Tournament/shared/TournamentName';


export const MobileAreaTournamentListFavouriteList = () => {
  return (
    <ErrorBoundaryWithRollbar
      FallbackComponent={
        <EmptyElementFeedback text="COULD_NOT_LOAD_CONTENT" />
      }
    >
      <TournamentAreaFavouriteListController>
        {({ areaList }) => {
          return (
            <TournamentFavouriteListSection>
              {({ favourites }) => (
                <TournamentList>
                  <>
                    {favourites.map(props => {
                      const { tournamentId, tournamentName, seasonId, areaCode, areaSort } = props;
                      const area = areaList.find(({ code }) => code === areaCode);

                      return (
                        <Tournament.Mobile
                          key={`${tournamentId} ${tournamentName}`}
                          tournamentId={tournamentId}
                          tournamentName={tournamentName}
                          seasonId={seasonId}
                          areaCode={areaCode}
                          areaSort={areaSort}
                          DisplayedContent={
                            <TournamentName.Favourite
                              displayedName={`(${areaCode}) ${tournamentName}`}
                              Flag={
                                <PlacholderFlag areaBadgeUrl={area?.badgeUrl} />
                              }
                            />
                          }
                        />
                      );
                    })}
                  </>
                </TournamentList>
              )}
            </TournamentFavouriteListSection>
          );
        }}
      </TournamentAreaFavouriteListController>
    </ErrorBoundaryWithRollbar>
  );
};
