import React from 'react';

import { PipeProviders } from '../hocs';

import { MatchProvider } from './match-context';
import { AreaProvider } from './area-context';
import { FavouriteListProvider } from './favourite/favourites-context';
import { SocketsLiveMatchListProvider } from './sockets/sockets-live-match-list-context';
import { TournamentsProvider } from './tournament-list-context';
import { MatchListProvider } from './match-list-context';
import { MatchListSideBetsOddsProvider } from './matchListSideBets/match-list-side-bets-odds-context';
import { LiveTickerProvider } from './liveTicker/live-ticker-context';
import { LiveMatchListProvider } from './liveTicker/live-match-list-context';
import { SocketsLiveOddListProvider } from './sockets/sockets-live-odd-list-context';
import { MatchDetailsSideBetsOddsProvider } from './match-side-bets-odds-context';
import { ConfigProvider } from './config-context';
import { BasketDashboardProvider } from './basket/basket-dashboard-context';
import { BasketConfigProvider } from './basket/basket-config-context';
import { BasketProvider } from './basket/basket-context';


export const AuthProviders = ({ children }) => {
  const Providers = [
    BasketDashboardProvider,
    BasketProvider,
    BasketConfigProvider,
    ConfigProvider,
    LiveTickerProvider,
    LiveMatchListProvider,
    SocketsLiveMatchListProvider,
    SocketsLiveOddListProvider,
  ];

  return (
    <PipeProviders components={Providers}>
      {children}
    </PipeProviders>
  );
};

export const DesktopMatchListProviders = ({ children }) => {
  const Providers = [
    MatchProvider,
    AreaProvider,
    FavouriteListProvider,
    TournamentsProvider,
    MatchListProvider,
    MatchListSideBetsOddsProvider,
  ];

  return (
    <PipeProviders components={Providers}>
      {children}
    </PipeProviders>
  );
};

export const MobileMatchListProviders = ({ children }) => {
  const Providers = [
    TournamentsProvider,
    MatchListProvider,
    MatchListSideBetsOddsProvider,
  ];

  return (
    <PipeProviders components={Providers}>
      {children}
    </PipeProviders>
  );
};

export const MobileAreaTournamentListProviders = ({ children }) => {
  const Providers = [
    AreaProvider,
    FavouriteListProvider,
    TournamentsProvider,
  ];

  return (
    <PipeProviders components={Providers}>
      {children}
    </PipeProviders>
  );
};

export const MobileMatchDetailsProviders = ({ children }) => {
  const Providers = [
    MatchProvider,
    MatchDetailsSideBetsOddsProvider,
  ];

  return (
    <PipeProviders components={Providers}>
      {children}
    </PipeProviders>
  );
};
