import {
  CountryLanguage,
  CountryCurrency,
  ProviderAppConfig,
  OddsProvider,
  SportsName,
  MatchSelectedFilter,
  MobileNavigationItem,
  ConfigState,
} from 'types';

const lang = window.__RUNTIME_CONFIG__.CONFIG_LANG as CountryLanguage;
const currency = window.__RUNTIME_CONFIG__.CONFIG_CURRENCY as CountryCurrency;

export const dashboardAppConfig: ProviderAppConfig = {
  provider: {
    name: OddsProvider.NorskTipping,
    currency,
  },
  filters: {
    sportName: [SportsName.Soccer],
    selected: [MatchSelectedFilter.NorskTipping],
  },
  sportsBar: [
    {
      displayLabel: 'Football',
      sportName: SportsName.Soccer,
    },
  ],
  mobileNavigation: [
    {
      label: MobileNavigationItem.Basket,
      state: ConfigState.Enabled,
    },
    {
      label: MobileNavigationItem.Settings,
      state: ConfigState.Enabled,
    },
  ],
  lang,
};

export const defaultConfig: ProviderAppConfig = {
  provider: {
    currency: CountryCurrency.International,
  },
  filters: {
    sportName: [SportsName.Soccer],
    selected: [],
  },
  sportsBar: [
    {
      displayLabel: 'Football',
      sportName: SportsName.Soccer,
    },
  ],
  lang: CountryLanguage.English,
};
