import React, { FC, Fragment } from 'react';
import { Popup, Table, Icon } from 'semantic-ui-react';
import { useTranslate } from 'context/translate-context';

import { Badge } from '../../Dashboard/shared';
import { Standing, StandingTableStylingVariant } from '../../../types';

import { TournamentDetailsStandingsTableHeader } from './TournamentDetailsStandingsTableHeader';


import './TournamentDetailsTabStandingsTable.scss';


type Props = {
  standings: Standing[];
  stylingVariant: StandingTableStylingVariant;
  groupName: string;
}

export const TournamentDetailsTabStandingsTable: FC<Props> = ({ standings, stylingVariant, groupName }) => {
  const isGroupStylingVariant = stylingVariant === StandingTableStylingVariant.Group;

  return (
    <>
      {isGroupStylingVariant && (
        <div className="tournament-table__group-name margin-top--size-m">
          {groupName}
        </div>
      )}
      <Table className={!isGroupStylingVariant ? 'margin-top' : 'margin-unset'}>
        <TournamentDetailsStandingsTableHeader />
        <Table.Body>
          {standings.map(({
            teamId,
            rank,
            teamBadgeUrl,
            teamDisplayedName,
            teamName,
            status,
            all,
            form,
            nextMatch,
          }) => {
            return (
              <Table.Row key={teamId}>
                <TeamCell
                  status={status}
                  rank={rank}
                  teamBadgeUrl={teamBadgeUrl}
                  teamName={teamDisplayedName || teamName}
                />
                <Points all={all} />
                <GoalsScored all={all} />
                <TeamWins teamId={teamId} form={form} />
                <NextMatchCell
                  nextMatch={nextMatch}
                />
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

const StandingPromotionStateMap = new Map();
StandingPromotionStateMap.set('same', <div className="tournament-table__standing-state tournament-table__standing-state--still" />);
StandingPromotionStateMap.set('up', <Icon name="chevron up" className="primary margin--unset" />);
StandingPromotionStateMap.set('down', <Icon name="chevron down" className="red margin--unset" />);


type TeamCellProps = Pick<Standing, 'rank' | 'status' | 'teamBadgeUrl' | 'teamName'>;

const TeamCell = ({ rank, status, teamBadgeUrl, teamName }: TeamCellProps)  => {
  return (
    <Table.Cell className="border-right">
      <div className="tournament-table__team">
        {rank && (
          <>
            <div className="tournament-table__rank margin-right--size-s">
              {rank}
            </div>
            <div className="tournament-table__rank-promotion">
              {StandingPromotionStateMap.get(status)}
            </div>
          </>
        )}
        <Badge
          customStyles="margin-right--size-s"
          badgeURL={teamBadgeUrl}
          alt={`${teamName} badge`}
        />
        {teamName && (
          <Popup
            content={teamName}
            trigger={
              <div className="tournament-table__team-name">
                {teamName}
              </div>
            }
          />
        )}
      </div>
    </Table.Cell>
  );
};

type NextMatchCellProps = Pick<Standing, 'nextMatch'>;

const NextMatchCell = ({ nextMatch = null }: NextMatchCellProps) => {
  const { translate } = useTranslate();
  const { team } = nextMatch || {};
  const { badgeUrl, displayedName, name } = team || {};
  const teamName = displayedName || name;

  return (
    <Table.Cell className="border-right">
      <div className="tournament-table__team">
        <Badge
          customStyles="tournament-table__badge"
          badgeURL={badgeUrl}
          alt={`${displayedName} badge`}
        />
        {teamName ? (
          <Popup
            content={teamName}
            trigger={
              <div className="tournament-table__team-name">
                {teamName}
              </div>
            }
          />
        ) : (
          <div className="tournament-table__team-name">
            {translate('TBA')}
          </div>
        )}
      </div>
    </Table.Cell>
  );
};

type PointsProps = Pick<Standing, 'all'>;

const Points = ({ all }: PointsProps) => {
  const { played, won, draw, lost } = all;

  return (
    <Table.Cell className="border-right">
      <div className="tournament-table__grid-points">
        <div className="tournament-table__cell-center">
          {played}
        </div>
        <div className="tournament-table__cell-center">
          {won}
        </div>
        <div className="tournament-table__cell-center">
          {draw}
        </div>
        <div className="tournament-table__cell-center">
          {lost}
        </div>
      </div>
    </Table.Cell>
  );
};

type GoalsScoredProps = Pick<Standing, 'all'>;

const GoalsScored = ({ all }: GoalsScoredProps) => {
  const { goalsScored = 0, goalsConceded = 0 } = all;
  const getGoalsScored = goalsScored || 0;
  const getGoalsConceded = goalsConceded || 0;

  const goalsDiff = getGoalsScored - getGoalsConceded;

  return (
    <Table.Cell className="border-right">
      <div className="tournament-table__grid-goals">
        <div className="tournament-table__cell-center">
          {goalsScored}
        </div>
        <div className="tournament-table__cell-center">
          {goalsConceded}
        </div>
        <div className="tournament-table__cell-center">
          {goalsDiff}
        </div>
      </div>
    </Table.Cell>
  );
};


type TeamWins = Pick<Standing, 'teamId' | 'form'>

const TEAM_SCORE = {
  WIN: 'W',
  DRAW: 'D',
  LOSE: 'L',
};

const TeamWinMap = new Map();
TeamWinMap.set(TEAM_SCORE.WIN, <div className="tournament-table__score tournament-table__score--win" />);
TeamWinMap.set(TEAM_SCORE.DRAW, <div className="tournament-table__score tournament-table__score--draw" />);
TeamWinMap.set(TEAM_SCORE.LOSE, <div className="tournament-table__score tournament-table__score--lose" />);

const TeamWins = ({ teamId, form }: TeamWins) => {
  return (
    <Table.Cell className="border-right">
      <div className="tournament-table__score-container">
        {form.map((teamScore, index) => (
          <Fragment key={`${teamId}-${teamScore}-${index}`}>{TeamWinMap.get(teamScore)}</Fragment>
        ))}
      </div>
    </Table.Cell>
  );
};
