import { getUpdatedList } from 'context/basket/oddList/shared/getUpdatedList.service';

import { UseUpdateBasketOddListByProviderHandlersProps } from '../updateBasketOddListByProviderHandlersMap';

import { findElementById } from './findElementById';


export const updateBasketOnOpenHandlers: UseUpdateBasketOddListByProviderHandlersProps = {
  getUpdatedList: getUpdatedList(findElementById),
};
