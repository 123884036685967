
import { FC, ReactElement, useCallback, useMemo } from 'react';
import { Match, Odd, OddOdd, BasketItem, OddsProvider  } from 'types';
import { useConfig } from 'context/config-context';
import { useMatchDetailsSideBetsOdds } from 'context/match-side-bets-odds-context';
import { useBasketDashboard } from 'context/basket/basket-dashboard-context';


type Props = {
  match: Match;
  oddType: Odd['type'][];
  children(props: {
    oddsQuantity: number;
    oddsByView: Odd[];
    selectedOdds: OddOdd[];
    handleToggleOddInBasket: ({ odd }: { odd?: Odd }) => ({ oddOdds }: { oddOdds: OddOdd }) => () => void;
    isOddInBasket: (odd: OddOdd) => boolean;
    match: Match;
  }): ReactElement | null;
};

export const MatchDetailsSideBetsOddsController: FC<Props> = ({ match, oddType, children }) => {
  const { event, home, away } = match;
  const { id: eventId, name: eventName, statusName: eventStatusName, status: eventStatus } = event;

  const {
    selectMatchOddsByProviderAndId,
    matchDetailsOddList,
  } = useMatchDetailsSideBetsOdds();
  const { configuration } = useConfig();
  const { provider: { name: providerName } } = configuration;
  const providers = [providerName] as OddsProvider[];

  const { isOddInBasket, sendBasketItem } = useBasketDashboard();

  const selectProvidersMatches = selectMatchOddsByProviderAndId(providers);

  const sideBets = selectProvidersMatches
    .filter(odd => providers.includes(odd.externalSource))?.find(odd => {
      return oddType.includes(odd.type);
    });

  const sideBetsOdds = sideBets?.odds;

  const oddsByView = useMemo(() => {
    return selectProvidersMatches.filter(odds => {
      return oddType.includes(odds.type);
    });
  }, [oddType, selectProvidersMatches]);

  const selectedOdds = useMemo(() => {
    const selectedList: OddOdd[] = [];
    if (!sideBetsOdds) {
      return selectedList;
    }

    Object.keys(sideBetsOdds).forEach(key => {
      if (!sideBets) {
        return;
      }

      const selectedOdd = sideBetsOdds[key];
      if (isOddInBasket(selectedOdd)) {
        selectedList.push(selectedOdd);
      }
    });

    return selectedList;
  }, [isOddInBasket, sideBets, sideBetsOdds]);

  const handleToggleOddInBasket = ({ odd }: { odd?: Odd }) => ({ oddOdds }: { oddOdds: OddOdd }) => () => {
    if (odd) {
      sendBasketItem({
        eventId,
        eventName,
        eventStatus,
        eventStatusName,
        homeShortName: home?.shortName || home.name,
        awayShortName: away?.shortName || away.name,
        providers,
        odd: oddOdds,
        oddType: odd.type,
        oddTypePeriod: odd.typePeriod,
        externalType: odd.externalType,
        oddId: odd.id,
      } as BasketItem);
    }
  };

  const handleToggleOddInBasketMemoized = useCallback(handleToggleOddInBasket, [eventId, eventName, sideBets, sendBasketItem]);

  const selectedAllMatchesOdds = () => {
    const foundEvent = matchDetailsOddList[0];

    if (foundEvent) {
      return foundEvent.odds;
    }

    return [];
  };

  const selectedAllEventOdds = useCallback(selectedAllMatchesOdds, [selectedAllMatchesOdds]);

  const props = {
    match,
    oddsQuantity: selectedAllEventOdds().length,
    oddsByView,
    selectedOdds,
    handleToggleOddInBasket: handleToggleOddInBasketMemoized,
    isOddInBasket,
  };

  return children(props);
};
