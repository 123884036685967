import { RouteParam } from 'views/Tournament/Routing/RouteParam';
import { TOURNAMENT_DETAILS } from 'views/Tournament/Routing/Routing';

import { getTournamentDetailsRouting } from '../../Routing/tournamentDetailsTabLinkListService';
import { TOURNAMENT_ROUTE } from '../../Routing/Routing';
import { TournamentDetailsTabStandings } from '../TournamentDetailsTabStandings';
import { TournamentDetailsTabStatistics } from '../TournamentDetailsTabStatistics';


const getMobileTournamentDetailsURLByProps = getTournamentDetailsRouting(TOURNAMENT_DETAILS.MOBILE_TOURNAMENT);

export const getDesktopTournamentDetailsRouteList = (params: RouteParam) => {
  return [
    {
      path: TOURNAMENT_ROUTE.STANDINGS,
      redirectURL: getMobileTournamentDetailsURLByProps.getTournamentStandingsTabURL(params),
      Component: TournamentDetailsTabStandings,
    },
    {
      path: TOURNAMENT_ROUTE.STATISTICS,
      redirectURL: getMobileTournamentDetailsURLByProps.getTournamentStatisticsTabURL(params),
      Component: TournamentDetailsTabStatistics,
    },
  ];
};

