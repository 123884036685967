import { Maybe } from '../Util';
import { Event } from '../Match';
import { TeamDetails } from '../Match/Match';


export type StandingResults = {
  goalsScored: Maybe<number>;
  lost: Maybe<number>;
  cleanSheets: Maybe<number>;
  goalsConceded: Maybe<number>;
  won: Maybe<number>;
  draw: Maybe<number>;
  played: Maybe<number>;
};

enum StandingMatchForm {
  Win = 'W',
  Lose = 'L',
  Draw = 'D',
}

export type StandingNextMatch = {
  event: Event;
  team: Pick<TeamDetails, 'badgeUrl' | 'displayedName' | 'id' | 'name'>;
}

export enum StandingStatus {
  Up = 'up',
  Down = 'down',
  Draw = 'draw',
}

export type StandingGroup = {
  id: string;
  name: string;
};

export type Standing = {
  acronym: string;
  all: StandingResults;
  home: StandingResults;
  away: StandingResults;
  form: StandingMatchForm[];
  formAll: StandingMatchForm[];
  lastRank: number;
  nextMatch: Maybe<StandingNextMatch>;
  originalPoints: Maybe<number>;
  points: Maybe<number>;
  rank: number;
  status: StandingStatus;
  teamBadgeThumbUrl?: string;
  teamBadgeUrl?: string;
  teamDisplayedName: string;
  teamId: string;
  teamName: string;
  teamShortName: string;
  zoneName: string;
};

export type StandingList = {
  standings: Standing[];
  group: StandingGroup;
}

export enum StandingTableStylingVariant {
  Group = 'Group',
  Virtual = 'Virtual'
}
