import { useState, useEffect } from 'react';
import { Maybe, Noop } from 'types';
import { useLiveTicker } from 'context/liveTicker/live-ticker-context';


type Props = {
  dequeue?: Noop;
  incidentsRemaining: number;
}

export const useLiveTickerProgressBar = ({ dequeue, incidentsRemaining }: Props) => {
  let intervalId: Maybe<NodeJS.Timer> = null;

  const { liveTickerDisplayLimit, tickTime, isPaused } = useLiveTicker();
  const [secondsRemaining, setSecondsRemaining] = useState<number>(0);

  const handleAddSeconds = () => setSecondsRemaining(prevState => prevState + 0.25);

  const countDownToDequeue = () => {
    if (!intervalId) {
      intervalId = setInterval(() => {
        if (isPaused) {
          return;
        }

        if (secondsRemaining > liveTickerDisplayLimit) {
          dequeue && dequeue();
          clearInterval(intervalId as NodeJS.Timer);

          return setSecondsRemaining(0);
        }

        return handleAddSeconds();
      }, tickTime);
    }
  };

  useEffect(() => {
    if (incidentsRemaining !== 0) {
      countDownToDequeue();
    }

    return () => {
      clearInterval(intervalId as NodeJS.Timer);
    };
  }, [incidentsRemaining, secondsRemaining, isPaused, intervalId]);


  return {
    secondsRemaining,
    liveTickerDisplayLimit,
  };
};
