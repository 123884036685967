import React, { memo } from 'react';
import { useTranslate } from 'context/translate-context';

import { MatchDetailsPredictionsSection } from '../../Prediction/MatchDetailsPredictionsSection';
import { MatchDetailsSideBetsOddsProviderContainer } from '../MatchDetailsSideBetsOddsProviderContainer';
import { MatchDetailsPredictionsColumnsGrid } from '../../Prediction/MatchDetailsPredictionsColumnsGrid';
import { MatchDetailsPredictionRow } from '../../Prediction/MatchDetailsPredictionRow';
import { MatchDetailsSideBetsOddsLabel } from '../MatchDetailsSideBetsOddsLabel';

import { MatchDetailsSideBetsGenericSectionController } from './MatchDetailsSideBetsGenericSectionController';
import { getColumnLabel } from './matchDetailsSideBetsGenericSectionTableServices';

import './MatchDetailsSideBetsGeneric.scss';


const maxDisplayedColumns = 3;
const twoColumns = 2;

export const MatchDetailsSideBetsGeneric = memo(() => {
  const { translate } = useTranslate();

  return (
    <MatchDetailsSideBetsGenericSectionController>
      {({ uniqueOddTypes, match }) => (
        <MatchDetailsSideBetsOddsProviderContainer
          oddType={uniqueOddTypes}
        >
          {({ oddsByView: oddsSections, isOddInBasket, handleToggleOddInBasket }) => {
            return (
              <ul className="match-details-side-bets-generic-section__grid">
                {oddsSections.map((oddElement, index) => {
                  const { odds, externalType } = oddElement;

                  return (
                    <li key={`generic odd section #${index}`}>
                      <MatchDetailsPredictionsSection
                        key={index}
                        label={externalType || translate('ODD')}
                      >
                        {() => {
                          const oddListEntries = Object.entries(odds);
                          const columnVariant = oddListEntries.length > maxDisplayedColumns ?
                            twoColumns :
                            oddListEntries.length;

                          const firstOdd = oddListEntries[0][1];

                          const ColumnLabel = getColumnLabel(columnVariant, firstOdd);

                          return (
                            <MatchDetailsPredictionsColumnsGrid
                              columnVariant={String(columnVariant)}
                            >
                              <ColumnLabel
                                oddsLabel={<MatchDetailsSideBetsOddsLabel />}
                              />
                              {/* odds */}
                              {oddListEntries.map(([, odd]) => {
                                return (
                                  <MatchDetailsPredictionRow
                                    match={match}
                                    key={`${odd.id} ${odd.name}`}
                                    label={odd.name}
                                    odd={odd}
                                    onOddClick={handleToggleOddInBasket({ odd: oddElement })}
                                    isOddInBasket={isOddInBasket}
                                  />
                                );
                              })}
                            </MatchDetailsPredictionsColumnsGrid>
                          );
                        }}
                      </MatchDetailsPredictionsSection>
                    </li>
                  );
                })}
              </ul>
            );
          }}
        </MatchDetailsSideBetsOddsProviderContainer>
      )}
    </MatchDetailsSideBetsGenericSectionController>
  );
});
