
type URLMethod = {
  name: string;
  method: Function;
}

type Props = (urlMethods: URLMethod[], getURLPrefix: Function) => (url: string) => Record<string, Function>;

export const createAggregatedURLListService: Props = (urlMetdhods, getURLPrefix) => url => {
  const aggregatedGetURLMethodsWithSetBaseUrl = Object.fromEntries(
    urlMetdhods.map(({ name, method }) => {
      return [name, method(getURLPrefix(url))];
    }),
  );

  return aggregatedGetURLMethodsWithSetBaseUrl;
};
