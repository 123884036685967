
import React, { FC, useContext, useMemo, useState } from 'react';
import { noop } from 'lodash';
import { useQuery } from 'react-query';
import { Odd, OddsProvider, OddsType, OddGroupingType } from 'types';
import OddsClient  from 'api/OddsClient';

import { useConfig } from './config-context';
import { useMatch } from './match-context';


const availableOddsTypes = [
  OddsType.Outcome,
  OddsType.TotalX,
  OddsType.CorrectScore,
  OddsType.Handicap3WX,
];

type Props = {
  matchDetailsOddList: OddGroupingType[];
  areOddsLoading: boolean;
  selectAllMatchOddsById: (oddTypes?: OddsType[]) => Odd[];
  selectMatchOddsByProviderAndId: (provider: OddsProvider[]) => Odd[];
  setMatchDetailsOddList: React.Dispatch<React.SetStateAction<OddGroupingType[]>>;
}

const MatchDetailsSideBetsOddsContext = React.createContext<Props>({
  matchDetailsOddList: [],
  areOddsLoading: false,
  selectAllMatchOddsById: () => [],
  selectMatchOddsByProviderAndId: () => [],
  setMatchDetailsOddList: noop,
});


const MatchDetailsSideBetsOddsProvider: FC = props => {
  const { configuration: { provider: { name: providerName = '' } } } = useConfig();
  const { selectedMatch } = useMatch();
  const eventId = selectedMatch?.event?.id;

  const eventsIds = eventId ? [eventId] : [];
  const handleProviderName = [providerName].filter(Boolean);

  const {
    isLoading: areOddsLoading,
    data,
  } = useQuery(
    ['matchDetailsOddList', { eventsIds }],
    () => OddsClient.getSideBetsOdds({ eventsIds, providers: [providerName] as OddsProvider[] }),
    {
      keepPreviousData: true,
      enabled: Boolean(eventsIds.length) && Boolean(handleProviderName.length),
    });

  const [matchDetailsOddList, setMatchDetailsOddList] = useState<OddGroupingType[]>([]);

  useMemo(() => {
    const result = data?.data?.odds || [];

    setMatchDetailsOddList(result);
  }, [data]);

  // selectors
  const selectAllMatchOddsById = (oddTypes = availableOddsTypes) => {
    const matchOddList = matchDetailsOddList[0];

    if (matchOddList) {
      const { odds: matchOdds } = matchOddList;

      return matchOdds.filter(odd => {
        return  oddTypes.includes(odd.type);
      });
    }

    return [];
  };

  const selectMatchOddsByProviderAndId = (providers: OddsProvider[]) => {
    const matchOddList = matchDetailsOddList[0];

    if (matchOddList) {
      const foundOddsByProvider = matchOddList.odds.filter(({ externalSource }) => {
        return providers.includes(externalSource);
      });

      return foundOddsByProvider;
    }

    return [];
  };

  const values = {
    matchDetailsOddList,
    areOddsLoading,
    selectAllMatchOddsById,
    selectMatchOddsByProviderAndId,
    setMatchDetailsOddList,
  };

  return (
    <MatchDetailsSideBetsOddsContext.Provider
      value={values}
      {...props}
    />
  );
};

const useMatchDetailsSideBetsOdds = () => {
  const context = useContext(MatchDetailsSideBetsOddsContext);

  if(context === undefined) {
    throw new Error('useMatchDetailsSideBetsOdds must be used within a MatchDetailsSideBetsOddsProvider');
  }

  return context;
};


export {
  MatchDetailsSideBetsOddsProvider,
  useMatchDetailsSideBetsOdds,
};
