import { Match, MatchStatus } from 'types';


export const showMatchResultWhenStateIsEqualTo = (states: MatchStatus[] = [], currentState: MatchStatus) => {
  return states.includes(currentState);
};

export const getPreviousRowCompetitionName = (matches: Match[], index: number) => {
  const previousRowCompetitionName = matches[index - 1]?.season?.competition.name;

  if (!previousRowCompetitionName) {
    return null;
  }
  return previousRowCompetitionName;
};
