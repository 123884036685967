import React, { FC } from 'react';
import { Device, Season } from 'types';

import { MatchListHeaderTournament } from '../../Header/MatchListHeaderTournament';

import { MatchListMissingSelectedTournamentFeedback } from './Feedback';

import './MatchListMissingSelectedTournament.scss';


type Props = {
  season: Season;
}

export const MatchListMissingSelectedTournamentSortVariantTournament: FC<Props> = ({ season }) => {
  return (
    <li className="match-list-missing-selected-tournament__container">
      <div className="match-list-missing-selected-tournament__tournament">
        <MatchListHeaderTournament season={season} deviceVariant={Device.Desktop} />
      </div>
      <MatchListMissingSelectedTournamentFeedback />
    </li>
  );
};
