import { useConfig } from 'context/config-context';
import { useHistory, useLocation } from 'react-router';
import { MatchSort, OddsProvider } from 'types';
import { addQuery } from 'utils';


const handleUpdateSort = (sortBy: MatchSort, search: string, options?: Record<string, unknown>) => addQuery(search, { order: sortBy, ...options });

const handleUpdateSortWithBetRatio = (sortBy: MatchSort, search: string, provider?: OddsProvider) => {
  if (provider) {
    return handleUpdateSort(sortBy, search, {
      selected: [provider],
    });
  }

  return handleUpdateSort(sortBy, search);
};

const queryByMatchSort = new Map();
queryByMatchSort.set(MatchSort.KICK_OFF_ASC, handleUpdateSort);
queryByMatchSort.set(MatchSort.KICK_OFF_DESC, handleUpdateSort);
queryByMatchSort.set(MatchSort.TOURNAMENT_ASC, handleUpdateSort);
queryByMatchSort.set(MatchSort.TOURNAMENT_DESC, handleUpdateSort);
queryByMatchSort.set(MatchSort.BET_RATIO_ASC, handleUpdateSortWithBetRatio);
queryByMatchSort.set(MatchSort.BET_RATIO_DESC, handleUpdateSortWithBetRatio);


export const useMatchListSort = () => {
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { configuration } = useConfig();


  const handleSetOrder = (sortBy: MatchSort) => {
    if ([MatchSort.BET_RATIO_ASC, MatchSort.BET_RATIO_DESC].includes(sortBy)) {
      const providerName = configuration.provider?.name;

      return push({
        pathname,
        search: queryByMatchSort.get(sortBy)(sortBy, search, providerName),
      });
    }
    return push({
      pathname,
      search: queryByMatchSort.get(sortBy)(sortBy, search),
    });
  };

  return {
    handleSetOrder,
  };
};
