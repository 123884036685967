import React, { FC } from 'react';
import { useTranslate } from 'context/translate-context';
import { ProviderAppConfig, SportsName } from 'types';

import { sportsBarIconsMap } from './sportsBarIconsMap';

import './SportsBar.scss';


type Props = {
  configuration: ProviderAppConfig;
  isSelected: (sport: SportsName) => boolean;
  handleSelectSport: (sport: SportsName) => void;
}

const SportsBar: FC<Props> = ({ configuration, isSelected, handleSelectSport }) => {
  const { translate } = useTranslate();
  const { sportsBar } = configuration;

  return (
    <section className="sports-bar__section">
      <ul className="sports-bar__list">
        {sportsBar.map(({ sportName, displayLabel }) => {
          const IconComponent = sportsBarIconsMap.get(sportName);

          return (
            <li
              key={sportName}
              className="sports-bar__element"
            >
              <button
                className={`sports-bar__button ${isSelected(sportName) ? 'sports-bar__button--active' : ''}`}
                aria-label={`Button selects ${translate(displayLabel.toUpperCase())}`}
                onClick={() => handleSelectSport(sportName)}
              >
                {IconComponent ? IconComponent() : null}
                <div className="margin-left--size-s">
                  {translate(displayLabel.toUpperCase())}
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default SportsBar;
