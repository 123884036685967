import React from 'react';
import { useParams } from 'react-router';

import { MobileMatchDetailsLinkListController } from '../../shared/MobileMatchDetailsLinkListController';
import { MobileTabList } from '../../../../../shared/Mobile/Tab/MobileTabList';
import { MobileTab } from '../../../../../shared/Mobile/Tab/MobileTab';
import { RouteParam } from '../../RouteParam';
import { MobileMatchDetailsSideBetsRouting } from '../Routing/MobileMatchDetailsSideBetsRouting';
import { getMatchDetailsSideBetsTabLinkList } from '../mobileMatchDetailsTabListLinks';


export const MobileMatchDetailsSideBetsDefault = () => {
  const params = useParams<RouteParam>();

  return (
    <section>
      <MobileMatchDetailsLinkListController
        getLinks={getMatchDetailsSideBetsTabLinkList(params)}
      >
        {({ linkList }) => (
          <MobileTabList linkList={linkList}>
            {props => {
              const { text } = props;

              return <MobileTab key={text} {...props} />;
            }}
          </MobileTabList>
        )}
      </MobileMatchDetailsLinkListController>
      <MobileMatchDetailsSideBetsRouting />
    </section>
  );
};
