import { usePrevious } from 'hooks/usePrevious';
import { useCallback, useEffect, useState } from 'react';


const TIMEOUT_TIME = 1000 * 10;
const INITIAL_STATE = '';

const compareIfLatestOddIsHigher = (prevValue: number, latestValue: number) => {
  return prevValue < latestValue;
};

const compareIfLatestOddIsLower = (prevValue: number, latestValue: number) => {
  return prevValue > latestValue;
};


type Props = {
  odd: number;
  oddIncreaseClassName: string;
  oddDecreaseClassName: string;
}

export const useCompareOdds = ({ odd, oddIncreaseClassName, oddDecreaseClassName }: Props) => {
  const previousOdd = usePrevious(odd || 0);

  const [highlightIncreasedOddClass, setHighlightIncreasedOddClass] = useState<string>(INITIAL_STATE);
  const [highlightDecreasedOddClass, setHighlightDecreasedOddClass] = useState<string>(INITIAL_STATE);

  const compareStatesAndHandleNewScore = () => {
    if (compareIfLatestOddIsHigher(previousOdd, odd)) {
      setHighlightIncreasedOddClass(oddIncreaseClassName);

      setTimeout(() => {
        setHighlightIncreasedOddClass(INITIAL_STATE);
      }, TIMEOUT_TIME);
    }

    if (compareIfLatestOddIsLower(previousOdd, odd)) {
      setHighlightDecreasedOddClass(oddDecreaseClassName);

      setTimeout(() => {
        setHighlightDecreasedOddClass(INITIAL_STATE);
      }, TIMEOUT_TIME);
    }
  };

  const memoCompareStatesAndHandleNewScore = useCallback(compareStatesAndHandleNewScore, [
    odd,
  ]);

  useEffect(() => {
    memoCompareStatesAndHandleNewScore();
  }, [memoCompareStatesAndHandleNewScore]);

  return {
    highlightIncreasedOddClass,
    highlightDecreasedOddClass,
  };
};
