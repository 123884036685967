import React, { FC } from 'react';

import { MatchDisplayPrediction, Noop } from '../../../../types';
import { Dropdown, DefaultDropdownList } from '../../../../components';
import { useTranslate } from '../../../../context/translate-context';


type Props = {
  displayPrediction: MatchDisplayPrediction;
  isExpanded: boolean;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleToggleExpand: Noop;
  dropdownRef: React.MutableRefObject<null>;
  handleSetDisplay: (sort: MatchDisplayPrediction) => void;
  availableLabels: {label: string}[];
};

export const MatchListFilterPrediction: FC<Props> = ({
  displayPrediction,
  dropdownRef,
  handleClick,
  handleToggleExpand,
  isExpanded,
  availableLabels,
  handleSetDisplay,
}) => {
  const { translate } = useTranslate();

  return (
    <Dropdown
      label={translate('SHOW_XPERT')}
      selectedElementLabel={displayPrediction}
      isExpanded={isExpanded}
      handleClick={handleClick}
      handleToggleExpand={handleToggleExpand}
      dropdownRef={dropdownRef}
    >
      <DefaultDropdownList
        list={availableLabels}
        handleSelect={handleSetDisplay as (value: string) => void}
      />
    </Dropdown>
  );
};
