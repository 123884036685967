import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { MobileTournamentDetails } from '../MobileTournamentDetails';
import { TOURNAMENT_DETAILS, TOURNAMENT_ROUTE } from '../../Routing/Routing';


export const MobileTournamentRouting = () => {
  return (
    <Switch>
      <Route path={TOURNAMENT_ROUTE.MOBILE_DEFAULT} component={MobileTournamentDetails} />
    </Switch>
  );
};

const getDefaultTournamentURL = (tournamentId: string) => {
  return `${TOURNAMENT_DETAILS.MOBILE_TOURNAMENT}/${tournamentId}${TOURNAMENT_DETAILS.STANDINGS}`;
};


export const MOBILE_TOURNAMENT_ROUTING = {
  getDefaultTournamentURL,
};
