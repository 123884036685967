import React from 'react';

import { ButtonBase } from '../ButtonBase';

import './ButtonSecondary.scss';


export const ButtonSecondary = props => {
  const Button = ButtonBase({ classPrefix: 'button-secondary' });

  return (
    <Button {...props} />
  );
};
