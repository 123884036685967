import React from 'react';

import { EmptyListFeedback } from '../../../../components';
import { MatchDetailsSummaryWrapper } from '../MatchDetailsSummaryWrapper';

import { MatchDetailsMatchReportIncidents } from './MatchDetailsMatchReportIncidents';
import { MatchDetailsMatchReportIncidentsController } from './MatchDetailsMatchReportIncidentsController';


export const MatchDetailsMatchReport = () => {
  return (
    <MatchDetailsSummaryWrapper>
      {() => (
        <MatchDetailsMatchReportIncidentsController>
          {({ incidents }) => (
            <>
              {incidents.length === 0 && <EmptyListFeedback />}
              {incidents.length > 0 && <MatchDetailsMatchReportIncidents incidents={incidents} />}
            </>
          )}
        </MatchDetailsMatchReportIncidentsController>
      )}
    </MatchDetailsSummaryWrapper>
  );
};
