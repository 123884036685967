
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { GetUser, UpdateUserProps } from 'api/UserClient/types';
import { RequestApiErrors } from 'types';
import UserClient from 'api/UserClient';

import { useTranslate } from '../../../context/translate-context';
import { useFormHandlers } from '../../../hooks/useFormHandlers';

type Props = {
  initialValues: {
    email: string;
    birthDate: string;
    password: string;
    repeatedPassword: string;
    termsAndPolicy: boolean;
  };
  children: any;
}

export const UserAccountFormController: FC<Props> = ({ initialValues, children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [errors, setErrors] = useState<Record<string, any>[]>([]);
  const { translate } = useTranslate();
  const updateUser = useMutation<AxiosResponse<GetUser>, RequestApiErrors, UpdateUserProps>(UserClient.updateUser, {
    onMutate: () => {
      setErrors([]);
    },
    onSuccess: () => {
      toast.success(translate('DATA_CHANGED'));
      getAccessTokenSilently({ ignoreCache: true });
    },
    onError: error => {
      setErrors(error?.response?.data?.errors || []);
    },
  });

  const handlers = useFormHandlers(initialValues);

  const { isLoading: isPending } = updateUser;
  const { fields } = handlers;

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    updateUser.mutate(fields);
  };

  const props = {
    ...handlers,
    errors,
    isPending,
    handleSubmit,
  };

  return children(props);
};


