import { getMatchDetailsRouting } from '../../../shared/MatchDetails/matchDetailsRoutingServices';
import { DASHBOARD_ROUTE } from '../../../Routing/Routing';
import { RouteParam } from '../RouteParam';
import { TabLinkListProps } from '../../../../shared/Routing/TabLink';
import { MATCH_DETAILS_SECTION_KEY } from '../../../../../constants';


type Props = TabLinkListProps<RouteParam>;

const MATCH_DETAILS_ROUTING = getMatchDetailsRouting(DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES);

export const getMatchDetailsSideBetsTabLinkList: Props = params => ({ translate, search, prediction }) => (
  [
    {
      text: translate(MATCH_DETAILS_SECTION_KEY.ALL_SIDE_BETS),
      to: `${MATCH_DETAILS_ROUTING.getSideBetsURL(params)}${search}`,
      exact: true,
    },
    {
      label: MATCH_DETAILS_SECTION_KEY.MATCH_RESULT,
      text: translate(MATCH_DETAILS_SECTION_KEY.MATCH_RESULT),
      to: `${MATCH_DETAILS_ROUTING.getMatchResultURL(params)}${search}`,
      exact: true,
      isDisabled: !Boolean(prediction?.prediction),
    },
    {
      label: MATCH_DETAILS_SECTION_KEY.CORRECT_SCORE,
      text: translate(MATCH_DETAILS_SECTION_KEY.CORRECT_SCORE),
      to: `${MATCH_DETAILS_ROUTING.getCorrectScoreURL(params)}${search}`,
      exact: true,
      isDisabled: !Boolean(prediction?.correctScore),
    },
    {
      label: MATCH_DETAILS_SECTION_KEY.NUMBER_OF_GOALS,
      text: translate(MATCH_DETAILS_SECTION_KEY.NUMBER_OF_GOALS),
      to: `${MATCH_DETAILS_ROUTING.getNumberOfGoalsURL(params)}${search}`,
      exact: true,
      isDisabled: !Boolean(prediction?.goalsPredictions),
    },
    {
      label: MATCH_DETAILS_SECTION_KEY.THREE_WAY_HANDICAPS,
      text: translate(MATCH_DETAILS_SECTION_KEY.THREE_WAY_HANDICAPS),
      to: `${MATCH_DETAILS_ROUTING.getThreeWayHandicapsURL(params)}${search}`,
      exact: true,
      isDisabled: !Boolean(prediction?.threeWayHandicap),
    },
  ]
);
