import React from 'react';
import { useParams } from 'react-router';
import { useMatch } from 'context/match-context';

import { MobileMatchDetailsLinkListController } from '../shared/MobileMatchDetailsLinkListController';
import { MobileTabList } from '../../../../shared/Mobile/Tab/MobileTabList';
import { MobileTab } from '../../../../shared/Mobile/Tab/MobileTab';
import { RouteParam } from '../RouteParam';

import { getMatchDetailsStatisticTabLinkList } from './mobileMatchDetailsTabListLinks';
import { MobileMatchDetailsStatisticRouting } from './Routing/MobileMatchDetailsStatisticRouting';


export const MobileMatchDetailsStatistic = () => {
  const params = useParams<RouteParam>();
  const { hasAnyLineup } = useMatch();

  return (
    <section>
      <MobileMatchDetailsLinkListController
        getLinks={getMatchDetailsStatisticTabLinkList({ hasAnyLineup })(params)}
      >
        {({ linkList }) => (
          <MobileTabList linkList={linkList}>
            {props => (
              <MobileTab key={props.text} {...props} />
            )}
          </MobileTabList>
        )}
      </MobileMatchDetailsLinkListController>
      <MobileMatchDetailsStatisticRouting />
    </section>
  );
};
