import React, { FC } from 'react';
import { MatchSort, Season } from 'types';

import { matchSortVariantMap } from './Tournament';

import './MatchListMissingSelectedTournamentList.scss';


type Props = {
  seasonList: Season[];
  order: MatchSort;
}

export const MatchListMissingSelectedTournamentList: FC<Props> = ({ seasonList, order }) => {
  const ListElement = matchSortVariantMap.get(order);

  return (
    <ul className="match-list-missing-selected-tournament-list__container">
      {seasonList.map(season => <ListElement key={season.id} season={season} /> )}
    </ul>
  );
};
