import { AxiosPromise, AxiosRequestConfig } from 'axios';

import api from '../services/FetchService';
import { Basket } from '../../types/Basket/Basket';

import * as query from './queries';
import { BasketResponse } from './types';


export class BasketClient {
  constructor() {
    this.postBasket = this.postBasket.bind(this);
  }

  postBasket(input: Pick<Basket, 'groupBetValues' | 'items' | 'mode'>): AxiosPromise<BasketResponse> {
    const options: AxiosRequestConfig = {
      ...query.postBasket,
      data: {
        ...input,
      },
    };

    return api.request(options);
  }
}
