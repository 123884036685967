import React from 'react';

import { useTranslate } from '../../context/translate-context';

import './EmptyListFeedback.scss';


export const EmptyListFeedback = ({ text = 'NO_DATA' }) => {
  const { translate } = useTranslate();

  return (
    <section className="empty-list-feedback__container">
      <div className="empty-list-feedback__content">
        {translate(text)}
      </div>
    </section>
  );
};
