import React, { FC } from 'react';
import { Noop } from 'types';

import './MatchListFilter.scss';


type Props = {
  callback: Noop;
  ariaLabel: string;
  isSelected: boolean;
  isDisabled?: boolean;
}

const handleActive = (isSelected: boolean) => isSelected ? '' : 'match-list-filter--inactive';

export const MatchListFilterButton: FC<Props> = ({
  ariaLabel,
  callback,
  isSelected,
  isDisabled = false,
  children,
}) => {
  return (
    <div
      className={`match-list-filter ${handleActive(isSelected)}`}
    >
      <div className="match-list-filter__container">
        <button
          aria-label={ariaLabel}
          className="match-list-filter__button"
          onClick={callback}
          disabled={isDisabled}
        >
          {children}
        </button>
      </div>
    </div>
  );
};
