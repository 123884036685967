
import { FC, ReactElement, useCallback } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import Big from 'big.js';

import { Basket, BasketItem, BasketItemGroup, GroupBetValue } from '../../../../../types';
import { useBasket } from '../../../../../context/basket/basket-context';
import { getTotalGroupCombinations } from '../../../utils';


type Props = {
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  children(props: {
    onItemGroupUpdate: (item: BasketItem, group: BasketItemGroup, value: boolean, index: number) => void;
  }): ReactElement;
}

export const SystemModeTabController: FC<Props> = ({ children, setValue, fields }) => {
  const { updateItem } = useBasket();

  const onItemGroupUpdate = useCallback((item, group = BasketItemGroup.A, value = false, index) => {
    let currentGroups = item.groups;
    let groups: BasketItemGroup[] = [];

    if (!Array.isArray(currentGroups)) {
      currentGroups = [currentGroups];
    }

    if (value) {
      groups = [group];
    } else {
      groups = [];
    }
    setValue(`items.${index}.groups` as 'items.0.groups', groups);

    Object.keys(BasketItemGroup).forEach(key => {
      const itemWasInGroup = currentGroups.indexOf(key) > -1;
      const itemIsInGroup = groups.indexOf(key as BasketItemGroup) > -1;

      if ((itemWasInGroup && itemIsInGroup) || (!itemWasInGroup && !itemIsInGroup)) {
        return;
      }

      const groupBetValues = fields.groupBetValues[key as BasketItemGroup] || [];

      let totalBetGroupFields = groupBetValues.length;

      if (itemWasInGroup && !itemIsInGroup) {
        totalBetGroupFields = totalBetGroupFields - 1;
      } else if (!itemWasInGroup && itemIsInGroup) {
        totalBetGroupFields = totalBetGroupFields + 1;
      }

      groupBetValues.forEach((groupBet: GroupBetValue, index) => {
        const value = Big(groupBet.stakeForCombination).toNumber();
        const combinations = getTotalGroupCombinations(groupBet.number, totalBetGroupFields);
        const newStakeForGroup = Big(value).times(combinations).toFixed(2);

        setValue(
          `groupBetValues.${key}.${index}.stakeForGroup` as 'groupBetValues.A.0.stakeForGroup',
          newStakeForGroup as never, // TODO: Needs fixing. Seems like react-hook-forms is not able to infer the type correctly
        );
      });
    });


    updateItem<{ groups: BasketItem['groups'] }>(item.odd.id, {
      groups,
    });
  }, [setValue, updateItem, fields]);

  const props = {
    onItemGroupUpdate,
  };

  return children(props);
};
