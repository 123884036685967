const common = {
  FOOTBALL: 'Fotboll', // provider app Config to change
  COULD_NOT_LOAD_CONTENT: 'Det gick inte att ladda innehållet',
  SHOW_ALL: 'Visa allt',
  NO_LIVE_MATCHES_AVAILABLE: 'Ingen live kamper tilgjengelig',
  NO_LIVE_INCIDENTS_AVAILABLE: 'No live match incidents are available',
  SETTINGS: 'Inställningar',
  LIVE_TICKER: 'Live ticker',
  MORE: 'Mer',
  LIGHT_THEME: 'Ljust tema',
  DARK_THEME: 'Mörkt tema',
};

export default common;
