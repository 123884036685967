import React from 'react';
import { Icon } from 'semantic-ui-react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { formatMonthAndYear, dateTranslations } from 'utils';
import { useTranslate } from 'context/translate-context';

import { CalendarBody } from './Body';
import { CalendarHeader } from './Header';

import './Calendar.scss';


export const CalendarView = ({
  close,
  currentDate,
  currentWeek,
  handleOnClick,
  handleOnKeyDown,
  nextMonth,
  previousMonth,
  weeks,
  buttonsState,
  selectedDate,
}) => {
  const { language, translate } = useTranslate();

  return (
    <section className="calendar">
      <div className="calendar__nav">
        <div className="calendar--flex-end calendar__close">
          <button
            className="calendar__button calendar__button--close"
            onClick={close}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="calendar__nav-actions">
          <button
            aria-label={translate('PREV_MONTH')}
            className="calendar__button margin-right--size-s"
            disabled={buttonsState.isPreviousMonthDisabled}
            onClick={previousMonth}
          >
            <Icon
              className="white margin--unset"
              name="chevron left"
            />
          </button>
          <div className="calendar__label">
            {(formatMonthAndYear(selectedDate, { locale: dateTranslations.get(language) }))}
          </div>
          <button
            aria-label={translate('NEXT_MONTH')}
            className="calendar__button margin-left--size-s"
            disabled={buttonsState.isNextMonthDisabled}
            onClick={nextMonth}
          >
            <Icon
              className="white margin--unset"
              name="chevron right"
            />
          </button>
        </div>
      </div>
      <CalendarHeader />
      <CalendarBody
        currentDate={currentDate}
        currentWeek={currentWeek}
        handleOnClick={handleOnClick}
        handleOnKeyDown={handleOnKeyDown}
        weeks={weeks}
      />
    </section>
  );
};
