const basket = {
  SINGLE: 'Singel',
  COMBI: 'Kombo',
  SYSTEM: 'System',
  CLOSE: 'Stäng',
  SYSTEM_GROUP: 'Systemgrupp',
  WITHOUT_GROUP: 'Utan grupp',
  GROUP: 'Grupp',
  BANKER: 'Banker',
  SELECT_BET: 'Välj din insats',
  BACK: 'Tillbaka',
  LINK_CLICKED: 'Klicka på länken',
  PLACE_BET: 'Betala spel',
  COMBINATION: 'Kombination',
  COMBINATIONS: 'Kombinationer',
  SELECT_COMBINATIONS: 'Välj kombinationer',
  CANT_BET: 'Du kan inte lägga det här spelet',
  INVALID_VALUE: 'Ogiltigt värde på insatsen',
  STAKE_PER_COMBO: 'Insats per kombination',
  TOTAL_STAKE: 'Total insats',
  MIN_WIN: 'Minsta vinst:',
  MAX_WIN: 'Strörsta vinst:',
  STAKE_PER_BETS: 'Insats per spel',
  TOTAL_ODDS: 'Totalodds',
  EMPTY_TICKET: 'Ditt spel är tomt.',
  CANT_MULTI_BET: 'Att placera flera spel på samma match är inte tillgängligt för kombinations- och systemspel',
  OVERDUE_BET_LIST: 'En eller flera insatser är inaktiva.\nTa bort inaktivt spel från kupongen.',
  TICKET: 'Kupong',
  SYSTEM_OF: 'Systemet avstängt',
  COMBO_BETS: 'Kombinationsspel',
};

export default basket;
