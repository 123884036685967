import { memo, FC, ReactElement } from 'react';
import { Noop } from 'types';

import { useLiveTickerProgressBar } from './useLiveTickerProgressBar';


type Props = {
  dequeue: Noop;
  incidentsRemaining: number;
  children(props: {
    secondsRemaining: number;
    liveTickerDisplayLimit: number;
  }): ReactElement;
};

export const LiveTickerProgressBarController: FC<Props> = memo(({ dequeue, incidentsRemaining, children }) => {
  const {
    secondsRemaining,
    liveTickerDisplayLimit,
  } = useLiveTickerProgressBar({ dequeue, incidentsRemaining });

  const props = {
    secondsRemaining,
    liveTickerDisplayLimit,
  };

  return children(props);
});


