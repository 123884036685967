import React, { FC } from 'react';
import { BasketErrorMessage, BasketErrorMessageLabel } from 'types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CloseErrorIcon } from 'assets/icons/close-error.svg';

import './BasketItemButton.scss';


const iconMap = new Map();
iconMap.set(BasketErrorMessageLabel.OVERDUE_BET_LIST, <CloseErrorIcon />);
iconMap.set(BasketErrorMessageLabel.INVALID_VALUE, <CloseErrorIcon />);

type Props = {
  error?: BasketErrorMessage;
  remove: () => void;
}

export const BasketItemButton: FC<Props> = ({ error, remove }) => {
  return (
    <div className="basket-item-button__container">
      <button className="basket-item-button__icon" onClick={remove}>
        {error ? iconMap.get(error.message) : <CloseIcon />}
      </button>
    </div>
  );
};

