import { noop } from 'lodash';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { BasketItem, OddOdd } from 'types';

import { BasketMessage } from './BasketMessage.type';
import { useInitializeWithBasketApp } from './useInitializeWithBasketApp';


export type Props = {
  isOddInBasket: (odd: OddOdd) => boolean;
  sendBasketItem: (item: BasketItem) => void;
}

export const BasketDashboardContext = React.createContext<Props>({
  isOddInBasket: () => false,
  sendBasketItem: noop,
});

const BasketDashboardProvider: FC = props => {
  const [items, setItems] = useState<BasketItem[]>([]);
  const isOddInBasket = useCallback(odd => {
    const foundItem = items.find((i: BasketItem) => {
      return i.odd?.id === odd?.id;
    });

    const isExisting = Boolean(foundItem);

    return isExisting;
  }, [items]);


  const sendBasketItem = (item: BasketItem) => {
    const event = new CustomEvent(BasketMessage.Odd, { detail: item });

    window.dispatchEvent(event);
  };

  const handleInitlizedBasket = (event: CustomEventInit<BasketItem[]>) => {
    if (!event.detail) {
      return;
    }

    return setItems(event.detail);
  };

  const memoHandleInitlizedBasket = useCallback(handleInitlizedBasket, []);

  useInitializeWithBasketApp(items);

  useEffect(() => {
    window.addEventListener(BasketMessage.State, memoHandleInitlizedBasket);

    return () => {
      window.removeEventListener(BasketMessage.State, memoHandleInitlizedBasket);
    };
  }, [memoHandleInitlizedBasket]);

  const values = {
    sendBasketItem,
    isOddInBasket,
  };

  return (
    <BasketDashboardContext.Provider value={values} {...props} />
  );
};


const useBasketDashboard = () => {
  const context = useContext(BasketDashboardContext);

  if(context === undefined) {
    throw new Error('useBasketDashboard must be used within a BasketDashboardProvider');
  }

  return context;
};


export {
  BasketDashboardProvider,
  useBasketDashboard,
};
