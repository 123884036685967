import { FC } from 'react';

import { useMatchDetailsRedirect } from './useMatchDetailsRedirect';


type Props = {
  link: Record<string, Function>;
}


export const MatchDetailsRedirect: FC<Props> = ({ link }) => {
  useMatchDetailsRedirect({ link });

  return null;
};
