import React, { FC, useContext, useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io-client/build/typed-events';
import { noop } from 'lodash';
import { OddGroupingType, SocketLiveOdd } from 'types';
import socketsService from 'utils/sockets';
import { useConfig } from 'context/config-context';


type Props = {
  isLoading: boolean;
  liveOddListSocket: OddGroupingType[];
  setLiveOddListSocket: React.Dispatch<React.SetStateAction<OddGroupingType[]>>;
}

const SocketsLiveMatchListContext = React.createContext<Props>({
  isLoading: false,
  liveOddListSocket: [],
  setLiveOddListSocket: noop,
});

const SocketsLiveOddListProvider: FC = props => {
  const { configuration: { provider: { name: providerName } } } = useConfig();
  const [liveOddListSocket, setLiveOddListSocket] = useState<OddGroupingType[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const socket = socketsService.getSocket() as Socket<DefaultEventsMap, DefaultEventsMap>;

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket connected');
    });
    setLoading(true);

    if (providerName) {
      socket.emit(`subscribe_odds_${providerName}`);

      socket.on(`odds_${providerName}`, ({ liveOddList = [] }: SocketLiveOdd) => {
        setLiveOddListSocket(liveOddList);

        isLoading && setLoading(false);
      });
    }

    socket.on('errors', errors => {
      console.log(errors);
    });

    socket.on('disconnect', reason => {
      if (reason) {
        socket.connect();
      }
      console.error(`Socket client disconnected: ${reason}`);
    });

    return () => {
      socket.disconnect();
    };
  }, [providerName]);


  const values = {
    isLoading,
    liveOddListSocket,
    setLiveOddListSocket,
  };

  return (
    <SocketsLiveMatchListContext.Provider value={values} {...props} />
  );
};

const useSocketsLiveOddList = () => {
  const context = useContext(SocketsLiveMatchListContext);

  if(context === undefined) {
    throw new Error('useSocketsLiveOddList must be used within a SocketsLiveOddListProvider');
  }

  return context;
};

export {
  SocketsLiveOddListProvider,
  useSocketsLiveOddList,
};
