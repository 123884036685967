import { isFunction } from 'lodash';
import { MatchStatusName, GameTeam, MatchScoreKeys, Event } from 'types';


export type GetResultsByStatusNameProps = ({ home, away }: {
  home?: GameTeam;
  away?: GameTeam;
}) => {
    homeResult: string;
    awayResult: string;
  }

type Props = ({ fieldName }: { fieldName: MatchScoreKeys }) => GetResultsByStatusNameProps;

export const transformResultToString = ({ homeResult, awayResult }: { homeResult: string; awayResult: string}) => `${homeResult} : ${awayResult}`;

const getResultByPropertyField: Props = ({ fieldName }) => ({ home, away }) => {
  const { [fieldName]: homeResult = 0 } = home || {};
  const { [fieldName]: awayResult = 0 } = away || {};
  const result = { homeResult: String(homeResult), awayResult: String(awayResult) };

  return result;
};

const getResultByOvertime: GetResultsByStatusNameProps = ({ home, away }) => {
  const {
    regularTimeScore: homeRegularResult = 0,
    overtimeScore: homeResult = 0,
  } = home || {};
  const {
    regularTimeScore: awayRegularResult = 0,
    overtimeScore: awayResult = 0,
  } = away || {};

  const result = { homeResult: String(homeResult + homeRegularResult), awayResult: String(awayResult + awayRegularResult) };

  return result;
};

const EMPTY_MATCH_RESULT = { homeResult: '0', awayResult: '0' };

export const getResultByMatchStatusNameMap = new Map<MatchStatusName, GetResultsByStatusNameProps>();
getResultByMatchStatusNameMap.set(MatchStatusName.NotStarted, getResultByPropertyField({ fieldName: MatchScoreKeys.FirstHalf }));
getResultByMatchStatusNameMap.set(MatchStatusName['1stHalf'], getResultByPropertyField({ fieldName: MatchScoreKeys.FirstHalf }));
getResultByMatchStatusNameMap.set(MatchStatusName.Halftime, getResultByPropertyField({ fieldName: MatchScoreKeys.FirstHalf }));
getResultByMatchStatusNameMap.set(MatchStatusName['2ndHalf'], getResultByPropertyField({ fieldName: MatchScoreKeys.RegularTime }));
getResultByMatchStatusNameMap.set(MatchStatusName.WaitingForExtratime, getResultByPropertyField({ fieldName: MatchScoreKeys.RegularTime }));
getResultByMatchStatusNameMap.set(MatchStatusName.Finished, getResultByPropertyField({ fieldName: MatchScoreKeys.RegularTime }));
getResultByMatchStatusNameMap.set(MatchStatusName.Extratime, getResultByOvertime);
getResultByMatchStatusNameMap.set(MatchStatusName.ExtratimeHalftime, getResultByOvertime);
getResultByMatchStatusNameMap.set(MatchStatusName.Extratime1stHalf, getResultByOvertime);
getResultByMatchStatusNameMap.set(MatchStatusName.Extratime2ndHalf, getResultByOvertime);
getResultByMatchStatusNameMap.set(MatchStatusName.FinishedAfterExtratime, getResultByOvertime);
getResultByMatchStatusNameMap.set(MatchStatusName.PenaltyShootout, getResultByPropertyField({ fieldName: MatchScoreKeys.Penalty }));
getResultByMatchStatusNameMap.set(MatchStatusName.FinishedAfterPenalties, getResultByPropertyField({ fieldName: MatchScoreKeys.Penalty }));


export const handleMatchResult = (matchStatusName: Event['statusName']) => ({ home, away }: { home?: GameTeam; away?: GameTeam }) => {
  const getMatchResultByStatus = getResultByMatchStatusNameMap.get(matchStatusName);

  if (isFunction(getMatchResultByStatus)) {
    return getMatchResultByStatus({ home, away });
  }

  return EMPTY_MATCH_RESULT;
};
