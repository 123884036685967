import { ReactElement, FC } from 'react';
import { useMatch } from 'context/match-context';
import { sortItemsAsc } from 'utils';
import { Incident, TeamScore, IncidentInternal } from 'types';

import { getIncidentTimeInSecondsAndOrigin, sortIncidentsByIncidentTimeInOrder } from '../../../shared/Incident/incidentServices';


type Props = {
  children(props: {
    incidents: IncidentInternal[];
    getIncidentsConnectedToPlayerName: (name: string) => Incident[];
  }): ReactElement;
}

export const MatchDetailsMatchReportIncidentsController: FC<Props> = ({ children }) => {
  const { selectHomeIncidents, selectAwayIncidents } = useMatch();

  const markIncidentsAsHome = selectHomeIncidents.map(incident => getIncidentTimeInSecondsAndOrigin(incident, TeamScore.Home));
  const markIncidentsAsAway = selectAwayIncidents.map(incident => getIncidentTimeInSecondsAndOrigin(incident, TeamScore.Away));

  const sortIncidentsInAscTime = [...markIncidentsAsHome, ...markIncidentsAsAway].sort(sortIncidentsByIncidentTimeInOrder(sortItemsAsc));


  const getIncidentsConnectedToPlayerName = (playerName: string) => {
    return sortIncidentsInAscTime.filter(({ responsible }) => responsible === playerName);
  };

  const props = {
    incidents: sortIncidentsInAscTime,
    getIncidentsConnectedToPlayerName,
  };

  return children(props);
};
