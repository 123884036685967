import { TeamScore } from 'types';


export const LINEUP_CLASSES = {
  PLAYER: team => `match-details-line-ups__player match-details-line-ups__player--${team}`,
  PLAYER_INCIDENTS: team => `
    match-details-line-ups__player-incidents match-details-line-ups--${team}
  `,
  PLAYER_NAME_CONTAINER: 'match-details-line-ups__player-name-container',
  PLAYER_NAME_WRAPPER: (team, incidentsLength) => `${incidentsLength ? `margin-${TeamScore.Home === team ? 'right' : 'left'}--size-xs` : ''}`,
  GOAL: 'match-details-line-ups__incident-code margin-right--size-xs',
  SHIRT_NUMBER: team => TeamScore.Home === team ?
    'match-details-line-ups__shirtNumber margin-right--size-s' :
    'match-details-line-ups__shirtNumber match-details-line-ups__shirtNumber--align-right margin-left--size-s',
};
