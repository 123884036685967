import React, { useMemo, FC }  from 'react';
import { MatchSort, Match, Noop, Maybe, Device } from 'types';

import MatchVariantKickOff from '../shared/Match/Variant/KickOff';
import MatchVariantTournament from '../shared/Match/Variant/Tournament';

import { MatchListHeader } from './Header/MatchListHeader';
import { getPreviousRowCompetitionName } from './matchListServices';
import { MatchListHeaderTournament } from './Header/MatchListHeaderTournament';
import { MatchListScrollController } from './MatchListScrollController';
import { MatchListLoader } from './MatchListLoader';

import './MatchListVariant.scss';


type MatchListProps = {
  matches: Match[];
  order: string;
  deviceVariant: Device;
}

const listDisplayMap = new Map();

export const MatchListVariant: FC<MatchListProps> = ({ matches, order, deviceVariant }) => {
  const ActiveListVariant = useMemo(() => {
    return listDisplayMap.get(order);
  }, [order]);

  return (
    <MatchListScrollController>
      {({ isLoading, handleScrollRef }) => (
        <div
          className="full-width"
        >
          <ul
            className="match-list-variant__grid"
          >
            <ActiveListVariant
              deviceVariant={deviceVariant}
              matches={matches}
              handleScrollRef={handleScrollRef}
            />
            {isLoading ? <MatchListLoader /> : null}
          </ul>
        </div>
      )}
    </MatchListScrollController>
  );
};


type ListProps = {
  matches: Match[];
  handleScrollRef: Maybe<Noop>;
  deviceVariant: Device;
}

const List: FC<ListProps> = ({ matches, handleScrollRef, deviceVariant }) => {
  const ListElement = MatchVariantKickOff[deviceVariant];

  return (
    <>
      {matches.map((match, index) => {
        const { event: { id } } = match;
        const handleAddRefToLastElement = matches.length === index + 1 ?
          { handleScrollRef } :
          {};

        const matchComponent = (
          <ListElement
            key={id}
            match={match}
            {...handleAddRefToLastElement}
          />
        );

        if (matches.length === index + 1) {
          return matchComponent;
        }

        return matchComponent;
      })}
    </>
  );
};

const TournamentsView: FC<ListProps> = ({ matches, handleScrollRef, deviceVariant }) => {
  const ListElement = MatchVariantTournament[deviceVariant];

  return (
    <>
      {matches.map((match, index) => {
        const { event: { id }, season } = match;
        const { competition: { name = '', id: tournamentId } } = season || {};
        const handleAddRefToLastElement = matches.length === index + 1 ?
          { handleScrollRef } :
          {};

        const matchComponent = (
          <ListElement
            key={id}
            match={match}
            {...handleAddRefToLastElement}
          />
        );

        if (name !== getPreviousRowCompetitionName(matches, index)) {
          return (
            <MatchListHeader
              key={tournamentId}
              tournamentName={name}
              Tournament={<MatchListHeaderTournament season={season} deviceVariant={deviceVariant} />}
              customClass="match-variant-tournament"
            >
              {matchComponent}
            </MatchListHeader>
          );
        }

        return matchComponent;
      })}
    </>
  );
};

listDisplayMap.set(MatchSort.TOURNAMENT_ASC, TournamentsView);
listDisplayMap.set(MatchSort.TOURNAMENT_DESC, TournamentsView);
listDisplayMap.set(MatchSort.KICK_OFF_ASC, List);
listDisplayMap.set(MatchSort.KICK_OFF_DESC, List);
listDisplayMap.set(MatchSort.BET_RATIO_ASC, List);
listDisplayMap.set(MatchSort.BET_RATIO_DESC, List);
