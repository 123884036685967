import React, { useCallback } from 'react';
import { useTranslate } from 'context/translate-context';
import { LabelCheckbox } from 'components';
import { BasketItemGroup, BasketMode } from 'types';
import { useFindBasketError } from 'components/Basket/validation/useFindBasketError';
import { useCompareOdds } from 'views/Dashboard/shared/hooks/useCompareOdds';

import { BasketItemOddInfo } from './BasketItemOddInfo';
import { BasketItemButton } from './components/BasketItemButton';

import './BasketItem.scss';


export const BasketItemSystem = ({ item, onRemove, onChange, index }) => {
  const { translate } = useTranslate();
  const { handleFoundError } = useFindBasketError({ item });
  const { odd: { id: oddId } } = item;

  const remove = useCallback(() => {
    onRemove(item, index);
  }, [item, index, onRemove]);

  const update = useCallback(event => {
    const { value, checked } = event.target;

    onChange(item, value, checked, index);
  }, [item, index, onChange]);

  const { homeShortName, awayShortName, odd } = item;
  const { odds } = odd;

  const oddsAsStringWithDigits = odds.toFixed(2);
  const {
    highlightIncreasedOddClass,
    highlightDecreasedOddClass,
  } = useCompareOdds({
    odd: odds,
    oddIncreaseClassName: 'basket-item__odd--increased',
    oddDecreaseClassName: 'basket-item__odd--decreased',
  });

  const handleHasOddChanged = `${highlightIncreasedOddClass || highlightDecreasedOddClass}`;

  const handleCheckboxes = group => {
    if (handleFoundError) {
      return {
        label: group,
        key: group,
        name: group,
        disabled: true,
      };
    }

    return {
      id: `${oddId}-odd-${group}`,
      label: group,
      value: group,
      name: group,
      onChange: update,
      key: group,
      defaultChecked: item.groups.indexOf(group) > -1,
    };
  };

  return (
    <div className={`basket-item ${handleFoundError ? 'basket-item--error' : ''}`}>
      <div className={`basket-item__container ${handleFoundError ? 'basket-item__container--error' : ''}`}>
        <div className="basket-item__content">
          <div className="basket-item__header">
            <div className="basket-item__header-team">
              <div className="basket-item__name">
                <span>{homeShortName}</span>
                <div className="basket-item__name--separator">
                  -
                </div>
                <span>{awayShortName}</span>
              </div>
              <div className={`basket-item__odd basket-item__odd--mobile ${handleHasOddChanged}`}>
                {oddsAsStringWithDigits}
              </div>
            </div>
            <BasketItemOddInfo basketItem={item} mode={BasketMode.System} />
            <div className="basket-item__odd-wrapper">
              <div className={`basket-item__odd ${handleHasOddChanged}`}>
                {oddsAsStringWithDigits}
              </div>
            </div>
          </div>
          <div className="basket-item__footer">
            <div className="basket-item__system-groups">
              <div className="basket-item__system-label">
                {translate('SYSTEM_GROUP')}
              </div>
              <div className="basket-item__system-options">
                {Object.values(BasketItemGroup).map(group => {
                  return (
                    <LabelCheckbox
                      {...handleCheckboxes(group)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BasketItemButton
        remove={remove}
        error={handleFoundError}
      />
    </div>
  );
};
