import { StandingList } from 'types';

import { sortItemsAsc } from '../../../utils/sort';


export const sortStandingsByGroup = (firstElement: StandingList, secondElement: StandingList) => {
  const { group: { name: firstElementName } } = firstElement;
  const { group: { name: secondElementName } } = secondElement;

  return sortItemsAsc(firstElementName, secondElementName);
};
