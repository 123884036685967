import React from 'react';

import { ButtonBase } from '../ButtonBase';


import './ButtonPrimary.scss';


export const ButtonPrimary = props => {
  const Button = ButtonBase({ classPrefix: 'button-primary' });

  return (
    <Button {...props} />
  );
};
