import React from 'react';
import { HeadingH2 } from 'components';
import { useTranslate } from 'context/translate-context';
import { ReactComponent as Filters } from 'assets/icons/filters.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { DashboardModalController } from '../../../shared/DashboardModalController';
import { ModalBlockScroll } from '../../../ModalBlockScroll/ModalBlockScroll';

import { MobileMatchListFilterModalSectionSort } from './Section/Sort/MobileMatchListFilterModalSectionSort';
import { MobileMatchListFilterModalSectionPrediction } from './Section/Prediction/MobileMatchListFilterModalSectionPrediction';

import './MobileMatchListFilterModal.scss';


export const MobileMatchListFilterModal = () => {
  const { translate } = useTranslate();

  return (
    <DashboardModalController>
      {({
        isExpanded,
        handleCollapse,
        handleToggleExpand,
        handleClick,
        dropdownRef,
      }) => {
        return (
          <>
            {isExpanded && (
              <ModalBlockScroll>
                <section
                  className="m-match-list-filter-modal__section"
                  onClick={handleClick}
                  ref={dropdownRef}
                >
                  <header className="m-match-list-filter-modal__header">
                    <HeadingH2 title={translate('DISPLAY_SETTINGS')} />
                    <button
                      className="m-match-list-filter-modal__close-btn"
                      onClick={handleCollapse}
                    >
                      <CloseIcon />
                    </button>
                  </header>
                  <div className="m-match-list-filter-modal__content">
                    <MobileMatchListFilterModalSectionSort />
                    <MobileMatchListFilterModalSectionPrediction />
                  </div>
                </section>
              </ModalBlockScroll>
            )}
            <button
              className="m-match-list-filter-modal__filters-btn"
              onClick={handleToggleExpand}
            >
              <Filters fill="var(--SaasText01)" />
            </button>
          </>
        );
      }}
    </DashboardModalController>
  );
};
