import React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { MatchStatus } from 'types';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useUpdateMatchDetailsSideBets } from 'views/Dashboard/shared/hooks/updateMatchDetailsSideBets/useUpdateMatchDetailsSideBets';
import { MatchDetailsSideBetsLoadingWrapper } from 'views/Dashboard/MatchDetails/SideBets/MatchDetailsSideBetsLoadingWrapper';

import { RouteParam } from '../../RouteParam';
import { mediaQuery } from '../../../../../../constants/mediaQuery';
import { MediaQueriesRedirectController } from '../../../../../shared/MediaQueriesRedirectController';
import { MobileMatchDetailsSummaryWrapper } from '../../Statistic/MobileMatchDetailsSummaryWrapper';

import { getMobileMatchDetailsSideBets } from './routes';


export const MobileMatchDetailsSideBetsRouting = () => {
  const params = useParams<RouteParam>();
  const isDesktop = useMediaQuery(mediaQuery.minDesktopSmall);

  useUpdateMatchDetailsSideBets();

  return (
    <Switch>
      {getMobileMatchDetailsSideBets(params).map(({ path, redirectURL, Component, OptionalWrapper }) => {
        return (
          <Route exact key={redirectURL} path={path}
            render={() => (
              <MediaQueriesRedirectController
                isMediaQueryActive={isDesktop}
                url={redirectURL}
              >
                <MatchDetailsSideBetsLoadingWrapper>
                  <MobileMatchDetailsSummaryWrapper
                    displayStatus={
                      [MatchStatus.Finished, MatchStatus.Live, MatchStatus.Scheduled]
                    }
                  >
                    {OptionalWrapper ? (
                      <OptionalWrapper>
                        <Component />
                      </OptionalWrapper>
                    ) :
                      <Component />
                    }
                  </MobileMatchDetailsSummaryWrapper>
                </MatchDetailsSideBetsLoadingWrapper>
              </MediaQueriesRedirectController>
            )}
          />
        );
      })}
    </Switch>
  );
};

