import React, { memo } from 'react';
import { MatchDetailsSideBetsHandleWhenUpdate } from 'views/Dashboard/MatchDetails/SideBets/MatchDetailsSideBetsHandleWhenUpdate';

import { MobileMatchDetailsSideBetsDefault } from './Default/MobileMatchDetailsSideBetsDefault';
import { MobileMatchDetailsSideBetsLive } from './Live/MobileMatchDetailsSideBetsLive';


export const MobileMatchDetailsSideBetsSection = memo(() => {
  return (
    <MatchDetailsSideBetsHandleWhenUpdate
      ComponentWithLiveUpdate={MobileMatchDetailsSideBetsLive}
    >
      <MobileMatchDetailsSideBetsDefault />
    </MatchDetailsSideBetsHandleWhenUpdate>
  );
});
