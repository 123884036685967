import { FC, ReactElement } from 'react';
import { MatchStatus } from 'types';

import { matchDetailsPrediction } from './matchPredictionHighlightFactory';
import { handleDisplayRecommendedBetByStatus } from './recommendedBet/matchPredictionRecommendedBet.services';


type Props = {
  variant: string;
  matchStatus: MatchStatus;
  isRecommendedBet?: boolean;
  children(props: {
    elementClassDisplayVariant: (prediction?: number) => string;
    handleRecommendedBet: boolean;
  }): ReactElement;
}

export const MatchPredictionClassHandler: FC<Props> = ({ matchStatus, isRecommendedBet, variant, children }) => {
  const elementClassDisplayVariant = matchDetailsPrediction(variant);
  const handleRecommendedBet = handleDisplayRecommendedBetByStatus(matchStatus, isRecommendedBet);

  const props = {
    elementClassDisplayVariant,
    handleRecommendedBet,
  };

  return children(props);
};
