import React, { ReactElement, FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { addQuery } from 'utils';


type Props = {
  label: string;
  link: string;
  Icon?: ReactElement;
  isTopLink?: boolean;
  disabled?: boolean;
};

const maxLengthOfLabel = 20;

export const MatchDetailsSidebarLink: FC<Props> = ({ label, link, Icon = null, isTopLink, disabled }) => {
  const { search } = useLocation();

  return (
    <li className={`
      match-detail-sidebar__element
      ${!Icon ? 'match-detail-sidebar__element--no-icon' : ''}
      ${isTopLink ? 'match-detail-sidebar__element--top-link' : ''}
      ${disabled ? 'match-detail-sidebar__element--disabled' : ''}
    `}
    >
      {link && !disabled ? (
        <NavLink
          exact
          activeClassName="match-detail-sidebar__link--is-active"
          className="match-detail-sidebar__link"
          to={{
            pathname: link,
            search: addQuery(search, {}),
          }}
        >
          <>
            {Icon && (
              <div className="match-detail-sidebar__icon margin-right--size-s">
                {Icon}
              </div>
            )}
          </>
          {label.length > maxLengthOfLabel ? (
            <Popup
              trigger={
                <div>
                  {`${label.slice(0, maxLengthOfLabel)}...`}
                </div>
              }
              content={label}
            />
          ) : label}
        </NavLink>
      ) : (
        <div>
          {Icon && (
            <div className="match-detail-sidebar__icon margin-right--size-s">
              {Icon}
            </div>
          )}
          <div>
            {label}
          </div>
        </div>
      )}
    </li>
  );
};
