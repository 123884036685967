import React, { ReactElement, FC } from 'react';
import { MatchTime } from 'types';
import { useTranslate } from 'context/translate-context';

import { MatchDetailsPredictionsSectionController } from './MatchDetailsPredictionsSectionController';

import './MatchDetailsPredictionsSection.scss';


type Props = {
  tabsActive?: boolean;
  label: string;
  children(props: {
      activePredictionTab: MatchTime;
   }): ReactElement;
}

export const MatchDetailsPredictionsSection: FC<Props> = ({ tabsActive, label, children }) => {
  return (
    <MatchDetailsPredictionsSectionController>
      {({ handleHighlightTab, setActivePredictionTab, activePredictionTab }) => {
        return (
          <section className="match-details-prediction-section__container">
            <PredictionsHeader label={label}>
              {tabsActive ? (
                <>
                  <PredictionTab
                    label="FULL_TIME"
                    tab={MatchTime.RegularTime}
                    handleHighlightTab={handleHighlightTab}
                    setTab={setActivePredictionTab}
                  />
                  <PredictionTab
                    label="FIRST_HALF"
                    tab={MatchTime.FirstHalf}
                    handleHighlightTab={handleHighlightTab}
                    setTab={setActivePredictionTab}
                  />
                  <PredictionTab
                    label="SECOND_HALF"
                    tab={MatchTime.SecondHalf}
                    handleHighlightTab={handleHighlightTab}
                    setTab={setActivePredictionTab}
                  />
                </>
              ) : <></>}
            </PredictionsHeader>
            <div className="match-details-prediction-section__table-container">
              {children({ activePredictionTab })}
            </div>
          </section>
        );
      }}
    </MatchDetailsPredictionsSectionController>

  );
};

type PredictionTabProps = {
  label: string;
  tab: MatchTime;
  handleHighlightTab: (matchTime: MatchTime) => string;
  setTab: (matchTime: MatchTime) => void;
};

const PredictionTab: FC<PredictionTabProps> = ({ label, handleHighlightTab, tab, setTab }) => {
  const { translate } = useTranslate();

  return (
    <div
      className={`match-details-prediction-section__tab ${handleHighlightTab(tab)}`}
      onClick={() => setTab(tab)}
    >
      {translate(label)}
    </div>
  );
};


type PredictionsHeaderProps = {
  label: string;
  handleHighlightTab?: (matchTime: MatchTime) => string;
  setTab?: (matchTime: MatchTime) => void;
  children?: ReactElement;
};

const PredictionsHeader: FC<PredictionsHeaderProps> = ({ label, children }) => {
  return (
    <div className="match-details-prediction-section__header">
      <div className="match-details-prediction-section__title">
        <div className="match-details-prediction-section__title-container">
          {label}
        </div>
      </div>
      <div className="match-details-prediction-section__tabs">
        {children}
      </div>
    </div>
  );
};
