import React, { FC } from 'react';
import { FieldCheckbox } from 'components';
import { ReactComponent as MutedSpeaker } from 'assets/icons/muted-speaker.svg';
import { ReactComponent as Speaker } from 'assets/icons/speaker.svg';
import { useLiveTicker } from 'context/liveTicker/live-ticker-context';
import { useTranslate } from 'context/translate-context';

import './LiveControlPanelIncidentList.scss';

type Props = {
  canMuteSound: boolean;
};

export const LiveControlPanelIncidentList: FC<Props> = ({ canMuteSound }) => {
  const { translate } = useTranslate();
  const {
    liveDashboardPanel,
    toggleSelectIncident,
    toggleIncidentSound,
  } = useLiveTicker();

  const handleGridWhenCanMuteSound = `live-control-panel-incident-list__incident--${canMuteSound ? '3-column' : '2-column'}`;

  return (
    <ul className="live-control-panel-incident-list">
      {liveDashboardPanel.map(({ isMuted, label, isSelected }) => {
        return (
          <li
            key={label}
            className={`live-control-panel-incident-list__incident ${handleGridWhenCanMuteSound}`}
          >
            {canMuteSound && (
              <button
                className={`live-control-panel-incident-list__icon live-control-panel-incident-list__icon--${isMuted ? 'inactive' : 'active'}`}
                onClick={() => toggleIncidentSound(label)}
              >
                {isMuted ? <MutedSpeaker /> : <Speaker />}
              </button>
            )}
            <div className="live-control-panel-incident-list__label">
              {translate(label)}
            </div>
            <FieldCheckbox
              ariaLabel={translate(label)}
              value={isSelected}
              name={label}
              handleChange={() => {
                toggleSelectIncident(label);
              }}
            />
          </li>
        );
      })}
    </ul>
  );
};
