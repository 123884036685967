import { isGoodBet } from 'views/Dashboard/shared';


export const getValueWithDisplayHandler = (displayPredictionHandler: (prediction: number, odd: number) => string) =>
  (odds: number, prediction?: number) => {
    return prediction ? displayPredictionHandler(prediction, odds) : undefined;
  };


export const getClassNameWithElementDisplayVariantWhenIsRecommendedBet = (elementClassDisplayVariant: (prediction: number) => string) =>
  (odds: number, prediction?: number): string | undefined => {
    if (prediction) {
      return isGoodBet(odds, prediction) ? elementClassDisplayVariant(prediction) : undefined;
    }
    return undefined;
  };
