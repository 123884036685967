import React, { useContext, useState, FC, useEffect, useCallback } from 'react';
import { noop } from 'lodash';

import { Theme, Maybe } from '../types';

import ThemeService  from './services/ThemeService';


type Props = {
  theme: Maybe<Theme>;
  handleChangeTheme: () => void;
}

const ThemeContext = React.createContext<Props>({
  theme: null,
  handleChangeTheme: noop,
});

const ThemeProvider: FC = props => {
  const savedTheme = ThemeService.getTheme() as Theme;
  const [theme, setTheme] = useState<Theme>(savedTheme);

  const handleSetTheme = () => {
    ThemeService.setTheme(savedTheme);
  };
  const memoSetTheme = useCallback(handleSetTheme, [savedTheme]);

  useEffect(() => {
    memoSetTheme();
  }, [memoSetTheme, savedTheme]);

  const handleChangeTheme = () => {
    if (theme === Theme.DARK) {
      ThemeService.setTheme(Theme.LIGHT);

      return setTheme(Theme.LIGHT);
    }
    ThemeService.setTheme(Theme.DARK);

    return setTheme(Theme.DARK);
  };

  if (!theme) {
    return null;
  }

  const values = {
    theme,
    handleChangeTheme,
  };

  return (
    <ThemeContext.Provider
      value={values}
      {...props}
    />
  );
};


const useTheme = () => {
  const context = useContext(ThemeContext);

  if(context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};


export {
  ThemeProvider,
  useTheme,
};
