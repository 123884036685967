import React, { FC, ReactElement } from 'react';
import { Noop } from 'types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { LiveControlTime } from '../Time/LiveControlTime';

import { LiveControlPanelIncidentList } from './IncidentList/LiveControlPanelIncidentList';
import { LiveControlPanelController } from './LiveControlPanelController';

import './LiveControlPanel.scss';


type Props = {
  position: 'top' | 'bottom';
  canControlTime?: boolean;
  canMuteSound?: boolean;
  children(props: {
    handleToggleExpand: Noop;
  }): ReactElement;
};


export const LiveControlPanel: FC<Props> = ({ position, canControlTime = false, canMuteSound = false, children }) => {
  return (
    <LiveControlPanelController>
      {({
        isExpanded,
        handleCollapse,
        handleToggleExpand,
        handleClick,
        dropdownRef,
      }) => {
        const handleVisible = isExpanded ? 'live-control-panel--visible' : '';

        return (
          <>
            <section
              className={`live-control-panel live-control-panel--position-${position} ${handleVisible}`}
              onClick={handleClick}
              ref={dropdownRef}
            >
              <header className="live-control-panel__header">
                <button
                  className="live-control-panel__close-btn"
                  onClick={handleCollapse}
                >
                  <CloseIcon />
                </button>
              </header>
              {canControlTime &&  <LiveControlTime />}
              <LiveControlPanelIncidentList canMuteSound={canMuteSound} />
            </section>
            {children({ handleToggleExpand })}
          </>
        );
      }}
    </LiveControlPanelController>
  );
};
