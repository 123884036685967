import { useEffect, useCallback } from 'react';
import { BasketItem } from 'types';

import { BasketMessage } from './BasketMessage.type';


export const useSendInitializedState = (items: BasketItem[]) => {
  const initializedMessage = (item: CustomEventInit<boolean>) => {
    const isInitialized = item?.detail;

    if (!isInitialized) {
      return;
    }

    const event = new CustomEvent(BasketMessage.State, { detail: items });

    window.dispatchEvent(event);
  };

  const memoInitializedMessage = useCallback(initializedMessage, [items.length]);

  useEffect(() => {
    window.addEventListener(BasketMessage.IsInitialized, memoInitializedMessage);

    return () => {
      window.removeEventListener(BasketMessage.IsInitialized, memoInitializedMessage);
    };
  }, [memoInitializedMessage]);
};
