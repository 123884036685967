import { CountryLanguage } from 'types';

import translations from './translations';

const translate = (language: CountryLanguage, phrase: string) => {
  let currentLangTranslations = translations[language];

  if (!currentLangTranslations) {
    currentLangTranslations = translations.en;
    console.warn(`Unsupported language: "${language}". Using default: "English".`);
  }

  if (!currentLangTranslations[phrase]) {
    if (language !== 'en') {
      console.warn(
        `Missing "${language}" translation for phrase: "${phrase}". Using not translated phrase: "${phrase}"`,
      );
    }

    return phrase;
  }

  return currentLangTranslations[phrase];
};

export default translate;
