import { ReactElement, FC } from 'react';
import { Area, Maybe } from 'types';
import { useArea } from 'context/area-context';

import { setGivenAreaBy } from './tournamentAreaServices';


type Props = {
  children(props: {
    areAreasLoading: boolean;
    areas: Area[];
    handleSelectArea: (area: Maybe<Area>) => void;
    handleSelectContinent: (area: Maybe<Area>) => void;
    selectedCountry: Maybe<Area>;
    selectedContinent: Maybe<Area>;
  }): ReactElement | null;
};

export const TournamentAreaListController: FC<Props> = ({ children }) => {
  const {
    areas,
    selectedCountry,
    selectedContinent,
    setSelectedCountry: setArea,
    setSelectedContinent,
    areAreasLoading,
  } = useArea();
  const setSelectedCountry = setGivenAreaBy({ selectedArea: selectedCountry, setArea });
  const setSelectedContinentArea = setGivenAreaBy({ selectedArea: selectedContinent, setArea: setSelectedContinent });

  const handleSelectArea = (area: Maybe<Area>) => setSelectedCountry(area);
  const handleSelectContinent = (continent: Maybe<Area>) => setSelectedContinentArea(continent);

  const props = {
    areAreasLoading,
    areas,
    handleSelectArea,
    handleSelectContinent,
    selectedCountry,
    selectedContinent,
  };

  return children(props);
};

