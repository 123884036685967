import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Season, Device, Size } from 'types';
import { PlacholderFlag, Popup } from 'components';
import { TournamentRedirectController } from 'views/shared/Tournament/TournamentRedirectController';

import { Badge } from '../../shared';

import './MatchListHeaderTournament.scss';


type MatchListHeaderTournamentProps = {
  deviceVariant: Device;
  season: Season;
};


export const MatchListHeaderTournament: FC<MatchListHeaderTournamentProps> = ({ season, deviceVariant }) => {
  return (
    <TournamentRedirectController deviceVariant={deviceVariant} season={season}>
      {({ name, areaBadgeUrl, competitionBadgeUrl, link }) => (
        <div className="match-list-header-tournament__content element-separator">
          <PlacholderFlag
            areaBadgeUrl={areaBadgeUrl}
            customStylesClasses="margin-right--size-s"
            customStyles={{ height: '16px', width: '24px' }}
          />
          <Link
            className="match-list-header-tournament__wrapper"
            to={link}
          >
            <Badge
              alt={`${name} badge`}
              size={Size.Tiny}
              customStyles={
                competitionBadgeUrl ?
                  'match-list-header-tournament__club-badge margin-right--size-s' :
                  'margin-right--size-s'
              }
              badgeURL={competitionBadgeUrl}

            />
            <Popup
              content={name}
              trigger={
                <div className="match-list-header-tournament__name">{name}</div>
              }
            />
          </Link>
        </div>
      )}
    </TournamentRedirectController>
  );
};
