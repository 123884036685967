import { useCallback, useEffect, useState } from 'react';

import { getCurrentMonthWeeks, getWeekOfSelectedDate, getClickableDayRange } from './calendarServices';


export const useGetWeeks = ({ currentDate }) => {
  const [weeks, setWeeks] = useState([]);

  const listOfClickableDays = getClickableDayRange();
  const [rangeStart] = listOfClickableDays;
  const {
    length,
    [length - 1]: rangeEnd,
  } = listOfClickableDays;

  const dateRanges = {
    rangeStart: new Date(rangeStart),
    rangeEnd: new Date(rangeEnd),
  };

  const memoWeeks = useCallback(() => {
    return getCurrentMonthWeeks(currentDate, setWeeks);
  }, [currentDate]);

  useEffect(() => {
    memoWeeks();
  }, [memoWeeks]);

  return {
    weeks,
    currentWeek: getWeekOfSelectedDate(),
    dateRanges,
    listOfClickableDays,
  };
};
