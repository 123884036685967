import { FC, ReactElement } from 'react';
import { MatchSort, MatchQueryFilters } from 'types';

import { useMatchesFilters } from '../useMatchesFilters';


const sortingLabels = [
  { label: 'Kick off (first)', value: MatchSort.KICK_OFF_ASC },
  { label: 'Kick off (last)', value: MatchSort.KICK_OFF_DESC },
  { label: 'Tournaments (first)', value: MatchSort.TOURNAMENT_ASC },
  { label: 'Tournaments (last)', value: MatchSort.TOURNAMENT_DESC },
  { label: 'Bet ratio (ascending)', value: MatchSort.BET_RATIO_ASC },
  { label: 'Bet ratio (descending)', value: MatchSort.BET_RATIO_DESC },
];

type Props = {
  filters: MatchQueryFilters;
  children(props: {
    availableLabelsInDropdown: { label: string; value: string }[];
    list: { label: string; value: string }[];
    currentLabel: string;
  }): ReactElement;

}

export const MatchListSortController: FC<Props> = ({ children }) => {
  const { filters } = useMatchesFilters();
  const currentLabel = sortingLabels.find(({ value }) => filters.order === value)?.label || sortingLabels[0].label;
  const availableLabelsInDropdown = sortingLabels.filter(({ label }) => label !== currentLabel);

  const props = {
    availableLabelsInDropdown,
    list: sortingLabels,
    currentLabel,
  };

  return children(props);
};
