import { FC, ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useConfig } from 'context/config-context';

import { addQuery, parseQuery } from '../../../../../utils';
import { OddsProvider } from '../../../../../types';


type Props = {
  children(props: {
    handleRedirect: () => void;
    isSelected: () => boolean;
  }): ReactElement;
};

export const MatchListFilterOddsenController: FC<Props> = ({ children }) => {
  const { search } = useLocation();
  const { push } = useHistory();
  const { configuration: {
    provider: { name: providerName } } } = useConfig();

  const OddsProviders = Object.values(OddsProvider) as string[];

  const parsedQuery = parseQuery(search);
  const { selected  = [] } = parsedQuery;
  const selectedCasted = selected as string[];
  const providers = selectedCasted.filter(item => OddsProviders.includes(item));

  const isSelected = () => {
    return providers.some(provider => OddsProviders.includes(provider));
  };

  const handleRedirect = () => {
    if (providerName) {
      if (!isSelected()) {
        return push({
          search: addQuery(search, {
            selected: [...selectedCasted, providerName],
          }),
        });
      }

      return push({
        search: addQuery(search, {
          selected: selectedCasted.filter(item => item !== providerName),
        }),
      });
    }
  };


  const props = {
    handleRedirect,
    isSelected,
  };

  return children(props);
};
