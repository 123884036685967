import { useCallback, useRef, useState } from 'react';
import { Maybe } from 'types';


type Props = {
  isSticky: boolean;
  handleScrollRef: (node: Maybe<Element>) => void;
}

export const useStickyTabletHeader = (): Props => {
  const [isSticky, setIsSticky] = useState(false);
  const observer = useRef<IntersectionObserver>();

  const handleScrollRef = useCallback((node: Maybe<Element>) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        return setIsSticky(false);
      }
      return setIsSticky(true);
    });
    if(node) observer.current.observe(node);
  }, []);


  return {
    isSticky,
    handleScrollRef,
  };
};
