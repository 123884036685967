import { useEffect, ReactElement, FC, memo } from 'react';
import { isEqual } from 'lodash';
import { useMatch } from 'context/match-context';
import { Match, GameTeam, Prediction } from 'types';

import { useSocketMatchesData } from '../shared/hooks/useSocketMatchesData';


type Props = {
  children(props: {
    selectedMatch: Match;
    selectHome?: GameTeam;
    selectAway?: GameTeam;
    hasAnyLineup: boolean;
    predictions: Prediction;
  }): ReactElement;
}

export const MatchDetailsController: FC<Props> = memo(({ children }) => {
  const {
    selectedMatch,
    selectHome,
    selectAway,
    setSelectedMatch,
    hasAnyLineup,
    matchPredictions,
  } = useMatch();

  const { syncedMatch } = useSocketMatchesData(selectedMatch);

  useEffect(() => {
    if(syncedMatch && !isEqual(syncedMatch, selectedMatch)) {
      setSelectedMatch(syncedMatch);
    }
  }, [syncedMatch, setSelectedMatch, selectedMatch]);

  if (!selectedMatch || !matchPredictions) {
    return null;
  }

  const props = {
    selectedMatch,
    selectHome,
    selectAway,
    hasAnyLineup,
    predictions: matchPredictions,
  };

  return children(props);
});

