import React from 'react';

import { LoadingElement } from '../../../components';
import { isObjectEmpty } from '../../../utils';

import { TournamentDetailsHistoricalSeasons } from './TournamentDetailsHistoricalSeasons';
import { TournamentDetailsHistoricalSeasonsController } from '../shared/TournamentDetailsHistoricalSeasonsController';


export const TournamentDetailsHistoricalSeasonsContainer = () => {
  return (
    <TournamentDetailsHistoricalSeasonsController>
      {props => {
        const { isLoading, selectedHistoricalSeason } = props;

        return (
          <>
            {isLoading && isObjectEmpty(selectedHistoricalSeason) && <LoadingElement />}
            {selectedHistoricalSeason && <TournamentDetailsHistoricalSeasons {...props} /> }
          </>
        );
      }}
    </TournamentDetailsHistoricalSeasonsController>
  );
};


