import React, { useMemo } from 'react';
import { getTimeFromDate, getMinutesForOngoingMatch, isClockTime } from 'utils';
import { MatchHalfName, MatchStatus, MatchStatusName, Event } from 'types';

import './MatchTime.scss';
import { useMatchTimeMinuteCounter } from './useMatchTimeMinuteCounter';


const ongoingMatch = ({ clockTime, startDate }: Pick<Event, 'startDate' | 'clockTime'>) => {
  return isClockTime(clockTime) ? `${getMinutesForOngoingMatch(clockTime)}'` : getTimeFromDate(startDate);
};

const secondHalfMatch = ({ clockTime, startDate }: Pick<Event, 'startDate' | 'clockTime'>) => {
  return isClockTime(clockTime) ? `${getMinutesForOngoingMatch(clockTime) + 45}'` : getTimeFromDate(startDate);
};

const matchWithDate = ({ startDate }: Pick<Event, 'startDate'>) => {
  return getTimeFromDate(startDate);
};

const halftime = () => MatchHalfName.HalfTime.toLowerCase();

const matchTimeView = new Map();
matchTimeView
  .set(MatchHalfName.SecondHalf, secondHalfMatch)
  .set(MatchHalfName.RegularTime, ongoingMatch)
  .set(MatchHalfName.FirstHalf, ongoingMatch)
  .set(MatchHalfName.HalfTime, halftime)
  .set(MatchStatus.Cancelled, matchWithDate)
  .set(MatchStatus.Scheduled, matchWithDate)
  .set(MatchStatus.Finished, matchWithDate)
  .set(MatchStatus.Interrupted, matchWithDate);

export const MatchTime = ({ startDate, clockTime = 0, status }: Pick<Event, 'startDate' | 'clockTime'> & { status: MatchStatus | MatchStatusName }) => {
  const matchTimeVariant = useMemo(() => {
    return matchTimeView.get(status) || matchWithDate;
  }, [status]);

  const { seconds } = useMatchTimeMinuteCounter({ initialSeconds: clockTime });

  const time = matchTimeVariant({ startDate, clockTime: seconds });

  return (
    <div className="match-time__details">
      <div className="match-time__time">
        {time}
      </div>
    </div>
  );
};
