import React, { FC } from 'react';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Basket, BasketItem } from 'types';

import { BasketItemSystem } from '../../BasketItem';
import { GroupedList } from '../../BasketItemList';

import { SystemModeTabController } from './SystemModeTabController';


type Props = {
  control: Control<Basket>;
  register: UseFormRegister<Basket>;
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  onItemRemove: (item: BasketItem, index: number) => void;
}

export const SystemModeTab: FC<Props> = ({
  control,
  register,
  setValue,
  fields,
  onItemRemove,
}) => {
  return (
    <SystemModeTabController
      setValue={setValue}
      fields={fields}
    >
      {({
        onItemGroupUpdate,
      }) => (
        <GroupedList
          list={fields.items}
          control={control}
          register={register}
          component={BasketItemSystem}
          onChange={onItemGroupUpdate}
          onRemove={onItemRemove}
        />
      )}
    </SystemModeTabController>
  );
};
