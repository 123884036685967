import { Event } from './Match';


export enum OddsLineTypes {
  RawJson = '[raw_json]',
  YesNo = '[yes/no]',
  OverUnder = '[over/under]',
  OddEven = '[odd/even]',
  OnlyHomeOnlyAwayBothNone = '[only_home/only_away/both/none]',
  FirstHalfSecondHalfEquals = '[first_half/second_half/equals]',
  HomeAwayDraw = '[h/a/d]',
  HomeAway = '[h/a]',
  HomeAwayDrawHomeAwayDraw = '[h/a/d]__[h/a/d]',
  HomeAwayDrawAndYesNo = '[h/a/d]_and_[yes/no]',
  HomeAwayDrawAndUnderOver = '[h/a/d]_and_[under/over]',
  ScoreXxXx = '[score__x_x__x_x]',
  ScoreXxOther = '[score__x_x/other]',
  HomeAwayDrawOrHomeAwayDraw = '[h/a/d]_or_[h/a/d]',
}

export enum OddsType {
  Unknown = 'unknown',
  HomeToScoreInBothHalves = 'home_to_score_in_both_halves',
  AwayToScoreInBothHalves = 'away_to_score_in_both_halves',
  TotalX = 'total_x',
  HomeTotalX = 'home_total_x',
  AwayTotalX = 'away_total_x',
  BothTeamsToScore = 'both_teams_to_score',
  OutcomeAndBothTeamsToScore = 'outcome_and_both_teams_to_score',
  HomeWinToNil = 'home_win_to_nil',
  AwayWinToNil = 'away_win_to_nil',
  OddEven = 'odd_even',
  Outcome = 'outcome',
  OutcomeAndTotalX = 'outcome_and_total_x',
  WhichTeamToScore = 'which_team_to_score',
  DrawNoBet = 'draw_no_bet',
  HighestScoringHalf = 'highest_scoring_half',
  HalftimeFulltime = 'halftime_fulltime',
  HalftimeFulltimeCorrectScore = 'halftime_fulltime_correct_score',
  DoubleChance = 'double_chance',
  Handicap3WX = 'handicap_3w_x',
  HomeToWinBothHalves = 'home_to_win_both_halves',
  AwayToWinBothHalves = 'away_to_win_both_halves',
  HomeToWinEitherHalf = 'home_to_win_either_half',
  AwayToWinEitherHalf = 'away_to_win_either_half',
  CorrectScore = 'correct_score',
  BothHalvesUnderX = 'both_halves_under_x',
  BothHalvesOverX = 'both_halves_over_x',
  HomeCleanSheet = 'home_clean_sheet',
  AwayCleanSheet = 'away_clean_sheet',
}

export enum OddsTypePeriod {
  Unknown = 'unknown',
  FirstHalf = 'first_half',
  SecondHalf = 'second_half',
  Fulltime = 'fulltime',
  Minutes = 'minutes',
}

export enum OddsProvider {
  NorskTipping = 'norsk_tipping',
  SvenskaSpel = 'svenskaspel',
}

export type OddOdd = {
  id: string;
  name: string;
  externalName?: string;
  odds: number;
  value: string;
};

export type ParentEvent = Pick<Event,
    'id' | 'name' | 'result' | 'neutralVenue' |
    'day' | 'startDate' | 'relationStatus' |
    'statusName' | 'createdBy' | 'name' |
  'scheduledStartedAt' | 'verifiedResult' | 'status'>;

export type OddGroupingType = {
  event: ParentEvent;
  odds: Odd[];
};

export type OddListGroupingType = {
  event: ParentEvent;
  odds?: Odd;
  oddListLength: number;
}

export type Odd = {
  id: string;
  odds: Record<string, OddOdd>;
  linesType: OddsLineTypes;
  type: OddsType;
  typePeriod: OddsTypePeriod;
  externalSource: OddsProvider;
  externalType: string;
  typeValue: string;
};

export enum OddsOverUnder {
  Under = 'under',
  Over ='over',
}
