import React, { FC } from 'react';
import { BasketCheckoutItem } from 'types';
import { Icon } from 'semantic-ui-react';
import { useBasket } from 'context/basket/basket-context';
import { useTranslate } from 'context/translate-context';

import { BetLinksList } from './components/BetLinksList';

import './GeneratedBets.scss';


type Props = {
  basket: BasketCheckoutItem[];
}

export const GeneratedBets: FC<Props> = ({ basket }) => {
  const { translate } = useTranslate();
  const { clearGeneratedBets, generatedBets } = useBasket();

  return (
    <div className="generated-bets generated-bets__wrapper">
      <button
        className="generated-bets generated-bets__back"
        onClick={clearGeneratedBets}
      >
        <Icon name="chevron left" />
        {translate('SYSTEM_OF')}
        <> {generatedBets.length} </>
        {translate('COMBO_BETS')}
      </button>
      <BetLinksList combinations={basket} />
    </div>
  );
};


