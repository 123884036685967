import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { AreaSort, FavouriteInput } from 'types';

import api from '../services/FetchService';

import { GetFavouriteList, GetFavourite } from './types';
import * as query from './queries';


export class FavouriteClient {
  constructor() {
    this.getFavourites = this.getFavourites.bind(this);
    this.createFavourite = this.createFavourite.bind(this);
    this.removeFavourite = this.removeFavourite.bind(this);
  }

  getFavourites(order: AreaSort): AxiosPromise<GetFavouriteList> {
    const options: AxiosRequestConfig = {
      ...query.getFavourites,
      params: {
        order,
      },
    };

    return api.request(options);
  }

  createFavourite(input: FavouriteInput): AxiosPromise<GetFavourite> {
    const options: AxiosRequestConfig = {
      ...query.createFavourite,
      data: {
        ...input,
      },
    };

    return api.request(options);
  }

  removeFavourite({ tournamentId, seasonId }: { tournamentId: string; seasonId: string }): AxiosPromise<void> {
    const options: AxiosRequestConfig = {
      ...query.deleteFavourite,
      url: `${query.deleteFavourite.url}/${tournamentId}/${seasonId}`,
    };

    return api.request(options);
  }
}
