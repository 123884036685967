import React, { FC } from 'react';
import { FavouriteController } from 'views/Dashboard/shared/FavouriteController';

import { TournamentCheckbox } from '../Checkbox';
import { TournamentController } from '../TournamentController';
import { TournamentFavouriteStar } from '../TournamentFavouriteStar';

import { Props } from './Props';

import './Tournament.scss';


export const TournamentVariantWithCheckbox: FC<Props> = ({ tournamentId, tournamentName, DisplayedContent: TournamentName, seasonId, areaCode, areaSort }) => {
  return (
    <TournamentController>
      {({ isIdSelected, handleSelect }) => {
        const isSelected = isIdSelected(seasonId);

        return (
          <li
            key={`${tournamentName} ${tournamentId}`}
            className={`tournament
              ${isSelected ? 'tournament--selected' : ''}`
            }
          >
            <div className="tournament__wrapper tournament__wrapper--3-el-grid">
              <div className="margin-right--size-s">
                <FavouriteController
                  tournamentId={tournamentId}
                  tournamentName={tournamentName}
                  areaSort={areaSort}
                  seasonId={seasonId}
                  areaCode={areaCode}
                >
                  {props => <TournamentFavouriteStar {...props} />}
                </FavouriteController>
              </div>
              {TournamentName}
              <TournamentCheckbox
                isSelected={isSelected}
                ariaLabel={`Tournament ${tournamentName} checkbox ${isSelected ? 'is selected' : ''}`}
                handleClick={() => handleSelect(seasonId)}
              />
            </div>
          </li>
        );
      }}
    </TournamentController>
  );
};
