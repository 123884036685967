import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { ButtonPrimary, FieldTextInput } from '../../../components';
import { ButtonSecondary } from '../../../components/Button/Secondary/ButtonSecondary';
import { FieldTextInputDate } from '../../../components/Field/TextInput/FieldTextInputDate';
import { useTranslate } from '../../../context/translate-context';

import { UserAccountFormGenderSection } from './UserAccountFormGenderSection';

import './UserAccountForm.scss';


export const UserAccountForm = ({
  fields,
  handleSubmit,
  handleChangeField,
  handleChangeDateField,
  errors,
  isPending,
}) => {
  const { translate } = useTranslate();
  const { logout } = useAuth0();

  return (
    <form
      onSubmit={handleSubmit}
      className="user-account-form__container"
    >
      <div className="user-account-form__inputs-container">
        <FieldTextInput
          labelText={translate('FIRST_NAME')}
          ariaLabel={translate('FIRST_NAME')}
          onChange={handleChangeField}
          name="given_name"
          value={fields.given_name}
          errors={errors}
        />
        <FieldTextInput
          labelText={translate('LAST_NAME')}
          ariaLabel={translate('LAST_NAME')}
          onChange={handleChangeField}
          name="family_name"
          value={fields.family_name}
          errors={errors}
        />
        <FieldTextInput
          labelText={translate('EMAIL_ADDRESS')}
          ariaLabel={translate('EMAIL_ADDRESS')}
          onChange={handleChangeField}
          name="email"
          value={fields.email}
          errors={errors}
        />
        <FieldTextInputDate
          labelText={translate('BIRTH_DATE')}
          ariaLabel={translate('BIRTH_DATE')}
          onChange={handleChangeDateField}
          name="birth_date"
          value={fields.birth_date}
          errors={errors}
        />
      </div>
      <UserAccountFormGenderSection
        onChange={handleChangeField}
        value={fields.gender}
        label={translate('GENDER')}
        fieldName="gender"
      />
      <div className="user-account-form__buttons-container">
        <ButtonSecondary
          type="button"
          callback={() => logout({ returnTo: window.location.origin })}
          text={translate('LOGOUT')}
        />
        <ButtonPrimary
          customStyles="full-width"
          isPending={isPending}
          text={translate('SAVE_CHANGES')}
        />
      </div>
    </form>
  );
};
