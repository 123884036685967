import React, { FC } from 'react';
import { BasketErrorMessageLabel } from 'types';
import { ReactComponent as ExclamationMarkInCircle } from 'assets/icons/exclamation-mark-in-circle.svg';

import './BasketModeFooterErrorMessage.scss';


type Props = {
  message: BasketErrorMessageLabel;
};

export const BasketModeFooterErrorMessage: FC<Props> = ({ message }) => {
  return (
    <div className="basket-mode-footer-error-message__container">
      <div className="basket-mode-footer-error-message__grid">
        <div className="basket-mode-footer-error-message__icon">
          <ExclamationMarkInCircle />
        </div>
        <div className="basket-mode-footer-error-message__text">
          {message}
        </div>
      </div>
    </div>
  );
};
