import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslate } from 'context/translate-context';

import { FavouriteListController } from '../../../shared/FavouriteListController';

import './MatchListFilter.scss';


export const MatchListFilterFavourite = () => {
  const { translate } = useTranslate();

  return (
    <FavouriteListController>
      {({ areFavouritesSelected, handleRedirect }) => {
        return (
          <button
            aria-label={translate('FAVOURITES')}
            className={`match-list-filter__button-icon match-list-filter${areFavouritesSelected ? '' : '--inactive'}`}
            onClick={handleRedirect}
          >
            <Icon
              name="star"
              className={`favourite margin--unset ${areFavouritesSelected ? 'favourite--selected' : ''}`}
            />
          </button>
        );
      }}
    </FavouriteListController>
  );
};
