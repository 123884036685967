import { MobileNavigationLinkHome } from './MobileNavigationLinkHome';
import { MobileNavigationLinkMatchListLive } from './MobileNavigationLinkMatchListLive';
import { MobileNavigationLinkTournamentList } from './MobileNavigationLinkTournamentList';


export const defaultNavigationList = [
  {
    Component: MobileNavigationLinkHome,
  },
  {
    Component: MobileNavigationLinkTournamentList,
  },
  {
    Component: MobileNavigationLinkMatchListLive,
  },
];
