import { useBasket } from 'context/basket/basket-context';
import { countBy } from 'lodash';
import { useMemo } from 'react';


export const useBetPerEvent = () => {
  const { items: basketItems } = useBasket();
  const betPerEvent = useMemo(() => countBy(basketItems, 'eventId'), [basketItems]);

  return {
    betPerEvent,
  };
};
