/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { EmptyElementFeedback, PlacholderFlag } from 'components';

import { TournamentFavouriteListSection } from '../../Tournament/Favourite';
import { TournamentList } from '../../Tournament/shared/TournamentList';
import { TournamentAreaFavouriteListController } from '../../Tournament/Favourite/TournamentAreaFavouriteListController';
import Tournament from '../../Tournament/shared/Tournament';
import TournamentName from '../../Tournament/shared/TournamentName';
import ErrorBoundaryWithRollbar from '../../../ErrorBoundaryWithRollbar';


export const SidebarAreaTournamentListFavouriteList = () => {
  return (
    <ErrorBoundaryWithRollbar
      FallbackComponent={
        <EmptyElementFeedback text="COULD_NOT_LOAD_CONTENT" />
      }
    >
      <TournamentAreaFavouriteListController>
        {({ areaList }) => {
          return (
            <TournamentFavouriteListSection>
              {({ favourites }) => (
                <TournamentList>
                  <>
                    {favourites.map(props => {
                      const { tournamentId, tournamentName, seasonId, areaCode, areaSort } = props;
                      const area = areaList.find(({ code }) => code === areaCode);

                      return (
                        <Tournament.DefaultWithCheckbox
                          key={`${tournamentId} ${tournamentName}`}
                          tournamentId={tournamentId}
                          areaSort={areaSort}
                          DisplayedContent={
                            <TournamentName.Favourite
                              displayedName={tournamentName}
                              Flag={
                                <PlacholderFlag
                                  label={area?.name}
                                  areaBadgeUrl={area?.badgeUrl}
                                />
                              }
                            />
                          }
                          tournamentName={tournamentName}
                          seasonId={seasonId}
                          areaCode={areaCode}
                        />
                      );
                    })}
                  </>
                </TournamentList>
              )}
            </TournamentFavouriteListSection>
          );
        }}
      </TournamentAreaFavouriteListController>
    </ErrorBoundaryWithRollbar>
  );
};
