import React, { useMemo } from 'react';
import { isBefore, parseISO } from 'date-fns';
import { MatchStatus, Event } from 'types';

import { useCompareScores } from './useCompareScores';


type Props = {
  className?: string;
  homeFirstHalfScore: string;
  awayFirstHalfScore: string;
  homeRegularScore: string;
  awayRegularScore: string;
} & Pick<Event, 'startDate' | 'status'>

export const useScore = ({
  className,
  homeFirstHalfScore,
  awayFirstHalfScore,
  homeRegularScore,
  awayRegularScore,
  startDate,
  status,
}: Props) => {
  const results = useMemo(() => {
    return {
      homeFirstHalfScore,
      awayFirstHalfScore,
      homeRegularScore,
      awayRegularScore,
    };
  }, [
    homeFirstHalfScore,
    awayFirstHalfScore,
    homeRegularScore,
    awayRegularScore,
  ]);

  const {
    isScoreAtFH: isHomeScoredAtFH,
    isScoreAtRT: isHomeScoredRT,
  } = useCompareScores({
    firstHalfScore: results.homeFirstHalfScore,
    regularTimeScore: results.homeRegularScore,
  });
  const {
    isScoreAtFH: isAwayScoredAtFH,
    isScoreAtRT: isAwayScoredRT,
  } = useCompareScores({
    firstHalfScore: results.awayFirstHalfScore,
    regularTimeScore: results.awayRegularScore,
  });

  const isFinished = MatchStatus.Finished === status ? `${className}__state--finished` : '';
  const isLive = MatchStatus.Live === status ? `${className}__state--live` : '';

  const handleRecentGoalClass = (isRecent: boolean) => isRecent ? `${className}__result--recent-score` : '';
  const score = (
    <div className={`${className}__score`}>
      <div
        className={`${className}__grid ${className}__result--size ${isFinished} ${isLive}`}
      >
        <span className={handleRecentGoalClass(isHomeScoredRT)}>
          {homeRegularScore}
        </span>
        <div>
          :
        </div>
        <span className={handleRecentGoalClass(isAwayScoredRT)}>
          {awayRegularScore}
        </span>
      </div>
      <div
        className={`${className}__grid ${className}__result--first-half-size`}
      >
        <div className={`${className}--flex-row`}>
          (
          <div className={handleRecentGoalClass(isHomeScoredAtFH)}>
            {homeFirstHalfScore}
          </div>
        </div>
        <div>
          :
        </div>
        <div className={`${className}--flex-row`}>
          <div className={handleRecentGoalClass(isAwayScoredAtFH)}>
            {awayFirstHalfScore}
          </div>
          )
        </div>
      </div>
    </div>
  );

  const now = Date.now();
  const matchTime = parseISO(String(startDate));
  const handleResultClassDisplay = isBefore(now, matchTime) ?
    `${className}__result--incoming` :
    '';

  return {
    score,
    handleResultClassDisplay,
  };
};
