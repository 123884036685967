import React, { FC } from 'react';
import { EmptyListFeedback } from 'components';
import { Loader } from 'semantic-ui-react';
import { MatchListMissingSelectedTournamentListController } from 'views/shared/MatchList/MatchListMissingSelectedTournamentListController';

import { MatchListMissingSelectedTournamentList } from './MatchListMissingSelectedTournamentList';

import './MatchListMissingSelectedTournamentListSection.scss';


type Props = {
  matchListLength: number;
  areMatchesLoading: boolean;
}

export const MatchListMissingSelectedTournamentListSection: FC<Props> = ({ matchListLength, areMatchesLoading }) => {
  return (
    <MatchListMissingSelectedTournamentListController>
      {({ seasonList, order, isLoading }) => {
        if (!areMatchesLoading && isLoading) {
          return (
            <div className="match-list-missing-selected-tournament-list-section__container">
              <Loader active inline="centered" />
            </div>
          );
        }

        if (!matchListLength && !seasonList.length && !areMatchesLoading && !isLoading) {
          return (
            <EmptyListFeedback />
          );
        }

        return (
          <>
            {!areMatchesLoading && seasonList.length > 0 && (
              <MatchListMissingSelectedTournamentList
                order={order}
                seasonList={seasonList}
              />
            )}
          </>
        );
      }}
    </MatchListMissingSelectedTournamentListController>
  );
};
