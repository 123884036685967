import { OddsProvider } from 'types';

import { Handlers } from '../Handlers';

import { updateMatchDetailsSideBetsHandlersByNorskTipping } from './norskTipping';
import { updateMatchDetailsSideBetsHandlersBySvenskaSpel } from './svenskaSpel';


export const updateMatchDetailsSideBetsByProviderMap = new Map<OddsProvider, Handlers>();
updateMatchDetailsSideBetsByProviderMap.set(OddsProvider.NorskTipping, updateMatchDetailsSideBetsHandlersByNorskTipping);
updateMatchDetailsSideBetsByProviderMap.set(OddsProvider.SvenskaSpel, updateMatchDetailsSideBetsHandlersBySvenskaSpel);
