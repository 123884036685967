const calendar = {
  Mon: 'Man',
  Tue: 'Tir',
  Wed: 'Ons',
  Thu: 'Tor',
  Fri: 'Fre',
  Sat: 'Lør',
  Sun: 'Søn',
  Monday: 'Mandag',
  Tuesday: 'Tirsdag',
  Wednesday: 'Onsdag',
  Thursday: 'Torsdag',
  Friday: 'Fredag',
  Saturday: 'Lørdag',
  Sunday: 'Søndag',
  PREV_DAY: 'Forrige dag',
  NEXT_DAY: 'Neste dag',
  PREV_MONTH: 'Forrige måned',
  NEXT_MONTH: 'Neste måned',
  CALENDAR: 'Kalender',
};

export default calendar;
