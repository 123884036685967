import React from 'react';
import { MatchDetailsMatchStatsListController } from 'views/Dashboard/MatchDetails/MatchStats/MatchDetailsMatchStatsListController';
import { Loader } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { EmptyListFeedback } from 'components';
import { MatchDetailsMatchStatsList } from 'views/Dashboard/MatchDetails/MatchStats/MatchDetailsMatchStatsList';


export const MobileMatchDetailsStatisticMatchStats = () => {
  return (
    <MatchDetailsMatchStatsListController>
      {props => {
        const { areStatsLoading, statistics } = props;
        const { commonStatistics = [] } = statistics || {};

        return (
          <>
            {areStatsLoading && <Loader active inline />}
            {!areStatsLoading && isEmpty(statistics) && <EmptyListFeedback />}
            {!areStatsLoading && commonStatistics.length > 0 && <MatchDetailsMatchStatsList {...props} />}
          </>
        );
      }}
    </MatchDetailsMatchStatsListController>
  );
};
