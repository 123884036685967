import React, { ReactElement, FC } from 'react';
import { EmptyListFeedback } from 'components';
import ErrorBoundaryWithRollbar from 'views/ErrorBoundaryWithRollbar';
import { MatchDetailsSideBetsLoadingWrapper } from 'views/Dashboard/MatchDetails/SideBets/MatchDetailsSideBetsLoadingWrapper';


type Props = {
  children: ReactElement;
}

export const MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper: FC<Props> = ({ children }) => {
  return (
    <ErrorBoundaryWithRollbar
      FallbackComponent={
        <EmptyListFeedback text="COULD_NOT_LOAD_CONTENT" />
      }
    >
      <MatchDetailsSideBetsLoadingWrapper>
        {children}
      </MatchDetailsSideBetsLoadingWrapper>
    </ErrorBoundaryWithRollbar>
  );
};
