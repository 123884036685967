import React, { FC } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useMediaQuery } from 'hooks/useMediaQuery';
import ErrorBoundaryWithRollbar from 'views/ErrorBoundaryWithRollbar';
import { EmptyElementFeedback } from 'components';
import { Size } from 'types';

import Live from '../Match';
import { mediaQuery } from '../../../constants/mediaQuery';

import { LiveTickerProgressBarController } from './LiveTickerProgressBarController';
import { LiveTickerOverlay } from './Overlay';
import { LivetTickerFeedbackPlaceholder } from './Feedback/LivetTickerFeedbackPlaceholder';
import { LiveTickerProgressBar } from './ProgressBar';
import { LiveTickerController } from './LiveTickerController';
import { LiveTickerOverlayQueueInfo } from './Overlay/LiveTickerOverlayQueueInfo';

import './LiveTicker.scss';


type Props = {
  canSeeOptions: boolean;
  canSeeRemainingIncidentsInQueue: boolean;
}

export const LiveTicker: FC<Props> = ({
  canSeeOptions = false,
  canSeeRemainingIncidentsInQueue = false,
}) => {
  const isDesktop = useMediaQuery(mediaQuery.minDesktopSmall);

  if (!isDesktop) {
    return null;
  }

  return (
    <ErrorBoundaryWithRollbar
      FallbackComponent={
        <section
          className="live-ticker"
        >
          <EmptyElementFeedback
            canSeeTextOnly
            size={Size.Big}
            text="COULD_NOT_LOAD_CONTENT"
          />
        </section>
      }
    >
      <LiveTickerController>
        {({
          incidentsRemaining,
          latestLiveMatch,
          dequeue,
          result,
          pauseTicker,
          resumeTicker,
        }) => {
          return (
            <section
              className="live-ticker"
            >
              <div className="live-ticker__container"
                onMouseOver={pauseTicker}
                onMouseOut={resumeTicker}
              >
                {canSeeOptions && <LiveTickerOverlay incidentsRemaining={incidentsRemaining} />}
                {canSeeRemainingIncidentsInQueue && <LiveTickerOverlayQueueInfo incidentsRemaining={incidentsRemaining} />}
                <TransitionGroup
                  component="div"
                >
                  <CSSTransition
                    key={latestLiveMatch?.id || 'placeholder'}
                    timeout={300}
                    classNames="live-ticker"
                  >
                    {latestLiveMatch ? (
                      <>
                        <Live.Match
                          incident={latestLiveMatch}
                          MatchResult={
                            <Live.MatchResult
                              teamOrigin={latestLiveMatch.teamOrigin}
                              incidentCode={latestLiveMatch.code}
                              confirmation={latestLiveMatch?.confirmation}
                              result={result}
                            />
                          }
                          DequeueIncident={
                            <Live.DequeueIncident dequeue={dequeue} />
                          }
                        />
                        <LiveTickerProgressBarController
                          dequeue={dequeue}
                          incidentsRemaining={incidentsRemaining}
                        >
                          {({
                            secondsRemaining,
                            liveTickerDisplayLimit,
                          }) => {
                            return (
                              <LiveTickerProgressBar
                                progress={secondsRemaining}
                                liveTickerDisplayLimit={liveTickerDisplayLimit}
                              />
                            );
                          }}
                        </LiveTickerProgressBarController>
                      </>
                    ) : <LivetTickerFeedbackPlaceholder text="NO_LIVE_MATCHES_AVAILABLE" />}
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </section>
          );
        }}
      </LiveTickerController>
    </ErrorBoundaryWithRollbar>
  );
};
