import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

import api from '../services/FetchService';

import * as query from './queries';
import { GetSeasons, GetSeason, GetStatisticList } from './types';


export class SeasonClient {
  constructor() {
    this.getSeasonList = this.getSeasonList.bind(this);
    this.getSeasonDetails = this.getSeasonDetails.bind(this);
    this.getSeasonStatistics = this.getSeasonStatistics.bind(this);
  }

  getSeasonList({ tournamentId, seasonIdList }: { tournamentId?: string; seasonIdList?: string[] }): AxiosPromise<GetSeasons> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.getSeasons,
      params: { tournamentId, seasonIdList: seasonIdList?.join(',') },
      cancelToken,
    };

    return api.request(options);
  }

  getSeasonDetails({ seasonId }: { seasonId: string }): AxiosPromise<GetSeason> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.getSeasons,
      url: `${query.getSeasons.url}/${seasonId}/details`,
      cancelToken,
    };

    return api.request(options);
  }

  getSeasonStatistics({ seasonId }: { seasonId: string }): AxiosPromise<GetStatisticList> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.getSeasonStatistics,
      url: `${query.getSeasonStatistics.url}/${seasonId}`,
      cancelToken,
    };

    return api.request(options);
  }
}
