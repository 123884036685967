import React, { FC, useContext, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { isEmpty, omitBy, noop } from 'lodash';
import { formatDate, getToday, getYesterday } from 'utils';
import { Noop, Match, MatchStatus, Prediction, MatchDisplayPrediction, MatchFilters } from 'types';
import MatchClient from 'api/MatchClient';

import { GetMatchListResponse } from '../api/MatchClient/types';
import { useMatchesFilters } from '../views/Dashboard/MatchList/useMatchesFilters';


type Props = {
  displayPrediction: MatchDisplayPrediction;
  fetchNextPage: Noop;
  fetchPreviousPage: Noop;
  filters: Record<string, any>;
  hasNextPage: boolean;
  isFetching: boolean;
  isLoading: boolean;
  matches: Match[];
  setDisplayPrediction: (prediction: MatchDisplayPrediction) => void;
  setMatches: (matches: Match[]) => void;
  matchesPredictions: Prediction[];
  arePredictionsLoading: boolean;
}

const MatchListContext = React.createContext<Props>({
  displayPrediction: MatchDisplayPrediction.PERCENT,
  fetchNextPage: noop,
  fetchPreviousPage: noop,
  filters: {},
  hasNextPage: true,
  arePredictionsLoading: false,
  isFetching: false,
  isLoading: false,
  matches: [],
  matchesPredictions: [],
  setDisplayPrediction: noop,
  setMatches: noop,
});

const MatchListProvider: FC = props => {
  const [displayPrediction, setDisplayPrediction] = useState<MatchDisplayPrediction>(MatchDisplayPrediction.PERCENT);
  const [matches, setMatches] = useState<Match[]>([]);

  const { filters: params } = useMatchesFilters();
  // Get params from search query
  const today = getToday();
  const yesterday = getYesterday();

  const handleStatusList = () => {
    if (![yesterday, today].includes(formatDate(params.from))) {
      return [
        MatchStatus.Scheduled,
        MatchStatus.Interrupted,
        MatchStatus.Finished,
      ];
    }
    return [
      MatchStatus.Scheduled,
      MatchStatus.Live,
      MatchStatus.Interrupted,
      MatchStatus.Finished,
    ];
  };

  const filters = {
    status: handleStatusList(),
    ...omitBy(params, isEmpty),
  } as MatchFilters;


  const {
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    data,
  } = useInfiniteQuery(['matchesList', filters], ({ pageParam }) => MatchClient.postSeasonMatches({ params: filters, meta: pageParam }), {
    getNextPageParam: (lastPage: AxiosResponse<GetMatchListResponse>) => {
      const offset = lastPage.data.meta.offset + lastPage.data.meta.limit;

      return lastPage.data.meta.total &&
        (offset < lastPage.data.meta.total) ? {
          ...lastPage.data.meta,
          offset,
        } : undefined;
    },
    getPreviousPageParam: (firstPage: AxiosResponse<GetMatchListResponse>) => {
      const offset = firstPage.data.meta.offset - firstPage.data.meta.limit;
      return offset > 0 ? {
        ...firstPage.data.meta,
        offset,
      } : undefined;
    },
  });

  useMemo(() => {
    const matches = data?.pages?.flatMap(p => p.data.events) || [];

    setMatches(matches);
  }, [data]);

  const eventsIds = matches.map(({ event }) => event.id);

  const {
    isLoading: arePredictionsLoading,
    data: matchPredictionsData,
  } = useQuery(['matchListPredictions', eventsIds], () => MatchClient.postPredictions({ eventsIds }),
    { enabled: Boolean(eventsIds.length) },
  );

  const matchesPredictions: Prediction[] = useMemo(() => {
    return matchPredictionsData?.data?.predictions || [];
  }, [matchPredictionsData]);

  const values = {
    matchesPredictions,
    arePredictionsLoading,
    displayPrediction,
    fetchNextPage,
    fetchPreviousPage,
    filters,
    hasNextPage: !!hasNextPage,
    isFetching: isFetchingNextPage,
    isLoading,
    matches,
    setDisplayPrediction,
    setMatches,
  };

  return (
    <MatchListContext.Provider
      value={values}
      {...props}
    />
  );
};

const useMatchList = () => {
  const context = useContext(MatchListContext);

  if(context === undefined) {
    throw new Error('useMatchList must be used within a MatchListProvider');
  }

  return context;
};


export {
  MatchListProvider,
  useMatchList,
};
