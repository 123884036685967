import { isAfter, isBefore } from 'date-fns';
import { Maybe, Match } from 'types';
import { addQuery, formatDate } from 'utils';
import { DASHBOARD_ROUTING } from 'views/Dashboard';


type RangesProps = {
  ranges: {
    rangeStart: Date;
    rangeEnd: Date;
  };
  date: Date;
}


type Props = {
  pathname: string;
  search: string;
  date: Date;
  match: Maybe<Match>;
};


export const handleSelectedDateWhenCurrentDateExceeds = ({ ranges, date }: RangesProps) => {
  const { rangeStart, rangeEnd } = ranges;

  if (isAfter(date, rangeEnd)) {
    return rangeEnd;
  }

  if (isBefore(date, rangeStart)) {
    return rangeStart;
  }

  return date;
};

export const handleCreatePathsForCalendar = ({ pathname, search, date, match }: Props) => {
  if (!match) {
    return {
      pathname: pathname,
      search: addQuery(search, {
        from: formatDate(date),
        to: formatDate(date),
        status: [],
      }),
    };
  }

  return {
    pathname: DASHBOARD_ROUTING.getDefaultDashboardURL(),
    search: addQuery(search, {
      from: formatDate(date),
      to: formatDate(date),
      season: [],
      status: [],
    }),
  };
};
