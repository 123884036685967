import React, { FC, ReactElement } from 'react';
import { Match, MatchStatusName, Size } from 'types';
import { useScore } from 'views/Dashboard/shared/hooks/useScore';

import { Badge } from '../../shared';
import { handleMatchResult } from '../../../shared/Match/matchServices';

import './MatchDetailsResultTeams.scss';


type Props = {
  match: Match;
  children?: ReactElement;
};

export const MatchDetailsResultTeams: FC<Props> = ({ match, children }) => {
  const { home, away, event: { startDate, status, statusName } } = match;
  const { badgeUrl: homeBadgeURL, shortName: homeName } = home?.teamDetails || {};
  const { badgeUrl: awayBadgeURL, shortName: awayName } = away?.teamDetails || {};

  const {
    homeResult: homeFirstHalfScore,
    awayResult: awayFirstHalfScore,
  } = handleMatchResult(MatchStatusName.Halftime)({ home, away });

  const secondHalfStatusName = MatchStatusName['1stHalf'] !== statusName ?
    statusName : MatchStatusName['2ndHalf'];

  const {
    homeResult: homeRegularScore,
    awayResult: awayRegularScore,
  } = handleMatchResult(secondHalfStatusName)({ home, away });

  const { score } = useScore({
    className: 'match-details-result-teams',
    homeFirstHalfScore,
    awayFirstHalfScore,
    startDate,
    homeRegularScore,
    awayRegularScore,
    status,
  });

  return (
    <section className="match-details-result-teams__container">
      <div className="match-details-result-teams__result-container">
        <div className="match-details-result-teams__badge-container match-details-result-teams--flex-end">
          <div className="match-details-result-teams__team-name margin-right--size-s">
            {homeName}
          </div>
          <Badge
            badgeURL={homeBadgeURL}
            size={Size.Big}
            alt={`${homeName} badge`}
            customStyles="match-details-result-teams__badge"
          />
        </div>
        <div className="match-details-result-teams__score">
          {score}
        </div>
        <div className="match-details-result-teams__badge-container match-details-result-teams--flex-start">
          <Badge
            badgeURL={awayBadgeURL}
            size={Size.Big}
            alt={`${awayName} badge`}
            customStyles="match-details-result-teams__badge"
          />
          <div className="match-details-result-teams__team-name margin-left--size-s">
            {awayName}
          </div>
        </div>
      </div>
      {children}
    </section>
  );
};

