import React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { RouteParam } from 'views/Tournament/Routing/RouteParam';

import { TOURNAMENT_DETAILS } from '../../Routing/Routing';
import { getTournamentDetailsRouting } from '../../Routing/tournamentDetailsTabLinkListService';
import { MediaQueriesRedirectController } from '../../../shared/MediaQueriesRedirectController';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { mediaQuery } from '../../../../constants/mediaQuery';

import { getDesktopTournamentDetailsRouteList } from './routes';


export const TournamentTabRouting = () => {
  const params = useParams<RouteParam>();
  const isMobile = useMediaQuery(mediaQuery.mobile);

  return (
    <Switch>
      {getDesktopTournamentDetailsRouteList(params).map(({ path, redirectURL, Component }, index) => {
        return (
          <Route exact key={`tournament details ${index}`} path={path} render={() => (
            <MediaQueriesRedirectController
              isMediaQueryActive={Boolean(redirectURL) && isMobile}
              url={redirectURL}
            >
              <Component />
            </MediaQueriesRedirectController>
          )}
          />
        );
      })}
    </Switch>
  );
};


const isStandingsURL = (pathname: string) => pathname.includes(TOURNAMENT_DETAILS.STANDINGS);

export const TOURNAMENT_TAB_ROUTING = {
  ...getTournamentDetailsRouting(TOURNAMENT_DETAILS.TOURNAMENT),
  isStandingsURL,
};
