import React, { FC, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { useTranslate } from 'context/translate-context';
import { Noop } from 'types';
import { addQuery } from 'utils';

import './NavigationHeader.scss';


type Props = {
  path?: string;
  callback?: Noop;
  isActive?: boolean;
  searchQuery?: Record<string, any>;
  actions?: ReactElement;
};

export const NavigationHeader: FC<Props> = ({
  isActive = true,
  path,
  callback,
  searchQuery,
  actions,
}) => {
  const { translate } = useTranslate();
  const { push } = useHistory();
  const { search } = useLocation();

  const handleRedirect = () => {
    if (!path) {
      return null;
    }

    if (searchQuery) {
      return push({
        pathname: path,
        search: addQuery(search, searchQuery),
      });
    }
    return push(path);
  };

  if (!isActive) {
    return null;
  }

  return (
    <header className={`navigation-header ${actions ? 'navigation-header--3-col-grid' : ''}`}>
      <button
        className="navigation-header__button"
        onClick={path ? handleRedirect : callback}
      >
        <Icon
          name="chevron left"
        />
        <div className="navigation-header__back-label">
          {translate('BACK')}
        </div>
      </button>
      <div className="navigation-header__title" />
      <div className="navigation-header__actions">
        {actions || null}
      </div>
    </header>
  );
};
