import { FC, ReactElement } from 'react';
import { useTournament } from 'context/tournament-context';
import { StatisticSeason, Maybe } from 'types';


type Props = {
  children(props: {
    statistic: Maybe<StatisticSeason>;
    isLoading: boolean;
  }): Maybe<ReactElement>;
}

export const TournamentDetailsStatisticsController: FC<Props> = ({ children }) => {
  const {
    statistic,
    areStatisticsLoading,
  } = useTournament();

  const props = {
    statistic,
    isLoading: areStatisticsLoading,
  };


  return children(props);
};

