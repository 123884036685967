
import React, { FC } from 'react';
import { Loader, Table } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { StatisticSeason } from 'types';


import { EmptyListFeedback } from '../../../components';
import { useTranslate } from '../../../context/translate-context';
import { TournamentDetailsStatisticsController } from '../shared/TournamentDetailsStatisticsController';

import { getAwayProps, getDrawProps, getHomeProps } from './tournamentDetailsTabStatisticsServices';

import './TournamentDetailsTabStatistics.scss';


export const TournamentDetailsTabStatistics = () => {
  const { translate } = useTranslate();

  return (
    <TournamentDetailsStatisticsController>
      {({ statistic, isLoading }) => {
        if (!statistic || isEmpty(statistic)) {
          return <EmptyListFeedback />;
        }

        if (isLoading) {
          return (
            <div className="tournament-details-page__loader-section">
              <Loader active inline  />
            </div>
          );
        }

        return (
          <section className="tournament-stats__section-grid">
            <div className="tournament-stats__table-grid-container">
              <HomeDrawAwaySection statistic={statistic} />
              <GoalsSection statistic={statistic} />
              <Goals
                goals={statistic.moreThanGoals}
                goalsPercentage={statistic.moreThanGoalsPercentage}
                label={translate('MORE_GOALS')}
              />
            </div>
          </section>
        );
      }}
    </TournamentDetailsStatisticsController>
  );
};

type StatisticProps = {
  statistic: StatisticSeason;
};

const HomeDrawAwaySection: FC<StatisticProps> = ({ statistic }) => {
  const { matchesPlayed  } = statistic;
  const { translate } = useTranslate();

  return (
    <div>
      <div className="tournament-stats__section-header">
        <div>
          {translate('H_A_D')}
        </div>
        <div>
          {translate('PLAYED')}: {matchesPlayed}
        </div>
      </div>
      <Table className="margin-unset small-font">
        <Table.Body>
          <Row {...getHomeProps({ ...statistic, label: translate('H_WINS') })} />
          <Row {...getDrawProps({ ...statistic, label: translate('DRAWS') })} />
          <Row {...getAwayProps({ ...statistic, label: translate('A_WINS') })} />
        </Table.Body>
      </Table>
    </div>
  );
};

const GoalsSection: FC<StatisticProps> = ({ statistic }) => {
  const {
    goals,
    goalsPerMatch,
    goalsScoredAtHome,
    goalsScoredAway,
  } = statistic;
  const { translate } = useTranslate();

  return (
    <div>
      <div className="tournament-stats__section-header">
        <div>
          {translate('GOALS')}
        </div>
        <div>
          {translate('NO_GOALS')}: {goals}
        </div>
      </div>
      <Table className="margin-unset small-font">
        <Table.Body>
          <Row label={translate('GOALS_MATCH')} score={String(goalsPerMatch)} />
          <Row label={translate('GOALS_HOME')} score={String(goalsScoredAtHome)} />
          <Row label={translate('GOALS_AWAY')} score={String(goalsScoredAway)} />
        </Table.Body>
      </Table>
    </div>
  );
};

type GoalsProps = {
  goals: StatisticSeason['moreThanGoals'];
  goalsPercentage: StatisticSeason['moreThanGoalsPercentage'];
  label: string;
};

const Goals: FC<GoalsProps> = ({ goals = [], goalsPercentage = [], label }) => {
  const { translate } = useTranslate();

  return (
    <div>
      <div className="tournament-stats__section-header">
        <div>
          {`${label}:`}
        </div>
      </div>
      <Table className="margin-unset small-font">
        <Table.Body>
          {goals.map((goal, index) => {
            const combinedLabel = `${label} ${index} ${translate('GOALS')}`;

            return (
              <Row
                key={combinedLabel}
                label={combinedLabel}
                score={String(goal)}
                percentage={String(goalsPercentage[index] || 0)}
              />
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};


type RowProps = {
  label: string;
  percentage?: string;
  score: string;
};


const Row: FC<RowProps> = ({ label, score, percentage }) => {
  return (
    <Table.Row>
      <Table.Cell className="narrow-left-padding highlight">
        {label}
      </Table.Cell>
      {score && (
        <Table.Cell className="narrow-cell align-right">
          {score}
        </Table.Cell>
      )}
      {percentage && (
        <Table.Cell className="narrow-cell align-right">
          {`${percentage}%`}
        </Table.Cell>
      )}
    </Table.Row>
  );
};
