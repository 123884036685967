import Big from 'big.js';

export const factorialize = (n: number, limit?: number): number => {
  if (n < 0) {
    return -1;
  } else if (n === 0) {
    return 1;
  } else if (limit && limit === n) {
    return 1;
  }
  return (Big(n).times(factorialize(n - 1, limit)).toNumber());
};
