import React from 'react';
import { Table } from 'semantic-ui-react';

import { useTranslate } from '../../../context/translate-context';


export const TournamentDetailsStandingsTableHeader = () => {
  const { translate } = useTranslate();

  return (
    <Table.Header>
      <Table.Row>
        <HeaderCell width={4} />
        <HeaderCell>
          <div className="tournament-table__grid-points">
            <div className="tournament-table__cell-center">
              {translate('POINTS')}
            </div>
            <div className="tournament-table__cell-center">
              {translate('WIN')}
            </div>
            <div className="tournament-table__cell-center">
              {translate('DRAW')}
            </div>
            <div className="tournament-table__cell-center">
              {translate('LOSE')}
            </div>
          </div>
        </HeaderCell>
        <HeaderCell>
          <div className="tournament-table__grid-goals">
            <div className="tournament-table__cell-center">
              {translate('GOALS_FOR')}
            </div>
            <div className="tournament-table__cell-center">
              {translate('GOALS_AGAINST')}
            </div>
            <div className="tournament-table__cell-center">
              {translate('GOALS_DIFF')}
            </div>
          </div>
        </HeaderCell>
        <HeaderCell>
          <div className="tournament-table__cell-center">
            {translate('FORM')}
          </div>
        </HeaderCell>
        <HeaderCell width={4}>
          <div className="tournament-table__cell-center tournament-table__next-match">
            {translate('NEXT_MATCH')}
          </div>
        </HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const HeaderCell = ({ isTextWhite, width, children }) => {
  return (
    <Table.HeaderCell width={width} className={`header-bg ${isTextWhite ? 'header-bg--white' : ''}`}>
      {children}
    </Table.HeaderCell>
  );
};
