import { useCallback, useMemo } from 'react';
import { BasketItem, BasketItemGroup } from 'types';

export const useGroupList = (list: BasketItem[]) => {
  const mapGroup = useCallback((group?: BasketItemGroup) => {
    const groupList: (BasketItem & { index: number })[] = [];
    list.forEach((item, index) => {
      if (!group) {
        // Handle Without Group
        if (item.groups.length === 0) {
          groupList.push({ ...item, index });
        }
      } else {
        if (item.groups.indexOf(group) > -1) {
          groupList.push({ ...item, index });
        }
      }
    });

    return groupList;
  }, [list]);

  const groupedList = useMemo(() => {
    const groupedList: Record<any, (BasketItem & { index: number })[]> = {};

    groupedList.NONE = mapGroup();

    Object.keys(BasketItemGroup).forEach(group => {
      groupedList[group] = mapGroup(group as BasketItemGroup);
    });

    return groupedList;
  }, [mapGroup]);

  return {
    groupedList,
  };
};
