import { useCallback, useRef, useState } from 'react';


export const useInfiniteScroll = (hasNextPage, isLoading) => {
  const [canFetch, setCanFetch] = useState(false);
  const observer = useRef();

  const handleScrollRef = useCallback(node => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasNextPage) {
        return setCanFetch(true);
      }
    });
    if(node) observer.current.observe(node);
  }, [isLoading, hasNextPage]);


  return {
    canFetch,
    setCanFetch,
    handleScrollRef,
  };
};
