import { useState, FC, ReactElement, useEffect } from 'react';
import { Noop } from 'types';


type Props = {
  initialState?: boolean;
  children(props: {
    isOpen: boolean;
    toggleDropdown: Noop;
    handleCollapse: Noop;
  }): ReactElement;
}

export const DashboardDropdownHandlerController: FC<Props> = ({ initialState = false, children }) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const toggleDropdown = () => setIsOpen(prevState => !prevState);
  const handleCollapse = () => setIsOpen(false);

  useEffect(() => {
    setIsOpen(initialState);
  }, [initialState]);

  const props = {
    isOpen,
    toggleDropdown,
    handleCollapse,
  };

  return children(props);
};
