import { MatchSort } from 'types';


export const transformHeaderVariantByMatchSortKey = {
  [MatchSort.KICK_OFF_ASC]: 'kick-off',
  [MatchSort.KICK_OFF_DESC]: 'kick-off',
  [MatchSort.TOURNAMENT_ASC]: 'tournament',
  [MatchSort.TOURNAMENT_DESC]: 'tournament',
  [MatchSort.BET_RATIO_ASC]: 'bet-ratio',
  [MatchSort.BET_RATIO_DESC]: 'bet-ratio',
};
