import { Match, MatchLiveIncident, TeamScore } from 'types';
import { getIncidentTimeInSecondsAndOrigin } from 'views/shared/Incident/incidentServices';


export const getFlattenedIncidentList = (matchList: Match[]) => {
  try {
    return  matchList.flatMap((match): MatchLiveIncident[] => {
      const {
        home: { incidents: homeIncidents = [] },
        away: { incidents: awayIncidents = [] },
      } = match || {};

      return ([
        ...homeIncidents.map(homeIncident => ({
          ...getIncidentTimeInSecondsAndOrigin(homeIncident, TeamScore.Home),
          match,
        })),
        ...awayIncidents.map(awayIncident => ({
          ...getIncidentTimeInSecondsAndOrigin(awayIncident, TeamScore.Away),
          match,
        })),
      ]);
    });
  } catch(error) {
    console.error(error);

    return [];
  }
};
