import React, { ReactElement, FC } from 'react';
import { EmptyListFeedback } from 'components';
import { MatchStatus } from 'types';
import { MatchDetailsResultTeams } from 'views/Dashboard/MatchDetails/TeamFormation/MatchDetailsResultTeams';

import { MatchDetailsTeamLineupsController } from '../../../MatchDetails/TeamFormation/MatchDetailsTeamLineupsController';
import { MatchState } from '../../../MatchList/Match/MatchState';
import { MOBILE_TOURNAMENT_ROUTING } from '../../../../Tournament/Mobile/Routing/MobileTournamentRouting';
import { MatchVariantKickOffCountry } from '../../../MatchList/MatchVariantKickOffCountry';

import '../../../MatchDetails/MatchDetailsSummaryWrapper.scss';
import './MobileMatchDetailsSummaryWrapper.scss';


type Props = {
  displayStatus: MatchStatus[];
  children: ReactElement;
};

export const MobileMatchDetailsSummaryWrapper: FC<Props> = ({ displayStatus, children }) => {
  return (
    <section className="m-match-details-summary-wrapper">
      <MatchDetailsTeamLineupsController>
        {({ selectedMatch }) => {
          const { event, season } = selectedMatch || {};
          const { status = MatchStatus.Scheduled, statusName } = event || {};

          return (
            <div className="m-match-details-summary-wrapper__container">
              <div className="margin-bottom--size-s">
                <MatchVariantKickOffCountry
                  season={season}
                  getRedirectURL={MOBILE_TOURNAMENT_ROUTING.getDefaultTournamentURL}
                />
              </div>
              <div className="m-match-details-summary-wrapper__state-container">
                <MatchState
                  state={status}
                  statusName={statusName}
                />
              </div>
              <MatchDetailsResultTeams
                match={selectedMatch}
              />
              {displayStatus.includes(status) ?
                <div className="m-match-details-summary-wrapper__grid">
                  {children}
                </div>
                :
                <EmptyListFeedback />}
            </div>
          );
        }}
      </MatchDetailsTeamLineupsController>
    </section>
  );
};

