import { createAggregatedURLListService } from 'views/shared/Routing/createAggregatedURLListService';

import { RouteParam } from '../Routing/RouteParam';

import { TOURNAMENT_DETAILS } from './Routing';


export const getURLWithId = (prefix: string) => ({ tournamentId }: RouteParam) => `${prefix}/${tournamentId}`;


type URLProps = (getPrefix: ({ tournamentId }: RouteParam) => string) => (props: RouteParam) => string;

const getTournamentStatisticsTabURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${TOURNAMENT_DETAILS.STATISTICS}`;
};

const getTournamentStandingsTabURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${TOURNAMENT_DETAILS.STANDINGS}`;
};

const getURLMethods = [
  { name: 'getTournamentStatisticsTabURL', method: getTournamentStatisticsTabURL },
  { name: 'getTournamentStandingsTabURL', method: getTournamentStandingsTabURL },
];


export const getTournamentDetailsRouting = createAggregatedURLListService(getURLMethods, getURLWithId);
