import { useEffect, ReactElement, FC } from 'react';
import { Tournament, Maybe } from 'types';
import { useTournament } from 'context/tournament-context';


type Props = {
  children(props: {
    tournamentDetails: Maybe<Tournament>;
    badgeUrl: string;
    name: string;
  }): ReactElement;
}

export const TournamentDetailsController: FC<Props> = ({ children }) => {
  const {
    tournamentDetails,
    setSelectedHistoricalSeason,
  } = useTournament();

  const { badgeUrl = '', name = '' } = tournamentDetails || {};

  useEffect(() => {
    return () => {
      setSelectedHistoricalSeason({});
    };
  }, [setSelectedHistoricalSeason]);

  const props = {
    tournamentDetails,
    badgeUrl,
    name,
  };

  return children(props);
};
