import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRollbarPerson } from '@rollbar/react';

import { ThemeMode } from '../../components/ThemeMode';
import { useTranslate } from '../../context/translate-context';
import { ReactComponent as Logo } from '../../assets/images/sportxpert-logo-light.svg';
import { USER_ROUTING } from '../../views/User/UserRouting';
import { MatchListFilterResetToDefaultController } from '../../views/Dashboard/MatchList/Filter/MatchListFilterResetToDefaultController';
import { UserInCircle } from '../UserInCircle';

import { getDisplayedUserName } from './headerService';


export const HeaderPrivate = ({ children }) => {
  const { user, logout } = useAuth0();
  const { translate } = useTranslate();
  const { push } = useHistory();

  useRollbarPerson(user ? { email: user.email } : {});

  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__container">
          <MatchListFilterResetToDefaultController>
            {({ handleResetMatchesFilters }) => (
              <div
                className="header__logo"
                onClick={handleResetMatchesFilters}
              >
                <Logo fill="var(--SaasText01)" width="100%" />
              </div>
            )}
          </MatchListFilterResetToDefaultController>
          <div className="header__actions">
            <ThemeMode customClass="margin-right--size-m" />
            <section>
              <span
                className="header__account-text link-text header__username"
                onClick={() => push(USER_ROUTING.getUserAccountUrl())}
              >
                {getDisplayedUserName(user)}
              </span>
            </section>
            <Dropdown
              aria-label={translate('ACCOUNT')}
              className="button"
              pointing="top right"
              trigger={<UserInCircle svgStyles="user-account__icon--small" />}
              icon={null}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  text={translate('ACCOUNT')}
                  to={USER_ROUTING.getUserAccountUrl()}
                  value="account"
                />
                <Dropdown.Item
                  as="a"
                  onClick={() => logout({ returnTo: window.location.origin })}
                  text={translate('SIGN_OUT')}
                />
              </Dropdown.Menu>
            </Dropdown>
            <div className="header__children">
              {children}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
