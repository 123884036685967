import React, { Suspense } from 'react';
import { Loader } from 'semantic-ui-react';
import { MOBILE_DASHBOARD_ROUTING } from 'views/Dashboard/Routing/MobileDashboardRouting';

import { SportsBarController } from '../../../SportsBar/SportsBarController';
import SportsBar from '../../../SportsBar';


const MobileAreaSportsBar = () => {
  return (
    <SportsBarController
      url={MOBILE_DASHBOARD_ROUTING.getTournamentsSelectURL()}
    >
      {props => (
        <Suspense
          fallback={
            <Loader active inline="centered" />
          }
        >
          <SportsBar {...props} />
        </Suspense>
      )}
    </SportsBarController>
  );
};


export default MobileAreaSportsBar;
