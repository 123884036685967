import React from 'react';
import PlaceholderBadge from 'assets/images/placeholder-club-badge.svg';
import { Size } from 'types';

import './Badge.scss';


const SIZE = {
  [Size.Tiny]: {
    height: 15,
    width: 15,
  },
  [Size.Small]: {
    height: 15,
    width: 15,
  },
  [Size.Normal]: {
    height: 23,
    width: 23,
  },
  [Size.Big]: {
    height: 60,
    width: 60,
  },
};

type Props = {
  customStyles?: string;
  badgeURL?: string;
  size?: Size;
  alt: string;
}

const badgeSizeMap = new Map();
badgeSizeMap.set('tiny', 'badge--tiny-size');
badgeSizeMap.set('small', 'badge--small-size');
badgeSizeMap.set('normal', 'badge--normal-size');
badgeSizeMap.set('big', 'badge--big-size');

export const Badge = ({ badgeURL, customStyles = '', size = Size.Normal, alt }: Props) => {
  const shape = SIZE[size];

  return (
    <img
      className={`${badgeSizeMap.get(size)} ${customStyles}`}
      src={badgeURL || PlaceholderBadge}
      alt={alt}
      {...shape}
    />
  );
};
