import React, { FC } from 'react';
import { TOURNAMENT_ROUTING } from 'views/Tournament/Routing/TournamentRouting';
import { Match, Prediction } from 'types';

import { Badge } from '../../shared';

import { MatchDetailsSidebarBetsLinks } from './MatchDetailsSidebarBetsLinks';
import { MatchDetailsSidebarLink } from './MatchDetailsSidebarLink';
import { MatchDetailsSidebarMatchLinks } from './MatchDetailsSidebarMatchLinks';

import './MatchDetailsSidebar.scss';


type Props = {
  match: Match;
  hasAnyLineup: boolean;
  predictions: Prediction;
}

export const MatchDetailsSidebar: FC<Props> = ({ match, hasAnyLineup, predictions }) => {
  const {
    season: { competition: { id: competitionId, name: competitionName, badgeUrl } },
  } = match;

  return (
    <aside className="match-detail-sidebar__container">
      <ul className="match-detail-sidebar__list">
        <MatchDetailsSidebarLink
          isTopLink
          label={competitionName}
          link={TOURNAMENT_ROUTING.getDefaultTournamentURL(competitionId)}
          Icon={
            <Badge
              badgeURL={badgeUrl}
              alt={competitionName}
              customStyles={badgeUrl ?
                'match-detail-sidebar__badge' :
                'match-detail-sidebar__badge-placeholder'
              }
            />
          }
        />
        <MatchDetailsSidebarMatchLinks
          match={match}
          hasAnyLineup={hasAnyLineup}
        />
        <MatchDetailsSidebarBetsLinks
          match={match}
          predictions={predictions}
        />
      </ul>
    </aside>
  );
};
