import React from 'react';
import { TeamScore } from 'types';
import { MatchDetailsTeamFormationPlayingField } from 'views/Dashboard/MatchDetails/TeamFormation/MatchDetailsTeamFormationPlayingField';
import { MatchDetailsTeamLineups } from 'views/Dashboard/MatchDetails/MatchDetailsTeamLineups';

import { MatchDetailsTeamLineupsController } from '../../../../MatchDetails/TeamFormation/MatchDetailsTeamLineupsController';

import './MobileMatchDetailsStatisticTeamFormation.scss';


export const MobileMatchDetailsStatisticTeamFormation = () => {
  return (
    <MatchDetailsTeamLineupsController>
      {props => {
        const { selectHome, selectAway } = props;

        return (
          <>
            <MatchDetailsTeamFormationPlayingField {...props} />
            <div className="m-match-details-statistic-team-formation__grid margin-top--size-m">
              <MatchDetailsTeamLineups
                teamScore={TeamScore.Home}
                teamLineup={props[TeamScore.Home]}
                team={selectHome}
              />
              <MatchDetailsTeamLineups
                teamScore={TeamScore.Away}
                teamLineup={props[TeamScore.Away]}
                team={selectAway}
              />
            </div>
          </>
        );
      }}
    </MatchDetailsTeamLineupsController>
  );
};
