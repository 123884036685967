import { FC, ReactElement } from 'react';
import { MatchTeamsCorrectScorePrediction } from 'types';

import { limitTeamScoresByRange } from '../matchDetailsSideBetsServices';


type Props = {
  visiblePredictions: MatchTeamsCorrectScorePrediction[];
  children(props: {
    predictions: MatchTeamsCorrectScorePrediction[];
    mappedPredictions: number[];

  }): ReactElement;
}

export const MatchDetailsSideBetsCorrectScoreController: FC<Props> = ({ visiblePredictions, children }) => {
  const mappedPredictions = visiblePredictions.map(({ prediction }) => prediction);
  const getPredictionsByRange = limitTeamScoresByRange({ start: 0, end: 5 });
  const predictions = visiblePredictions.filter(({ home, away }) => getPredictionsByRange({ home, away }));

  const props = {
    mappedPredictions,
    predictions,
  };

  return children(props);
};
