import React from 'react';

import { MatchDetailsSideBetsMatchResult } from '../MatchDetailsSideBetsMatchResult';
import { getSideBetsGridHeaders } from '../../../../shared/MatchDetails/SideBets/matchDetailsSideBetsServices';
import { rows } from '../sectionRows.options';


export const MatchDetailsSideBetsMatchResultDefault = () => {
  return (
    <MatchDetailsSideBetsMatchResult
      ColumnsLabels={getSideBetsGridHeaders(rows)}
    />
  );
};
