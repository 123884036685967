import { IncidentInternal, Incident, TeamScore } from 'types';
import { SortItemsProps } from 'utils/sort';


export const getMinutesFromMatch = (time: string) => {
  const [minutes] = time.split(':');

  return `${minutes}'`;
};

export const getIncidentTimeInSecondsAndOrigin = (incident: Incident, teamOrigin: TeamScore): IncidentInternal => {
  return ({
    ...incident,
    teamOrigin,
  });
};


export const sortIncidentsByIncidentTimeInOrder = (sortItems: SortItemsProps) =>
  (firstElement: IncidentInternal, secondElement: IncidentInternal) => {
    const { createdAt: firstIncidentime } = firstElement;
    const { createdAt: secondIncidentTime } = secondElement;

    return sortItems(firstIncidentime, secondIncidentTime);
  };
