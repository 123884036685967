import { useSocketsLiveOddList } from 'context/sockets/sockets-live-odd-list-context';
import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useMatchListSideBetsOdds } from 'context/matchListSideBets/match-list-side-bets-odds-context';
import { transformToDisplayedSideBetsOnMatchList } from 'context/matchListSideBets/services';

import { updateMatchListOddList } from './updateMatchListOddList.service';


export const useUpdateMatchListSideBets = () => {
  const { liveOddListSocket } = useSocketsLiveOddList();
  const { oddList, setOddList } = useMatchListSideBetsOdds();

  const transformedOddList = useMemo(() => {
    if (liveOddListSocket.length === 0) {
      return [];
    }
    return transformToDisplayedSideBetsOnMatchList(liveOddListSocket);
  }, [liveOddListSocket]);

  const syncedOddList = useMemo(() => {
    return updateMatchListOddList(oddList, transformedOddList);
  }, [oddList, transformedOddList]);


  const memoShouldOddListBeUpdated = useMemo(() => {
    if (syncedOddList.length === 0) {
      return false;
    }

    const isSomeOfElementsEqual = oddList.some((element, index) => {
      const notEqual = !isEqual(element, syncedOddList[index]);

      return notEqual;
    });

    return isSomeOfElementsEqual;
  }, [syncedOddList, oddList]);

  useEffect(() => {
    if (memoShouldOddListBeUpdated) {
      setOddList(syncedOddList);
    }
  }, [memoShouldOddListBeUpdated, syncedOddList,  setOddList]);
};
