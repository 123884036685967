const area = {
  'australia': 'Australia',
  'africa': 'Afrika',
  'asia': 'Asia',
  'europe': 'Europa',
  'north america': 'Nord- og Mellom-Amerika',
  'south america': 'Sør-Amerika',
  'oceania': 'Oceania',
  AFC: 'Asia (AFC)',
  CAF: 'Afrika (CAF)',
  CONCACAF: 'Nord- og Mellom-Amerika (CONCACAF)',
  CONMEBOL: 'Sør-Amerika (CONMEBOL)',
  OFC: 'Oceania (OFC)',
  UEFA: 'Europa (UEFA)',
};

export default area;
