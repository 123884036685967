import { FC, ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useFavouriteList } from 'context/favourite/favourites-context';
import { useTournaments } from 'context/tournament-list-context';

import { addQuery, parseQuery } from '../../../utils';
import { Favourite } from '../../../types';
import { MatchSelectedFilter } from '../../../types/Match/MatchFilters';


type Props = {
  children(props: {
    handleRedirect: () => void;
    areFavouritesSelected: boolean;
    favourites: Favourite[];
  }): ReactElement;
};

export const FavouriteListController: FC<Props> = ({ children }) => {
  const { search } = useLocation();
  const { push } = useHistory();
  const { favourites } = useFavouriteList();
  const { selectedTournaments } = useTournaments();

  const parsedQuery = parseQuery(search);
  const { selected = [] } = parsedQuery;
  const castedSelected = selected as MatchSelectedFilter[];

  const areFavouritesSelected = castedSelected?.includes(MatchSelectedFilter.Favourites) && favourites.every((favourite: Favourite) => selectedTournaments.includes(favourite.seasonId));
  const seasonIds = favourites.map(favourite => favourite.seasonId);


  const handleRedirect = () => {
    if (!selected?.includes(MatchSelectedFilter.Favourites)) {
      return push({
        search: addQuery(search, { selected: [...castedSelected, MatchSelectedFilter.Favourites], season: seasonIds }),
      });
    }

    return push({
      search: addQuery(search, {
        selected: castedSelected.filter(item => item !== MatchSelectedFilter.Favourites),
        season: [],
      }),
    });
  };


  const props = {
    handleRedirect,
    areFavouritesSelected,
    favourites,
  };

  return children(props);
};
