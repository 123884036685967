import React, { useCallback } from 'react';
import { useCompareOdds } from 'views/Dashboard/shared/hooks/useCompareOdds';
import { useFindBasketError } from 'components/Basket/validation/useFindBasketError';

import { BasketItemButton } from './components/BasketItemButton';
import { BasketItemOddInfo } from './BasketItemOddInfo';

import './BasketItem.scss';


export const BasketItemCombo = ({ item, onRemove, index }) => {
  const { handleFoundError } = useFindBasketError({ item });

  const remove = useCallback(() => {
    onRemove(item, index);
  }, [item, index, onRemove]);

  const { homeShortName, awayShortName, odd } = item;
  const { odds } = odd;

  const oddsAsStringWithDigits = odds.toFixed(2);
  const {
    highlightIncreasedOddClass,
    highlightDecreasedOddClass,
  } = useCompareOdds({
    odd: odds,
    oddIncreaseClassName: 'basket-item__odd--increased',
    oddDecreaseClassName: 'basket-item__odd--decreased',
  });

  const handleHasOddChanged = `${highlightIncreasedOddClass || highlightDecreasedOddClass}`;

  return (
    <div className={`basket-item ${handleFoundError ? 'basket-item--error' : ''}`}>
      <div className={`basket-item__container ${handleFoundError ? 'basket-item__container--error' : ''}`}>
        <div className="basket-item__content">
          <div className="basket-item__header">
            <div className="basket-item__header-team">
              <div className="basket-item__name">
                <span>{homeShortName}</span>
                <div className="basket-item__name--separator">
                  -
                </div>
                <span>{awayShortName}</span>
              </div>
              <div className={`basket-item__odd basket-item__odd--mobile ${handleHasOddChanged}`}>
                {oddsAsStringWithDigits}
              </div>
            </div>

          </div>
          <BasketItemOddInfo basketItem={item} />
          <div className="basket-item__odd-wrapper">
            <div className={`basket-item__odd ${handleHasOddChanged}`}>
              {oddsAsStringWithDigits}
            </div>
          </div>
        </div>
      </div>
      <BasketItemButton
        remove={remove}
        error={handleFoundError}
      />
    </div>
  );
};
