import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { addQuery } from '../../../utils';
import { useMatchList } from '../../../context/match-list-context';
import { useMatch } from '../../../context/match-context';


const removeMatchDetailsTab = pathname => {
  return pathname.slice(0, -1);
};

export const MatchListOpenedMatchHandlerController = ({ children }) => {
  const textInPathToRemove = ['dashboard', 'matches', 'season'];
  const { push } = useHistory();
  const {
    matches,
  } = useMatchList();
  const { setSelectedMatch } = useMatch();
  const { pathname, search } = useLocation();

  const handleOpenMatch = () => {
    const [eventId, seasonId] = removeMatchDetailsTab(pathname.split('/'))
      .filter(text => {
        return Boolean(text) && !textInPathToRemove.includes(text);
      });


    if (!matches.length && seasonId) {
      return push({
        search: addQuery(search, {
          season: [seasonId],
        }),
      });
    }

    if (eventId) {
      const foundMatch = matches.find(match => match.event.id === eventId) || {};

      return setSelectedMatch(foundMatch);
    }
  };

  const memoHandler = useCallback(handleOpenMatch, [pathname, matches.length]);

  useEffect(() => {
    memoHandler();
  }, [memoHandler]);

  return children;
};
