import { useMatch } from 'context/match-context';
import { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { useSocketMatchesData } from 'views/Dashboard/shared/hooks/useSocketMatchesData';
import { isEqual } from 'lodash';
import MatchClient from 'api/MatchClient';
import { MatchStatus } from 'types';

import { RouteParam } from './RouteParam';


type Props = {
  children(props: {
    isLoading: boolean;
    hasAnyLineup: boolean;
  }): ReactElement;
}

export const MobileMatchDetailsController: FC<Props> = ({ children }) => {
  const { eventId } = useParams<RouteParam>();
  const {
    selectedMatch,
    setSelectedMatch,
    hasAnyLineup,
  } = useMatch();

  const isLive = selectedMatch?.event.status === MatchStatus.Live;
  const {
    isLoading,
    data: matchData,
  } = useQuery(['matchDetails', eventId], () => MatchClient.getMatch({ eventId }),
    { enabled: Boolean(eventId) });

  const eventData = matchData?.data?.event || null;

  const load = useCallback(() => {
    if (eventData) {
      setSelectedMatch(eventData);
    }
    return null;
  }, [eventData, setSelectedMatch]);

  useEffect(() => {
    if (!selectedMatch) {
      load();
    }
  }, [load, selectedMatch]);

  const { syncedMatch } = useSocketMatchesData(selectedMatch);

  const memoSyncedMatch = useMemo(() => {
    if (selectedMatch && isLive) {
      if(syncedMatch && !isEqual(syncedMatch, selectedMatch)) {
        return syncedMatch;
      }
      return selectedMatch;
    }
    return null;
  }, [selectedMatch, syncedMatch, isLive]);

  useEffect(() => {
    if (memoSyncedMatch) {
      setSelectedMatch(memoSyncedMatch);
    }
  }, [memoSyncedMatch, setSelectedMatch]);

  const props = {
    isLoading,
    hasAnyLineup,
  };
  return children(props);
};
