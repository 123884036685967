
import React, { useContext, FC, useMemo } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { noop } from 'lodash';
import { ProviderAppConfig, Maybe } from 'types';
import { dashboardAppConfig } from 'config/dashboardAppConfig';

import { DASHBOARD_STORAGE_KEY, CONFIG_STORAGE_KEY, FILTERS_STORAGE_KEY } from '../constants';


type Props = {
  configuration: ProviderAppConfig;
  setConfiguration: (config: ProviderAppConfig) => void;
  setConfigFilters: (config: Maybe<ProviderAppConfig['filters']>) => void;
  configFilters: Maybe<ProviderAppConfig['filters']>;
}

const ConfigContext = React.createContext<Props>({
  configuration: dashboardAppConfig,
  setConfiguration: noop,
  setConfigFilters: noop,
  configFilters: null,
});

const ConfigProvider: FC = props => {
  const [storedValue, setConfigFilters] = useLocalStorage<Maybe<ProviderAppConfig['filters']>>(`${CONFIG_STORAGE_KEY}_${FILTERS_STORAGE_KEY}`, null);
  const [configuration, setConfiguration] = useLocalStorage<ProviderAppConfig>(`${DASHBOARD_STORAGE_KEY}_${CONFIG_STORAGE_KEY}`, dashboardAppConfig);

  const configFilters = useMemo(() => {
    return storedValue;
  }, [storedValue]) as ProviderAppConfig['filters'];

  const values = {
    configuration,
    configFilters,
    setConfiguration,
    setConfigFilters,
  };

  return (
    <ConfigContext.Provider
      value={values}
      {...props}
    />
  );
};


const useConfig = () => {
  const context = useContext(ConfigContext);

  if(context === undefined) {
    throw new Error('useConfig must be used within a ConfigContext');
  }

  return context;
};


export {
  ConfigProvider,
  useConfig,
};
