import { useTranslate } from 'context/translate-context';
import React, { FC } from 'react';
import { MatchStatus, MatchStatusName } from 'types';


import './MatchState.scss';


type Props = {
  state: MatchStatus;
  statusName?: MatchStatusName;
}

export const MatchState: FC<Props> = ({ state, statusName }) => {
  const { translate } = useTranslate();

  const mappedMatchState = new Map();
  mappedMatchState.set(MatchStatus.Live,
    <div className="match-state__container match-state__container--live">
      <span className="match-state__dot match-state__dot--live margin-right--size-xs" />
      {translate('LIVE')}
    </div>,
  );
  mappedMatchState.set(MatchStatus.Finished,
    <span className="match-state__container match-state__container--finished">
      {translate('ENDED')}
    </span>,
  );
  mappedMatchState.set(MatchStatusName.Postponed,
    <span className="match-state__container match-state__container--postponed">
      {translate('POSTPONED')}
    </span>,
  );

  if (statusName && [MatchStatusName.Postponed].includes(statusName)) {
    return mappedMatchState.get(statusName);
  }

  if (!mappedMatchState.get(state)) {
    return <div />;
  }

  return mappedMatchState.get(state);
};
