import { useTranslate } from 'context/translate-context';
import React, { FC, ReactElement } from 'react';
import { Icon } from 'semantic-ui-react';

import { Noop } from '../../types/Util';

import { handleActiveExpandedClass } from './services';

import './Dropdown.scss';


type Props = {
  label: string;
  selectedElementLabel: string;
  isExpanded: boolean;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleToggleExpand: Noop;
  dropdownRef: React.MutableRefObject<null>;
  children: ReactElement;
};

export const Dropdown: FC<Props> = ({
  label,
  selectedElementLabel,
  isExpanded,
  handleClick,
  handleToggleExpand,
  dropdownRef,
  children,
}) => {
  const { translate } = useTranslate();
  const setExpandedDropdownModifier = handleActiveExpandedClass(isExpanded);

  return (
    <div
      ref={dropdownRef}
      onClick={handleClick}
      className="dropdown__container"
    >
      <div className="dropdown__label margin-right--size-s">
        {label}
      </div>
      <div className="dropdown__box">
        <div className="dropdown__inner-bg">
          <div
            className="dropdown__selected"
            onClick={handleToggleExpand}
          >
            <div className="dropdown__text">
              {translate(selectedElementLabel)}
            </div>
            <button
              className="dropdown__button"
              aria-label={translate(isExpanded ? 'CLOSE_DROPDOWN' : 'OPEN_DROPDOWN')}
            >
              <Icon
                name="chevron down"
                className={`margin--unset ${setExpandedDropdownModifier('active-rotate')}`}
              />
            </button>
            {isExpanded && children}
          </div>
        </div>
      </div>
    </div>
  );
};


type ListProps = {
  list: { label: string; value?: string }[];
  handleSelect: (item: string) => void;
};

export const DefaultDropdownList: FC<ListProps> = ({ list, handleSelect }) => {
  return (
    <ul className="dropdown__list">
      {list.map(props => (
        <DefaultDropdownElement
          key={props.label}
          handleSelect={handleSelect}
          {...props}
        />
      ))}
    </ul>
  );
};

type DropdownElementProps = {
  handleSelect: (value: string) => void;
  label: string;
  value?: string;
}

export const DefaultDropdownElement: FC<DropdownElementProps> = ({ handleSelect, label, value }) => {
  const { translate } = useTranslate();

  return (
    <li
      key={label}
    >
      <button
        className="dropdown__text dropdown__list-element"
        onClick={() => handleSelect(value || label)}
      >
        {translate(label)}
      </button>
    </li>
  );
};
