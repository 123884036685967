import React from 'react';

import { SIDE_BETS_PREDICTION_VARIANT } from '../../../../constants';
import { useTranslate } from '../../../../context/translate-context';

import './MatchListHeaderBetsSection.scss';


export const MatchListHeaderBetsSection = () => {
  return (
    <div className="match-list-header-bets__container">
      <BetHeader
        variant={SIDE_BETS_PREDICTION_VARIANT.ODDS}
        customClass="element-separator element-separator--tournament"
      />
      <BetHeader
        variant={SIDE_BETS_PREDICTION_VARIANT.XPERT}
      />
    </div>
  );
};

const BetHeader = ({ variant, customClass = '' }) => {
  const { translate } = useTranslate();
  const handleGridVariant = variant === SIDE_BETS_PREDICTION_VARIANT.ODDS ?
    'match-list-header-bets__prediction-grid--odds' : 'match-list-header-bets__prediction-grid--xpert';

  const { component: Label, labels } = betHeaderMap.get(variant);

  return (
    <div className={`match-list-header-bets__prediction-container ${customClass}`}>
      <Label text={translate(variant)} />
      <div className={`match-list-header-bets__prediction-grid ${handleGridVariant}`}>
        {labels.map(label => (
          <div
            key={label}
            className="match-list-header-bets__bet-container"
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

const Odds = ({ text }) => {
  return (
    <div className="match-list-header-bets__title-grid match-list-header-bets__title-grid--odds">
      <div className="match-list-header-bets__bet-container">
        <div className="match-list-header-bets__label">
          {text}
        </div>
      </div>
    </div>
  );
};

const Xpert = ({ text }) => {
  return (
    <div className="match-list-header-bets__bet-container match-list-header-bets__title-grid--xpert">
      <div className="match-list-header-bets__label">
        {text}
      </div>
    </div>
  );
};


const betHeaderLabelsOdds = ['1', 'X', '2', <div style={{ fontSize: '15px' }}>+</div>];
const betHeaderLabelsXpert = ['1', 'X', '2'];

const betHeaderMap = new Map();
betHeaderMap.set(SIDE_BETS_PREDICTION_VARIANT.ODDS, { component: Odds, labels: betHeaderLabelsOdds });
betHeaderMap.set(SIDE_BETS_PREDICTION_VARIANT.XPERT, { component: Xpert, labels: betHeaderLabelsXpert });
