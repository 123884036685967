import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { MatchFilters, Meta, LiveMatchFilters } from 'types';

import { DEFAULT_META } from '../../constants';
import api from '../services/FetchService';

import * as query from './queries';
import {
  GetMatchListResponse,
  GetMatchResponse,
  GetStandingList,
  GetStatistic,
  GetPredictionList,
} from './types';
import { getFormattedLiveMatchParams, getFormattedMatchParams } from './formatters';


export class MatchClient {
  constructor() {
    this.postSeasonMatches = this.postSeasonMatches.bind(this);
    this.getMatch = this.getMatch.bind(this);
    this.getStandings = this.getStandings.bind(this);
    this.postPredictions = this.postPredictions.bind(this);
  }

  postSeasonMatches({ params, meta = DEFAULT_META }: { params: MatchFilters; meta: Meta }): AxiosPromise<GetMatchListResponse> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.getSeasonMatches,
      data: {
        ...getFormattedMatchParams(params),
        meta,
      },
      cancelToken,
    };

    return api.request(options);
  }

  postSeasonLiveMatches({ params }: { params: LiveMatchFilters }): AxiosPromise<GetMatchListResponse> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.getSeasonMatches,
      data: {
        ...getFormattedLiveMatchParams(params),
      },
      cancelToken,
    };

    return api.request(options);
  }

  getMatch({ eventId }: { eventId: string }): AxiosPromise<GetMatchResponse> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.getMatchDetails,
      url: `${query.getMatchDetails.url}/${eventId}/details`,
      params: {
        eventId,
      },
      cancelToken,
    };

    return api.request(options);
  }

  getStatistics({ eventId }: { eventId: string }): AxiosPromise<GetStatistic> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.getMatchStatistics,
      url: `${query.getMatchStatistics.url}/${eventId}`,
      params: { eventId },
      cancelToken,
    };

    return api.request(options);
  }

  getStandings({ seasonId }: { seasonId: string }): AxiosPromise<GetStandingList> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.getStandings,
      url: `${query.getStandings.url}/${seasonId}`,
      cancelToken,
    };

    return api.request(options);
  }

  postPredictions({ eventsIds }: { eventsIds: string[] }): AxiosPromise<GetPredictionList> {
    const cancelToken = axios.CancelToken.source().token;
    const options: AxiosRequestConfig = {
      ...query.postPredictionList,
      cancelToken,
      data: {
        eventsIds,
      },
    };

    return api.request(options);
  }
}
