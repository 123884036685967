import { useArea } from 'context/area-context';
import React, { FC, ReactElement } from 'react';
import { SportsName, Area, Maybe } from 'types';

import { TournamentContinentListSectionVariantSoccer } from './Soccer/TournamentContinentListSectionVariantSoccer';
import { TournamentContinentListSectionVariantDefault } from './TournamentContinentListSectionVariantDefault';


const continentListSectionVariantMap = new Map<SportsName, typeof TournamentContinentListSectionVariantDefault>();

continentListSectionVariantMap.set(SportsName.Soccer, TournamentContinentListSectionVariantSoccer);
continentListSectionVariantMap.set(SportsName.Hockey, TournamentContinentListSectionVariantDefault);

type Props = {
  children(props: {
    handleSelectArea: (area: Area) => void;
    areas: Area[];
    selectedArea: Maybe<Area>;
  }): ReactElement;
}

export const TournamentContinentListSectionVariantHandler: FC<Props> = ({ children }) => {
  const { sportName } = useArea();

  const Section = continentListSectionVariantMap.get(sportName) || TournamentContinentListSectionVariantDefault;

  return (
    <Section>
      {props => children(props)}
    </Section>
  );
};
