import { useLiveTicker } from 'context/liveTicker/live-ticker-context';
import { useCallback, useEffect } from 'react';
import { MatchLiveIncident, IncidentState } from 'types';

import { liveSoundListMapByConfirmation } from './liveSoundsMap';


export const useHandleLiveTickerSound = (latestLiveMatch: MatchLiveIncident) => {
  const {
    liveDashboardPanel,
    setAudio,
  } = useLiveTicker();

  const isIncidentMuted = useCallback((incident: MatchLiveIncident) => {
    return liveDashboardPanel.find(({ codeList }) => codeList.includes(incident.code))?.isMuted;
  }, [liveDashboardPanel]);

  const handlePlaySound = () => {
    if (!latestLiveMatch) {
      return;
    }

    const { code, confirmation = IncidentState.Tbd } = latestLiveMatch;

    const soundMapConfirmation = liveSoundListMapByConfirmation.get(confirmation);
    const sound = soundMapConfirmation.get(code);

    if (sound) {
      const audio = new Audio(sound);
      audio.muted = Boolean(isIncidentMuted(latestLiveMatch));
      const promiseAudioPlay = audio.play();

      if (promiseAudioPlay !== undefined) {
        promiseAudioPlay
          .then(() => {
            // play audio
          })
          .catch(() => {
            setAudio(audio);
          });
      }
    }
  };

  useEffect(() => {
    handlePlaySound();
  }, [setAudio, latestLiveMatch]);
};
