import { Season } from './Match';
import { Area } from './Area';
import { Gender } from './User';


export type TournamentShortcut = {
  areaCode: string;
  badgeUrl?: string;
  name: string;
  seasonId: string;
  tournamentId: string;
};

export enum TournamentType {
  International = 'international',
  InternationalClubs = 'international_clubs',
  CountryLeague = 'country_league',
  CountryCups = 'country_cups',
  Undefined = 'undefined',
}

export type Tournament = {
  id: string;
  abbrev: string;
  name: string;
  shortName: string;
  areaType: string;
  areaSort: number;
  gender: Gender;
  predictionImportance: number;
  predictionLevel: number;
  type: TournamentType;
  badgeThumbUrl?: string;
  badgeUrl?: string;
  season: Season;
  area: Area;
};
