import { Event } from '../Match';
import { OddOdd, OddsProvider, OddsType, OddsTypePeriod } from '../Odd';


export enum BasketItemGroup {
  A = 'A',
  B = 'B',
  C = 'C',
  S = 'S',
}

export type GroupBetValue = {
  number: number;
  stakeForCombination: number | string;
  stakeForGroup: number | string;
  selected: boolean;
};

export type BasketItem = {
  eventId: Event['id'];
  eventName: Event['name'];
  providers: OddsProvider[];
  homeShortName: string;
  awayShortName: string;
  odd: OddOdd;
  oddType: OddsType;
  oddTypePeriod: OddsTypePeriod;
  eventStatusName: Event['statusName'];
  eventStatus: Event['status'];
  betValue: number | string;
  groups: BasketItemGroup[];
  externalType: string;
  oddId: string;
};

export enum BasketMode {
  Single = 'single',
  Combo = 'combo',
  System = 'system',
}

export type Basket = {
  mode: BasketMode;
  items: BasketItem[];
  groupBetValues: Record<BasketItemGroup, GroupBetValue[]>;
  stakePerBet: number | string;
  stakePerCombo: number | string;
  totalStake: number | string;
};

export type BasketCheckoutItem = {
	events: BasketItem[];
	link: string;
};
