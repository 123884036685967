import React from 'react';

import { TournamentDetailsTabStandings } from '../../Details/TournamentDetailsTabStandings';

import './MobileTournamentDetailsStandingList.scss';


export const MobileTournamentDetailsStandingList = () => {
  return (
    <div className="m-tournament-details-standing-list__container">
      <TournamentDetailsTabStandings />
    </div>
  );
};
