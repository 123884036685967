import { MatchLiveIncident } from 'types';
import { differenceBy } from 'lodash';

import { useAddUpdatedIncidentListToLiveTicker } from './useUpdateLiveTicker';
import { useUpdateIncidentList } from './useUpdateIncidentList';
import { useLiveMatchListContext } from './live-match-list-context';


export const useUpdateLiveIncidentList = () => {
  const { setIncidentList } = useLiveMatchListContext();

  const {
    updateLiveIncidentListWithChangedIncidents,
  } = useUpdateIncidentList(setIncidentList);

  const {
    updateLiveTickerWithSpecificIncident,
  } = useAddUpdatedIncidentListToLiveTicker();

  const handleUpdate = ({ prevState, latestState, stateShouldBeUpdated }: {
    prevState: MatchLiveIncident[];
    latestState: MatchLiveIncident[];
    stateShouldBeUpdated: boolean;
  }) => {
    const areStatesEqual = prevState.every(incident => latestState.some(latestIncident => {
      return incident.id === latestIncident.id && incident.statscoreUt  === latestIncident.statscoreUt;
    }));

    const isAnyDependencyUpdated = !stateShouldBeUpdated &&
      !areStatesEqual;

    if (isAnyDependencyUpdated) {
      const recentlyUpdatedLiveIncidentList = differenceBy(latestState, prevState, 'statscoreUt');

      updateLiveIncidentListWithChangedIncidents({
        recentlyUpdatedLiveIncidentList,
        latestState,
      });

      updateLiveTickerWithSpecificIncident({
        recentlyUpdatedLiveIncidentList,
        latestState,
      });
    }
  };

  return {
    handleUpdate,
  };
};
