import React from 'react';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { BasketMode } from 'types';
import { useTranslate } from 'context/translate-context';
import { useBasket } from 'context/basket/basket-context';
import { isEmpty } from 'lodash';
import ErrorBoundaryWithRollbar from 'views/ErrorBoundaryWithRollbar';
import { EmptyElementFeedback } from 'components';
import { useBasketConfig } from 'context/basket/basket-config-context';

import { mediaQuery } from '../../constants';

import { ExpandedBasket } from './ExpandedBasket';
import { RegularBasket } from './RegularBasket';

import './Basket.scss';


export const Basket = () => {
  const { translate } = useTranslate();
  const { items: basketItems, generatedBets } = useBasket();
  const isMinTabletLandscape = useMediaQuery(mediaQuery.minTabletLandscape);
  const { configuration } = useBasketConfig();
  const tabs = [{
    name: BasketMode.Single,
    label: translate('SINGLE'),
  }, {
    name: BasketMode.Combo,
    label: translate('COMBI'),
  }, {
    name: BasketMode.System,
    label: translate('SYSTEM'),
  }];

  if (!isEmpty(configuration) && configuration?.provider?.name) {
    return (
      <ErrorBoundaryWithRollbar
        FallbackComponent={
          <EmptyElementFeedback text="COULD_NOT_LOAD_CONTENT" />
        }
      >
        {
          isMinTabletLandscape ? (
            <ExpandedBasket
              tabs={tabs}
              basketItems={basketItems}
              generatedBets={generatedBets}
            />
          ) : (
            <RegularBasket
              tabs={tabs}
              basketItems={basketItems}
              generatedBets={generatedBets}
            />
          )
        }
      </ErrorBoundaryWithRollbar>
    );
  }

  return null;
};
