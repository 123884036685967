import { FC, ReactElement, useMemo, useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import Big from 'big.js';
import { useBasket } from 'context/basket/basket-context';
import { Basket } from 'types';

import { useBetPerEvent } from '../../../useBetPerEvent';


type Props = {
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  children(props: {
    onTotalStakeChange: (value: number | string) => void;
  }): ReactElement;
}

export const ComboModeFooterController: FC<Props> = ({ children, setValue, fields }) => {
  const { items: basketItems, setItems } = useBasket();
  const { betPerEvent } = useBetPerEvent();

  const validBets = useMemo(() => {
    const validBets = Object.values(betPerEvent).reduce((acc, count) => {
      if (count > 1) {
        return acc;
      }
      return acc.add(count);
    }, new Big(0));

    return validBets;
  }, [betPerEvent]);

  const totalStake = useMemo(() => {
    const totalStake = fields.items.reduce((acc, i) => {
      if (betPerEvent[i.eventId] > 1) {
        return acc;
      }
      return acc.add(i.betValue || 0);
    }, new Big(0)).toNumber();

    return totalStake;
  }, [betPerEvent, fields]);

  const updateAllBetValues = useCallback((betValue = 0) => {
    // Update field inputs
    fields.items.forEach((i, idx) => {
      setValue(`items.${idx}.betValue` as 'items.0.betValue', betValue.toFixed(2));
    });

    // Update list in context
    const newItems = basketItems.map(item => ({
      ...item,
      betValue: betValue.toFixed(2),
    }));
    setItems(newItems);
  }, [basketItems, fields.items, setValue, setItems]);

  const onTotalStakeChange = useCallback((value = 0) => {
    const totalValue = new Big(value).abs();

    const betValue =  validBets.gt(0) ? totalValue.div(validBets) : new Big(0);

    updateAllBetValues(betValue.toNumber());

    setValue('stakePerBet', betValue.toFixed(2));

    // Remove - from input
    if (value < 0) {
      setValue('totalStake', totalValue.toFixed(2));
    }
  }, [validBets, setValue, updateAllBetValues]);

  useEffect(() => {
    setValue(
      'totalStake',
      totalStake.toFixed(2),
    );
  }, [setValue, totalStake]);

  const props = {
    onTotalStakeChange,
  };

  return children(props);
};
