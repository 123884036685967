import React, { memo, FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { useMatch } from 'context/match-context';
import { Match, Maybe, Noop, GameTeamPosition } from 'types';
import { useMatchesHandlers } from 'views/Dashboard/MatchList/matchListHooks';
import { MatchDetails } from 'views/Dashboard/MatchDetails';
import MatchTeam from 'views/Dashboard/MatchList/Match/Team';

import MatchResult from '../../Result';
import { MatchStatusWithTime } from '../../../../MatchList/Match/MatchStatusWithTime';
import { MatchSideBetsSection } from '../../../../MatchList/Match/MatchSideBetsSection';

import './MatchVariantTournament.scss';
import '../../MatchListElement.scss';


type Props = {
  match: Match;
  handleScrollRef?: Maybe<Noop>;
}

export const MatchVariantTournament: FC<Props> = memo(({ match, handleScrollRef = null }) => {
  const { selectedMatch } = useMatch();
  const { event, home, away } = match;
  const { startDate, status, statusName, clockTime } = event;

  const isMatchSelected = event.id === selectedMatch?.event?.id;
  const handleSelectedClass = isMatchSelected ? 'match-variant-tournament--expanded' : '';
  const { handleSelectedMatch } = useMatchesHandlers();

  return (
    <li
      ref={handleScrollRef}
      key={event.id}
      className={`
        match-variant-tournament__wrapper
        ${handleSelectedClass}
        match-variant-tournament__container--border
      `}
    >
      <div className="match-list-element__bg match-list-element__wrapper">
        <div className="match-variant-tournament__grid">
          <div className="match-variant-tournament__match-details-grid">
            <MatchStatusWithTime
              startDate={startDate}
              status={status}
              statusName={statusName}
              clockTime={clockTime}
            />
            <section className="match-list-element__team-grid">
              <MatchTeam.Desktop team={home} type={GameTeamPosition.Home} />
              <MatchResult.Desktop
                event={event}
                home={home}
                away={away}
              />
              <MatchTeam.Desktop team={away} type={GameTeamPosition.Away} />
              <Icon
                onClick={() => handleSelectedMatch(match)}
                className={`match-list-element__dropdown margin--unset ${isMatchSelected ? 'active-rotate' : ''}`}
                name="chevron down"
                size="large"
              />
            </section>
          </div>
          <div className="match-list-element__bets">
            <MatchSideBetsSection match={match} />
          </div>
        </div>
      </div>
      {isMatchSelected && <MatchDetails />}
    </li>
  );
});
