import { addMonths, isSameMonth, subMonths } from 'date-fns';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { handleCreatePathsForCalendar, handleSelectedDateWhenCurrentDateExceeds } from 'views/Dashboard/MatchList/Filter/Button/matchListFilterCalendarServices';
import { useMatchesFilters } from 'views/Dashboard/MatchList/useMatchesFilters';
import { useMatch } from 'context/match-context';
import { formatDate, addQuery } from 'utils';

import { useGetWeeks } from './calendarHooks';


export const CalendarController = ({ close, children }) => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const { filters: { from: dateFrom } } = useMatchesFilters();
  const { selectedMatch, setSelectedMatch } = useMatch();
  const [currentDate, setCurrentDate] = useState(new Date(dateFrom));

  const {
    currentWeek,
    dateRanges: { rangeStart,  rangeEnd },
    weeks,
    listOfClickableDays,
  } = useGetWeeks({ currentDate });

  const previousMonth = () => {
    const dateInPrevMonth = subMonths(currentDate, 1);
    const handleDate = handleSelectedDateWhenCurrentDateExceeds({ ranges: { rangeStart, rangeEnd }, date: dateInPrevMonth });

    setCurrentDate(handleDate);
  };
  const nextMonth = () => {
    const dateInNextMonth = addMonths(currentDate, 1);
    const handleDate = handleSelectedDateWhenCurrentDateExceeds({ ranges: { rangeStart, rangeEnd }, date: dateInNextMonth });

    setCurrentDate(handleDate);
  };

  const handleOnKeyDown = (event, date) => {
    const ENTER_KEY = 13;

    if (event.keyCode === ENTER_KEY) {
      push(handleCreatePathsForCalendar({ ranges: { rangeStart, rangeEnd }, listOfClickableDays, date, search, pathname, match: selectedMatch }));
      selectedMatch && setSelectedMatch(null);

      return close();
    }
  };

  const handleOnClick = date => {
    push(handleCreatePathsForCalendar({ ranges: { rangeStart, rangeEnd }, listOfClickableDays, date, search, pathname, match: selectedMatch }));
    selectedMatch && setSelectedMatch(null);

    return close();
  };

  const handleClose = () => {
    if (!listOfClickableDays.includes(formatDate(currentDate))) {
      setCurrentDate(new Date(dateFrom));

      return close();
    }

    push({
      pathname,
      search: addQuery(search, { from: formatDate(currentDate), to: formatDate(currentDate) }),
    });

    return close();
  };

  const buttonsState = {
    isPreviousMonthDisabled: isSameMonth(currentDate, rangeStart),
    isNextMonthDisabled: isSameMonth(currentDate, rangeEnd),
  };

  const props = {
    buttonsState,
    close: handleClose,
    currentDate,
    selectedDate: currentDate,
    currentWeek,
    handleOnClick,
    handleOnKeyDown,
    nextMonth,
    previousMonth,
    weeks,
  };

  return children(props);
};


