import { useConfig } from 'context/config-context';
import { ConfigState, MobileNavigationItem } from 'types';

import { defaultNavigationList } from './defaultNavigationList';
import { MobileNavigationLinkBasket } from './MobileNavigationLinkBasket';
import { MobileNavigationLinkModalMenu } from './MobileNavigationLinkModalMenu';


type NavigationElement = {
  Component: () => JSX.Element;
  customStyleClass?: string;
};

export const useMobileNavigationList = (): NavigationElement[] => {
  const { configuration: {
    mobileNavigation = [],
  } } = useConfig();


  const getMobileNavBasketConfigStatus = mobileNavigation?.find(({ label }) => label === MobileNavigationItem.Basket);
  const Basket = getMobileNavBasketConfigStatus?.state === ConfigState.Enabled ? MobileNavigationLinkBasket : null;

  const getMobileNavSettingsConfigStatus = mobileNavigation?.find(({ label }) => label === MobileNavigationItem.Settings);
  const Settings = getMobileNavSettingsConfigStatus?.state === ConfigState.Enabled ? MobileNavigationLinkModalMenu : null;

  const navigationFromConfig = [
    {
      Component: Basket,
      customStyleClass: 'm-navigation__element m-navigation--semi-transparent',
    },
    {
      Component: Settings,
      customStyleClass: 'm-navigation__element',
    },
  ].filter(({ Component }) => Component !== null) as NavigationElement[];


  return navigationFromConfig?.length > 0 ? [...defaultNavigationList, ...navigationFromConfig] : defaultNavigationList;
};
