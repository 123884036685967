const basket = {
  SINGLE: 'Single',
  COMBI: 'Combi',
  SYSTEM: 'System',
  CLOSE: 'Close',
  SYSTEM_GROUP: 'System group',
  WITHOUT_GROUP: 'Without group',
  GROUP: 'Group',
  BANKER: 'Banker',
  SELECT_BET: 'Select your bet',
  BACK: 'Back',
  LINK_CLICKED: 'Link was clicked',
  PLACE_BET: 'Place a bet',
  COMBINATION: 'Combination',
  COMBINATIONS: 'combinations',
  SELECT_COMBINATIONS: 'Select combinations',
  CANT_BET: 'You can\'t place this bet',
  INVALID_VALUE: 'Invalid value of the bet',
  STAKE_PER_COMBO: 'Stake per combo',
  TOTAL_STAKE: 'Total Stake',
  MIN_WIN: 'Min. win:',
  MAX_WIN: 'Max. win:',
  STAKE_PER_BETS: 'Stake per bets',
  TOTAL_ODDS: 'Total Odds',
  EMPTY_TICKET: 'Your ticket is empty.',
  CANT_MULTI_BET: 'Placing multiple bets on the same match is not available for combo and system bets',
  OVERDUE_BET_LIST: 'One or more bets are inactive.\nRemove inactive bet from coupon.',
  TICKET: 'Ticket',
  SYSTEM_OF: 'System of',
  COMBO_BETS: 'combo bets',
};

export default basket;
