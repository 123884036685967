import React from 'react';
import { Loader } from 'semantic-ui-react';

import './LoadingElement.scss';


export const LoadingElement = props => {
  return (
    <div className="loading-element__container margin-top--size-s margin-bottom--size-s">
      <Loader active inline {...props} />
    </div>
  );
};
