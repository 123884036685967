import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import OddsClient  from 'api/OddsClient';
import { Basket, BasketItem, OddsProvider } from 'types';
import { UseFormSetValue } from 'react-hook-form';
import { useBasket } from 'context/basket/basket-context';
import { useBasketConfig } from 'context/basket/basket-config-context';

import { getUniqueList } from '../shared/getUniqueIdList.service';
import { handleGetUpdateList } from '../shared/handleGetUpdateList';


type Props = {
  setFormValue: UseFormSetValue<Basket>;
}

const STALE_TIME_OF_REQUEST = (1000 * 20);

export const useUpdateBasketOddListOnOpen = ({ setFormValue }: Props) => {
  const { items, setItems, isOpen } = useBasket();

  const eventsIds = getUniqueList(items.map(({ eventId }) => eventId));
  const { configuration } = useBasketConfig();
  const { provider: { name: providerName } } = configuration;

  const isAllowedToMakeRequest = [
    isOpen,
    Boolean(eventsIds.length),
    Boolean(providerName),
  ].every(Boolean);

  const providers = [providerName].filter(Boolean) as OddsProvider[];
  const {
    data,
    isLoading,
  } = useQuery(
    ['basketOddList', { eventsIds }],
    () => OddsClient.getSideBetsOdds({ eventsIds, providers }),
    {
      staleTime: STALE_TIME_OF_REQUEST,
      enabled: isAllowedToMakeRequest,
    });

  const oddList = useMemo(() => {
    const result = data?.data?.odds;
    return result || [];
  }, [data]);

  const syncedList = () => {
    const getUpdateList = handleGetUpdateList(providerName);

    if (getUpdateList) {
      return getUpdateList(oddList, items);
    }
    return [];
  };
  const memoSyncedList = useMemo(syncedList, [oddList, items, isLoading, providerName]);

  const addNewItems = (items: BasketItem[]) => setItems(items);
  const memoAddNewItems = useCallback(addNewItems, []);

  useEffect(() => {
    if (isOpen) {
      memoAddNewItems(memoSyncedList);
      setFormValue('items', memoSyncedList);
    }
    // eslint-disable-next-line
  }, [isOpen]);
};
