import { OddGroupingType, BasketItem } from 'types';

import { FindElementProps } from './FindElement';
import { updateBasketItem } from './updateBasketItem.service';


export const getUpdatedList = (findElement: FindElementProps) => (oddList: OddGroupingType[], items: BasketItem[]) => {
  const updatedList: BasketItem[] = items.map(updateBasketItem(findElement)(oddList));

  return updatedList;
};
