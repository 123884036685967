import React, { FC, useState } from 'react';
import { useTranslate } from 'context/translate-context';
import { Icon } from 'semantic-ui-react';

import './BetLink.scss';


type Props = {
  name: string;
  link: string;
};

export const BetLink: FC<Props> = ({ name, link }) => {
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const { translate } = useTranslate();

  return (
    <div className="bet">
      {name}
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="bet__link"
        onClick={() => setIsClicked(true)}
      >
        {translate(isClicked ? 'LINK_CLICKED' : 'PLACE_BET')}
        <div className="bet__arrow">
          <Icon
            name="arrow right"
            className="bet__arrow-content"
          />
        </div>
      </a>
    </div>
  );
};
