import React from 'react';
import { MobileMatchListHeaderOddSection } from 'views/Dashboard/MatchList/Header/OddSection/MobileMatchListHeaderOddSection';

import { MobileMatchList } from './MobileMatchList';

import './MobileMatchListSection.scss';


export const MobileMatchListSection = () => {
  return (
    <div className="m-match-list-section__wrapper">
      <MobileMatchListHeaderOddSection />
      <MobileMatchList />
    </div>
  );
};
