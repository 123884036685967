export const isObjectEmpty = value => {
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false;
};

export const cleanObject = obj => {
  const newObj = { ...obj };

  for (const propName in newObj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  }

  return newObj;
};
