import { isEmpty } from 'lodash';
import { FC, memo } from 'react';
import { useMatchList } from 'context/match-list-context';

import { useUpdateMatchListSideBets } from '../../../shared/hooks/updateMatchListSideBets/useUpdateMatchListSideBets';
import { useMatchesDefaultParams } from '../../matchListHooks';

import { MatchListControllerProps } from './MatchListControllerProps';


export const MatchListLiveController: FC<MatchListControllerProps> = memo(({ children }) => {
  const {
    matches,
    isLoading,
    filters,
  } = useMatchList();

  useMatchesDefaultParams();
  useUpdateMatchListSideBets();

  if (isEmpty(filters)) {
    return null;
  }

  const props = {
    matches,
    filters,
    areMatchesLoading: isLoading,
  };

  return children(props);
});
