
import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { TournamentDetailsHistoricalSeasonsContainer } from 'views/Tournament/Details/TournamentDetailsHistoricalSeasonsContainer';

import { TOURNAMENT_TAB_ROUTING } from '../../Details/Routing/TournamentTabRouting';

import './MobileTournamentDetailsBar.scss';

type Props = {
  badgeUrl: string;
  name: string;
}

export const MobileTournamentDetailsBar: FC<Props> = ({ badgeUrl, name }) => {
  const { pathname } = useLocation();

  return (
    <div className="m-tournament-details-bar__wrapper">
      <div className="m-tournament-details-bar__container">
        <div className="m-tournament-details-bar__title">
          {badgeUrl && (
            <img
              className="m-tournament-details-bar__tournament-badge margin-right--size-s"
              src={badgeUrl}
              alt="tournament badge"
            />
          )}
          {name}
        </div>
        {TOURNAMENT_TAB_ROUTING.isStandingsURL(pathname) && (
          <div className="m-tournament-details-bar__historical-seasons">
            <TournamentDetailsHistoricalSeasonsContainer />
          </div>
        )}
      </div>
    </div>
  );
};
