import React, { FC, useEffect } from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import Big from 'big.js';

import { Basket, BasketItemGroup } from '../../../../types';
import { DEFAULT_BET_VALUE } from '../../../../constants/basket';
import { useTranslate } from '../../../../context/translate-context';
import { Accordion } from '../../../../components';
import { getTotalGroupCombinations } from '../../utils';

import { Bet } from './components/Bet';
import './GroupBets.scss';


type Props = {
  control: Control<Basket>;
  register: UseFormRegister<Basket>;
  group: BasketItemGroup;
  count: number;
}

export const GroupBets: FC<Props> = ({ control, group, count }) => {
  const { translate } = useTranslate();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `groupBetValues.${group}` as const,
  });

  useEffect(() => {
    if (count !== fields.length) {
      if (count > fields.length) {
        const number = fields.length + 1;
        const groupBetValue = {
          number,
          stakeForCombination: DEFAULT_BET_VALUE.toFixed(2),
          stakeForGroup: Big(DEFAULT_BET_VALUE).times(getTotalGroupCombinations(number, count)).toFixed(2),
          selected: false,
        };
        append(groupBetValue);
      } else {
        const lastField = fields.length - 1;
        remove(lastField);
      }
    }
  }, [count, append, remove, fields]);

  if (count === 0) {
    return null;
  }

  return (
    <Accordion
      expanded
      header={(
        <span>
          {`${translate('GROUP')} ${group} (${count})`}
        </span>
      )}
    >
      {fields.map((item: any, index) => (
        <Bet
          key={item.id}
          control={control}
          group={group}
          item={item}
          index={index}
          count={count}
        />),
      )}
    </Accordion>
  );
};
