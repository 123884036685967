
import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { DASHBOARD_ROUTING } from 'views/Dashboard';
import { useTranslate } from 'context/translate-context';


type Props = {
  badgeUrl: string;
  name: string;
}

export const TournamentDetailsHeader: FC<Props> = ({ badgeUrl, name }) => {
  const { translate } = useTranslate();
  const { push } = useHistory();

  return (
    <div className="tournament-details-page__header-wrapper">
      <header className="tournament-details-page__header">
        <button
          className="tournament-details-page__navigate-back"
          onClick={() => push(DASHBOARD_ROUTING.getDefaultDashboardURL())}
        >
          <Icon name="chevron left" className="white" />
          <div className="tournament-details-page__back-text">
            {translate('GO_MAIN')}
          </div>
        </button>
        <div className="tournament-details-page__title">
          {badgeUrl && (
            <img
              className="tournament-details-page__tournament-badge margin-right--size-s"
              src={badgeUrl}
              height={24}
              width={24}
              alt="tournament badge"
            />
          )}
          {name}
        </div>
      </header>
    </div>
  );
};
