import { MatchStatusName, IncidentInternal } from 'types';


export const matchDetailsMatchReportLabelMap = new Map();
matchDetailsMatchReportLabelMap.set(MatchStatusName['1stHalf'], 'FIRST_TIME_LABEL');
matchDetailsMatchReportLabelMap.set(MatchStatusName.Extratime1stHalf, 'FIRST_HALF_EXTRA_TIME_LABEL');
matchDetailsMatchReportLabelMap.set(MatchStatusName.Halftime, 'HALF_TIME_LABEL');
matchDetailsMatchReportLabelMap.set(MatchStatusName.ExtratimeHalftime, 'HALF_TIME_EXTRA_TIME_LABEL');
matchDetailsMatchReportLabelMap.set(MatchStatusName['2ndHalf'], 'SECOND_HALF_LABEL');
matchDetailsMatchReportLabelMap.set(MatchStatusName.Extratime2ndHalf, 'SECOND_HALF_EXTRA_TIME_LABEL');
matchDetailsMatchReportLabelMap.set(MatchStatusName.PenaltyShootout, 'PENALTY_LABEL');

export const getPreviousIncidentEventStatusName = (incidentList: IncidentInternal[], index: number) => {
  const previousRowCompetitionName = incidentList[index - 1]?.eventStatusName;

  if (!previousRowCompetitionName) {
    return null;
  }
  return previousRowCompetitionName;
};
