export const TEAM_SCORE = {
  HOME: 'home',
  DRAW: 'draw',
  AWAY: 'away',
};

export const SIDE_BETS_PREDICTION_VARIANT = {
  XPERT: 'XPERT',
  ODDS: 'ODDS',
};

export const PLAYER_POSITION = {
  GOALKEEPER: 'goalkeeper',
  DEFENDER: 'defender',
  MIDFIELDER: 'midfielder',
  ATTACKER: 'attacker',
};

export const TEAM_FORMATION = {
  DEFAULT: '4-4-2',
};

export const PREDICTION_DISPLAY = {
  PERCENT: 'PERCENTAGE',
  DECIMAL_FRACTION: 'DECIMAL',
};

export const MATCH_TIME = {
  REGULAR_TIME: 'regularTime',
  FIRST_HALF: 'firstHalf',
  SECOND_HALF: 'secondHalf',
  HALF_TIME: 'halftime',
};

export const MATCH_DETAILS_SECTION_KEY = {
  TEAM_FORMATION: 'TEAM_FORMATION',
  MATCH_STATS: 'MATCH_STATS',
  MATCH_REPORT: 'MATCH_REPORT',
  ALL_SIDE_BETS: 'ALL_SIDE_BETS',
  SIDE_BETS: 'SIDE_BETS',
  MATCH_RESULT: 'MATCH_RESULT',
  CORRECT_SCORE: 'CORRECT_SCORE',
  NUMBER_OF_GOALS: 'NUMBER_OF_GOALS',
  THREE_WAY_HANDICAPS: 'THREE_WAY_HANDICAPS',
};
