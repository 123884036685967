import { pipe } from 'utils/pipe';

import { SIDE_BETS_PREDICTION_VARIANT } from '../../../../../constants';


type RangeProps = { rangeStart: number; rangeEnd: number; value: number }
type XpertPredictionProps = Pick<RangeProps, 'rangeStart' | 'rangeEnd'> & { expectedClass: string };

const isValueBetween = ({ rangeStart, rangeEnd, value }: RangeProps) => {
  return rangeStart <= value && value <= rangeEnd;
};

const connectWith = (resultArray: string[]) => ({ rangeStart, rangeEnd, expectedClass }: XpertPredictionProps) => (value: number) => {
  if (isValueBetween({ rangeStart, rangeEnd, value })) {
    resultArray.push(expectedClass);
  }
  return value;
};

const xpertPrediction = (prediction = 0) => {
  const expectedClassResult: string[] = [];
  const roundedPercent = Math.round(prediction * 100);

  const handleInstructions = connectWith(expectedClassResult);

  const setValueToInstructions = pipe(
    handleInstructions({
      rangeStart: 0,
      rangeEnd: 200,
      expectedClass: 'match-prediction__score match-prediction--xpert-highlight',
    }),
  );

  const getHighlightClass = () => setValueToInstructions(roundedPercent);

  getHighlightClass();

  const [expectedClass] = expectedClassResult;

  return expectedClass;
};


export const matchDetailsPrediction = (variant: string) => {
  const componentsVariants = {
    [SIDE_BETS_PREDICTION_VARIANT.XPERT]: xpertPrediction,
  };

  return componentsVariants[variant];
};
