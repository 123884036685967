import React, { ErrorInfo, ReactElement } from 'react';
import Rollbar from 'rollbar';


type Props = {
  FallbackComponent: ReactElement;
}

type State = {
  FallbackComponent: ReactElement;
  hasError: boolean;
}

const rollbar = new Rollbar();

export default class ErrorBoundaryWithRollbar extends React.Component<Props, State> {
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      FallbackComponent: props.FallbackComponent,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error);

    rollbar.error(error);
  }

  render() {
    const { hasError, FallbackComponent } = this.state;

    if (hasError) {
      return FallbackComponent;
    }

    return this.props.children;
  }
}
