import React, { FC, useMemo } from 'react';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { EmptyListFeedback } from 'components';
import { Basket, BasketMode, BasketItem, BasketErrorMessageLabel } from 'types';
import { useBasket } from 'context/basket/basket-context';

import { InvalidBasketFeedback } from '../InvalidBasketFeedback';

import { SingleModeTab } from './SingleModeTab';
import { ComboModeTab } from './ComboModeTab';
import { SystemModeTab } from './SystemModeTab';


type Props = {
  control: Control<Basket>;
  register: UseFormRegister<Basket>;
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  onItemRemove: (item: BasketItem, index: number) => void;
}

export const ModeTab: FC<Props> = ({
  control,
  register,
  setValue,
  fields,
  onItemRemove,
}) => {
  const { errors } = useBasket();
  const isEmpty = fields.items.length === 0;
  const foundMultibetError = errors
    .find(error => error?.title === BasketErrorMessageLabel.CANT_BET);

  const ModeTab = useMemo(() => {
    const controllers = {
      [BasketMode.Single]: SingleModeTab,
      [BasketMode.Combo]: ComboModeTab,
      [BasketMode.System]: SystemModeTab,
    };

    return controllers[fields.mode];
  }, [fields.mode]);

  if (isEmpty) {
    return (
      <EmptyListFeedback text="EMPTY_TICKET" />
    );
  }

  return (
    <div className="basket__container">
      {foundMultibetError ? (
        <InvalidBasketFeedback
          title={foundMultibetError?.title || ''}
          text={foundMultibetError.message}
        />
      ) : (
        <ModeTab
          control={control}
          setValue={setValue}
          register={register}
          onItemRemove={onItemRemove}
          fields={fields}
        />
      )}
    </div>
  );
};
