import React from 'react';
import { isEmpty } from 'lodash';
import { useTranslate } from 'context/translate-context';

import { MATCH_DETAILS_SECTION_KEY } from '../../../../../../constants';
import { MatchDetailsPredictionController } from '../../../Prediction/MatchDetailsPredictionController';
import { MatchDetailsSideBetsOddsLabel } from '../../MatchDetailsSideBetsOddsLabel';
import { OddsType, TeamScore, Odd } from '../../../../../../types';
import { activeTabToOddsPeriod } from '../../matchDetailsSideBetsServices';
import { MatchDetailsPredictionDisplayController } from '../../../Prediction/MatchDetailsPredictionDisplayController.js';
import { MatchDetailsPredictionsSection } from '../../../Prediction/MatchDetailsPredictionsSection';
import { MatchDetailsPredictionsColumnsGrid } from '../../../Prediction/MatchDetailsPredictionsColumnsGrid';
import { MatchDetailsPredictions } from '../../../Prediction/MatchDetailsPredictions';
import { MatchDetailsPredictionRow } from '../../../Prediction/MatchDetailsPredictionRow';
import { MatchDetailsSideBetsOddsProviderContainer } from '../../MatchDetailsSideBetsOddsProviderContainer';
import { EmptyListFeedback } from '../../../../../../components/EmptyListFeedback/EmptyListFeedback.js';


export const sectionOddType = OddsType.Handicap3WX;

const defaultColumnQuantity = 3;
const oneColumn = 1;

export const getOdd = (odds: Odd[]) => (label: string) => {
  return odds.find(odd => odd.typeValue === label);
};

export const getTeamScoreOdds = (odds: Odd[]) => (label: string, teamScore: TeamScore) => {
  return getOdd(odds)(label)?.odds[teamScore];
};


export const MatchDetailsSideBetsThreeWayHandicapDefault = () => {
  const { translate } = useTranslate();

  return (
    <MatchDetailsPredictionDisplayController sectionNameToCheck={MATCH_DETAILS_SECTION_KEY.THREE_WAY_HANDICAPS}>
      <MatchDetailsSideBetsOddsProviderContainer
        oddType={[sectionOddType]}
      >
        {({ oddsByView, isOddInBasket, handleToggleOddInBasket, match }) => {
          return (
            <MatchDetailsPredictionController>
              {({ predictions }) => {
                return (
                  <MatchDetailsPredictionsSection
                    tabsActive
                    label={translate(MATCH_DETAILS_SECTION_KEY.THREE_WAY_HANDICAPS)}
                  >
                    {({ activePredictionTab }) => {
                      const threeWayHandicapsPredictions = predictions?.threeWayHandicap?.[activePredictionTab];
                      const getOddsByActiveTab = oddsByView
                        .filter(odd => odd.typePeriod === activeTabToOddsPeriod.get(activePredictionTab));
                      const getOddByLabel = getTeamScoreOdds(getOddsByActiveTab);
                      const getSideBetWith = getOdd(getOddsByActiveTab);

                      const oddsLabel = !isEmpty(getOddsByActiveTab) ? <MatchDetailsSideBetsOddsLabel /> : null;
                      const allColumnsAreEmpty = Object.values(threeWayHandicapsPredictions || {})
                        .every(column => column.length === 0);

                      if (allColumnsAreEmpty) {
                        return <EmptyListFeedback />;
                      }

                      return (
                        <MatchDetailsPredictionsColumnsGrid
                          columnVariant={String(allColumnsAreEmpty ? oneColumn : defaultColumnQuantity)}
                        >
                          {/* columns */}
                          {threeWayHandicapsPredictions && (Object.keys(threeWayHandicapsPredictions) as TeamScore[])
                            .map(teamScore => {
                              const sideWhichScore = threeWayHandicapsPredictions[teamScore];

                              if (sideWhichScore.length === 0) {
                                return <EmptyListFeedback />;
                              }

                              return (
                                <li
                                  key={teamScore}
                                >
                                  <MatchDetailsPredictions
                                    label={oddsLabel}
                                  >
                                    <>
                                      {sideWhichScore.map(({ label, prediction }) => {
                                        return (
                                          <MatchDetailsPredictionRow
                                            key={`${label} ${prediction}`}
                                            match={match}
                                            label={label}
                                            prediction={prediction}
                                            odd={getOddByLabel(label, teamScore)}
                                            onOddClick={handleToggleOddInBasket({ odd: getSideBetWith(label) })}
                                            isOddInBasket={isOddInBasket}
                                          />
                                        );
                                      })
                                      }
                                    </>
                                  </MatchDetailsPredictions>
                                </li>
                              );
                            })}
                        </MatchDetailsPredictionsColumnsGrid>
                      );
                    }}
                  </MatchDetailsPredictionsSection>
                );
              }}
            </MatchDetailsPredictionController>
          );
        }}
      </MatchDetailsSideBetsOddsProviderContainer>
    </MatchDetailsPredictionDisplayController>
  );
};
