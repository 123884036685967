import React from 'react';
import { useUpdateMatchDetailsSideBets } from 'views/Dashboard/shared/hooks/updateMatchDetailsSideBets/useUpdateMatchDetailsSideBets';

import { MobileMatchDetailsSideBetsDefault } from '../Default/MobileMatchDetailsSideBetsDefault';


export const MobileMatchDetailsSideBetsLive = () => {
  useUpdateMatchDetailsSideBets();

  return (
    <MobileMatchDetailsSideBetsDefault />
  );
};
