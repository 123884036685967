const liveTicker = {
  ARIA_DECREMENT_TIME_BTN: 'Reduser tid-knapp',
  ARIA_INCREMENT_TIME_BTN: 'Øk tid-knapp',
  SET_DISPLAY_TIME: 'Angi visningstid',
  MATCH_STATUS: 'Kampstatus',
  CARDS: 'Kort',
  INCIDENTS_IN_QUEUE: 'Hendelser i kø',
  LIVE_TICKER_SETTINGS: 'Live ticker-innstillinger',
};

export default liveTicker;
