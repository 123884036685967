import { ReactElement, FC } from 'react';
import { useFavouriteList } from 'context/favourite/favourites-context';
import { isObjectEmpty } from 'utils/object';


type Props = {
  tournamentId: string;
  tournamentName: string;
  seasonId: string;
  areaCode: string;
  areaSort: number;
  children(props: {
    handleAddNewFavourite: () => Promise<void>;
    handleRemoveFavourite: () => Promise<void>;
    isFavourite: boolean;
    tournamentName: string;
  }): ReactElement;
}

export const FavouriteController: FC<Props> = ({ tournamentId, tournamentName, seasonId, areaCode, areaSort, children }) => {
  const {
    favourites,
    handleAdd,
    handleRemove,
  } = useFavouriteList();

  const handleAddNewFavourite = handleAdd({ tournamentId, tournamentName, seasonId, areaCode, areaSort });
  const handleRemoveFavourite = handleRemove({ tournamentId, seasonId });

  const foundFavourite = favourites.find(favourite => favourite.tournamentId === tournamentId && favourite.seasonId === seasonId) || {};
  const isFavourite = !isObjectEmpty(foundFavourite);

  return children({
    handleAddNewFavourite,
    handleRemoveFavourite,
    isFavourite,
    tournamentName,
  });
};
