import React from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { RouteParam } from 'views/Tournament/Routing/RouteParam';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { TOURNAMENT_DETAILS } from '../../Routing/Routing';
import { MediaQueriesRedirectController } from '../../../shared/MediaQueriesRedirectController';
import { mediaQuery } from '../../../../constants/mediaQuery';
import { getMobileTournamentDetailsRouteList } from '../../Routing/routes';
import { getTournamentDetailsRouting } from '../../Routing/tournamentDetailsTabLinkListService';


export const MobileTournamentTabRouting = () => {
  const params = useParams<RouteParam>();
  const isDesktop = useMediaQuery(mediaQuery.minDesktopSmall);

  return (
    <Switch>
      {getMobileTournamentDetailsRouteList(params).map(({ path, redirectURL, Component }, index) => {
        return (
          <Route exact key={`mobile tournament details ${index}`} path={path} render={() => (
            <MediaQueriesRedirectController
              isMediaQueryActive={Boolean(redirectURL) && isDesktop}
              url={redirectURL}
            >
              <Component />
            </MediaQueriesRedirectController>
          )}
          />
        );
      })}
    </Switch>
  );
};


const isStandingsURL = (pathname: string) => pathname.includes(TOURNAMENT_DETAILS.STANDINGS);

export const MOBILE_TOURNAMENT_TAB_ROUTING = {
  ...getTournamentDetailsRouting(TOURNAMENT_DETAILS.MOBILE_TOURNAMENT),
  isStandingsURL,
};
