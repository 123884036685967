import React, { ReactElement, FC } from 'react';
import { useMatchDetailsSideBetsOdds } from 'context/match-side-bets-odds-context';
import { Loader } from 'semantic-ui-react';

import './MatchDetailsSideBetsLoadingWrapper.scss';


type Props = {
  children: ReactElement;
}

export const MatchDetailsSideBetsLoadingWrapper: FC<Props> = ({ children }) => {
  const { areOddsLoading } = useMatchDetailsSideBetsOdds();

  if (areOddsLoading) {
    return (
      <div className="match-details-side-bets-loading-wrapper__container">
        <Loader active inline="centered" />
      </div>
    );
  }

  return children;
};
