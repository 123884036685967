import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useMatchList } from 'context/match-list-context';
import { usePrevious } from 'hooks/usePrevious';
import { MatchStatus, MatchStatusName, Match, Maybe } from 'types';
import { useSocketsLiveMatchList } from 'context/sockets/sockets-live-match-list-context';


export const useSocketMatchesData = (selectedMatch: Maybe<Match>) => {
  const { matches } = useMatchList();
  const { matchesSockets } = useSocketsLiveMatchList();
  const prevSockets = usePrevious(matchesSockets);

  const findEvent = useCallback((arr: Match[] = [], prop: Match['event']) => arr.find(el => el.event.id === prop.id), []);

  const getUpdatedMatch = useCallback((match: Match) => {
    const liveMatch = findEvent(matchesSockets, match.event);

    if (liveMatch) {
      return liveMatch;
    }

    const prevLiveMatch = findEvent(prevSockets, match.event);

    if (prevLiveMatch) {
      return {
        ...match,
        event: {
          ...match.event,
          status: MatchStatus.Finished,
          statusName: MatchStatusName.Finished,
        },
      };
    }

    return match;
  }, [matchesSockets, findEvent, prevSockets]);

  const syncedMatches: Match[] = useMemo(() => {
    const updatedMatches = [...matches];

    return updatedMatches.map(match => {
      return getUpdatedMatch(match);
    });
  }, [matches, getUpdatedMatch]);

  const syncedMatch = useMemo(() => {
    if (selectedMatch) {
      return getUpdatedMatch(selectedMatch);
    }
  }, [getUpdatedMatch, selectedMatch]);

  const shouldMatchesBeUpdated = useMemo(() => {
    let isChanged = false;

    syncedMatches.map((match, idx) => {
      if(!isEqual(match, matches[idx])) {
        isChanged = true;
      }
      return match;
    });

    return isChanged;
  }, [syncedMatches, matches]);

  return {
    syncedMatches,
    syncedMatch,
    shouldMatchesBeUpdated,
  };
};
