import { getMatchDetailsRouting } from '../../../shared/MatchDetails/matchDetailsRoutingServices';
import { DASHBOARD_ROUTE } from '../../../Routing/Routing';
import { TabLinkListProps } from '../../../../shared/Routing/TabLink';
import { RouteParam } from '../RouteParam';


const MATCH_DETAILS_ROUTING = getMatchDetailsRouting(DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES);


type Props = TabLinkListProps<RouteParam>;

export const getCategoryLinkList: Props = props => ({ translate, search }) => ([
  {
    text: translate('SIDE_BETS'),
    to: `${MATCH_DETAILS_ROUTING.getSideBetsURL(props)}${search}`,
    exact: true,
  },
  {
    text: translate('STATISTICS'),
    to: `${MATCH_DETAILS_ROUTING.getStatisticsURL(props)}${search}`,
    exact: true,
  },
]);
