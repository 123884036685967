import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import App from './app';
import AppProviders from './context';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';

import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';

ReactDOM.render(
  <AppProviders>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
    <ToastContainer />
  </AppProviders>,
  document.getElementById('root'),
);
