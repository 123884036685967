import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslate } from 'context/translate-context';
import { MOBILE_DASHBOARD_ROUTING } from 'views/Dashboard/Routing/MobileDashboardRouting';
import { ReactComponent as ChampionshipCup } from 'assets/icons/championshipCup.svg';


export const MobileNavigationLinkTournamentList = () => {
  const { search } = useLocation();
  const { translate } = useTranslate();

  return (
    <NavLink
      className="m-navigation__link m-navigation__element"
      to={`${MOBILE_DASHBOARD_ROUTING.getTournamentsSelectURL()}${search}`}
    >
      <ChampionshipCup fill="var(--SaasText01)" width="26px" height="26px" />
      <div className="m-navigation__element-text margin-top--size-s">
        {translate('TOURNAMENTS')}
      </div>
    </NavLink>
  );
};
