import React, { FC, useContext, useState } from 'react';
import { noop } from 'lodash';
import { Noop, LiveTickerOption, Maybe, MatchLiveIncident } from 'types';
import useLocalStorage from 'hooks/useLocalStorage';

import { panelOptionList } from './panelOptionList';


type Props = {
  incrementDisplayLimit: Noop;
  decrementDisplayLimit: Noop;
  toggleIncidentSound: (label: string) => void;
  toggleSelectIncident: (label: string) => void;
  getSelectedIncidentListByCode: (incidentList: MatchLiveIncident[]) => MatchLiveIncident[];
  liveTickerDisplayLimit: number;
  liveDashboardPanel: LiveTickerOption[];
  tickTime: number;
  isControlPanelOpen: boolean;
  setIsControlPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAudio: (audio: Maybe<HTMLAudioElement>) => void;
  audio: Maybe<HTMLAudioElement>;
  incidentsDisplayQueue: MatchLiveIncident[];
  setIncidentsDisplayQueue: React.Dispatch<React.SetStateAction<MatchLiveIncident[]>>;
  isPaused: boolean;
  resumeTicker: () => void;
  pauseTicker: () => void;
}

const LiveTickerContext = React.createContext<Props>({
  incidentsDisplayQueue: [],
  setIncidentsDisplayQueue: noop,
  incrementDisplayLimit: noop,
  decrementDisplayLimit: noop,
  toggleIncidentSound: noop,
  getSelectedIncidentListByCode: () => [],
  toggleSelectIncident: noop,
  liveTickerDisplayLimit: 10,
  liveDashboardPanel: panelOptionList,
  tickTime: 200,
  isControlPanelOpen: false,
  setIsControlPanelOpen: noop,
  audio: null,
  setAudio: noop,
  isPaused: false,
  resumeTicker: noop,
  pauseTicker: noop,
});

const LiveTickerProvider: FC = props => {
  const [liveTickerDisplayLimit, setLiveTickerDisplayLimit] = useLocalStorage<number>('LIVE_TICKER_DISPLAY_LIMIT', 10);
  const [liveDashboardPanel, setLiveDashboardPanel] = useLocalStorage<LiveTickerOption[]>('LIVE_DASHBOARD_PANEL', panelOptionList);
  const [incidentsDisplayQueue, setIncidentsDisplayQueue] = useState<MatchLiveIncident[]>([]);
  const [isControlPanelOpen, setIsControlPanelOpen] = useState<boolean>(false);
  const [audio, setAudio] = useState<Maybe<HTMLAudioElement>>(null);
  const tickerDisplayOptions = {
    min: 10,
    max: 60,
    incrementValue: 10,
    tickTime: 200,
  };

  const toggle = (fieldName: keyof LiveTickerOption) => (label: string) => {
    setLiveDashboardPanel(
      (liveDashboardPanel as LiveTickerOption[])
        .map(option => option.label === label ? { ...option, [fieldName]: !option[fieldName] } : option),
    );
  };

  const toggleIncidentSound = toggle('isMuted');
  const toggleSelectIncident = toggle('isSelected');

  const { min, max, incrementValue, tickTime } = tickerDisplayOptions;

  const incrementDisplayLimit = () => {
    if (liveTickerDisplayLimit < max) {
      setLiveTickerDisplayLimit(liveTickerDisplayLimit + incrementValue);
    }
  };

  const decrementDisplayLimit = () => {
    if (liveTickerDisplayLimit > min) {
      setLiveTickerDisplayLimit(liveTickerDisplayLimit - incrementValue);
    }
  };

  const getSelectedIncidentListByCode = (incidentList: MatchLiveIncident[]) => {
    const selectedCodeList = (liveDashboardPanel as LiveTickerOption[])
      .filter(({ isSelected }) => isSelected)
      .flatMap(({ codeList }) => codeList);

    if (selectedCodeList.length > 0) {
      return incidentList.filter(incident => selectedCodeList.includes(incident.code));
    }
    return incidentList;
  };

  const [isPaused, setIsPaused] = useState<boolean>(false);

  const pauseTicker = () => setIsPaused(true);
  const resumeTicker = () => setIsPaused(false);

  const values = {
    incidentsDisplayQueue,
    setIncidentsDisplayQueue,
    incrementDisplayLimit,
    decrementDisplayLimit,
    liveTickerDisplayLimit,
    tickTime,
    liveDashboardPanel,
    toggleIncidentSound,
    toggleSelectIncident,
    getSelectedIncidentListByCode,
    isControlPanelOpen,
    setIsControlPanelOpen,
    audio,
    setAudio,
    isPaused,
    pauseTicker,
    resumeTicker,
  };

  return (
    <LiveTickerContext.Provider value={values} {...props} />
  );
};

const useLiveTicker = () => {
  const context = useContext(LiveTickerContext);

  if(context === undefined) {
    throw new Error('useLiveTicker must be used within a LiveTickerContext');
  }

  return context;
};

export {
  LiveTickerProvider,
  useLiveTicker,
};
