import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { EmptyListFeedback, LoadingElement, NavigationHeader } from 'components';
import { useLiveMatchListContext } from 'context/liveTicker/live-match-list-context';
import ErrorBoundaryWithRollbar from 'views/ErrorBoundaryWithRollbar';

import { LiveDashboardNavigationHeaderActions } from './Header/LiveDashboardNavigationHeaderActions';
import { LiveDashboardIncidentList } from './IncidentList/LiveDashboardIncidentList';

import './LiveDashboard.scss';


export const LiveDashboard = () => {
  const { goBack } = useHistory();
  const { incidentList, isLoading, setLoading } = useLiveMatchListContext();

  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoading && incidentList.length > 0) {
      setLoading(false);
    }

    if (incidentList.length === 0) {
      setTimeout(() => {
        setLoading(false);
      }, 10 * 1000);
    }
  }, [incidentList.length, isLoading, setLoading]);

  return (
    <section className="live-dashboard">
      <ErrorBoundaryWithRollbar
        FallbackComponent={
          <div className="live-dashboard__list">
            <EmptyListFeedback
              text="COULD_NOT_LOAD_CONTENT"
            />
          </div>
        }
      >
        <>
          <NavigationHeader
            callback={goBack}
            actions={<LiveDashboardNavigationHeaderActions />}
          />
          <div className="live-dashboard__list">
            {isLoading && incidentList.length === 0 && <LoadingElement />}
            {!isLoading && incidentList.length === 0 && <EmptyListFeedback />}
            {!isLoading && incidentList.length > 0 && <LiveDashboardIncidentList incidentList={incidentList} />}
          </div>
        </>
      </ErrorBoundaryWithRollbar>
    </section>
  );
};
