import React, { FC, ReactElement } from 'react';
import { Icon } from 'semantic-ui-react';
import { Noop } from 'types';
import { useTranslate } from 'context/translate-context';

import { LiveContronTimeController } from './LiveContronTimeController';

import './LiveControlTime.scss';


export const LiveControlTime = () => {
  const { translate } = useTranslate();

  return (
    <section className="live-control-time">
      <div className="live-control-time__grid">
        <div className="live-control-time__text">
          {translate('SET_DISPLAY_TIME')}
        </div>
        <LiveContronTimeController>
          {({
            incrementDisplayLimit,
            decrementDisplayLimit,
            liveTickerDisplayLimit,
          }) => {
            return (
              <>
                <LiveControlTimeBtn
                  ariaLabel={translate('ARIA_DECREMENT_TIME_BTN')}
                  callback={decrementDisplayLimit}
                  Icon={
                    <Icon
                      name="minus"
                      size="small"
                      style={{ color: 'var(--SaasElement03)' }}
                      className="margin--unset white"
                    />
                  }
                />
                <div className="live-control-time__text live-control-time__counter">
                  {`${liveTickerDisplayLimit}s`}
                </div>
                <LiveControlTimeBtn
                  ariaLabel={translate('ARIA_INCREMENT_TIME_BTN')}
                  callback={incrementDisplayLimit}
                  Icon={
                    <Icon
                      name="plus"
                      size="small"
                      style={{ color: 'var(--SaasElement03)' }}
                      className="margin--unset white"
                    />
                  }
                />
              </>
            );
          }}
        </LiveContronTimeController>
      </div>
    </section>
  );
};

type Props = {
  callback: Noop;
  Icon: ReactElement;
  ariaLabel: string;
}

export const LiveControlTimeBtn: FC<Props> = ({ callback, Icon, ariaLabel }) => {
  return (
    <button
      aria-label={ariaLabel}
      className="live-control-time__btn"
      onClick={callback}
    >
      {Icon}
    </button>
  );
};
