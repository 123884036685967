import { PlacholderFlag } from 'components';
import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { Area, Maybe } from 'types';

import './TournamentArea.scss';


type Props = {
  id: string;
  name: string;
  handleSelectArea: (area: Area) => void;
  selectedArea: Maybe<Area>;
  area: Area;
  badgeUrl?: string;
};

export const TournamentArea: FC<Props> = ({
  id,
  name,
  handleSelectArea,
  selectedArea,
  area,
  badgeUrl,
  children,
}) => {
  return (
    <li
      key={id}
      className="tournament-area"
    >
      <div
        className="tournament-area__container"
        onClick={() => handleSelectArea(area)}
      >
        <div className="tournament-area__wrapper">
          <PlacholderFlag
            areaBadgeUrl={badgeUrl}
          />
          <div className="tournament-area__name margin-left--size-s">
            {name}
          </div>
        </div>
        <button className="tournament-area__icon">
          <Icon
            name="chevron down"
            className={`margin--unset ${selectedArea?.id === id ? 'active-rotate' : ''}`}
          />
        </button>
      </div>
      {children}
    </li>
  );
};

