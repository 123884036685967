import React from 'react';
import { LiveTicker } from 'components';
import { Switch, Redirect, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { DashboardRouting } from './Dashboard';
import { MobileDashboardRouting } from './Dashboard/Routing/MobileDashboardRouting';
import { DASHBOARD_ROUTE } from './Dashboard/Routing/Routing';
import { LiveDashboardRouting } from './LiveDashboard/Routing/LiveDashboardRouting';
import { LIVE_DASHBOARD_ROUTE } from './LiveDashboard/Routing/Routing';
import { MobileTournamentRouting } from './Tournament/Mobile/Routing/MobileTournamentRouting';
import { TOURNAMENT_ROUTE } from './Tournament/Routing/Routing';
import { TournamentRouting } from './Tournament/Routing/TournamentRouting';
import { UserRouting, USER_ROUTE } from './User';


const withAuthenticationRequiredWrapper = isInjected => comp => !isInjected
  ? withAuthenticationRequired(comp)
  : comp;

const getRoutes = isInjected => [
  {
    Component: withAuthenticationRequiredWrapper(isInjected)(DashboardRouting),
    path: DASHBOARD_ROUTE.DASHBOARD,
  },
  {
    Component: withAuthenticationRequiredWrapper(isInjected)(TournamentRouting),
    path: TOURNAMENT_ROUTE.DEFAULT,
  },
  {
    Component: withAuthenticationRequiredWrapper(isInjected)(UserRouting),
    path: USER_ROUTE.DEFAULT,
  },
];

const AuthRouter = ({ isInjected = false }) => {
  return (
    <Switch>
      {getRoutes(isInjected).map(({ Component, path }) => {
        return (
          <Route key={path} path={path}
            render={() => {
              return (
                <>
                  <Component />
                  <LiveTicker
                    canSeeOptions
                  />
                </>
              );
            }}
          />
        );
      })}
      <Route
        path={TOURNAMENT_ROUTE.MOBILE_DEFAULT}
        component={withAuthenticationRequiredWrapper(isInjected)(MobileTournamentRouting)}
      />
      <Route
        path={`${DASHBOARD_ROUTE.MOBILE}${DASHBOARD_ROUTE.DASHBOARD}`}
        component={withAuthenticationRequiredWrapper(isInjected)(MobileDashboardRouting)}
      />
      <Route
        path={LIVE_DASHBOARD_ROUTE.DEFAULT}
        render={() => {
          const Component = withAuthenticationRequiredWrapper(isInjected)(LiveDashboardRouting);

          return (
            <>
              <Component />
              <LiveTicker
                canSeeRemainingIncidentsInQueue
              />
            </>
          );
        }
        }
      />

      <Redirect from="/" to={DASHBOARD_ROUTE.DASHBOARD_MATCHES} />
    </Switch>
  );
};

export default AuthRouter;
