import React, { useState } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { Calendar } from 'components/Calendar/Calendar.js';
import { useTranslate } from 'context/translate-context';

import { FormatDate } from './FormatDate';
import { MatchListFilterCalendarController } from './MatchListFilterCalendarController';

import './MatchListFilterCalendar.scss';


export const MatchListFilterCalendar = () => {
  const { translate } = useTranslate();

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <MatchListFilterCalendarController>
      {({
        dateFrom,
        isButtonDisabled,
        handlePrevDay,
        handleNextDay,
        selectedDateFromFilters,
      }) => {
        const isDisabledClassActive = ({ prevDay = '', nextDay = '' }: { prevDay?: string; nextDay?: string}) => {
          return isButtonDisabled({ prevDay, nextDay }) ? 'disabled' : '';
        };

        return (
          <section
            className="match-list-calendar__container"
          >
            <button
              aria-label={translate('PREV_DAY')}
              className="match-list-calendar__button"
              onClick={() => isButtonDisabled({ prevDay: dateFrom }) ? null : handlePrevDay()}
            >
              <Icon
                name="chevron left"
                className={`white margin--unset ${isDisabledClassActive({ prevDay: dateFrom })}`}
              />
            </button>
            <button
              aria-label={translate('CALENDAR')}
              className="match-list-calendar__date-picker-button"
              onClick={handleOpen}
            >
              <div className="match-list-calendar__date-text-wrapper match-list-calendar__date-text-wrapper--display-small-phone">
                <div className="match-list-calendar__date-text">
                  {selectedDateFromFilters(FormatDate.CALENDAR_DISPLAY_DATE_FORMAT_DAY_TEXT)}
                </div>
                <div className="match-list-calendar__date-text">
                  {selectedDateFromFilters(FormatDate.CALENDAR_DISPLAY_DATE_FORMAT_DAY_MONTH)}
                </div>
              </div>
              <div className="match-list-calendar__date-text-wrapper match-list-calendar__date-text-wrapper--display-mid-phone">
                <div className="match-list-calendar__date-text match-list-calendar__date-text--border-right">
                  {selectedDateFromFilters(FormatDate.CALENDAR_DISPLAY_DATE_FORMAT_DAY_TEXT)}
                </div>
                <div className="match-list-calendar__date-text">
                  {selectedDateFromFilters(FormatDate.CALENDAR_DISPLAY_DATE_FORMAT_DAY_MONTH)}
                </div>
              </div>
              <div className="match-list-calendar__date-text-wrapper--display-desktop">
                {selectedDateFromFilters(FormatDate.CALENDAR_DISPLAY_DATE_FORMAT)}
              </div>
            </button>
            <button
              aria-label={translate('NEXT_DAY')}
              className="match-list-calendar__button"
              onClick={() => isButtonDisabled({ nextDay: dateFrom }) ? null : handleNextDay()}
            >
              <Icon
                name="chevron right"
                className={`white margin--unset ${isDisabledClassActive({ nextDay: dateFrom })}`}
              />
            </button>
            <Modal
              className="calendar-modal"
              open={isOpen}
              onClose={handleClose}
            >
              <div className="match-list-calendar__date-picker">
                <Calendar close={handleClose} />
              </div>
            </Modal>
          </section>
        );
      }}
    </MatchListFilterCalendarController>
  );
};
