const matchDetails = {
  SEASON: 'Season',
  STADIUM: 'Stadium',
  DRAW: 'D',
  FORM: 'Form',
  GOALS_AGAINST: 'GA',
  GOALS_DIFF: 'GD',
  GOALS_FOR: 'GF',
  LEAGUE_TABLE: 'League table',
  LOSE: 'L',
  NEXT_MATCH: 'Next match',
  POINTS: 'P',
  WIN: 'W',
  STATISTICS: 'Statistics',
  TBA: 'TBA',
  MORE_GOALS: 'Goals more than',
  H_A_D: 'Home - Draw - Away',
  PLAYED: 'Matches played',
  H_WINS: 'Home wins',
  DRAWS: 'Draws',
  A_WINS: 'Away wins',
  GOALS: 'Goals',
  NO_GOALS: 'Number of goals',
  GOALS_MATCH: 'Goals per match',
  GOALS_HOME: 'Goals scored at home',
  GOALS_AWAY: 'Goals scored away',
  SUBSTITUES: 'Substitutes', // MATCHDETAILS SUMMARY
  LINE_UP: 'Starting line-up',
  ODDS: 'Odds', // MATCHDETAILS SIDEBETS
  ODD: 'Odd',
  TEAM_FORMATION: 'Team formation',
  MATCH_STATS: 'Match stats',
  MATCH_REPORT: 'Match report',
  ALL_SIDE_BETS: 'All',
  SIDE_BETS: 'Side bets',
  OUTCOME: 'Match result',
  MATCH_RESULT: 'Match result',
  NUMBER_OF_GOALS: 'Number of goals',
  THREE_WAY_HANDICAPS: 'Three way handicaps',
  ZERO_GOALS: 'No goals', // MATCHDETAILS  SIDEBETS NUMBER OF GOALS
  UNDER: 'Under',
  OVER: 'Over',
  GOALS_FACTOR: 'Goals factor', // MATCHDETAILS  SIDEBETS CORRECT SCORE
  CORRECT_SCORE: 'Correct score',
  HANDICAP_3W_X: 'Three way handicaps',
  TOTAL_X: 'Number of goals',
  MATCH_DETAILS: 'Match Details',
  FULL_TIME: 'Full time', // MATCHDETAILS PREDICTION
  FIRST_HALF: 'First half',
  SECOND_HALF: 'Second half',
  XPERT: 'Xpert',
  'Goals 76-90': 'Goals 76-90', // MATCHDETAILS STATS
  'Attacks': 'Attacks',
  'Red cards': 'Red cards',
  'Red card': 'Red card',
  'Yellow card': 'Yellow card',
  'Offsides': 'Offsides',
  'Fouls': 'Fouls',
  'Free kicks': 'Free kicks',
  'Penalties unsuccessful': 'Penalties unsuccessful',
  'Shots off target': 'Shots off target',
  'Penalties saved': 'Penalties saved',
  'Corners': 'Corners',
  'Throw-ins': 'Throw-ins',
  'Goals 16-30': 'Goals 16-30',
  'Shots on target': 'Shots on target',
  'Ball possession percentage': 'Ball possession percentage',
  'Dangerous free kicks': 'Dangerous free kicks',
  'Penalties given': 'Penalties given',
  'Goals 1-15': 'Goals 1-15',
  'Goals 46-60': 'Goals 46-60',
  'Dangerous attacks': 'Dangerous attacks',
  'Shots woodwork': 'Shots woodwork',
  'Shots blocked': 'Shots blocked',
  'Yellow cards': 'Yellow cards',
  'Own goals': 'Own goals',
  'Goal kicks': 'Goal kicks',
  'Total shots': 'Total shots',
  'Formation': 'Formation',
  'Goals 31-45': 'Goals 31-45',
  'Penalties scored': 'Penalties scored',
  'Penalties missed': 'Penalties missed',
  'Goals 61-75': 'Goals 61-75',
  'Substitutions': 'Substitutions',
  'expensive': 'Because this service is too expensive', // CONST ACCOUNT
  'Lacking functionality': 'Because I would like to have some additional functionalities',
  'Another company': 'Another company offers the same functionality',

  PENALTY_SAVED_BY_GOAL_KEEPER: 'pen-saved-by-gk', // CONST MATCH
  PENALTY_CANCELLED: 'penalty-cancelled',
  POSSIBLE_CARD_CANCELLED: 'possible-card-cancelled',
  POSSIBLE_PENALTY: 'possible-penalty',
  PENALTY_GOAL: 'penalty-goal',
  POSSIBLE_CARD: 'possible-card',
  MISSED_PENALTY: 'missed-penalty',
  POSSIBLE_GOAL: 'possible-goal',
  GOAL_CANCELLED: 'goal-cancelled',
  HALF_1: 'half-1',
  OWN_GOAL: 'own-goal',
  CORNER: 'corner',
  PENALTY: 'penalty',
  YELLOW_CARD: 'yellow-card',
  RED_CARD: 'red-card',
  GOAL: 'goal',
  SUB_IN: 'sub-in',
  SUB_OUT: 'sub-out',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  TBD: 'tbd',
  GOALKEEPER: 'goalkeeper',
  DEFENDER: 'defender',
  MIDFIELDER: 'midfielder',
  ATTACKER: 'attacker',
  REGULAR_TIME: 'regularTime',
  HALF_TIME: 'halftime',
  FIRST_TIME_LABEL: 'Kick off',
  HALF_TIME_LABEL: 'Half time',
  SECOND_HALF_LABEL: '2nd half',
  FIRST_HALF_EXTRA_TIME_LABEL: '1st Half extra time',
  SECOND_HALF_EXTRA_TIME_LABEL: '2nd Half extra time',
  HALF_TIME_EXTRA_TIME_LABEL: 'Half time extra time',
  PENALTY_LABEL: 'Penalty shootout',
  OUR_TYPE: 'Our type', // component XpertType
  NO_ODDS_AVAILABLE: 'No odds available',
  NO_PREDICTION_AVAILABLE: 'No predictions available',
};

export default matchDetails;
