import React, { memo } from 'react';
import { MatchDetailsSideBetsOddsProvider } from 'context/match-side-bets-odds-context';

import { MatchDetailsRedirect } from '../../shared/Match/MatchDetailsRedirect';

import { MatchDetailsSidebar } from './Sidebar/MatchDetailsSidebar';
import { MatchDetailsController } from './MatchDetailsController';
import { MatchDetailsRouting, MATCH_DETAILS_ROUTING } from './Routing/MatchDetailsRouting';
import { MatchDetailsMatchStatsListController } from './MatchStats/MatchDetailsMatchStatsListController';

import './MatchDetails.scss';


export const MatchDetails = memo(() => {
  return (
    <MatchDetailsController>
      {({ selectedMatch, hasAnyLineup, predictions }) => (
        <MatchDetailsSideBetsOddsProvider>
          <MatchDetailsMatchStatsListController>
            {() => (
              <section className="match-details__grid margin-top--size-xs">
                <MatchDetailsRedirect link={MATCH_DETAILS_ROUTING} />
                <MatchDetailsSidebar
                  match={selectedMatch}
                  hasAnyLineup={hasAnyLineup}
                  predictions={predictions}
                />
                <div className="match-details__content">
                  <MatchDetailsRouting />
                </div>
              </section>
            )}
          </MatchDetailsMatchStatsListController>
        </MatchDetailsSideBetsOddsProvider>
      )}
    </MatchDetailsController>
  );
});
