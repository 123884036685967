import { Handlers } from '../../Handlers';
import { getEventGroupingTypeFromSockets } from '../../shared/getEventGroupingTypeFromSockets';
import { updateMatchDetailsOddGroupingTypeList } from '../../shared/updateMatchDetailsOddGroupingTypeList';

import { updateMatchDetailsOddList } from './updateMatchDetailsOddList.service';


export const updateMatchDetailsSideBetsHandlersBySvenskaSpel: Handlers = {
  updateMatchDetailsOddGroupingTypeList,
  getEventGroupingTypeFromSockets,
  updateMatchDetailsOddList,
};
