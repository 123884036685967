import React from 'react';
import { useParams } from 'react-router';
import { TournamentProvider } from 'context/tournament-context';
import { NavigationHeader } from 'components';
import { MobileCategoryLinkList } from 'views/shared/Mobile/Category/MobileCategoryLinkList';

import { TournamentDetailsController } from '../shared/TournamentDetailsController';
import { MOBILE_DASHBOARD_ROUTING } from '../../Dashboard/Routing/MobileDashboardRouting';
import { MobileMatchDetailsLinkListController } from '../../Dashboard/Mobile/MatchDetails/shared/MobileMatchDetailsLinkListController';
import { RouteParam } from '../Routing/RouteParam';

import { getMobileTournamentDetailsTabLinkList } from './mobileTournamentDetailsTabListLinks';
import { MobileTournamentDetailsBar } from './Bar/MobileTournamentDetailsBar';
import { MobileTournamentTabRouting } from './Routing/MobileTournamentTabRouting';

import '../Details/TournamentDetails.scss';
import './MobileTournamentDetails.scss';


export const MobileTournamentDetails = () => {
  const params = useParams<RouteParam>();

  return (
    <TournamentProvider>
      <NavigationHeader
        path={MOBILE_DASHBOARD_ROUTING.getDefaultDashboardURL()}
      />
      <MobileMatchDetailsLinkListController
        getLinks={getMobileTournamentDetailsTabLinkList(params)}
      >
        {({ linkList }) => (
          <MobileCategoryLinkList categoryTabList={linkList} />
        )}
      </MobileMatchDetailsLinkListController>
      <TournamentDetailsController>
        {tournamentProps => (
          <MobileTournamentDetailsBar {...tournamentProps} />
        )}
      </TournamentDetailsController>
      <div className="m-tournament-details__container">
        <div className="m-tournament-details__page">
          <MobileTournamentTabRouting />
        </div>
      </div>
    </TournamentProvider>
  );
};
