import React from 'react';
import { TeamScore } from 'types';

import { useTranslate } from '../../../context/translate-context';

import { MatchDetailsTeamLineupsPlayer } from './MatchDetailsTeamLineupsPlayer';
import { MatchDetailsSummaryWrapperTeamLineupsPlayerController } from './MatchDetailsTeamLineupsPlayerController';


import './MatchDetailsTeamLineups.scss';


const CLASSES = {
  TEAM_NAME: teamScore => `match-details-line-ups__team-name match-details-line-ups--${teamScore}`,
  LINE_UP_SUBTITLE: teamScore =>`match-details-line-ups__line-up-subtitle match-details-line-ups--${teamScore}`,
  PLAYERS: teamScore => `match-details-line-ups__players match-details-line-ups--${teamScore}`,
  SUBSTITUTES_SUBTITLE: teamScore =>  `match-details-line-ups__substitutes-title match-details-line-ups--${teamScore}`,
};

export const MatchDetailsTeamLineups = ({ team, teamLineup = {}, teamScore = TeamScore.Home }) => {
  const { translate } = useTranslate();
  const { teamDetails = {} } = team;
  const { displayedName = '', shortName = '' } = teamDetails;
  const { lineup = [], bench = [] } = teamLineup;

  return (
    <MatchDetailsSummaryWrapperTeamLineupsPlayerController>
      {({ getIncidentsConnectedToPlayerName }) => (
        <section className="match-details-line-ups__team">
          <h3 className={`${CLASSES.TEAM_NAME(teamScore)} margin-bottom--size-s`}>
            {displayedName || shortName}
          </h3>
          {lineup.length > 0 && (
            <>
              <h4 className={`${CLASSES.LINE_UP_SUBTITLE(teamScore)} margin-bottom--size-s`}>
                {translate('LINE_UP')}
              </h4>
              <ul className={`${CLASSES.PLAYERS(teamScore)} margin-bottom--size-m`}>
                {lineup.map(player => {
                  const { id, name, shortName, shirt } = player;

                  return (
                    <MatchDetailsTeamLineupsPlayer
                      key={id}
                      teamScore={teamScore}
                      name={name}
                      shortName={shortName}
                      shirtNumber={shirt}
                      incidents={getIncidentsConnectedToPlayerName(name)}
                    />
                  );
                })}
              </ul>
            </>
          )}
          {bench.length > 0 && (
            <>
              <h4 className={`${CLASSES.SUBSTITUTES_SUBTITLE(teamScore)} margin-bottom--size-s`}>
                {translate('SUBSTITUTES')}
              </h4>
              <ul className={CLASSES.PLAYERS(teamScore)}>
                {bench.map(player => {
                  const { id, name, shortName, shirt } = player;

                  return (
                    <MatchDetailsTeamLineupsPlayer
                      key={id}
                      teamScore={teamScore}
                      name={name}
                      shortName={shortName}
                      shirtNumber={shirt}
                      incidents={getIncidentsConnectedToPlayerName(name)}
                    />

                  );
                })}
              </ul>
            </>
          )}
        </section>
      )}
    </MatchDetailsSummaryWrapperTeamLineupsPlayerController>
  );
};

