import React, { FC, useCallback, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

import './Accordion.scss';


type Props = {
  expanded?: boolean;
  header: React.ReactNode;
  headerClass?: string;
}

export const Accordion: FC<Props> = ({ expanded = false, header, children, headerClass }) => {
  const [open, setOpen] = useState(expanded);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  useEffect(() => {
    if (expanded) {
      setOpen(expanded);
    }
  }, [expanded]);

  return (
    <div className={`accordion ${open ? 'accordion--expanded' : 'accordion--closed'}`}>
      <div
        className={`accordion__header ${headerClass ? headerClass : ''}`}
        role="button"
        onClick={toggle}
      >
        {header}
        <Icon
          name="chevron down"
          className={`margin--unset ${open ? 'active-rotate' : ''}`}
        />
      </div>
      <div className="accordion__content">
        {children}
      </div>
    </div>
  );
};
