import React, { FC, ReactElement } from 'react';

import { TabLink } from '../../Routing/TabLink';

import './MobileTabList.scss';


type Props = {
  linkList: TabLink[];
  children(props: TabLink): ReactElement;
}

export const MobileTabList: FC<Props> = ({ linkList, children }) => {
  return (
    <section className="m-tab-list__section">
      <ul className="m-tab-list__grid">
        {linkList.map(props => children(props))}
      </ul>
    </section>
  );
};
