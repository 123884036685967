import { FC, ReactElement, useCallback, useEffect } from 'react';
import { isUndefined } from 'lodash';
import { ProviderAppConfig } from 'types';

import { useConfig } from './context/config-context';
import { useTranslate } from './context/translate-context';


type Props = {
  configuration?: ProviderAppConfig;
  children: ReactElement;
}

export const ConfigController: FC<Props> = ({ configuration, children }) => {
  const {
    setConfiguration,
    setConfigFilters,
  } = useConfig();
  const { setLanguage } = useTranslate();

  const handleSetConfig = () => {
    if (configuration) {
      setConfiguration(configuration);
      setConfigFilters(configuration.filters);

      if(configuration.lang) {
        setLanguage(configuration.lang);
      }
    }
  };

  const memoHandleSetConfig = useCallback(handleSetConfig, [!isUndefined(configuration)]);

  useEffect(() => {
    memoHandleSetConfig();
  }, [memoHandleSetConfig]);


  return children;
};
