import { RouteParam } from 'views/Tournament/Routing/RouteParam';
import { TOURNAMENT_DETAILS } from 'views/Tournament/Routing/Routing';

import { TournamentDetailsTabStatistics } from '../Details/TournamentDetailsTabStatistics';
import { MobileTournamentDetailsStandingList } from '../Mobile/StandingList/MobileTournamentDetailsStandingList';

import { getTournamentDetailsRouting } from './tournamentDetailsTabLinkListService';
import { TOURNAMENT_ROUTE } from './Routing';


const getDesktopTournamentDetailsURLByProps = getTournamentDetailsRouting(TOURNAMENT_DETAILS.TOURNAMENT);

export const getMobileTournamentDetailsRouteList = (params: RouteParam) => {
  return [
    {
      path: TOURNAMENT_ROUTE.MOBILE_STANDINGS,
      redirectURL: getDesktopTournamentDetailsURLByProps.getTournamentStandingsTabURL(params),
      Component: MobileTournamentDetailsStandingList,
    },
    {
      path: TOURNAMENT_ROUTE.MOBILE_STATISTICS,
      redirectURL: getDesktopTournamentDetailsURLByProps.getTournamentStatisticsTabURL(params),
      Component: TournamentDetailsTabStatistics,
    },
  ];
};

