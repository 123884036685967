export enum AreaConfederation {
  Asian = 'AFC',
  African = 'CAF',
  NorthAndCentralAmerica = 'CONCACAF',
  SouthAmerica = 'CONMEBOL',
  Oceania = 'OFC',
  Europe = 'UEFA',
}

export type Area = {
  id: string;
  code: string;
  name: string;
  badgeThumbUrl?: string;
  confederation?: string;
  confederationName?: string;
  badgeUrl?: string;
  countCompetitions?: number;
  areas?: Area[];
  areaSort: number;
}


export enum AreaSort {
  AREA_SORT_ASC = 'areaSort:asc',
  AREA_SORT_DESC = 'areaSort:desc',
}
