export enum CountryLanguage {
  English = 'en',
  Swedish = 'sv',
  Norwegian = 'no',
}

export enum CountryCurrency {
  UE = '€',
  International = '$',
  Sweden = 'kr',
  Norway = 'kr',
}
