import { FC, ReactElement } from 'react';
import { addDays, subDays } from 'date-fns';
import { useHistory, useLocation } from 'react-router';
import { Noop } from 'types';
import { formatDate } from 'utils';
import { useTranslate } from 'context/translate-context';
import { useMatch } from 'context/match-context';

import { useMatchesFilters } from '../../useMatchesFilters';

import { handleCreatePathsForCalendar } from './matchListFilterCalendarServices';
import { FormatDate } from './FormatDate';
import { formatCalendarDateMap } from './formatDateServices';


type Props = {
  children(props: {
    dateFrom: string;
    selectedDateFromFilters: (dateFormat: FormatDate) => string;
    handleNextDay: Noop;
    handlePrevDay: Noop;
    isButtonDisabled: (props: {prevDay?: string; nextDay?: string}) => boolean;
    isDisabledClassActive: (props: {prevDay: string; nextDay: string}) => string;
  }): ReactElement;
}

export const MatchListFilterCalendarController: FC<Props> = ({ children }) => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const { selectedMatch, setSelectedMatch } = useMatch();
  const { filters } = useMatchesFilters();
  const { from: dateFrom } = filters;
  const { translate } = useTranslate();

  // button handlers
  const tenDaysBeforeToday = formatDate(subDays(new Date(), 10));
  const tenDaysAfterToday = formatDate(addDays(new Date(), 10));

  const isButtonDisabled = ({ prevDay, nextDay }: { prevDay?: string; nextDay?: string }) => {
    return tenDaysBeforeToday === prevDay || tenDaysAfterToday === nextDay;
  };

  const isDisabledClassActive = ({ prevDay, nextDay }: { prevDay: string; nextDay: string }) => {
    return isButtonDisabled({ prevDay, nextDay }) ? 'disabled' : '';
  };


  const handlePrevDay = () => {
    const currentDateFrom = subDays(new Date(dateFrom), 1);

    push(handleCreatePathsForCalendar({ date: currentDateFrom, search, pathname, match: selectedMatch }));
    selectedMatch && setSelectedMatch(null);
  };

  const handleNextDay = () => {
    const currentDateFrom = addDays(new Date(dateFrom), 1);

    push(handleCreatePathsForCalendar({ date: currentDateFrom, search, pathname, match: selectedMatch }));
    selectedMatch && setSelectedMatch(null);
  };

  const selectedDateFromFilters = (dateFormat: FormatDate) => {
    const formatCalendarDate = formatCalendarDateMap.get(dateFormat)(translate);

    return formatCalendarDate(dateFrom);
  };

  const props = {
    dateFrom,
    selectedDateFromFilters,
    handleNextDay,
    handlePrevDay,
    isButtonDisabled,
    isDisabledClassActive,
  };

  return children(props);
};
