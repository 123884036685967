import React from 'react';

import { MatchDetailsSideBetsHandleWhenUpdate } from '../MatchDetailsSideBetsHandleWhenUpdate';

import { MatchDetailsSideBetsMatchResultDefault } from './Default/MatchDetailsSideBetsMatchResultDefault';
import { MatchDetailsSideBetsMatchResultLive } from './Live/MatchDetailsSideBetsMatchResultLive';


export const MatchDetailsSideBetsMatchResultSection = () => {
  return (
    <MatchDetailsSideBetsHandleWhenUpdate
      ComponentWithLiveUpdate={MatchDetailsSideBetsMatchResultLive}
    >
      <MatchDetailsSideBetsMatchResultDefault />
    </MatchDetailsSideBetsHandleWhenUpdate>
  );
};
