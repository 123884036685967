import React, { FC } from 'react';
import { MatchSection } from 'types';
import { MatchDetailsPredictionDisplayController } from 'views/Dashboard/MatchDetails/Prediction/MatchDetailsPredictionDisplayController';
import { MatchDetailsSideBetsLoadingWrapper } from 'views/Dashboard/MatchDetails/SideBets/MatchDetailsSideBetsLoadingWrapper';

import { MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper } from '../../../MatchDetails/SideBets/MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper';


type Props = {
  SectionList: ({
    Component: () => JSX.Element;
    label: MatchSection;
} | {
    Component: React.MemoExoticComponent<() => JSX.Element>;
    label?: undefined;
})[];
}

export const MatchDetailsAllSideBetsList: FC<Props> = ({ SectionList }) => {
  return (
    <MatchDetailsSideBetsLoadingWrapper>
      <>
        {SectionList.map(({ Component, label }) => {
          if (!label) {
            return (
              <MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper
                key={MatchSection.GenericOdds}
              >
                <li>
                  <Component />
                </li>
              </MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper>
            );
          }

          return (
            <MatchDetailsPredictionDisplayController
              key={label}
              sectionNameToCheck={label}
            >
              <MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper>
                <li>
                  <Component />
                </li>
              </MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper>
            </MatchDetailsPredictionDisplayController>
          );
        })}
      </>
    </MatchDetailsSideBetsLoadingWrapper>
  );
};

