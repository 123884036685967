import React, { FC } from 'react';
import { ReactComponent as Logo } from 'assets/images/sportxpert-logo-mark-light.svg';
import { useTranslate } from 'context/translate-context';

import './LivetTickerFeedbackPlaceholder.scss';


type Props = {
  text: string;
}

export const LivetTickerFeedbackPlaceholder: FC<Props> = ({ text }) => {
  const { translate } = useTranslate();

  return (
    <div className="live-ticker-feedback-placeholder">
      <div className="live-ticker-feedback-placeholder__container">
        <div className="live-ticker-feedback-placeholder__icon">
          <Logo viewBox="0 0 150 142" />
        </div>
        <div className="live-ticker-feedback-placeholder__text">
          {translate(text)}
        </div>
      </div>
    </div>
  );
};
