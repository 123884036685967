import React, { FC, Fragment } from 'react';
import { Popup } from 'components';
import { IncidentInternal, IncidentState, IncidentCode, TeamScore } from 'types';

import { getMinutesFromMatch } from '../../../shared/Incident/incidentServices';

import {
  getIncidentAndMargin,
  handleDisplayPlayerNameAndSubstitute,
} from './matchDetailsMatchReportIncidentsServices';
import { MatchDetailsMatchReportIncidentBar } from './MatchDetailsMatchReportIncidentBar';
import { getPreviousIncidentEventStatusName, matchDetailsMatchReportLabelMap } from './matchDetailsMatchReportServices';

import './MatchDetailsMatchReportIncidents.scss';


type Props = {
  incidents: IncidentInternal[];
};

export const MatchDetailsMatchReportIncidents: FC<Props> = ({ incidents }) => {
  return (
    <section className="match-report-incidents__section">
      <ul className="match-report-incidents__list">
        {incidents.map((incident, index) => {
          const { eventStatusName } = incident;
          const IncidentComponent = (
            <Incident
              key={incident.id}
              incident={incident}
            />
          );

          if (eventStatusName && eventStatusName !== getPreviousIncidentEventStatusName(incidents, index)) {
            return (
              <Fragment key={index}>
                <MatchDetailsMatchReportIncidentBar
                  label={matchDetailsMatchReportLabelMap.get(eventStatusName)}
                />
                {IncidentComponent}
              </Fragment>
            );
          }
          return IncidentComponent;
        })}
      </ul>
    </section>
  );
};


const Incident: FC<{ incident: IncidentInternal }> = ({ incident }) => {
  const { id, teamOrigin, player, confirmation, name, occurredAt, code } = incident;

  const playerName = player ? `${player?.firstName || ''} ${player.lastName}` : '';

  const time = (
    <span className="match-report-incidents__time margin-left--size-s margin-right--size-s">
      {getMinutesFromMatch(occurredAt)}
    </span>
  );

  const handleDisplayTogetherSubsitutes = IncidentCode.SubIn !== code ? 'margin-bottom--size-xxs' : '';
  const handleDisplayedPopupName = confirmation === IncidentState.Cancelled ? `${name} ${confirmation}` : name;

  const incidentPopup = (
    <Popup
      content={handleDisplayedPopupName}
      trigger={getIncidentAndMargin({ incidentCode: code, status: confirmation })}
    />
  );

  return (
    <li key={id} className={`${handleDisplayTogetherSubsitutes} match-report-incidents__incident match-report-incidents__incident--${teamOrigin}`}>
      <div className={`match-report-incidents__team match-report-incidents__team--${teamOrigin}`}>
        {teamOrigin === TeamScore.Away && incidentPopup}
        {teamOrigin === TeamScore.Away && time}
        <div className="match-report-incidents__player-container ">
          <div className={`match-report-incidents__player match-report-incidents__player--${teamOrigin}`}>{handleDisplayPlayerNameAndSubstitute(playerName, code)}</div>
        </div>
        {teamOrigin === TeamScore.Home && time}
        {teamOrigin === TeamScore.Home && incidentPopup}
      </div>
    </li>
  );
};
