import React from 'react';
import { useTranslate } from 'context/translate-context';

import { MatchDetailsMatchStatsListRow } from './MatchDetailsMatchStatsListRow';

import './MatchDetailsMatchStatsList.scss';


export const MatchDetailsMatchStatsList = props => {
  const { statistics: { commonStatistics } } = props;
  const { translate } = useTranslate();

  return (
    <div className="match-details-stats__container">
      <ul className="match-details-stats__list">
        {commonStatistics.map(({ name, home, away }) => (
          <MatchDetailsMatchStatsListRow key={`${name} ${home} ${away}`} homeValue={home} label={translate(name)} awayValue={away} />
        ))}
      </ul>
    </div>
  );
};
