import React, { useContext, useState, FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { noop } from 'lodash';
import { useMatchesFilters } from 'views/Dashboard/MatchList/useMatchesFilters';
import { Area, SportsName, Maybe } from 'types';

import AreaClient from '../api/AreaClient';


type Props = {
  areas: Area[];
  areAreasLoading: boolean;
  selectedCountry: Maybe<Area>;
  selectedContinent: Maybe<Area>;
  setSelectedCountry: (area: Maybe<Area>) => void;
  setSelectedContinent: (area: Maybe<Area>) => void;
  sportName: SportsName;
}

const AreaContext = React.createContext<Props>({
  areAreasLoading: false,
  areas: [],
  selectedCountry: null,
  selectedContinent: null,
  setSelectedCountry: noop,
  setSelectedContinent: noop,
  sportName: SportsName.Soccer,
});

const AreaProvider: FC = props => {
  const [selectedContinent, setSelectedContinent] = useState<Maybe<Area>>(null);
  const [selectedCountry, setSelectedCountry] = useState<Maybe<Area>>(null);
  const { filters: params } = useMatchesFilters();
  const { sportName } = params;
  const [selectedSportName] = sportName;

  const {
    isLoading: areAreasLoading,
    data,
  } = useQuery('areaList', () => AreaClient.getAreaList(selectedSportName), {
    enabled: Boolean(selectedSportName),
  });

  const areas: Area[] = useMemo(() => {
    return data?.data?.areas || [];
  }, [data]);

  const values = {
    areas,
    areAreasLoading,
    selectedCountry,
    selectedContinent,
    setSelectedCountry,
    setSelectedContinent,
    sportName: selectedSportName,
  };

  return (
    <AreaContext.Provider
      value={values}
      {...props}
    />
  );
};


const useArea = () => {
  const context = useContext(AreaContext);

  if(context === undefined) {
    throw new Error('useArea must be used within a AreaProvider');
  }

  return context;
};


export {
  AreaProvider,
  useArea,
};
