import React, { memo } from 'react';
import { useUpdateMatchDetailsSideBets } from 'views/Dashboard/shared/hooks/updateMatchDetailsSideBets/useUpdateMatchDetailsSideBets';

import { MatchDetailsSideBetsMatchResultDefault } from '../Default/MatchDetailsSideBetsMatchResultDefault';


export const MatchDetailsSideBetsMatchResultLive = memo(() => {
  useUpdateMatchDetailsSideBets();

  return (
    <MatchDetailsSideBetsMatchResultDefault />
  );
});
