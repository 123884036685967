import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { useMatch } from 'context/match-context';
import { Match, Maybe, Noop, GameTeamPosition } from 'types';
import { useMatchesHandlers } from 'views/Dashboard/MatchList/matchListHooks';
import { MatchDetails } from 'views/Dashboard/MatchDetails';
import { TOURNAMENT_ROUTING } from 'views/Tournament/Routing/TournamentRouting';
import MatchTeam from 'views/Dashboard/MatchList/Match/Team';

import { MatchSideBetsSection } from '../../../../MatchList/Match/MatchSideBetsSection';
import MatchResult from '../../Result';
import { MatchVariantKickOffCountry } from '../../../../MatchList/MatchVariantKickOffCountry';
import { MatchStatusWithTime } from '../../../../MatchList/Match/MatchStatusWithTime';

import './MatchVariantKickOff.scss';
import '../../MatchListElement.scss';


type Props = {
  match: Match;
  handleScrollRef?: Maybe<Noop>;
};

export const MatchVariantKickOff: FC<Props> = ({ match, handleScrollRef = null }) => {
  const { selectedMatch } = useMatch();
  const { event, season, home, away } = match;
  const isMatchSelected = event.id === selectedMatch?.event?.id;
  const handleSelectedClass = isMatchSelected ? 'match-variant-kick-off--expanded' : '';
  const { handleSelectedMatch } = useMatchesHandlers();
  const { startDate, status, statusName, clockTime } = event;

  return (
    <li
      ref={handleScrollRef}
      key={event.id}
      className={`match-variant-kick-off
      match-variant-kick-off__wrapper
      match-variant-kick-off__container--border
      ${handleSelectedClass}`}
    >
      <div className="match-list-element__bg match-list-element__wrapper">
        <MatchVariantKickOffCountry
          season={season}
          getRedirectURL={TOURNAMENT_ROUTING.getDefaultTournamentURL}
        />
        <div className="match-variant-kick-off__grid">
          <div className="match-variant-kick-off__match-details-grid">
            <MatchStatusWithTime
              startDate={startDate}
              status={status}
              statusName={statusName}
              clockTime={clockTime}
            />
            <section className="match-list-element__team-grid">
              <MatchTeam.Desktop team={home} type={GameTeamPosition.Home} />
              <MatchResult.Desktop
                event={event}
                home={home}
                away={away}
              />
              <MatchTeam.Desktop team={away} type={GameTeamPosition.Away} />
              <Icon
                onClick={() => handleSelectedMatch(match)}
                className={`match-list-element__dropdown margin--unset ${isMatchSelected ? 'active-rotate' : ''}`}
                name="chevron down"
                size="large"
              />
            </section>
          </div>
          <div className="match-list-element__bets">
            <MatchSideBetsSection match={match} />
          </div>
        </div>
      </div>
      {isMatchSelected && <MatchDetails />}
    </li>
  );
};
