import React, { FC, memo, ReactElement } from 'react';
import { useLocation } from 'react-router';
import { TeamScore, MatchLiveIncident } from 'types';
import { getMinutesFromMatch } from 'views/shared/Incident/incidentServices';
import { MATCH_DETAILS_ROUTING } from 'views/Dashboard/MatchDetails/Routing/MatchDetailsRouting';
import { addQuery } from 'utils/queries';
import { Link } from 'react-router-dom';

import { LiveMatchTournament } from './Tournament/LiveMatchTournament';
import { LiveMatchIncident } from './Incident/LiveMatchIncident';
import { LiveMatchTeam } from './Team/LiveMatchTeam';

import './LiveMatch.scss';


type Props = {
  incident: MatchLiveIncident;
  MatchResult: ReactElement;
  DequeueIncident?: ReactElement;
}

export const Match: FC<Props> = memo(({ incident, MatchResult, DequeueIncident }) => {
  const { search } = useLocation();
  const { match, player, confirmation, code, name } = incident;
  const { home, away, season, event } = match;
  const { teamDetails: homeTeamDetails } = home;
  const { teamDetails: awayTeamDetails } = away;
  const { teamDetails: { badgeUrl, acronym } } = match[incident.teamOrigin as TeamScore.Home | TeamScore.Away];

  const link = `${MATCH_DETAILS_ROUTING.getTeamFormationURL({
    eventId: event.id, seasonId: season.id,
  })}?${addQuery(search, { season: [season.id] })}`;

  const handleGridDisplay = `live-match__grid--${DequeueIncident ? 'col-3' : 'col-2'}`;

  return (
    <div className="live-match__container">
      <div className={`live-match__grid ${handleGridDisplay}`}>
        <div className="live-match__time-and-match">
          <LiveMatchTournament
            season={season}
          />
          <Link
            className="live-match__teams"
            to={link}
          >
            <LiveMatchTeam teamDetails={homeTeamDetails} whoPlays={TeamScore.Home} />
            {MatchResult}
            <LiveMatchTeam teamDetails={awayTeamDetails} whoPlays={TeamScore.Away} />
          </Link>
        </div>
        <div className="live-match__incident-section">
          <LiveMatchIncident
            name={name}
            time={getMinutesFromMatch(incident.occurredAt)}
            confirmation={confirmation}
            incidentCode={code}
            badgeUrl={badgeUrl}
            player={player}
            acronym={acronym}
          />
        </div>
        {DequeueIncident || null}
      </div>
    </div>
  );
});

