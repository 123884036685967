import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { TournamentDetails } from '../Details/TournamentDetails';

import { TOURNAMENT_ROUTE, TOURNAMENT_DETAILS } from './Routing';


export const TournamentRouting = () => {
  return (
    <Switch>
      <Route path={TOURNAMENT_ROUTE.DEFAULT} component={TournamentDetails} />
    </Switch>
  );
};

const getDefaultTournamentURL = (tournamentId: string) => {
  return `${TOURNAMENT_DETAILS.TOURNAMENT}/${tournamentId}${TOURNAMENT_DETAILS.STANDINGS}`;
};

export const TOURNAMENT_ROUTING = {
  getDefaultTournamentURL,
};
