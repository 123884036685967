import React, { FC } from 'react';
import { useTranslate } from 'context/translate-context';
import { BasketCheckoutItem } from 'types';

import { BetLink } from './../BetLink';


type Props = {
  combinations: BasketCheckoutItem[];
};

export const BetLinksList: FC<Props> = ({ combinations }) => {
  const { translate } = useTranslate();

  return (
    <ul>
      {combinations.map((combination, idx) => (
        <li
          key={combination.link}
        >
          <BetLink
            name={`${translate('COMBINATION')} ${idx + 1}`} link={combination.link}
          />
        </li>
      ))}
    </ul>
  );
};
