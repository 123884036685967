import { useMatchList } from 'context/match-list-context';
import React, { FC } from 'react';
import { MatchFilters } from 'types';

import { MatchListHeaderBetsSection } from '../MatchListHeaderBetsSection';

import { useListWidth } from './useListWidth';
import { useStickyTabletHeader } from './useStickyTabletHeader';
import { transformHeaderVariantByMatchSortKey } from './common';

import './MobileMatchListHeaderOddSection.scss';


export const MobileMatchListHeaderOddSection: FC = () => {
  const { filters } = useMatchList();
  const { order } = filters as MatchFilters;
  const getKey = transformHeaderVariantByMatchSortKey[order];
  const { isSticky, handleScrollRef } = useStickyTabletHeader();

  const { elementWidth } = useListWidth('.m-match-list');
  const width = `${elementWidth}px` || '100%';
  const handleVisibility = `m-match-list-header-odd-section-wrapper-${getKey}--${isSticky ? 'fixed' : 'hidden'}`;

  const StickyHeader = (
    <section
      className={`m-match-list-header-odd-section-wrapper-${getKey} ${handleVisibility}`}
    >
      <Bar width={width} />
    </section>
  );

  return (
    <section
      ref={handleScrollRef}
      className={`m-match-list-header-odd-section-wrapper-${getKey}`}
    >
      {StickyHeader}
      {isSticky ? <div className="m-match-list-header-odd-section__placeholder" /> : <Bar width={width} />}
    </section>
  );
};

const Bar = ({ customClass, width }: { customClass?: string; width: string }) => {
  const handleClassName = `m-match-list-header-odd-section ${customClass || ''} m-match-list-header-odd-section__container m-match-list-header-odd-section__grid`;

  return (
    <div
      className={handleClassName}
      style={{ width }}
    >
      <div />
      <MatchListHeaderBetsSection />
    </div>
  );
};
