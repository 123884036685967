import React, { FC, ReactElement } from 'react';
import { useTranslate } from 'context/translate-context';
import { Maybe } from 'types';

import { handleDisplayGrid } from './matchDetailsPredictionsGridService';

import './MatchDetailsPrediction.scss';


type Props = {
  disabledLabels?: boolean;
  isPredictionDisabled?: boolean;
  label?: Maybe<JSX.Element>;
  children?: ReactElement;
};

export const MatchDetailsPredictions: FC<Props> = ({
  disabledLabels,
  label,
  isPredictionDisabled,
  children,
}) => {
  const { translate } = useTranslate();

  return (
    <div className="match-details-prediction__container">
      <div className={`match-details-prediction__header ${handleDisplayGrid(isPredictionDisabled)}`}>
        {!disabledLabels && (
          <>
            <div className="match-details-prediction--second-column">
              {label}
            </div>
            {!isPredictionDisabled && (
              <div className="match-details-prediction__header-label match-details-prediction--third-column">
                {translate('XPERT')}
              </div>
            )}
          </>
        )}
      </div>
      <ul className="match-details-prediction__list">
        {children}
      </ul>
    </div>
  );
};
