import React, { FC, ReactElement } from 'react';

import { TournamentName } from './TournamentName';

import './TournamentNameFavouriteVariant.scss';


type Props = {
  displayedName: string;
  Flag?: ReactElement;
};

export const TournamentNameFavouriteVariant: FC<Props> = ({ displayedName, Flag }) => {
  return (
    <div className="tournament-name-favourite-variant__container">
      {Flag}
      <div className="margin-left--size-s">
        <TournamentName
          customClass="tournament-name-favourite-variant__name"
          displayedName={displayedName}
        />
      </div>
    </div>
  );
};
