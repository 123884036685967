import React from 'react';
import { useParams } from 'react-router';
import { NavigationHeader, LoadingElement } from 'components';
import { MobileMatchDetailsProviders } from 'context/AuthProviders';
import { MobileCategoryLinkList } from 'views/shared/Mobile/Category/MobileCategoryLinkList';

import { DASHBOARD_ROUTE } from '../../Routing/Routing';
import { MatchDetailsRedirect } from '../../../shared/Match/MatchDetailsRedirect';
import { MOBILE_DASHBOARD_ROUTING } from '../../Routing/MobileDashboardRouting';

import { getCategoryLinkList } from './Category/matchDetailsCategoryLinkList';
import { MobileMatchDetailsController } from './MobileMatchDetailsController';
import { MobileMatchDetailsRouting } from './MobileMatchDetailsRouting';
import { MobileMatchDetailsLinkListController } from './shared/MobileMatchDetailsLinkListController';
import { RouteParam } from './RouteParam';


export const MobileMatchDetails = () => {
  const params = useParams<RouteParam>();

  return (
    <MobileMatchDetailsProviders>
      <section>
        <MobileMatchDetailsController>
          {({ isLoading }) => (
            <>
              <NavigationHeader
                searchQuery={{}}
                path={DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES}
              />
              <MatchDetailsRedirect link={MOBILE_DASHBOARD_ROUTING} />
              <MobileMatchDetailsLinkListController
                getLinks={getCategoryLinkList(params)}
              >
                {({ linkList }) => (
                  <MobileCategoryLinkList
                    categoryTabList={linkList}
                  />
                )}
              </MobileMatchDetailsLinkListController>
              {isLoading ? <LoadingElement /> : <MobileMatchDetailsRouting />}
            </>
          )}
        </MobileMatchDetailsController>
      </section>
    </MobileMatchDetailsProviders>
  );
};
