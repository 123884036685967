import React, { FC, useMemo, useEffect, useState } from 'react';
import { useBasket } from 'context/basket/basket-context';
import { useTranslate } from 'context/translate-context';

import { ReactComponent as CartIcon } from '../../assets/images/icon-cart.svg';

import { MAX_NUMBER_OF_ITEM_COUNT } from './const';

import './Cart.scss';


type Props = {
  isLabelVisible?: boolean;
  customTextClass?: string;
}

export const Cart: FC<Props> = ({ isLabelVisible = false, customTextClass = '' }) => {
  const { items, openBasket } = useBasket();
  const { translate } = useTranslate();
  const [ bounce, setBounce ] = useState(true);
  const count = useMemo(() => {
    if (items.length > MAX_NUMBER_OF_ITEM_COUNT) {
      return `${MAX_NUMBER_OF_ITEM_COUNT}+`;
    }
    return items.length;
  }, [items.length]);

  useEffect(() => {
    setBounce(!bounce);
  }, [items.length]);

  return (
    <button className="cart"
      onClick={openBasket}
      data-bounce={bounce}
      onAnimationEnd={() => setBounce(false)}
    >
      <div className="cart__container">
        <CartIcon className="icon-color--first" />
        <div className="cart__count">
          {count}
        </div>
      </div>
      {isLabelVisible && (
        <div className={`${customTextClass} margin-top--size-s`}>
          {translate('BETS')}
        </div>
      )}
    </button>
  );
};
