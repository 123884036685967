import { FC, ReactElement, useMemo } from 'react';
import minBy from 'lodash/minBy';
import Big from 'big.js';

import { Basket } from '../../../../types';


type Props = {
  fields: Basket;
  children(props: {
    minPrize: string;
    maxPrize: string;
    totalOdds: string;
  }): ReactElement;
}


export const SingleModeFooterResultsController: FC<Props> = ({ children, fields }) => {
  const totalOdds = useMemo(() => {
    if(fields.items.length === 0) {
      return 0;
    }

    const initialOddValue = new Big(0);
    const sum = fields.items.reduce((acc, curr) => {
      return acc.add(curr.odd.odds);
    }, initialOddValue);

    return sum.toNumber();
  }, [fields.items]);

  const minPrize = useMemo(() => {
    if (fields.items.length === 0) {
      return 0;
    }

    const minOddBet = minBy(fields.items, item => item.odd.odds);

    if (!minOddBet) {
      return new Big(0).toNumber();
    }

    return new Big(minOddBet?.betValue || 0).times(minOddBet.odd.odds).toNumber();
  }, [fields.items]);


  const maxPrize = useMemo(() => {
    if (fields.items.length === 0) {
      return 0;
    }

    const initialPrizeValue = new Big(0);

    const sum = fields.items.reduce((acc, curr) => {
      const betMaxPrize = new Big(curr.odd.odds).times(curr?.betValue || 0);

      return acc.add(betMaxPrize);
    }, initialPrizeValue);

    return sum.toNumber();
  }, [fields.items]);


  const props = {
    minPrize: minPrize.toFixed(2),
    maxPrize: maxPrize.toFixed(2),
    totalOdds: totalOdds.toFixed(2),
  };

  return children(props);
};
