import { FindElementProps } from '../../../../FindElement';


export const findElementById: FindElementProps = (oddList, basketItem) => {
  const foundEvent = oddList.find(({ event }) => {
    return event.id === basketItem.eventId;
  });

  const foundOdd = foundEvent?.odds
    .find(odd => odd.id === basketItem.oddId);

  const foundOddOdd = foundOdd?.odds &&
    Object.values(foundOdd.odds)
      .find(({ id }) => id === basketItem.odd.id);

  return {
    foundEvent,
    foundOddOdd,
  };
};
