import { FC, ReactElement } from 'react';
import { Match, GameTeam, PlayerInLineup } from 'types';
import { useMatch } from 'context/match-context';

import { transformLineupsToLineupsAndBench } from './matchDetailsTeamLineupsControllerServices';


type Props = {
  children: (props: {
    home: { lineup: PlayerInLineup[]; bench: PlayerInLineup[] };
    away: { lineup: PlayerInLineup[]; bench: PlayerInLineup[] };
    selectedMatch: Match;
    selectHome?: GameTeam;
    selectAway?: GameTeam;
  }) => ReactElement;
};

export const MatchDetailsTeamLineupsController: FC<Props> = ({ children }) => {
  const {
    selectHomeLineups,
    selectAwayLineups,
    selectedMatch,
    selectHome,
    selectAway,
  } = useMatch();

  if (!selectedMatch) {
    return null;
  }

  const props = {
    home: transformLineupsToLineupsAndBench(selectHomeLineups),
    away: transformLineupsToLineupsAndBench(selectAwayLineups),
    selectedMatch,
    selectHome,
    selectAway,
  };

  return children(props);
};
