import { Maybe } from '../Util';

import { Match, Event } from './Match';
import { TeamScore } from './MatchState.enum';


type Player = {
  id: string;
  firstName?: string;
  lastName: string;
  name: string;
};

export type Incident = {
  code: IncidentCode;
  confirmation?: IncidentState;
  eventStatusName?: Event['statusName'];
  id: string;
  name: string;
  occurredAt: string;
  createdAt: number;
  statscoreIncidentId: number;
  statscoreParticipantId: number;
  statscoreUt: number;
  // player
  responsible: Maybe<string>;
  player: Maybe<Player>;
};

export type IncidentInternal = {
  teamOrigin: TeamScore;
} & Incident;

export type MatchLiveIncident = {
  match: Match;
} & IncidentInternal;

export enum IncidentState {
  Confirmed = 'confirmed',
  Cancelled = 'cancelled',
  Tbd = 'tbd',
}

export enum IncidentCode {
  PenaltySavedByGoalKeeper = 'pen-saved-by-gk',
  PenaltyCancelled = 'penalty-cancelled',
  PossibleCardCancelled = 'possible-card-cancelled',
  PossiblePenalty = 'possible-penalty',
  PenaltyGoal = 'penalty-goal',
  PossibleCard = 'possible-card',
  MissedPenalty = 'missed-penalty',
  PossibleGoal = 'possible-goal',
  GoalCancelled = 'goal-cancelled',
  // eslint-disable-next-line @typescript-eslint/camelcase
  Half_1 = 'half-1',
  OwnGoal = 'own-goal',
  Corner = 'corner',
  Penalty = 'penalty',
  YellowCard = 'yellow-card',
  RedCard = 'red-card',
  Goal = 'goal',
  SubIn = 'sub-in',
  SubOut = 'sub-out',
}
