import React from 'react';
import { Loader } from 'semantic-ui-react';


export const ButtonBase = ({ classPrefix }) =>
  ({ text, callback, isPending, isDisabled, customStyles, type }) => {
    const isDisabledState = isPending || isDisabled;
    const isLoadingStateActive = isPending ? `${classPrefix}--loading` : '';

    return (
      <button
        type={type || null}
        disabled={isDisabledState}
        className={`${classPrefix}__btn ${isLoadingStateActive || ''} ${customStyles || ''}`}
        onClick={callback}
      >
        {isPending && <Loader active inline  size="small" />}
        <span className={isPending ? `${classPrefix}__text--loading` : ''}>{text}</span>
      </button>
    );
  };
