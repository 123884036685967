import React, { FC, memo } from 'react';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import { MatchLiveIncident } from 'types';
import LiveIncident from 'components/Live/Match';

import { getResultByCountingIncidents } from '../liveDashboardServices';

import './LiveDashboardIncidentList.scss';


type Props = {
  incidentList: MatchLiveIncident[];
}

export const getPreviousRowMatchName = ({ incidentList, index }: PreviousRowMatchNameProps) => {
  const previousRowMatchName = incidentList[index - 1]?.match.event.name;
  if (!previousRowMatchName) {
    return null;
  }
  return previousRowMatchName;
};

export const LiveDashboardIncidentList: FC<Props> = memo(({ incidentList }) => {
  const getResult = getResultByCountingIncidents(incidentList);

  return (
    <TransitionGroup
      component="ul"
      className="live-dashboard-incident-list"
    >
      {incidentList.map((incident, index) => {
        const { id } = incident;
        const { name: eventName } = incident.match.event;

        if (eventName !== getPreviousRowMatchName({ incidentList, index })) {
          return (
            <CSSTransition
              key={id}
              timeout={250}
              classNames="item"
            >
              <li
                key={id}
                className="live-dashboard-incident-list__incident"
              >
                <LiveIncident.Match
                  incident={incident}
                  MatchResult={
                    <LiveIncident.MatchResult
                      teamOrigin={incident.teamOrigin}
                      incidentCode={incident.code}
                      result={getResult(incident)}
                      confirmation={incident?.confirmation}
                    />
                  }
                />
              </li>
            </CSSTransition>
          );
        }

        return (
          <CSSTransition
            key={id}
            timeout={250}
            classNames="item"
          >
            <li
              key={id}
              className="live-dashboard-incident-list__incident"
            >
              <LiveIncident.AggregatedIncident incident={incident} />
            </li>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
});

type PreviousRowMatchNameProps = {
  incidentList: MatchLiveIncident[];
  index: number;
}
