import { FC, ReactElement } from 'react';
import { useLiveMatchListContext } from 'context/liveTicker/live-match-list-context';


type Props = {
  children(props: {
    incidentsRemaining: number;
  }): ReactElement;
};

export const LiveTickerShortcutController: FC<Props> = ({ children }) => {
  const {
    incidentList,
  } = useLiveMatchListContext();

  const props = {
    incidentsRemaining: incidentList.length,
  };

  return children(props);
};


