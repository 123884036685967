import React, { memo, FC } from 'react';
import { MatchStatus, Event } from 'types';
import { showMatchResultWhenStateIsEqualTo } from 'views/Dashboard/MatchList/matchListServices';

import { useCompareScores } from '../../hooks/useCompareScores';

import './MatchResult.scss';


type Props = {
  firstHalfScore: string;
  regularTimeScore: string;
  status: Event['status'];
}

export const MobileMatchResult: FC<Props> = memo(({ firstHalfScore, regularTimeScore, status }) => {
  const isFinished = MatchStatus.Finished === status ? 'match-result__state--finished' : '';
  const isLive = MatchStatus.Live === status ? 'match-result__state--live' : '';

  const { isScoreAtFH, isScoreAtRT } = useCompareScores({ firstHalfScore, regularTimeScore });

  const handleRecentGoalClass = (isRecent: boolean) => isRecent ? 'match-result__result--recent-score' : '';

  return (
    <div className={'match-result__score'}>
      {
        showMatchResultWhenStateIsEqualTo([MatchStatus.Live, MatchStatus.Finished], status) &&
        <div className={'match-result__row'}>
          <div className={`match-result__result--size margin-right--size-s ${isFinished} ${isLive}`}>
            <span
              className={handleRecentGoalClass(isScoreAtRT)}
            >
              {regularTimeScore}
            </span>
          </div>
          <div className="match-result__result--first-half-size match-result--flex-row">
          (
            <div className={handleRecentGoalClass(isScoreAtFH)}>
              {firstHalfScore}
            </div>
          )
          </div>
        </div>
      }
    </div>
  );
});
