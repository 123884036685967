import { useRef, useEffect, useCallback } from 'react';

const useOnBlur = setClose => {
  const ref = useRef(null);

  const handleClick = useCallback(e => {
    if (ref.current?.contains(e.target)) {
      return;
    }
    setClose();
  }, [setClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return {
    ref,
    handleClick,
  };
};

export default useOnBlur;
