import { useBasket } from 'context/basket/basket-context';
import { BasketItem, BasketErrorMessage } from 'types';


type Props = {
  item: BasketItem;
}

export const useFindBasketError = ({ item }: Props): { handleFoundError?: BasketErrorMessage } => {
  const { errors } = useBasket();

  const foundEventIdError = errors
    .find(error => error?.invalidEventIdList?.includes(item.eventId));

  const foundOddIdError = errors
    .find(error => error?.invalidOddIdList?.includes(item.odd.id));

  const handleFoundError = foundEventIdError || foundOddIdError;

  return {
    handleFoundError,
  };
};
