import React from 'react';

import { MatchListFilterSection } from './MatchListFilterSection';
import MatchListHeaderOddSection from './Header/OddSection/MatchListHeaderOddSection';
import { MatchList } from './List/MatchList';

import './MatchListSection.scss';


export const MatchListSection = () => {
  return (
    <div className="match-list-section__wrapper">
      <MatchListFilterSection />
      <MatchListHeaderOddSection />
      <MatchList />
    </div>
  );
};
