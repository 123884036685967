const formFields = {
  ACTIVATION_CODE: 'Aktiveringskod',
  BIRTH_DATE: 'Födelsedatum',
  EMAIL: 'E-post',
  FIRST_NAME: 'Förnamn',
  LAST_NAME: 'Efternamn',
  PASSWORD: 'Efternamn',
  REPEATED_PASSWORD: 'Upprepat lösenord',
  TERMS_AND_POLICY: 'Användarvillkor och sekretesspolicy',
  CREATE_YOUR_ACCOUNT: 'Skapa ditt konto',
  FORGOT_YOUR_PASSWORD: 'Glömt ditt lösenord?',
  LOGIN_WITH_FACEBOOK: 'Logga in med Facebook',
  LOGIN_WITH_GOOGLE: 'Logga in med Google',
  SIGN_UP_WITH_FACEBOOK: 'Registrera dig med Facebook',
  SIGN_UP_WITH_GOOGLE: 'Registrera dig med Google',
  SIGN_UP_TO_UNLOCK: 'Registrera dig för att låsa upp',
  LOGIN: 'Logga in',
  OR: 'eller',
  SIGN_UP: 'Registrera dig',
  FORGOT_PASSWORD_TITLE: 'Glömt ditt lösenord? Oroa dig inte! ',
  RESET_MESSAGE_CONFIRMATION: 'Ett meddelande har skickats till dig som e-post med instruktioner om hur du återställer ditt lösenord.',
  RESET_MESSAGE_HINT: 'Skriv adressen som är länkad till ditt konto. Vi skickar e-post till dig för att återställa ditt lösenord. De kan hamna i din skräppostmapp, så kolla också där. ',
  RETURN_TO_LOGIN: 'Återgå till inloggning',
  RESET_PASSWORD: 'Återställ lösenord',
  CHECK_YOUR_EMAIL: 'Kontrollera e-postmeddelandet som vi just skickade till ',
  FOR_ACTIVATION: ' för att aktivera ditt konto',
  CREATING_ACCOUNT: 'Att skapa ett konto betyder att du godkänner vårt',
  TERMS_OF_SERVICE: 'Användarvillkor',
  OUR: 'vårt',
  PRIVACY_POLICY: 'Sekretesspolicy',
  USER_HAVE_AN_ACCOUNT: 'Har du redan ett konto?',
  NEW_PASSWORD: 'Nytt lösenord',
  REPEAT_PASSWORD: 'Upprepa lösenord',
  CHANGE_PASSWORD: 'Ändra lösenord',
  ACTIVE_NOW: 'Ditt konto är aktivt nu',
  EMAIL_ADDRESS: 'E-postadress',
  FEMALE: 'Kvinna',
  GENDER: 'Kön',
  LOGOUT: 'Logga ut',
  MALE: 'Man',
  SAVE_CHANGES: 'Spara ändringar',
  UNSPECIFIED: 'Ospecificerad',
  DATA_CHANGED: 'Kontodata har ändrats!',
  OTHER: 'annat',
  GO_MAIN: 'Gå till huvudsidan',
  NO_DATA: 'Ingen data tillgänglig',
};

export default formFields;
