import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Device } from 'types';
import { DashboardSocketsController } from 'views/Dashboard/shared/DashboardSocketsController';
import { MatchListOpenedMatchHandlerController } from 'views/Dashboard/MatchList/MatchListOpenedMatchHandlerController';
import { MatchListVariant } from 'views/Dashboard/MatchList/MatchListVariant';
import { MatchListMissingSelectedTournamentListSection } from 'views/Dashboard/MatchList/Missing/MatchListMissingSelectedTournamentListSection';

import { MatchListLiveController } from '../Controller/MatchListLiveController';

import '../MatchList.scss';


export const MatchListLiveUpdate = () => {
  return (
    <DashboardSocketsController>
      <div className="match-list">
        <MatchListOpenedMatchHandlerController>
          <MatchListLiveController>
            {props => {
              const {
                matches,
                filters,
                areMatchesLoading,
              } = props;

              return (
                <>
                  {areMatchesLoading &&
                    <Loader active inline="centered" />
                  }
                  {matches.length > 0 && (
                    <MatchListVariant
                      deviceVariant={Device.Desktop}
                      matches={matches}
                      order={filters.order}
                    />
                  )}
                  <MatchListMissingSelectedTournamentListSection
                    matchListLength={matches.length}
                    areMatchesLoading={areMatchesLoading}
                  />
                </>
              );
            }}
          </MatchListLiveController>
        </MatchListOpenedMatchHandlerController>
      </div>
    </DashboardSocketsController>
  );
};
