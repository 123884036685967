const dashboardLayout = {
  CLEAR_TOURNAMENTS: 'Clear tournaments',
  FAVOURITES: 'Favourites', // DASHBOARD - SIDEBAR
  LIVE: 'Live', // DASHBOARD - MATCH
  ENDED: 'Ended',
  ODDS_FILTER: 'Select odds',
  POSTPONED: 'Postponed',
  KICK_OFF: 'Kick off',
  FAVOURITE_BTN: 'Add to favourite',
  'Kick off (first)': 'Kick off (first)', // DASHBOARD MATCH SORT
  'Kick off (last)': 'Kick off (last)',
  'Tournaments (first)': 'Tournaments (first)',
  'Tournaments (last)': 'Tournaments (last)',
  'Bet ratio (ascending)': 'Bet ratio (ascending)',
  'Bet ratio (descending)': 'Bet ratio (descending)',
  SORT_EVENTS: 'Sort events by',
  SHOW_XPERT: 'Show Xpert as',
  PERCENTAGE: 'Percentage',
  DECIMAL: 'Decimal',
  RATIO: 'Bet ratio',
  FILTERS_RESET: 'Filters have been reset to their default values',
  TOURNAMENT_LIST_CLEARED: 'Tournaments were cleared',
  FILTERS_NOT_CHANGED: 'Filters have not been changed',
  PREVIOUS_GAMEWEEK: 'Previous gameweek',
  NEXT_GAMEWEEK: 'Next gameweek', // DASHBOARD MATCHLIST FILTER BUTTON
  SING_OUT: 'Sing out',
  ACCOUNT: 'Account',
  SIGN_OUT: 'Sign out',
  DISPLAY_SETTINGS: 'Display settings',
  BETS: 'Bets',
  HOME: 'Home',
  TOURNAMENTS: 'Tournaments',
  NO_MATCHES_AVAILABLE: 'No matches available',
};

export default dashboardLayout;
