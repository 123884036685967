import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Maybe } from 'types';


const getWindowWidth = (setWindowWidth: React.Dispatch<React.SetStateAction<number>>) =>
  debounce(() => {
    const innerWidth = window?.innerWidth || 0;

    if (innerWidth) {
      return setWindowWidth(innerWidth);
    }
  }, 1000 * 0.1);


export const useListWidth = (pointedClass: string) => {
  const [element, setElement] = useState<Maybe<Element>>(null);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  const listener = getWindowWidth(setWindowWidth);

  const memoListener = useCallback(listener, []);

  useEffect(() => {
    window.addEventListener('resize', memoListener);

    return () => {
      window.removeEventListener('resize', memoListener);
    };
  }, [memoListener]);

  const getElement = () => {
    const matchList = document.querySelector(pointedClass);

    if (matchList) {
      setElement(matchList);
    }
  };

  const memoGetElement = useCallback(getElement, [windowWidth]);

  useEffect(() => {
    memoGetElement();
  }, [memoGetElement]);

  const elementWidth = element?.clientWidth;

  return {
    elementWidth,
  };
};
