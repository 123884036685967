import React, { FC } from 'react';
import { MatchStatus, Match } from 'types';
import { useTranslate } from 'context/translate-context';
import { ReactComponent as ShirtIcon } from 'assets/icons/shirts.svg';

import { MATCH_DETAILS_ROUTING } from '../Routing/MatchDetailsRouting';

import { MatchDetailsSidebarLink } from './MatchDetailsSidebarLink';
import { MatchDetailsSidebarLinksSeparator } from './MatchDetailsSidebarLinksSeparator';


type Props = {
  match: Match;
  hasAnyLineup: boolean;
};

export const MatchDetailsSidebarMatchLinks: FC<Props> = ({ match, hasAnyLineup }) => {
  const {
    event: { id: eventId, status },
    season: { id: seasonId },
  } = match;
  const { translate } = useTranslate();
  const isNotScheduled = MatchStatus.Scheduled !== status;

  return (
    <>
      <MatchDetailsSidebarLinksSeparator
        Icon={<ShirtIcon className="icon-color--first" />}
        label={translate('MATCH_DETAILS')}
      />
      <MatchDetailsSidebarLink
        label={translate('TEAM_FORMATION')}
        link={MATCH_DETAILS_ROUTING.getTeamFormationURL({ eventId, seasonId })}
        disabled={!hasAnyLineup}
      />
      <MatchDetailsSidebarLink
        label={translate('MATCH_REPORT')}
        link={MATCH_DETAILS_ROUTING.getMatchReportURL({ eventId, seasonId })}
        disabled={!isNotScheduled}
      />
      <MatchDetailsSidebarLink
        label={translate('MATCH_STATS')}
        link={MATCH_DETAILS_ROUTING.getMatchStatsURL({ eventId, seasonId })}
        disabled={!isNotScheduled}
      />
    </>
  );
};
