import { useState } from 'react';

import { unmaskDate } from '../utils/inputMasks';
import { ApiErrors } from '../types';

export const useFormHandlers = (initialValues: any) => {
  const [fields, setFields] = useState(initialValues);
  const [errors, setErrors] = useState<ApiErrors>([]);

  const handleChangeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    const handleValueByType = type === 'checkbox' ? checked : value;
    const updatedFields = {
      ...fields,
      [name]: handleValueByType,
    };

    setFields(updatedFields);
  };

  const handleChangeDateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFields = {
      ...fields,
      [name]: unmaskDate(value),
    };

    setFields(updatedFields);
  };


  return {
    fields,
    handleChangeField,
    handleChangeDateField,
    errors,
    setErrors,
  };
};
