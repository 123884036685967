import { useTournament } from 'context/tournament-context';
import { StandingList } from 'types';
import { ReactElement, FC } from 'react';

import { sortStandingsByGroup } from './tournamentDetailsStandingsService';


type Props = {
  children(props: {
    isLoading: boolean;
    groupedStandings: StandingList[];
  }): ReactElement;
}

export const TournamentDetailsStandingsProviderController: FC<Props> = ({ children }) => {
  const {
    standings,
    areStandingsLoading,
  } = useTournament();

  const sortedStandingsByGroup = standings.sort(sortStandingsByGroup);
  const props = {
    isLoading: areStandingsLoading,
    groupedStandings: sortedStandingsByGroup,
  };

  return children(props);
};
