import React, { ReactElement, FC } from 'react';


type Props = {
  label: string;
  Icon: ReactElement;
  borderTop?: boolean;
};

export const MatchDetailsSidebarLinksSeparator: FC<Props> = ({ label, Icon, borderTop }) => {
  const handleBorderTop = borderTop ? 'match-detail-sidebar__element--border-top' : '';

  return (
    <li className={`match-detail-sidebar__element ${handleBorderTop}`}>
      <div
        className="match-detail-sidebar__text"
      >
        <div className="match-detail-sidebar__icon margin-right--size-s">
          {Icon}
        </div>
        {label}
      </div>
    </li>
  );
};
