import React from 'react';
import { Loader } from 'semantic-ui-react';
import { Device } from 'types';
import { MatchListOpenedMatchHandlerController } from 'views/Dashboard/MatchList/MatchListOpenedMatchHandlerController';
import { MatchListMissingSelectedTournamentListSection } from 'views/Dashboard/MatchList/Missing/MatchListMissingSelectedTournamentListSection';

import { MatchListController } from '../Controller/MatchListController';
import { MatchListVariant } from '../../MatchListVariant';

import '../MatchList.scss';


export const MatchListStatic = () => {
  return (
    <div className="match-list">
      <MatchListOpenedMatchHandlerController>
        <MatchListController>
          {props => {
            const {
              matches,
              filters,
              areMatchesLoading,
            } = props;

            return (
              <>
                {areMatchesLoading &&
                  <Loader active inline="centered" />
                }
                {matches.length > 0 && (
                  <MatchListVariant
                    deviceVariant={Device.Desktop}
                    matches={matches}
                    order={filters.order}
                  />
                )}
                <MatchListMissingSelectedTournamentListSection
                  matchListLength={matches.length}
                  areMatchesLoading={areMatchesLoading}
                />
              </>
            );
          }}
        </MatchListController>
      </MatchListOpenedMatchHandlerController>
    </div>
  );
};
