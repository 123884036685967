import { factorialize } from '../../utils/number';


export const getTotalGroupCombinations = (num = 0, total = 0): number => {
  let max = total;
  if (num > max) {
    max = num;
  }

  return factorialize(max, max - num) / factorialize(num);
};
