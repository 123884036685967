import { useTranslate } from 'context/translate-context';
import React from 'react';

import { MobileMatchListFilterModalSection } from '../MobileMatchListFilterModalSection';
import { MobileMatchListFilterModalButton } from '../../Button/MobileMatchListFilterModalButton';
import { MatchListSortController } from '../../../../../MatchList/Sort/MatchListSortController';
import { MatchListFilterSectionController } from '../../../../../MatchList/MatchListFilterSectionController';
import { MatchSort } from '../../../../../../../types/Match/MatchFilters';


export const MobileMatchListFilterModalSectionSort = () => {
  const { translate } = useTranslate();

  return (
    <MatchListFilterSectionController>
      {({ filters, handleSetOrder }) => (
        <MobileMatchListFilterModalSection label={`${translate('SORT_EVENTS')}:`}>
          <MatchListSortController filters={filters}>
            {({ list, currentLabel }) => {
              return (
                <div className="m-match-list-filter-modal-section__container">
                  <ul className="m-match-list-filter-modal-section__list m-match-list-filter-modal-section--grid-4">
                    {list.map(({ label, value }) => {
                      return (
                        <li key={value}>
                          <MobileMatchListFilterModalButton
                            text={translate(label) as string}
                            isSelected={currentLabel === label}
                            handleClick={() => handleSetOrder(value as MatchSort)}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            }}
          </MatchListSortController>
        </MobileMatchListFilterModalSection>
      )}
    </MatchListFilterSectionController>
  );
};
