import React from 'react';
import { useLocation } from 'react-router';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { DesktopMatchListProviders } from 'context/AuthProviders';

import { mediaQuery } from '../../constants/mediaQuery';
import { MediaQueriesRedirectController } from '../shared/MediaQueriesRedirectController';

import { SportsBarController } from './SportsBar/SportsBarController';
import { DASHBOARD_ROUTING } from './Routing/DashboardRouting';
import { MOBILE_DASHBOARD_ROUTING } from './Routing/MobileDashboardRouting';
import { MatchListSection } from './MatchList/MatchListSection';
import { DASHBOARD_ROUTE } from './Routing/Routing';
import Sidebar from './Sidebar';
import SportsBar from './SportsBar';

import './Dashboard.scss';


export const Dashboard = () => {
  const isMobile = useMediaQuery(mediaQuery.mobile);
  const { pathname } = useLocation();

  return (
    <DesktopMatchListProviders>
      <MediaQueriesRedirectController
        url={MOBILE_DASHBOARD_ROUTING.getDefaultDashboardURL()}
        isMediaQueryActive={isMobile && DASHBOARD_ROUTE.DASHBOARD_MATCHES === pathname}
        options={{ season: [] }}
      >
        <div className="dashboard-page__container">
          <div className="dashboard-page__sports-bar">
            <SportsBarController
              url={DASHBOARD_ROUTING.getDefaultDashboardURL()}
            >
              {props => (
                <SportsBar {...props} />
              )}
            </SportsBarController>
          </div>
          <div className="layout__grid">
            <Sidebar />
            <div className="dashboard-page__section-container">
              <MatchListSection />
            </div>
          </div>
        </div>
      </MediaQueriesRedirectController>
    </DesktopMatchListProviders>
  );
};
