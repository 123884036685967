import React from 'react';
import { IncidentState, IncidentCode } from 'types';
import { getConfirmedIncidentsWithClassName, getCancelledIncidentsWithClassName } from 'views/shared/Incident/incidentCodesByStatus';


export const incidentConfirmedCodeMap = new Map();

const confirmedIncidents = getConfirmedIncidentsWithClassName('live-match-incident__incident-icon');

confirmedIncidents.forEach(({ code, icon, className }) => (
  incidentConfirmedCodeMap.set(
    code,
    <div className={className}>
      {icon}
    </div>,
  )
));


export const incidentCancelledCodeMap = new Map();

const cancelledIncidents = getCancelledIncidentsWithClassName('live-match-incident__incident-icon');

cancelledIncidents.forEach(({ code, icon, className }) => (
  incidentCancelledCodeMap.set(code,
    <div className={className}>
      {icon}
    </div>,
  )
));


export const incidentsMapsByStatus = new Map();
incidentsMapsByStatus.set(IncidentState.Confirmed, incidentConfirmedCodeMap);
incidentsMapsByStatus.set(IncidentState.Cancelled, incidentCancelledCodeMap);


type Props = {
  incidentCode: IncidentCode;
  status?: IncidentState;
};

export const getIncident = ({ incidentCode, status }: Props) => {
  const getIncidentsMapByStatus = incidentsMapsByStatus.get(status) || incidentConfirmedCodeMap;

  return getIncidentsMapByStatus.get(incidentCode);
};
