export const Gender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
};

export const genderSelectValues = [
  { key: Gender.MALE, text: Gender.MALE },
  { key: Gender.FEMALE, text: Gender.FEMALE },
  { key: Gender.OTHER, text: Gender.OTHER },
];
