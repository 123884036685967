const common = {
  FOOTBALL: 'Football', // provider app Config to change
  COULD_NOT_LOAD_CONTENT: 'Could not load the content',
  SHOW_ALL: 'Show all',
  NO_LIVE_MATCHES_AVAILABLE: 'No live matches available',
  NO_LIVE_INCIDENTS_AVAILABLE: 'No live incidents available',
  SETTINGS: 'Settings',
  LIVE_TICKER: 'Live ticker',
  MORE: 'More',
  LIGHT_THEME: 'Light theme',
  DARK_THEME: 'Dark theme',
};

export default common;
