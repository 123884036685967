import React from 'react';
import { OddsOverUnder, OddsType } from 'types';


import { MATCH_DETAILS_SECTION_KEY } from '../../../../../../constants';
import { useTranslate } from '../../../../../../context/translate-context';
import { MatchDetailsPredictionController } from '../../../Prediction/MatchDetailsPredictionController';
import { MatchDetailsSideBetsOddsLabel } from '../../MatchDetailsSideBetsOddsLabel';
import { MatchDetailsPredictionsSection } from '../../../Prediction/MatchDetailsPredictionsSection';
import { MatchDetailsPredictionsColumnsGrid } from '../../../Prediction/MatchDetailsPredictionsColumnsGrid';
import { MatchDetailsPredictions } from '../../../Prediction/MatchDetailsPredictions';
import { MatchDetailsSideBetsOddsProviderContainer } from '../../MatchDetailsSideBetsOddsProviderContainer';
import { activeTabToOddsPeriod, overUnderFindOddByTypeValue } from '../../matchDetailsSideBetsServices';
import {
  displayLessThanGoalsLabel,
  displayMoreThanGoalsLabel,
  getLessThanGoalsTypeValue,
  getMoreThanGoalsTypeValue,
  setRenderRowsProps,
} from '../matchDetailsSideBetsNumberOfGoalsServices';


export const sectionOddType = OddsType.TotalX;

export const MatchDetailsSideBetsNumberOfGoalsDefault = () => {
  const { translate } = useTranslate();

  return (
    <MatchDetailsPredictionController>
      {({ predictions }) => {
        return (
          <MatchDetailsPredictionsSection
            tabsActive
            label={translate(MATCH_DETAILS_SECTION_KEY.NUMBER_OF_GOALS)}
          >
            {({ activePredictionTab }) => {
              const lessThanGoals = predictions?.goalsPredictions?.[activePredictionTab]?.lessThanGoals || [];
              const moreThanGoals = predictions?.goalsPredictions?.[activePredictionTab]?.moreThanGoals || [];

              return (
                <MatchDetailsSideBetsOddsProviderContainer
                  oddType={[sectionOddType]}
                >
                  {({ isOddInBasket, oddsByView, handleToggleOddInBasket, match }) => {
                    const getOdd = overUnderFindOddByTypeValue(oddsByView, activeTabToOddsPeriod.get(activePredictionTab));
                    const renderRows = setRenderRowsProps({ handleToggleOddInBasket, isOddInBasket, getOdd, match });

                    const oddsLabel = oddsByView.length > 0 ? <MatchDetailsSideBetsOddsLabel /> : null;

                    return (
                      <MatchDetailsPredictionsColumnsGrid columnVariant="2">
                        <li key={`column ${OddsOverUnder.Under}`}>
                          <MatchDetailsPredictions label={oddsLabel}>
                            {renderRows({
                              list: lessThanGoals,
                              getLabel: displayLessThanGoalsLabel(translate),
                              oddType: OddsOverUnder.Under,
                              getTypeValueLabel: getLessThanGoalsTypeValue,
                            })}
                          </MatchDetailsPredictions>
                        </li>
                        <li key={`column ${OddsOverUnder.Over}`}>
                          <MatchDetailsPredictions label={oddsLabel}>
                            {renderRows({
                              list: moreThanGoals,
                              getLabel: displayMoreThanGoalsLabel(translate),
                              oddType: OddsOverUnder.Over,
                              getTypeValueLabel: getMoreThanGoalsTypeValue,
                            })}
                          </MatchDetailsPredictions>
                        </li>
                      </MatchDetailsPredictionsColumnsGrid>
                    );
                  }}
                </MatchDetailsSideBetsOddsProviderContainer>
              );
            }}
          </MatchDetailsPredictionsSection>
        );
      }}
    </MatchDetailsPredictionController>
  );
};
