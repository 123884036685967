import React, { memo } from 'react';
import { useUpdateMatchDetailsSideBets } from 'views/Dashboard/shared/hooks/updateMatchDetailsSideBets/useUpdateMatchDetailsSideBets';

import { MatchDetailsSideBetsCorrectScoreDefault } from '../Default/MatchDetailsSideBetsCorrectScoreDefault';


export const MatchDetailsSideBetsCorrectScoreLive = memo(() => {
  useUpdateMatchDetailsSideBets();

  return (
    <MatchDetailsSideBetsCorrectScoreDefault />
  );
});
