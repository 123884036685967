import { IncidentCode, LiveTickerOption } from 'types';


export const panelOptionList: LiveTickerOption[] = [
  {
    isMuted: false,
    label: 'GOALS',
    isSelected: false,
    codeList: [IncidentCode.Goal, IncidentCode.PenaltyGoal, IncidentCode.OwnGoal],
  },
  {
    isMuted: false,
    label: 'Yellow card',
    isSelected: false,
    codeList: [IncidentCode.YellowCard],
  },
  {
    isMuted: false,
    label: 'Red card',
    isSelected: false,
    codeList: [IncidentCode.RedCard],
  },
  {
    isMuted: false,
    label: 'Substitutions',
    isSelected: false,
    codeList: [IncidentCode.SubIn, IncidentCode.SubOut],
  },
];
