const dashboardLayout = {
  CLEAR_TOURNAMENTS: 'Rensa turneringar',
  FAVOURITES: 'Favoriter',
  LIVE: 'Live',
  ENDED: 'Avslutad',
  ODDS_FILTER: 'Velg odds',
  POSTPONED: 'Uppskjuten',
  KICK_OFF: 'Matchstart',
  FAVOURITE_BTN: 'Lägg till favorit',
  'Kick off (first)': 'Matchstart (först)',
  'Kick off (last)': 'Matchstart (sist)',
  'Tournaments (first)': 'Ligor (först)',
  'Tournaments (last)': 'Ligor (sist)',
  'Bet ratio (ascending)': 'Insatsförhållande (stigande)',
  'Bet ratio (descending)': 'Insatsförhållande (nedåtgående)',
  SORT_EVENTS: 'Sortera efter',
  SHOW_XPERT: 'Visa Odds som',
  PERCENTAGE: 'Procent',
  DECIMAL: 'Decimal',
  RATIO: 'Insatsförhållande',
  FILTERS_RESET: 'Filter har återställts till standardvärdena',
  TOURNAMENT_LIST_CLEARED: 'Turneringar rensades',
  FILTERS_NOT_CHANGED: 'Filtren har inte ändrats',
  PREVIOUS_GAMEWEEK: 'Föregående spelvecka',
  NEXT_GAMEWEEK: 'Nästa spelvecka',
  SING_OUT: 'Logga ut',
  ACCOUNT: 'Konto',
  SIGN_OUT: 'LOGGA UT',
  DISPLAY_SETTINGS: 'Skärminställningar',
  BETS: 'Satsningar',
  HOME: 'Hem',
  TOURNAMENTS: 'Turneringar',
  NO_MATCHES_AVAILABLE: 'Inga matcher tillgängliga',
};

export default dashboardLayout;
