import { LoadingElement } from 'components';
import React, { FC, ReactElement } from 'react';
import { Area, Maybe } from 'types';
import { DashboardDropdownHandlerController } from 'views/Dashboard/DashboardDropdownHandlerController';
import { TournamentCategory } from 'views/Dashboard/Tournament/shared/TournamentCategory';

import { TournamentAreaListController } from '../TournamentAreaListController';
import { getLabelToLowerCase, sortParentAreaListInOrder } from '../tournamentAreaServices';


type Props = {
  children(props: {
    handleSelectArea: (area: Area) => void;
    areas: Area[];
    selectedArea: Maybe<Area>;
  }): ReactElement;
}

export const TournamentContinentListSectionVariantDefault: FC<Props> = ({ children }) => {
  return (
    <TournamentAreaListController>
      {props => {
        const {
          areAreasLoading,
          areas: continentAreas,
          handleSelectArea,
          selectedCountry,
        } = props;


        if(!continentAreas.length) {
          return null;
        }

        return (
          <TournamentAreaListController>
            {({ handleSelectContinent, selectedContinent }) => {
              return (
                <ul
                  className="tournament-category__list"
                >
                  {areAreasLoading && <LoadingElement />}
                  {sortParentAreaListInOrder()({ continents: continentAreas })
                    .map(continent => {
                      const { id, name, areas  = [] } = continent;

                      return (
                        <DashboardDropdownHandlerController initialState={selectedContinent?.id === id} key={id}>
                          {({ isOpen, toggleDropdown }) => (
                            <li className="tournament-category">
                              <TournamentCategory
                                label={getLabelToLowerCase(name)}
                                handleClick={() => {
                                  toggleDropdown();
                                  handleSelectContinent(continent);
                                }}
                                isOpen={isOpen}
                              />
                              {selectedContinent?.id === continent.id && (
                                children({ areas, handleSelectArea, selectedArea: selectedCountry })
                              )}
                            </li>
                          )}
                        </DashboardDropdownHandlerController>
                      );
                    })}
                </ul>
              );
            }}
          </TournamentAreaListController>
        );
      }}
    </TournamentAreaListController>
  );
};
