import { OddListGroupingType } from 'types';


export const updateMatchListOddList = (oddList: OddListGroupingType[], socketOddList: OddListGroupingType[]) => {
  const syncOddList = oddList.map(element => {
    const { event } = element;

    const foundElement = socketOddList.find(element => {
      return element.event.id === event.id;
    });

    return foundElement || element;
  });

  return syncOddList;
};
