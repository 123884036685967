import React from 'react';
import { EmptyListFeedback } from 'components';
import { formatDate, getToday, getYesterday } from 'utils';
import { useMatchesFilters } from 'views/Dashboard/MatchList/useMatchesFilters';
import { MatchStatus } from 'types';

import ErrorBoundaryWithRollbar from '../../../ErrorBoundaryWithRollbar';

import { mobileMatchListMap } from './mobileMatchListMap';


export const MobileMatchList = () => {
  const { filters: { from } } = useMatchesFilters();

  const today = getToday();
  const yesterday = getYesterday();
  const handleListDisplayBySettingStatus = [yesterday, today].includes(formatDate(from)) ? MatchStatus.Live : MatchStatus.Scheduled;
  const List = mobileMatchListMap.get(handleListDisplayBySettingStatus);

  return (
    <ErrorBoundaryWithRollbar
      FallbackComponent={
        <EmptyListFeedback text="COULD_NOT_LOAD_CONTENT" />
      }
    >
      <List />
    </ErrorBoundaryWithRollbar>
  );
};
