import React from 'react';
import { Loader } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import { EmptyListFeedback } from '../../../../components';
import { MatchDetailsSummaryWrapper } from '../MatchDetailsSummaryWrapper';

import { MatchDetailsMatchStatsListController } from './MatchDetailsMatchStatsListController';
import { MatchDetailsMatchStatsList } from './MatchDetailsMatchStatsList';


export const MatchDetailsMatchStats = () => {
  return (
    <MatchDetailsSummaryWrapper>
      {() => (
        <MatchDetailsMatchStatsListController>
          {props => {
            const { areStatsLoading, statistics } = props;
            const { commonStatistics = [] } = statistics || {};

            return (
              <>
                {areStatsLoading && <Loader active inline  />}
                {!areStatsLoading && isEmpty(statistics) && <EmptyListFeedback />}
                {!areStatsLoading && commonStatistics.length > 0 && <MatchDetailsMatchStatsList {...props} />}
              </>
            );
          }}
        </MatchDetailsMatchStatsListController>
      )}
    </MatchDetailsSummaryWrapper>
  );
};
