import React, { FC, memo } from 'react';
import { Icon } from 'semantic-ui-react';
import { IncidentState, MatchLiveIncident, TeamDetails, Size } from 'types';
import { Badge } from 'views/Dashboard/shared/Badge/Badge';
import { Popup } from 'components';
import { useTranslate } from 'context/translate-context';

import { getIncident } from './services';
import './LiveMatchIncident.scss';


type Props = {
  name: MatchLiveIncident['name'];
  time: string;
  confirmation?: MatchLiveIncident['confirmation'];
  incidentCode: MatchLiveIncident['code'];
  player: MatchLiveIncident['player'];
  badgeUrl: TeamDetails['badgeUrl'];
  acronym: string;
};

const NAME_MAX_LENGTH = 20;

export const LiveMatchIncident: FC<Props> = memo(({
  name,
  time,
  confirmation = IncidentState.Tbd,
  incidentCode,
  player,
  badgeUrl,
  acronym: teamAcronym,
}) => {
  const { translate } = useTranslate();
  const IncidentIcon = getIncident({ incidentCode, status: confirmation });

  const incidentPopup = (
    <Popup
      content={`${name} (${translate(confirmation.toUpperCase())})`}
      trigger={IncidentIcon}
    />
  );

  const handleDisplayName = player ? `(${teamAcronym}) ${player?.firstName || ''} ${player.lastName}` : teamAcronym;

  const Name = (
    <span className="live-match-incident__player-name">
      {handleDisplayName}
    </span>
  );

  return (
    <div className="live-match-incident">
      <div className="live-match-incident__grid">
        <div className="live-match-incident__time-container">
          <Icon
            name="clock outline"
            className="margin--unset permanent-white clock"
          />
          <div className="live-match-incident__time">
            {time}
          </div>
        </div>
        <Badge
          alt={`${teamAcronym} badge`}
          size={Size.Tiny}
          badgeURL={badgeUrl}
        />
        <div className="live-match-incident__player">
          {incidentPopup}
          {handleDisplayName.length > NAME_MAX_LENGTH ? (
            <Popup
              position="top left"
              content={handleDisplayName}
              trigger={
                <span className="live-match-incident__player-name">
                  {handleDisplayName}
                </span>
              }
            />
          ) : Name}
        </div>
      </div>
    </div>
  );
});
