import { Handlers } from '../Handlers';


type Props = Handlers['updateMatchDetailsOddGroupingTypeList'];


export const updateMatchDetailsOddGroupingTypeList: Props = (selectedMatchId, oddListToInject) => groupedOdd => {
  if (groupedOdd?.event.id === selectedMatchId) {
    return {
      event: groupedOdd.event,
      odds: oddListToInject,
    };
  }

  return groupedOdd;
};
