import React, { FC } from 'react';
import { isUndefined } from 'lodash';
import { OddOdd, Match } from 'types';
import { isGoodBet } from 'views/Dashboard/shared/Match/Prediction/matchPredictionServices';
import { MatchPredictionSidebBetsOdds } from 'views/Dashboard/shared';
import { MatchPredictionClassHandler } from 'views/Dashboard/shared/Match/Prediction/MatchPredictionClassHandler';
import { MatchPrediction } from 'views/Dashboard/shared/Match/Prediction/MatchPrediction';

import { SIDE_BETS_PREDICTION_VARIANT } from '../../../../constants';
import { getPercent } from '../../MatchList/matchListPredictionSectionServices';

import { handleDisplayGrid } from './matchDetailsPredictionsGridService';


type Props = {
  match: Match;
  label: string;
  isOddInBasket: (odd: OddOdd) => boolean;
  onOddClick: ({ oddOdds }: { oddOdds: OddOdd }) => () => void;
  odd?: OddOdd;
  prediction?: number;
}

export const MatchDetailsPredictionRow: FC<Props> = ({
  match,
  label,
  isOddInBasket,
  onOddClick,
  odd,
  prediction,
}) => {
  const gridClass = handleDisplayGrid(isUndefined(prediction));
  const isGood = odd && isGoodBet(odd?.odds, prediction);

  return (
    <li className={`match-details-prediction__prediction ${gridClass}`}>
      <div className="match-details-prediction__label">
        {label}
      </div>
      {odd?.odds ? (
        <div className="match-details-prediction--second-column">
          <MatchPredictionSidebBetsOdds
            options={
              {
                match,
                scoreValue: odd.odds,
                isSelected: isOddInBasket(odd),
                isGood,
                handleClick: onOddClick({ oddOdds: odd }),
              }
            }
          />
        </div>
      ) : null}
      {!isUndefined(prediction) && (
        <div className="match-details-prediction--third-column">
          <MatchPredictionClassHandler
            variant={SIDE_BETS_PREDICTION_VARIANT.XPERT}
            isRecommendedBet={isGood}
            matchStatus={match.event.status}
          >
            {({ elementClassDisplayVariant, handleRecommendedBet }) => (
              <MatchPrediction
                value={getPercent(prediction)}
                customClasses={handleRecommendedBet ? elementClassDisplayVariant(prediction) : ''}
              />
            )}
          </MatchPredictionClassHandler>
        </div>
      )}
    </li>
  );
};
