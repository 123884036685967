import React, { FC } from 'react';
import { Noop } from 'types';

import './MobileMatchListFilterModalButton.scss';


type Props = {
  text: string;
  handleClick?: Noop;
  isSelected: boolean;
}

export const MobileMatchListFilterModalButton: FC<Props> = ({ text, handleClick, isSelected }) => {
  return (
    <button
      className={`m-match-list-filter-modal-button ${isSelected ? 'm-match-list-filter-modal-button--selected' : ''}`}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};
