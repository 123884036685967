import { useEffect } from 'react';
import { BasketItem } from 'types';

import { BasketMessage } from './BasketMessage.type';


type Props = (item: CustomEventInit<BasketItem[]>) => void;

export const useHandleReceivedOdd = (handleSentOdd: Props) => {
  useEffect(() => {
    window.addEventListener(BasketMessage.Odd, handleSentOdd);

    return () => {
      window.removeEventListener(BasketMessage.Odd, handleSentOdd);
    };
  }, [handleSentOdd]);
};
