import React, { forwardRef } from 'react';

import ErrorMessage from '../../ErrorMessage';

import './LabelCheckbox.scss';


type Props = {
  label: string;
  ariaLabel?: string;
  name: string;
  value?: any;
  errors?: any[];
  id: string;
}

export const LabelCheckbox = forwardRef<HTMLInputElement, Props>(({
  label,
  ariaLabel = label,
  name,
  value,
  errors = [],
  id,
  ...props
}, ref) => {
  return (
    <div className="label-checkbox">
      <div className="label-checkbox__container">
        <input
          ref={ref}
          id={id}
          name={name}
          value={value}
          aria-label={ariaLabel}
          type="checkbox"
          {...props}
        />
        <label htmlFor={id} className="label-checkbox__label">
          {label}
        </label>
      </div>
      <ErrorMessage fieldName={name} errors={errors} />
    </div>
  );
});
