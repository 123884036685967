
import React, { useContext, FC } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { noop } from 'lodash';
import { ProviderBasketAppConfig } from 'types';
import { defaultBasketConfig } from 'config/basketAppConfig';

import { BASKET_STORAGE_KEY, CONFIG_STORAGE_KEY } from '../../constants';


type Props = {
  configuration: ProviderBasketAppConfig;
  setConfiguration: (config: ProviderBasketAppConfig) => void;
}

const BasketConfigContext = React.createContext<Props>({
  configuration: defaultBasketConfig,
  setConfiguration: noop,
});

const BasketConfigProvider: FC = props => {
  const [configuration, setConfiguration] = useLocalStorage<ProviderBasketAppConfig>(`${BASKET_STORAGE_KEY}_${CONFIG_STORAGE_KEY}`, defaultBasketConfig);

  const values = {
    configuration,
    setConfiguration,
  };

  return (
    <BasketConfigContext.Provider
      value={values}
      {...props}
    />
  );
};


const useBasketConfig = () => {
  const context = useContext(BasketConfigContext);

  if(context === undefined) {
    throw new Error('useBasketConfig must be used within a BasketConfigProvider');
  }

  return context;
};


export {
  BasketConfigProvider,
  useBasketConfig,
};
