import React, { memo, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Match, Maybe, Noop, GameTeamPosition } from 'types';
import { useMatch } from 'context/match-context';
import { ReactComponent as Shirts } from 'assets/icons/shirts.svg';
import MatchTeam from 'views/Dashboard/MatchList/Match/Team';

import { MatchSideBetsSection } from '../../../../MatchList/Match/MatchSideBetsSection';
import { MatchStatusWithTime } from '../../../../MatchList/Match/MatchStatusWithTime';
import { addQuery } from '../../../../../../utils/queries';
import { MOBILE_DASHBOARD_ROUTING } from '../../../../Routing/MobileDashboardRouting';

import './MatchVariantTournament.scss';
import '../../MatchListElement.scss';


type Props = {
  match: Match;
  handleScrollRef?: Maybe<Noop>;
}

export const MobileMatchVariantTournament: FC<Props> = memo(({ match, handleScrollRef = null }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { event, home, away, season } = match;
  const { startDate, status, statusName, clockTime } = event;
  const { setSelectedMatch } = useMatch();

  return (
    <li
      ref={handleScrollRef}
      key={event.id}
      className="match-list-element__wrapper match-list-element__bg match-variant-tournament__wrapper match-variant-tournament__container--border"
    >
      <div className="match-variant-tournament__grid">
        <div
          className="match-variant-tournament__match-details-grid"
          onClick={() => {
            setSelectedMatch(match);
            push({
              pathname: MOBILE_DASHBOARD_ROUTING.getTeamFormationURL({
                eventId: event.id, seasonId: season.id,
              }),
              search: addQuery(search, {}),
            });
          }}
        >
          <MatchStatusWithTime
            startDate={startDate}
            status={status}
            statusName={statusName}
            clockTime={clockTime}
          />
          <section className="match-list-element__team-grid">
            <MatchTeam.Mobile
              team={home}
              statusName={event.statusName}
              status={event.status}
              type={GameTeamPosition.Home}
            />
            <MatchTeam.Mobile
              team={away}
              statusName={event.statusName}
              status={event.status}
              type={GameTeamPosition.Away}
            />
          </section>
          <div className="match-variant-tournament__shirt-container">
            <div className="match-variant-tournament__icon-shirt">
              <Shirts fill="var(--SaasText02)" width="20px" height="16px" />
            </div>
          </div>
        </div>
        <div className="match-list-element__bets">
          <MatchSideBetsSection match={match} />
        </div>
      </div>
    </li>
  );
});
