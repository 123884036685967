import { isEqual } from 'lodash';
import { FC, ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { addQuery } from 'utils/queries';
import { Noop } from 'types';
import { useMatch } from 'context/match-context';
import { useTranslate } from 'context/translate-context';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { useMatchesFilters } from '../useMatchesFilters';
import { DASHBOARD_ROUTING } from '../../Routing/DashboardRouting';
import { mediaQuery } from '../../../../constants/mediaQuery';
import { MOBILE_DASHBOARD_ROUTING } from '../../Routing/MobileDashboardRouting';


type Props = {
  children(props: {
    handleResetMatchesFilters: Noop;
  }): ReactElement;
};

export const MatchListFilterResetToDefaultController: FC<Props> = ({ children }) => {
  const { push } = useHistory();
  const { translate } = useTranslate();
  const { pathname } = useLocation();
  const { setSelectedMatch } = useMatch();
  const { filters, DEFAULT_FILTERS } = useMatchesFilters();
  const isDesktop = useMediaQuery(mediaQuery.minTabletLandscape);

  const handleURLByMQ = isDesktop ? DASHBOARD_ROUTING : MOBILE_DASHBOARD_ROUTING;
  const defaultURL = handleURLByMQ.getDefaultDashboardURL();

  const handleResetMatchesFilters = () => {
    if (!isEqual(filters, DEFAULT_FILTERS)) {
      push({
        pathname: defaultURL,
        search: addQuery('', DEFAULT_FILTERS),
      });
      setSelectedMatch(null);

      return toast.success(translate('FILTERS_RESET'));
    }

    return !pathname.includes(defaultURL) ?
      push({ pathname: defaultURL }) :
      toast.warning(translate('FILTERS_NOT_CHANGED'));
  };

  const props = { handleResetMatchesFilters };

  return children(props);
};
