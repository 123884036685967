export const handleNewList = ({ oldList, list }: { oldList: string[]; list: string[] }) => {
  let newList = [];

  const areSomeElementsSelected = oldList.some(status => list.includes(status));

  if (areSomeElementsSelected) {
    newList = oldList.filter(status => !list.includes(status));
  } else {
    newList = list;
  }

  return newList;
};
