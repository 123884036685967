import React from 'react';

import { MatchDetailsSideBetsThreeWayHandicapDefault } from '../../../../MatchDetails/SideBets/ThreeWayHandicap/Default/MatchDetailsSideBetsThreeWayHandicapDefault';


export const MobileMatchDetailsSideBetsThreeWayHandicap = () => {
  return (
    <MatchDetailsSideBetsThreeWayHandicapDefault />
  );
};
