import { FC, ReactElement } from 'react';
import { useConfig } from 'context/config-context';
import { useHistory, useLocation } from 'react-router-dom';
import { ProviderAppConfig, SportsName } from 'types';

import { addQuery } from '../../../utils/queries';


type Props = {
  url: string;
  children(props: {
    configuration: ProviderAppConfig;
    isSelected: (sport: SportsName) => boolean;
    handleSelectSport: (sport: SportsName) => void;
  }): ReactElement;
}

export const SportsBarController: FC<Props> = ({ url, children }) => {
  const { configuration } = useConfig();
  const { search } = useLocation();
  const { push } = useHistory();

  const isSelected = (sport: SportsName) => search.includes(sport);

  const handleSelectSport = (sport: SportsName) => {
    return push({
      pathname: url,
      search: addQuery(search, { sportName: [sport] }),
    });
  };

  const props = {
    configuration,
    isSelected,
    handleSelectSport,
  };

  return children(props);
};
