import { createMatchDetailsSideBetsRowHeaderWithKey } from 'views/Dashboard/shared/MatchDetails/SideBets/matchDetailsSideBetsServices';


const createRowWithCustomClass = createMatchDetailsSideBetsRowHeaderWithKey({ key: 'correct-score' });

export const rows = [
  createRowWithCustomClass({}),
  createRowWithCustomClass({}),
  createRowWithCustomClass({}),
];
