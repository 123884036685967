import { MatchLiveIncident } from 'types';


type Props = {
  recentlyUpdatedLiveIncidentList: MatchLiveIncident[];
  latestState: MatchLiveIncident[];
};


export const useUpdateIncidentList = (setIncidentList: React.Dispatch<React.SetStateAction<MatchLiveIncident[]>>) => {
  const updateLiveIncidentListWithChangedIncidents = ({
    recentlyUpdatedLiveIncidentList,
    latestState,
  }: Props) => {
    const liveIncidentIdList = recentlyUpdatedLiveIncidentList.map(({ id }) => id);

    const getItemToUpdate = (incidentToUpdate: MatchLiveIncident) =>
      latestState.find(incident =>
        incident.id === incidentToUpdate.id);

    setIncidentList(prevState => {
      return prevState.map(previousIncident => {
        const foundItem = getItemToUpdate(previousIncident);
        return liveIncidentIdList.includes(previousIncident.id) && foundItem ?
          foundItem :
          previousIncident;
      });
    });
  };

  return {
    updateLiveIncidentListWithChangedIncidents,
  };
};
