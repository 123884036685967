import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { getMatchDetailsRoutes } from '../../shared/MatchDetails/matchDetailsRoutingServices';
import { DASHBOARD_ROUTE, MATCH_DETAILS } from '../../Routing/Routing';

import { MobileMatchDetailsStatistic } from './Statistic/MobileMatchDetailsStatistic';
import { MobileMatchDetailsSideBetsSection } from './SideBets/MobileMatchDetailsSideBetsSection';


const MATCH_DETAILS_ROUTE = getMatchDetailsRoutes({
  pathname: MATCH_DETAILS.MOBILE_DEFAULT,
  dashboardPath: DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES,
});

export const MobileMatchDetailsRouting = () => {
  return (
    <Switch>
      <Route path={MATCH_DETAILS_ROUTE.STATISTICS} component={MobileMatchDetailsStatistic} />
      <Route path={MATCH_DETAILS_ROUTE.SIDE_BETS} component={MobileMatchDetailsSideBetsSection} />
    </Switch>
  );
};

