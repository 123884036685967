import React, { FC, useEffect, ReactElement } from 'react';

import './ModalBlockScroll.scss';


type Props = {
  children: ReactElement;
}

export const ModalBlockScroll: FC<Props> = ({ children }) => {
  const body = document.body.classList;

  useEffect(() => {
    body.add('no-scroll');

    return () => {
      body.remove('no-scroll');
    };
  }, [body]);

  return (
    <div className="modal-block-scroll">
      {children}
    </div>
  );
};
