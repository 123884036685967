import React, { ReactElement } from 'react';
import { Maybe } from 'types';

import { MatchDetailsPredictions } from '../../../MatchDetails/Prediction/MatchDetailsPredictions';


export type MatchDetailsSideBetsRowLabel = {
  customStyle?: string;
  key: string;
  isPredictionDisabled?: boolean;
}

export const createMatchDetailsSideBetsRowHeaderWithKey = ({ key }: { key: string }) =>
  ({ customStyle, isPredictionDisabled = false }: { customStyle?: string; isPredictionDisabled?: boolean }) => ({
    key,
    customStyle,
    isPredictionDisabled,
  });

export const getSideBetsGridHeaders = (rows: MatchDetailsSideBetsRowLabel[]) => ({ oddsLabel }: { oddsLabel: Maybe<ReactElement> }) => {
  return (
    <>
      {rows.map(({ customStyle, key, isPredictionDisabled }, index) => {
        return (
          <li key={`${index} ${key}`}
            className={customStyle || ''}
          >
            <MatchDetailsPredictions
              label={oddsLabel}
              isPredictionDisabled={isPredictionDisabled}
            />
          </li>
        );
      })}
    </>
  );
};
