import socketIO from 'socket.io-client';


const SOCKETS_CONFIG = {
  baseUrl: new URL(window.__RUNTIME_CONFIG__.API_URL).origin,
  socket: {
    transports: [ 'websocket' ],
    jsonp: false,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    randomizationFactor: 0.5,
  },
};

class SocketsService {
  constructor() {
    this.socket = socketIO(SOCKETS_CONFIG.baseUrl, SOCKETS_CONFIG.socket);
  }

  getSocket = () => {
    if (!this.socket.connected) {
      this.socket.connect();
    }

    return this.socket;
  }
}

const socketsService = new SocketsService();

export default socketsService;
