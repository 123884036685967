import { OddsProvider, BasketItem, OddGroupingType  } from 'types';

import { updateBasketOnOpenHandlers as updateBasketOnOpenHandlersNorskTipping } from './norskTipping';
import { updateBasketOnOpenHandlers as updateBasketOnOpenHandlersSvenskaSpel } from './svenskaSpel';


export type UseUpdateBasketOddListByProviderHandlersProps = {
  getUpdatedList: (oddList: OddGroupingType[], items: BasketItem[]) => BasketItem[];
}

export const updateBasketOddListByProviderHandlersMap = new Map<OddsProvider, UseUpdateBasketOddListByProviderHandlersProps>();
updateBasketOddListByProviderHandlersMap.set(OddsProvider.NorskTipping, updateBasketOnOpenHandlersNorskTipping);
updateBasketOddListByProviderHandlersMap.set(OddsProvider.SvenskaSpel, updateBasketOnOpenHandlersSvenskaSpel);
