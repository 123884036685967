import { MatchStatus, Match } from 'types';
import { useConfig } from 'context/config-context';
import { oddsBlockedByStatusListByProviderMap } from 'views/shared/Odds/blockedByStatus/provider/oddsBlockedByStatusListByProviderMap';
import { oddsBlockedByStatusNameListByProviderMap } from 'views/shared/Odds/blockedByStatus/provider/oddsBlockedByStatusNameListByProviderMap';

import { useCompareOdds } from '../../hooks/useCompareOdds';

import { isMatchStatusBlockedByStatusNameList, isMatchStatusEqualTo, isMatchStatusBlockedByStatusList } from './matchPredictionServices';
import { handleDisplayRecommendedBetByStatus } from './recommendedBet/matchPredictionRecommendedBet.services';


type Props = {
  scoreValue: number;
  handleClick: () => void;
  match: Match;
  isGood?: boolean;
}

type Output = {
  handleAllowOnClick: {
    onClick: (() => void) | undefined;
  };
  handleHasOddChanged: string;
  allowAction: boolean;
  isFinished: boolean;
  handleRecommendedBet: boolean;
}

export const useMatchPredictionsSideBetsOdds = ({ scoreValue, handleClick, match, isGood }: Props): Output => {
  const { configuration: { provider: { name: providerName } } } = useConfig();

  const isFinished = isMatchStatusEqualTo({ match, status: MatchStatus.Finished });
  const statusListByProvider = (providerName && oddsBlockedByStatusListByProviderMap.get(providerName)) || [];
  const statusNameListByProvider = (providerName && oddsBlockedByStatusNameListByProviderMap.get(providerName)) || [];

  const isMatchBlockedByStatus = isMatchStatusBlockedByStatusList({ match, statusList: statusListByProvider });
  const isMatchBlockedByStatusName = isMatchStatusBlockedByStatusNameList({ match, statusList: statusNameListByProvider });

  const allowAction = [
    !isFinished,
    !isMatchBlockedByStatus,
    !isMatchBlockedByStatusName,
    Boolean(scoreValue),
  ].every(Boolean);
  const handleAllowOnClick = { onClick: allowAction ? handleClick : undefined };

  const {
    highlightIncreasedOddClass,
    highlightDecreasedOddClass,
  } = useCompareOdds({
    odd: scoreValue || 0,
    oddIncreaseClassName: 'match-prediction-side-bets-odds__prediction--odd-increased',
    oddDecreaseClassName: 'match-prediction-side-bets-odds__prediction--odd-decreased',
  });

  const handleHasOddChanged = `${highlightIncreasedOddClass || highlightDecreasedOddClass || ''}`;
  const handleRecommendedBet = handleDisplayRecommendedBetByStatus(match.event.status, isGood);

  const props = {
    handleAllowOnClick,
    handleHasOddChanged,
    allowAction,
    isFinished,
    handleRecommendedBet,
  };

  return props;
};
