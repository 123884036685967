import React, { FC, useCallback, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FieldCheckbox } from 'components';
import { Basket, BasketItemGroup } from 'types';
import { useTranslate } from 'context/translate-context';

import { getTotalGroupCombinations } from '../../../../utils';


type Props = {
  item: any;
  index: number;
  control: Control<Basket>;
  group: BasketItemGroup;
  count: number;
}

export const Bet: FC<Props> = ({ item, count, control, index, group }) => {
  const { translate } = useTranslate();
  const betHeaderRef = useRef<HTMLDivElement>(null);

  const toggleCheckbox = useCallback((value, field) => {
    field.onChange(value);
    betHeaderRef.current?.classList.toggle('group-bet__header--selected');
  }, [betHeaderRef]);

  return (
    <div key={item.id}>
      <div ref={betHeaderRef} className="group-bet__header">
        <div className="group-bet__header-title">
          {item.number}/{count}
          <span className="group-bet__header-subtitle">
            ({getTotalGroupCombinations(item.number, count)} {translate('COMBINATIONS')})
          </span>
        </div>
        <Controller
          control={control}
          name={`groupBetValues.${group}.${index}.selected` as const}
          defaultValue={item.selected}
          render={({ field }) => (
            <FieldCheckbox
              labelText=""
              ariaLabel={translate('SELECT_COMBINATIONS')}
              handleChange={(value: any) => toggleCheckbox(value, field)}
              errors={[]}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
};
