import React from 'react';

import { CalendarView } from './CalendarView';
import { CalendarController } from './CalendarController';


export const Calendar = ({ close }) => {
  return (
    <CalendarController close={close}>
      {props => (
        <CalendarView {...props} />
      )}
    </CalendarController>
  );
};
