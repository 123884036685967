import React from 'react';

import { MatchDetailsSideBetsHandleWhenUpdate } from '../MatchDetailsSideBetsHandleWhenUpdate';

import { MatchDetailsSideBetsNumberOfGoalsLive } from './Live/MatchDetailsSideBetsNumberOfGoalsLive';
import { MatchDetailsSideBetsNumberOfGoalsDefault } from './Default/MatchDetailsSideBetsNumberOfGoalsDefault';


export const MatchDetailsSideBetsNumberOfGoalsSection = () => {
  return (
    <MatchDetailsSideBetsHandleWhenUpdate
      ComponentWithLiveUpdate={MatchDetailsSideBetsNumberOfGoalsLive}
    >
      <MatchDetailsSideBetsNumberOfGoalsDefault />
    </MatchDetailsSideBetsHandleWhenUpdate>
  );
};
