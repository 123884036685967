import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { MobileAreaTournamentList } from '../Mobile/Area/MobileAreaTournamentList';
import { MobileDashboard } from '../Mobile/MobileDashboard';
import {
  getURLWithId,
  getTeamFormationURL as getTeamFormation,
  getSideBetsURL as getSideBets,
  getMatchReportURL as getMatchReport,
} from '../shared/MatchDetails/matchDetailsRoutingServices';
import { MobileMatchDetails } from '../Mobile/MatchDetails/MobileMatchDetails';

import { DASHBOARD_ROUTE, MATCH_DETAILS } from './Routing';


export const MobileDashboardRouting = () => {
  return (
    <Switch>
      <Route exact path={DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES} component={MobileDashboard} />
      <Route exact path={`${DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES}${DASHBOARD_ROUTE.TOURNAMENT_LIST}`} component={MobileAreaTournamentList} />
      <Route path={MATCH_DETAILS.MOBILE_DEFAULT} component={MobileMatchDetails} />
    </Switch>
  );
};


const getDefaultDashboardURL = () => {
  return DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES;
};

const getTournamentsSelectURL = () => {
  return `${DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES}${DASHBOARD_ROUTE.TOURNAMENT_LIST}`;
};


const getTeamFormationURL = getTeamFormation(getURLWithId(DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES));
const getSideBetsURL = getSideBets(getURLWithId(DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES));
const getMatchReportURL = getMatchReport(getURLWithId(DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES));

export const MOBILE_DASHBOARD_ROUTING = {
  getDefaultDashboardURL,
  getTournamentsSelectURL,
  getTeamFormationURL,
  getSideBetsURL,
  getMatchReportURL,
};
