type SortedElementProp = string | number | Date;

export type SortItemsProps = (firstElement: SortedElementProp, secondElement: SortedElementProp) => 1 | -1 | 0


export const sortItemsAsc: SortItemsProps = ( firstElement, secondElement) => {
  if (firstElement < secondElement) {
    return -1;
  }

  return firstElement > secondElement ? 1 : 0;
};

export const sortItemsDesc: SortItemsProps = (firstElement, secondElement) => {
  if (firstElement > secondElement) {
    return -1;
  }

  return firstElement < secondElement ? 1 : 0;
};
