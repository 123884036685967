import React, { FC } from 'react';
import { Match, MatchStatus } from 'types';

import { MatchTime } from './MatchTime';
import { MatchState } from './MatchState';

import './MatchStatusWithTime.scss';


type Props = {
  startDate: Match['event']['startDate'];
  status: Match['event']['status'];
  statusName: Match['event']['statusName'];
  clockTime: Match['event']['clockTime'];
}

export const MatchStatusWithTime: FC<Props> = ({ startDate, status, statusName, clockTime = null }) => {
  const matchStatus = status === MatchStatus.Live ? statusName : status;

  return (
    <div className="match-status-with-time">
      <MatchTime startDate={startDate} clockTime={clockTime} status={matchStatus} />
      <div>
        <MatchState state={status} statusName={statusName} />
      </div>
    </div>
  );
};
