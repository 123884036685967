import { MatchFilters } from 'types';

import { formatDateToISO } from '../../utils';
import { endOfSelectedDay, startOfSelectedDay } from '../../utils/date.js';
import { OddsProvider } from '../../types/Odd';
import { LiveMatchFilters } from '../../types/Live/LiveMatch';


const oddsProvidersValues = Object.values(OddsProvider) as string[];

export const getParamFromDate = (from: string) => {
  // subMinutes to receive range without selected day, e.g only for today
  return formatDateToISO(startOfSelectedDay(new Date(from)));
};
export const getParamToDate = (to: string) => {
  // subMinutes to receive range without selected day, e.g only for today
  return formatDateToISO(endOfSelectedDay(new Date(to)));
};

export const getFormattedMatchParams = (params: MatchFilters) => ({
  seasonsIds: params.season || [],
  from: getParamFromDate(params.from),
  to: getParamToDate(params.to),
  order: params.order,
  providers: params.providers || (params?.selected || []).filter(item => oddsProvidersValues.includes(item)) || [],
  eventsStatus: params.status,
});

export const getFormattedLiveMatchParams = (params: LiveMatchFilters) => ({
  seasonsIds: params.season || [],
  from: getParamFromDate(params.from),
  to: getParamToDate(params.to),
  providers: params.providers || (params?.selected || []).filter(item => oddsProvidersValues.includes(item)) || [],
  eventsStatus: params.status,
});
