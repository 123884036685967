/* eslint-disable @typescript-eslint/no-var-requires */
import { IncidentCode, IncidentState } from 'types';


const whistle = 'https://storage.googleapis.com/sportxpert-cdn/sounds/whistle.wav';
const cheer = 'https://storage.googleapis.com/sportxpert-cdn/sounds/cheer.wav';
const swish = 'https://storage.googleapis.com/sportxpert-cdn/sounds/swish.wav';

const liveSoundListMapByConfirmedCode = new Map();
liveSoundListMapByConfirmedCode.set(IncidentCode.RedCard, whistle);
liveSoundListMapByConfirmedCode.set(IncidentCode.YellowCard, whistle);
liveSoundListMapByConfirmedCode.set(IncidentCode.Goal, cheer);
liveSoundListMapByConfirmedCode.set(IncidentCode.PenaltyGoal, cheer);
liveSoundListMapByConfirmedCode.set(IncidentCode.SubIn, swish);
liveSoundListMapByConfirmedCode.set(IncidentCode.SubOut, swish);

const liveSoundListMapByCancelledCode = new Map();

const liveSoundListMapByTbdCode = new Map();
liveSoundListMapByTbdCode.set(IncidentCode.RedCard, whistle);
liveSoundListMapByTbdCode.set(IncidentCode.YellowCard, whistle);
liveSoundListMapByTbdCode.set(IncidentCode.Goal, cheer);
liveSoundListMapByTbdCode.set(IncidentCode.PenaltyGoal, cheer);
liveSoundListMapByTbdCode.set(IncidentCode.SubIn, swish);
liveSoundListMapByTbdCode.set(IncidentCode.SubOut, swish);

export const liveSoundListMapByConfirmation = new Map();
liveSoundListMapByConfirmation.set(IncidentState.Confirmed, liveSoundListMapByConfirmedCode);
liveSoundListMapByConfirmation.set(IncidentState.Cancelled, liveSoundListMapByCancelledCode);
liveSoundListMapByConfirmation.set(IncidentState.Tbd, liveSoundListMapByTbdCode);
