import React, { FC, useContext, useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import { noop, pick } from 'lodash';
import { MatchLiveIncident, Match, MatchStatus, OddsProvider } from 'types';
import { sortItemsDesc, parseQuery } from 'utils';
import { sortIncidentsByIncidentTimeInOrder } from 'views/shared/Incident/incidentServices';
import { useLiveTicker } from 'context/liveTicker/live-ticker-context';
import { useConfig } from 'context/config-context';
import { useQuery } from 'react-query';
import { endOfToday, subDays } from 'date-fns';
import MatchClient from 'api/MatchClient';
import { useMatchesFilters } from 'views/Dashboard/MatchList/useMatchesFilters';


type Props = {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
  incidentList: MatchLiveIncident[];
  setIncidentList: React.Dispatch<React.SetStateAction<MatchLiveIncident[]>>;
  handleIncidentListInLiveFeed: (matchList?: MatchLiveIncident[]) => void;
}

const LiveMatchListContext = React.createContext<Props>({
  isLoading: false,
  incidentList: [],
  setLoading: noop,
  setIncidentList: noop,
  handleIncidentListInLiveFeed: noop,
});

const LiveMatchListProvider: FC = props => {
  const [incidentList, setIncidentList] = useState<MatchLiveIncident[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { configuration: { provider } } = useConfig();

  const handleIncidentListInLiveFeed = (matchLiveIncidentList: MatchLiveIncident[] = []) => {
    setIncidentList(prevState => {
      if (prevState.length === 0) {
        return matchLiveIncidentList
          .sort(sortIncidentsByIncidentTimeInOrder(sortItemsDesc));
      }

      const incidentsIds = prevState.map(({ id }) => id);

      const getUniqueWithId = matchLiveIncidentList
        .filter(incident => !incidentsIds.includes(incident.id))
        .sort(sortIncidentsByIncidentTimeInOrder(sortItemsDesc));

      return [...getUniqueWithId, ...prevState];
    });
    return;
  };

  const { getSelectedIncidentListByCode } = useLiveTicker();

  const { search } = useLocation();
  const parsedQuery = parseQuery(search);
  const { season = [] } = parsedQuery;
  const castedSeasonList = season as string[];
  const providerName = provider?.name as OddsProvider;

  const { filters: params } = useMatchesFilters();

  const filters = {
    ...pick(params, ['sportName']),
    status: [MatchStatus.Live, MatchStatus.Scheduled],
    from: String(subDays(new Date(), 1)),
    to: String(endOfToday()),
    providers: [providerName],
    selected: [],
    season: castedSeasonList,
  };

  const dependencies = [filters.sportName, ...filters.season];

  const {
    data: matchListData,
  } = useQuery(
    ['liveMatchList', dependencies],
    () => MatchClient.postSeasonLiveMatches({ params: filters }),
    { enabled: Boolean(incidentList.length) },
  );

  const matchWithOddsList: Match[] = useMemo(() => {
    return matchListData?.data?.events || [];
  }, [matchListData]);

  const matchIds = matchWithOddsList.map(({ event }) => event.id);

  const narrowIncidentList = (incidentList: MatchLiveIncident[]) => {
    return incidentList.filter(({ match }) => matchIds.includes(match.event.id));
  };

  const narrowedIncidentList = narrowIncidentList(getSelectedIncidentListByCode(incidentList));

  const values = {
    isLoading,
    setLoading,
    incidentList: narrowedIncidentList,
    setIncidentList,
    handleIncidentListInLiveFeed,
  };

  return (
    <LiveMatchListContext.Provider value={values} {...props} />
  );
};

const useLiveMatchListContext = () => {
  const context = useContext(LiveMatchListContext);

  if(context === undefined) {
    throw new Error('useLiveMatchListContext must be used within a LiveMatchListProvider');
  }

  return context;
};

export {
  LiveMatchListProvider,
  useLiveMatchListContext,
};
