/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { MobileAreaTournamentListProviders } from 'context/AuthProviders';
import { NavigationHeader } from 'components';
import { useMediaQuery } from 'hooks/useMediaQuery';

import { MOBILE_DASHBOARD_ROUTING } from '../../Routing/MobileDashboardRouting';
import { MediaQueriesRedirectController } from '../../../shared/MediaQueriesRedirectController';
import { DASHBOARD_ROUTING } from '../../Routing/DashboardRouting';
import { mediaQuery } from '../../../../constants/mediaQuery';

import { MobileAreaTournamentListFavouriteList } from './FavouriteList/MobileAreaTournamentListFavouriteList';
import { MobileAreaTournamentListContinentList } from './ContinentList/MobileAreaTournamentListContinentList';
import MobileAreaSportsBar from './SportsBar/MobileAreaSportsBar';

import './MobileAreaTournamentList.scss';


export const MobileAreaTournamentList = () => {
  const isDesktop = useMediaQuery(mediaQuery.minDesktopSmall);

  return (
    <MobileAreaTournamentListProviders>
      <MediaQueriesRedirectController
        url={DASHBOARD_ROUTING.getDefaultDashboardURL()}
        isMediaQueryActive={isDesktop}
      >
        <div className="dashboard-page__container">
          <NavigationHeader
            searchQuery={{ season: [] }}
            path={MOBILE_DASHBOARD_ROUTING.getDefaultDashboardURL()}
          />
          <div className="dashboard-page__sports-bar">
            <MobileAreaSportsBar />
          </div>

          <div className="dashboard-page__column">
            <div className="m-area-tournament-list__container">
              <section className="m-area-tournament-list__section">
                <MobileAreaTournamentListFavouriteList />
              </section>
              <section className="m-area-tournament-list__section">
                <MobileAreaTournamentListContinentList />
              </section>
            </div>
          </div>
        </div>
      </MediaQueriesRedirectController>
    </MobileAreaTournamentListProviders>
  );
};
