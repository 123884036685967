import React from 'react';
import { IncidentCode } from 'types';
import { ReactComponent as SubIn } from 'assets/icons/incidents/subIn.svg';
import { ReactComponent as SubOut } from 'assets/icons/incidents/subOut.svg';
import { ReactComponent as Goal } from 'assets/icons/incidents/goal.svg';
import { ReactComponent as GoalCancelled } from 'assets/icons/incidents/goal-cancelled.svg';
import { ReactComponent as OwnGoal } from 'assets/icons/incidents/own-goal.svg';
import { ReactComponent as YellowCard } from 'assets/icons/incidents/yellow-card.svg';
import { ReactComponent as YellowCardCancelled } from 'assets/icons/incidents/yellow-card-cancelled.svg';
import { ReactComponent as RedCard } from 'assets/icons/incidents/red-card.svg';
import { ReactComponent as RedCardCancelled } from 'assets/icons/incidents/red-card-cancelled.svg';
import { ReactComponent as PenaltyGoal } from 'assets/icons/incidents/penalty-goal.svg';
import { ReactComponent as PenaltyMissed } from 'assets/icons/incidents/penalty-missed.svg';
import { ReactComponent as PenaltyGoalCancelled } from 'assets/icons/incidents/penalty-goal-cancelled.svg';
import { ReactComponent as PenaltyGoalSavedByGoalKeeper } from 'assets/icons/incidents/goal-keeper-save.svg';


export const incidentConfirmedCodeMap = new Map();

export const getConfirmedIncidentsWithClassName = (className: string) => {
  return [
    { code: IncidentCode.Goal, className, icon: <Goal className="icon-color--first" /> },
    { code: IncidentCode.GoalCancelled, className, icon: <GoalCancelled className="icon-color--first" /> },
    { code: IncidentCode.SubIn, className, icon: <SubIn /> },
    { code: IncidentCode.SubOut, className, icon: <SubOut /> },
    { code: IncidentCode.YellowCard, className,  icon: <YellowCard /> },
    { code: IncidentCode.RedCard, className, icon: <RedCard /> },
    { code: IncidentCode.Penalty, className, icon: <PenaltyGoal className="icon-color--first" /> },
    { code: IncidentCode.PenaltyCancelled, className, icon: <PenaltyGoalCancelled className="icon-color--first" /> },
    { code: IncidentCode.PossiblePenalty, className },
    { code: IncidentCode.PenaltyGoal, className, icon: <PenaltyGoal className="icon-color--first" /> },
    { code: IncidentCode.MissedPenalty, className, icon: <PenaltyMissed className="icon-color--first" /> },
    { code: IncidentCode.PenaltySavedByGoalKeeper, className, icon: <PenaltyGoalSavedByGoalKeeper className="icon-color--first" /> },
    { code: IncidentCode.PossibleCardCancelled, className },
    { code: IncidentCode.PossibleCard, className },
    { code: IncidentCode.PossibleGoal, className },
    { code: IncidentCode.Half_1, className },
    { code: IncidentCode.OwnGoal, className, icon: <OwnGoal className="icon-color--first" /> },
    { code: IncidentCode.Corner, className },
  ];
};

export const getCancelledIncidentsWithClassName = (className: string) => {
  return [
    { code: IncidentCode.Goal, className, icon: <GoalCancelled className="icon-color--first" /> },
    { code: IncidentCode.PenaltySavedByGoalKeeper, className },
    { code: IncidentCode.SubIn, className, icon: <SubIn /> },
    { code: IncidentCode.SubOut, className, icon: <SubOut /> },
    { code: IncidentCode.YellowCard, className, icon: <YellowCardCancelled /> },
    { code: IncidentCode.RedCard, className, icon: <RedCardCancelled /> },
    { code: IncidentCode.Penalty, className, icon: <PenaltyGoalCancelled className="icon-color--first" />  },
    { code: IncidentCode.PenaltyCancelled, className, icon: <PenaltyGoalCancelled className="icon-color--first" />  },
    { code: IncidentCode.PossiblePenalty, className, icon: <PenaltyGoalCancelled className="icon-color--first" />  },
    { code: IncidentCode.PenaltyGoal, className, icon: <PenaltyGoalCancelled className="icon-color--first" /> },
    { code: IncidentCode.MissedPenalty, className, icon: <PenaltyGoalCancelled className="icon-color--first" />  },
    { code: IncidentCode.PossibleCardCancelled, className },
    { code: IncidentCode.PossibleCard, className },
    { code: IncidentCode.PossibleGoal, className },
    { code: IncidentCode.GoalCancelled, className, icon: <GoalCancelled className="icon-color--first" /> },
    { code: IncidentCode.Half_1, className },
    { code: IncidentCode.OwnGoal, className },
    { code: IncidentCode.Corner, className },
  ];
};
