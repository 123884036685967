import { createAggregatedURLListService } from 'views/shared/Routing/createAggregatedURLListService';

import { MATCH_DETAILS } from '../../Routing/Routing';


export const getMatchDetailsRoutes = ({ pathname, dashboardPath }: {pathname: string; dashboardPath: string }) => {
  return {
    DASHBOARD_MATCH: dashboardPath,
    DEFAULT: pathname,
    STATISTICS: `${pathname}${MATCH_DETAILS.STATISTICS}`,
    STATISTICS_TEAM_FORMATION: `${pathname}${MATCH_DETAILS.STATISTICS}${MATCH_DETAILS.TEAM_FORMATION}`,
    STATISTICS_MATCH_REPORT: `${pathname}${MATCH_DETAILS.STATISTICS}${MATCH_DETAILS.MATCH_REPORT}`,
    STATISTICS_MATCH_STATISTICS: `${pathname}${MATCH_DETAILS.STATISTICS}${MATCH_DETAILS.MATCH_STATS}`,
    STATISTICS_MATCH_ANALYSIS: `${pathname}${MATCH_DETAILS.STATISTICS}${MATCH_DETAILS.ANALYSIS}`,
    SIDE_BETS_THREE_WAY_HANDICAPS: `${pathname}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.THREE_WAY_HANDICAPS}`,
    SIDE_BETS_NUMBER_OF_GOALS: `${pathname}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.NUMBER_OF_GOALS}`,
    SIDE_BETS_HANDICAPS: `${pathname}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.HANDICAPS}`,
    SIDE_BETS_MATCH_RESULT: `${pathname}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.MATCH_RESULT}`,
    SIDE_BETS_CORRECT_SCORE: `${pathname}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.CORRECT_SCORE}`,
    SIDE_BETS: `${pathname}${MATCH_DETAILS.SIDE_BETS}`,
  };
};


type Props = {
  eventId: string;
  seasonId: string;
};

export const getURLWithId = (prefix: string) => ({ eventId, seasonId }: Props) => `${prefix}/${eventId}/season/${seasonId}`;


type URLProps = (getPrefix: ({ eventId, seasonId }: Props) => string) => (props: Props) => string;

export const getMatchDetailsURL: URLProps = getPrefix => props => {
  return getPrefix(props);
};

const getStatisticsURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.STATISTICS}`;
};

export const getTeamFormationURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.STATISTICS}${MATCH_DETAILS.TEAM_FORMATION}`;
};

export const getMatchReportURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.STATISTICS}${MATCH_DETAILS.MATCH_REPORT}`;
};

const getMatchStatsURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.STATISTICS}${MATCH_DETAILS.MATCH_STATS}`;
};

export const getSideBetsURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.SIDE_BETS}`;
};

const getMatchResultURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.MATCH_RESULT}`;
};

const getNumberOfGoalsURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.NUMBER_OF_GOALS}`;
};


const getHandicapsURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.HANDICAPS}`;
};

const getThreeWayHandicapsURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.THREE_WAY_HANDICAPS}`;
};

const getCorrectScoreURL: URLProps = getPrefix => props => {
  return `${getPrefix(props)}${MATCH_DETAILS.SIDE_BETS}${MATCH_DETAILS.CORRECT_SCORE}`;
};

const getURLMethods = [
  { name: 'getMatchDetailsURL', method: getMatchDetailsURL },
  { name: 'getStatisticsURL', method: getStatisticsURL },
  { name: 'getTeamFormationURL', method: getTeamFormationURL },
  { name: 'getMatchReportURL', method: getMatchReportURL },
  { name: 'getMatchStatsURL', method: getMatchStatsURL },
  { name: 'getSideBetsURL', method: getSideBetsURL },
  { name: 'getMatchResultURL', method: getMatchResultURL },
  { name: 'getCorrectScoreURL', method: getCorrectScoreURL },
  { name: 'getNumberOfGoalsURL', method: getNumberOfGoalsURL },
  { name: 'getHandicapsURL', method: getHandicapsURL },
  { name: 'getThreeWayHandicapsURL', method: getThreeWayHandicapsURL },
];

export const getMatchDetailsRouting = createAggregatedURLListService(getURLMethods, getURLWithId);
