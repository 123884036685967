import React from 'react';
import { useTheme } from 'context/theme-context';
import { Theme } from 'types';
import { useTranslate } from 'context/translate-context';

import { ThemeMode } from '../../../../../components/ThemeMode/ThemeMode';

import './MobileModalMenuTheme.scss';


const Translation: Record<Theme, string> = {
  [Theme.LIGHT]: 'LIGHT_THEME',
  [Theme.DARK]: 'DARK_THEME',
};

export const MobileModalMenuTheme = () => {
  const { translate } = useTranslate();
  const { theme } = useTheme();

  if (!theme) {
    return null;
  }
  return (
    <>
      <ThemeMode />
      <div className="m-modal-menu-theme__text margin-left--size-m">
        {translate(Translation[theme])}
      </div>
    </>
  );
};
