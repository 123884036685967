import React, { FC } from 'react';
import { useMatchList } from 'context/match-list-context';
import { EmptyElementFeedback } from 'components';
import { MatchPrediction, MatchStatus, TeamScore } from 'types';

import { DISPLAY_PREDICTION_HANDLER } from './matchListPredictionSectionServices';
import { MatchPredictions } from './Match/Predictions/MatchPredictions';


type Props = {
  matchStatus: MatchStatus;
  predictions: MatchPrediction;
  odds: Record<TeamScore, number>;
}

export const MatchSideBetsXpertPredictions: FC<Props> = ({ predictions, odds, matchStatus }) => {
  const { displayPrediction } = useMatchList();
  const { home: homePrediction, draw: drawPrediction, away: awayPrediction } = predictions;

  const isThereAnyPrediction = [homePrediction, drawPrediction, awayPrediction].some(Boolean);

  if (!isThereAnyPrediction) {
    return <EmptyElementFeedback text="NO_PREDICTION_AVAILABLE" />;
  }

  return (
    <MatchPredictions
      matchStatus={matchStatus}
      predictions={predictions}
      odds={odds}
      displayPredictionHandler={DISPLAY_PREDICTION_HANDLER[displayPrediction]}
    />
  );
};
