import { useCallback, useEffect, useState } from 'react';
import { BasketItem, Maybe } from 'types';

import { BasketMessage } from './BasketMessage.type';


const RETRY_LIMIT = 3;
const TICK_TIME = 1000;

export const useInitializeWithBasketApp = (items: BasketItem[]) => {
  let intervalId: Maybe<NodeJS.Timer> = null;

  const [madeRetry, setMadeRetry] = useState<number>(0);
  const itemsLength = items.length;

  const initializeRetry = () => {
    if (!intervalId) {
      intervalId = setInterval(() => {
        if (madeRetry >= RETRY_LIMIT) {
          return clearInterval(intervalId as NodeJS.Timer);
        }

        setMadeRetry(prevState => prevState + 1);

        if (itemsLength === 0) {
          const event = new CustomEvent(BasketMessage.IsInitialized, { detail: true });

          window.dispatchEvent(event);
        }
      }, TICK_TIME);
    }
  };

  const memoInitalizeRetry = useCallback(initializeRetry, [madeRetry, itemsLength]);

  useEffect(() => {
    memoInitalizeRetry();

    return () => {
      clearInterval(intervalId as NodeJS.Timer);
    };
  }, [memoInitalizeRetry, intervalId]);
};
