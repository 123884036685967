import React, { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { PipeProviders } from '../hocs';


import { TranslateProvider } from './translate-context';
import { ThemeProvider } from './theme-context';

type Props = {
  basename?: string;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 9, // 9 minutes
      staleTime: 1000 * 60 * 9, // 9 minutes
      refetchOnMount: false,
    },
  },
});

const AppProviders: FC<Props> = ({ basename, children }) => {
  const Providers = [
    TranslateProvider,
    ThemeProvider,
  ];

  return (
    <Router basename={basename}>
      <QueryClientProvider client={queryClient}>
        <PipeProviders components={Providers}>
          {children}
        </PipeProviders>
      </QueryClientProvider>
    </Router>
  );
};

export default AppProviders;
