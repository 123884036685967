const basket = {
  SINGLE: 'Enkel',
  COMBI: 'Kombo',
  SYSTEM: 'System',
  CLOSE: 'Lukk', // Kurv
  SYSTEM_GROUP: 'Systemgruppe',
  WITHOUT_GROUP: 'Ingen gruppe',
  GROUP: 'Gruppe',
  BANKER: 'Banker',
  SELECT_BET: 'Velg innsatsen din',
  BACK: 'Tilbake',
  LINK_CLICKED: 'Linken ble benyttet',
  PLACE_BET: 'Sett et spill',
  COMBINATION: 'Kombinasjon',
  COMBINATIONS: 'Kombinasjoner',
  SELECT_COMBINATIONS: 'Velg kombinasjoner',
  CANT_BET: 'Du kan ikke plassere dette spillet',
  INVALID_VALUE: 'Ugyldig innsats',
  STAKE_PER_COMBO: 'Innsats per kombinasjon',
  TOTAL_STAKE: 'Total innsats',
  MIN_WIN: 'Min. premie:',
  MAX_WIN: 'Maks. premie:',
  STAKE_PER_BETS: 'Innsats per spill',
  TOTAL_ODDS: 'Total Odds',
  EMPTY_TICKET: 'Kupongen er tom.',
  CANT_MULTI_BET: 'Ikke tillatt å spille singel på denne kampen',
  OVERDUE_BET_LIST: 'Ett eller flere spill er inaktive.\nFjern inaktiv innsats fra kupongen.',
  TICKET: 'Kupong',
  SYSTEM_OF: 'System av',
  COMBO_BETS: 'kombo spill',
};

export default basket;
