import React, { ReactElement, FC } from 'react';
import { MatchStatus, TeamScore } from 'types';
import { isEmpty } from 'lodash';
import { EmptyListFeedback } from 'components';

import { MatchDetailsVenue } from '../shared/MatchDetails/Venue/MatchDetailsVenue';

import { MatchDetailsResultTeams } from './TeamFormation/MatchDetailsResultTeams';
import { MatchDetailsTeamLineups } from './MatchDetailsTeamLineups';
import { MatchDetailsTeamLineupsController } from './TeamFormation/MatchDetailsTeamLineupsController';

import './MatchDetailsSummaryWrapper.scss';


type Props = {
  children: (props: {
    status: MatchStatus;
  }) => ReactElement;
};

export const MatchDetailsSummaryWrapper: FC<Props> = ({ children }) => {
  return (
    <MatchDetailsTeamLineupsController>
      {({ home, away, selectedMatch, selectHome, selectAway }) => {
        const { event, venue } = selectedMatch || {};
        const { status = MatchStatus.Scheduled } = event || {};

        return (
          <>
            {
              [MatchStatus.Finished, MatchStatus.Live].includes(status) ? (
                <div className="match-details-summary-wrapper__container">
                  <MatchDetailsTeamLineups
                    teamScore={TeamScore.Home}
                    teamLineup={home}
                    team={selectHome}
                  />
                  <MatchDetailsResultTeams
                    match={selectedMatch}
                  >
                    <>
                      {!isEmpty(venue) && <MatchDetailsVenue venue={venue} />}
                      {children({ status })}
                    </>
                  </MatchDetailsResultTeams>
                  <MatchDetailsTeamLineups
                    teamLineup={away}
                    team={selectAway}
                    teamScore={TeamScore.Away}
                  />
                </div>
              ) : <EmptyListFeedback />
            }
          </>
        );
      }}
    </MatchDetailsTeamLineupsController>
  );
};
