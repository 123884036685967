import React, { FC } from 'react';
import { MatchLiveIncident, TeamScore } from 'types';
import { getMinutesFromMatch } from 'views/shared/Incident/incidentServices';

import { LiveMatchIncident } from './Incident/LiveMatchIncident';

import './LiveMatchAggregatedIncident.scss';


type Props = {
  incident: MatchLiveIncident;
}

export const LiveMatchAggregatedIncident: FC<Props> = ({ incident }) => {
  const { teamOrigin, player, match, name } = incident;
  const { teamDetails: { badgeUrl, acronym } } = match[teamOrigin as TeamScore.Home | TeamScore.Away];


  return (
    <div className="aggregated-incident__container">
      <div className="aggregated-incident__grid">
        <div />
        <div className="aggregated-incident__incident-section">
          <LiveMatchIncident
            name={name}
            time={getMinutesFromMatch(incident.occurredAt)}
            confirmation={incident?.confirmation}
            incidentCode={incident.code}
            player={player}
            acronym={acronym}
            badgeUrl={badgeUrl}
          />
        </div>
      </div>
    </div>
  );
};
