import { useState, useEffect } from 'react';


export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);
  const media = window.matchMedia(query);

  useEffect(() => {
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const callback = () => {
      setMatches(media.matches);
    };
    media.addEventListener('change', callback);

    return () => {
      media.removeEventListener('change', callback);
    };
    // eslint-disable-next-line
  }, [matches, query]);

  return matches;
};


