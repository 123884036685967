import { format } from 'date-fns';

import { FormatDate } from './FormatDate';


export const formatDayAndMonthByCustomTranslation = (translate: (text: string) => string) => (date: string) => {
  return `${format(new Date(date), 'dd')} ${translate(format(new Date(date), 'MM'))}`;
};

export const formatShortMontTextByCustomTranslation = (translate: (text: string) => string) => (date: string) => {
  return `${translate(format(new Date(date), FormatDate.CALENDAR_DISPLAY_DATE_FORMAT_DAY_TEXT))}`;
};

export const formatFullDateByCustomTranslation = (translate: (text: string) => string) => (date: string) => {
  return `${translate(format(new Date(date), 'iiii'))} ${format(new Date(date), 'dd')} ${translate(format(new Date(date), 'MMMM'))} ${format(new Date(date), 'yyyy')}`;
};


export const formatCalendarDateMap = new Map();

formatCalendarDateMap.set(FormatDate.CALENDAR_DISPLAY_DATE_FORMAT_DAY_MONTH, formatDayAndMonthByCustomTranslation);
formatCalendarDateMap.set(FormatDate.CALENDAR_DISPLAY_DATE_FORMAT_DAY_TEXT, formatShortMontTextByCustomTranslation);
formatCalendarDateMap.set(FormatDate.CALENDAR_DISPLAY_DATE_FORMAT, formatFullDateByCustomTranslation);
