import React from 'react';

import { useTranslate } from '../../../context/translate-context';

import { genderSelectValues } from './userAccountFormInitialValues';

import './UserAccountFormGenderSection.scss';


export const UserAccountFormGenderSection = ({ value, label,  fieldName, onChange }) => {
  const { translate } = useTranslate();

  return (
    <section className="user-account-form-gender__radio-buttons-container">
      <div className="user-account-form-gender__label">{label}</div>
      <div className="user-account-form-gender__radio-buttons">
        {genderSelectValues.map(genderInfo => (
          <RadioButton
            value={value}
            fieldName={fieldName}
            onChange={onChange}
            {...genderInfo}
            text={translate(`${genderInfo.text}`)}
          />
        ))}
      </div>
    </section>
  );
};


const RadioButton = ({ text, value, fieldName, onChange }) => {
  const isRadioButtonValueEqualToFormValue = text === value;
  const handleActiveTextClass = isRadioButtonValueEqualToFormValue ? 'user-account-form-gender__label--active' : '';

  return (
    <div className="user-account-form-gender__radio-button">
      <button
        type="button"
        className="user-account-form-gender__radio-outline margin-right--size-m"
        onClick={() => onChange({ target: { value: text, name: fieldName } })}
      >
        {isRadioButtonValueEqualToFormValue && (
          <div className="user-account-form-gender__radio-dot" />
        )}
      </button>
      <div className={`user-account-form-gender__label ${handleActiveTextClass}`}>{text}</div>
    </div>
  );
};
