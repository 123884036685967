import React from 'react';

import { ReactComponent as User } from 'assets/icons/user.svg';

import './UserInCircle.scss';


export const UserInCircle = ({ containerStyles, svgStyles }) => {
  return (
    <div className={`user-in-circle__icon-container ${containerStyles || ''}`}>
      <div className="user-in-circle__icon-container-inner-background">
        <User fill="var(--SaasAccentGreen)" className={`user-in-circle__svg ${svgStyles || ''}`}/>
      </div>
    </div>
  );
};
