import React from 'react';

import { MatchDetailsSideBetsCorrectScore } from '../../../../MatchDetails/SideBets/CorrectScore/MatchDetailsSideBetsCorrectScore';
import { getSideBetsGridHeaders, createMatchDetailsSideBetsRowHeaderWithKey } from '../../../../shared/MatchDetails/SideBets/matchDetailsSideBetsServices';


const createRowWithCustomClass = createMatchDetailsSideBetsRowHeaderWithKey({ key: 'correct-score' });
const rows = [
  createRowWithCustomClass({}),
  createRowWithCustomClass({ customStyle: 'match-details-prediction--from-tablet' }),
  createRowWithCustomClass({ customStyle: 'match-details-prediction--from-tablet' }),
];

export const MobileMatchDetailsSideBetsCorrectScoreSection = () => {
  return (
    <MatchDetailsSideBetsCorrectScore
      ColumnsLabels={getSideBetsGridHeaders(rows)}
    />
  );
};
