import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { SportsName } from 'types';

import api from '../services/FetchService';

import { GetAreaList } from './types';
import * as query from './queries';


export class AreaClient {
  getAreaList(sportName: SportsName): AxiosPromise<GetAreaList> {
    const options: AxiosRequestConfig = {
      ...query.getAreaList,
      url: `${query.getAreaList.url}/${sportName}`,
    };

    return api.request(options);
  }
}
