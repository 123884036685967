const apiErrorsNO = {
  'ACCESS_TOKEN_ALREADY_EXISTS': 'Tilgangstoken eksisterer allerede.',
  'ACCESS_TOKEN_NOT_EXISTS': 'Tilgangstoken eksisterer ikke.',
  'ACTIVATION_CODE_DOESNT_EXIST': 'Aktiviseringskoden eksisterer ikke.',
  'BIRTH_DATE_IS_IN_FUTURE': 'Fødselsdato kan ikke være i fremtiden',
  'BIRTH_DATE_IS_NOT_A_DATE': 'Ugyldig datoformat',
  'BIRTH_DATE_TO_FAR_IN_PAST': 'Fødselsdato er for langt i fortiden',
  'CREDIT_CARD_NUMBER_NOT_PROVIDED': 'Kredittkortdata er ikke oppgitt',
  'DATE_IS_OUT_OF_THE_RANGE': 'Datoen er ugyldig.',
  'DOMAIN_ALREADY_EXIST': 'Dette domenet eksisterer allerede',
  'EMAIL_REQUIRED': 'E-postadresse er påkrevd.',
  'FETCH_AREAS_FAILED': 'Henting av områder mislyktes.',
  'FETCH_PACKAGES_FAILED': 'Henting av pakker mislyktes.',
  'INVALID_DOMAIN_DATA': 'Ugyldig domenedata.',
  'INVALID_DOMAIN_LENGTH': 'Domenenavn er for kort eller for langt.',
  'INVALID_DOMAIN_NAME': 'Domenenavn er ikke gyldig.',
  'INVALID_FORMAT_EXPECTED_STRING': 'Ugyldig format',
  'INVALID_PASSWORDS_DATA': 'Ugyldig data for passord.',
  'INVALID_USER_DATA': 'Ugyldige brukerdata.',
  'INVALID_USER_EMAIL_FORMAT': 'Ugyldig e-postformat.',
  'INVALID_USER_EMAIL_OR_PASSWORD': 'Ugyldig e-post eller passord.',
  'INVALID_USER_EMAIL': 'Ugyldig e-post.',
  'INVALID_USER_FIELD_LENGTH': 'Verdien er for lang eller for kort.',
  'INVALID_USER_PASSWORD_LENGTH': 'Ugyldig lengde på passordet.',
  'IP_NOT_AUTHORIZED': 'IP-adresse er ikke autorisert.',
  'IP_NOT_DELETED': 'IP-adresse blir ikke slettet.',
  'IP_NOT_FOUND': 'Ip-adresse eksisterer ikke.',
  'IS_REQUIRED': 'Dette feltet er obligatorisk.',
  'MISSING_EVENT_IDS': 'Hendelsen mangler ID.',
  'MISSING_NEW_PASSWORD': 'Nytt passord kan ikke være tomt.',
  'MISSING_ODDS_PROVIDER': 'Mangler oddsleverandør.',
  'MISSING_OLD_PASSWORD': 'Gammelt passord kan ikke være tomt.',
  'MISSING_PERMISSIONS': 'Mangler tillatelse.',
  'MISSING_REPEAT_NEW_PASSWORD': 'Du må skrive inn passord på nytt.',
  'MISSING_TOKEN': 'Token kreves for å bruke programmet.',
  'MODE_DOESNT_EXIST': 'Modus eksisterer ikke',
  'MODE_NOT_AVAILABLE': 'Modus er ikke tilgjengelig.',
  'NEW_PASSWORD_TOO_SHORT': 'Nytt passord er for kort.',
  'NOT_SELECTED_COMBINATIONS': 'Kombinasjoner ble ikke valgt',
  'PASSWORD_RESET_LINK_INVALID_OR_EXPIRED': 'Lenken er ugyldig eller utløpt',
  'PASSWORDS_DONT_MATCH': 'Passordene er ulike.',
  'TOKEN_NOT_DELETED': 'Token ble ikke slettet.',
  'TOKEN_NOT_FOUND': 'Token finnes ikke.',
  'UNKNOWN_API_ERROR': 'Ukjent feil oppstod. Prøv igjen om noen sekunder.',
  'USER_DID_NOT_CONNECT_CARD': 'Koble til kredittkortet ditt, ellers blir tilgangen din opphevet etter prøveperioden.',
  'USER_DID_NOT_PAY': 'Det oppsto et problem med din siste betaling. Ta kontakt med brukerstøtten for mer informasjon.',
  'USER_DOESNT_EXIST': 'Bruker eksisterer ikke.',
  'USER_EXISTS': 'Bruker eksisterer allerede.',
  'USER_IS_ALREADY_ACTIVE': 'Brukeren er allerede aktiv.',
  'USER_IS_UNDERAGED': 'Brukeren er under tillatt alder.',
  'USER_NOT_AUTHORIZED': 'Brukeren er ikke logget inn',
  'USER_NOT_EXISTS': 'Bruker eksisterer ikke.',
  'USER_NOT_IN_DB': 'Brukeren finnes ikke i databasen.',
  'USER_NOT_VERIFIED': 'Brukeren er ikke bekreftet.',
  'USER_ON_TRIAL': 'Du er i prøveperioden. Legg til et kredittkort for å starte abonnementet og beholde tilgangen.',
  'USER_WAS_NOT_UPDATED': 'Brukeren ble ikke oppdatert',
  'WRONG_OLD_PASSWORD': 'Det gamle passordet er ugyldig.'
};

export default apiErrorsNO;
