import React, { memo } from 'react';
import { useUpdateMatchDetailsSideBets } from 'views/Dashboard/shared/hooks/updateMatchDetailsSideBets/useUpdateMatchDetailsSideBets';

import { MatchDetailsSideBetsNumberOfGoalsDefault } from '../Default/MatchDetailsSideBetsNumberOfGoalsDefault';


export const MatchDetailsSideBetsNumberOfGoalsLive = memo(() => {
  useUpdateMatchDetailsSideBets();

  return (
    <MatchDetailsSideBetsNumberOfGoalsDefault />
  );
});
