
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { noop } from 'lodash';
import { InfiniteData, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { Match, OddsProvider, OddListGroupingType } from 'types';
import OddsClient  from 'api/OddsClient';
import { RouteParam } from 'views/Dashboard/Mobile/MatchDetails/RouteParam';

import { GetMatchListResponse } from '../../api/MatchClient/types';
import { useConfig } from '../config-context';

import { transformToDisplayedSideBetsOnMatchList } from './services';


type Props = {
  oddList: OddListGroupingType[];
  areOddsLoading: boolean;
  setOddList: React.Dispatch<React.SetStateAction<OddListGroupingType[]>>;
}

const MatchListSideBetsOddsContext = React.createContext<Props>({
  oddList: [],
  areOddsLoading: false,
  setOddList: noop,
});

const MatchListSideBetsOddsProvider: FC = props => {
  const { eventId } = useParams<RouteParam>();
  const { configuration: { provider: { name: providerName = '' } } } = useConfig();
  const [matchesCache, setMatchesCache] = useState<InfiniteData<AxiosResponse<GetMatchListResponse>>>();

  const query = useQueryClient().getQueryCache();

  useEffect(() => {
    let isMounted = true;
    query.subscribe(notify => {
      if (notify?.query?.queryKey[0] === 'matchesList') {
        if (isMounted) {
          setMatchesCache(notify.query.state.data);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [query]);

  const { pages = [] } = matchesCache || {};
  const events: Match[] = useMemo(() => {
    return pages?.flatMap(p => p.data.events) || [];
  }, [pages]);

  const eventsIds = eventId ?
    [eventId, ...events.map(({ event }) => event.id)] :
    events.map(({ event }) => event.id);

  const {
    isLoading: areOddsLoading,
    data,
  } = useQuery(
    ['matchListOddList', { eventsIds }],
    () => OddsClient.getSideBetsOdds({ eventsIds, providers: [providerName] as OddsProvider[] }),
    {
      keepPreviousData: true,
      enabled: Boolean(eventsIds.length) && Boolean([providerName].length),
    });

  const [oddList, setOddList] = useState<OddListGroupingType[]>([]);

  useMemo(() => {
    const result = data?.data?.odds || [];

    setOddList(transformToDisplayedSideBetsOnMatchList(result));
  }, [data]);

  // selectors
  const values = {
    oddList,
    areOddsLoading,
    setOddList,
  };

  return (
    <MatchListSideBetsOddsContext.Provider
      value={values}
      {...props}
    />
  );
};

const useMatchListSideBetsOdds = () => {
  const context = useContext(MatchListSideBetsOddsContext);

  if(context === undefined) {
    throw new Error('useMatchListSideBetsOdds must be used within a MatchListSideBetsOddsProvider');
  }

  return context;
};


export {
  MatchListSideBetsOddsProvider,
  useMatchListSideBetsOdds,
};
