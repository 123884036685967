const calendar = {
  'Mon': 'Mån',
  'Tue': 'Tis',
  'Wed': 'Ons',
  'Thu': 'Tor',
  'Fri': 'Fre',
  'Sat': 'Lör',
  'Sun': 'Sön',
  Monday: 'Måndag',
  Tuesday: 'Tisdag',
  Wednesday: 'Onsdag',
  Thursday: 'Torsdag',
  Friday: 'Fredag',
  Saturday: 'Lördag',
  Sunday: 'Söndag',
  PREV_DAY: 'Föregående dag',
  NEXT_DAY: 'Nästa dag',
  PREV_MONTH: 'Föregående månaden',
  NEXT_MONTH: 'Nästa månaden',
  CALENDAR: 'Kalender',
};

export default calendar;
