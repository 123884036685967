import { addDays, eachDayOfInterval, endOfMonth, endOfWeek, isSameMonth, startOfMonth, startOfWeek, subDays } from 'date-fns';

import { formatDate, formatDay } from '../../utils';

export const getCurrentMonthWeeks = (currentDate, setWeeks) => {
  const {
    firstWeekOfTheMonth,
    lastWeekOfTheMonth,
  } = getWeeksAndBoundaryMonthDates(currentDate);

  const getWeeks = getDates(firstWeekOfTheMonth, lastWeekOfTheMonth, currentDate);

  setWeeks(getWeeks);
};

const createDays = (days, currentlySelectedMonth) => {
  const selectedMonth = new Date(currentlySelectedMonth);

  return days.map(day => {
    return {
      day: formatDay(day),
      dateFormated: formatDate(day),
      date: day,
      isCurrentMonth: isSameMonth(day, selectedMonth),
      isInClickableRange: getClickableDayRange().includes(formatDate(day)),
    };
  });
};

const getDates = (startDate, endDate, currentDate) => {
  return createDays(eachDayOfInterval({ start: startDate, end: endDate }), currentDate);
};

const getWeeksAndBoundaryMonthDates = currentDate => {
  const mondayStartOfTheWeek = { weekStartsOn: 1 };
  const startOfTheMonth = startOfMonth(currentDate, mondayStartOfTheWeek);
  const endOfTheMonth = endOfMonth(currentDate, mondayStartOfTheWeek);

  const firstWeekOfTheMonth = startOfWeek( startOfTheMonth, mondayStartOfTheWeek);
  const lastWeekOfTheMonth = new Date(endOfWeek(endOfTheMonth, mondayStartOfTheWeek));

  return {
    firstWeekOfTheMonth,
    lastWeekOfTheMonth,
  };
};

export const getWeekOfSelectedDate = () => {
  const today = new Date();
  const mondayStartOfTheWeek = { weekStartsOn: 1 };
  const weekStart = startOfWeek(new Date(today), mondayStartOfTheWeek);
  const weekEnd = endOfWeek(new Date(today), mondayStartOfTheWeek);

  return eachDayOfInterval({ start: weekStart, end: weekEnd })
    .map(day => formatDate(day));
};

export const getClickableDayRange = () => {
  const { rangeStart, rangeEnd } = dateRange();

  return eachDayOfInterval({ start: rangeStart, end: rangeEnd })
    .map(day => formatDate(day));
};

export const dateRange = () => {
  const today = new Date();
  const tenDaysBefore = subDays(today, 10);
  const tenDaysAfter = addDays(today, 10);

  return { rangeStart: tenDaysBefore, rangeEnd: tenDaysAfter };
};

