
import { FC, ReactElement } from 'react';

import { isObjectEmpty } from '../../../utils';
import { MatchQueryFilters, Event } from '../../../types';
import { MatchSort } from '../../../types/Match/MatchFilters';

import { useMatchesHandlers } from './matchListHooks';
import { useMatchesFilters } from './useMatchesFilters';
import { useMatchListSort } from './useMatchListSort';


type Props = {
  children(props: {
    filters: MatchQueryFilters;
    handleSetOrder: (sort: MatchSort) => void;
    handleSetStatus: (sort: Event['status'][]) => void;
    isSelectedStatusActive: (sort: Event['status']) => boolean;
  }): ReactElement;
}


export const MatchListFilterSectionController: FC<Props> = ({ children }) => {
  const { filters } = useMatchesFilters();
  const {
    handleSetStatus,
    isSelectedStatusActive,
  } = useMatchesHandlers();

  const { handleSetOrder } = useMatchListSort();

  if (isObjectEmpty(filters)) {
    return null;
  }

  const props = {
    handleSetOrder,
    handleSetStatus,
    isSelectedStatusActive,
    filters,
  };

  return children(props);
};
