import React, { Fragment, memo, FC, ReactElement } from 'react';

import './MatchListHeader.scss';


type Props = {
  tournamentName?: string;
  Tournament?: ReactElement;
  customClass?: string;
  children?: ReactElement;
};

export const MatchListHeader: FC<Props> = memo(({
  tournamentName = '',
  Tournament,
  customClass = '',
  children,
}) => {
  return (
    <Fragment>
      <li
        className={`match-list-header__container match-list-header__grid ${customClass}`}
        key={`${tournamentName}-list-element`}
      >
        {Tournament || <div />}
      </li>
      {children}
    </Fragment>
  );
});
