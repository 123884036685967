import React from 'react';
import { EmptyListFeedback } from 'components';
import { MatchDetailsMatchReportIncidentsController } from 'views/Dashboard/MatchDetails/MatchReport/MatchDetailsMatchReportIncidentsController';
import { MatchDetailsMatchReportIncidents } from 'views/Dashboard/MatchDetails/MatchReport/MatchDetailsMatchReportIncidents';


export const MobileMatchDetailsStatisticMatchReport = () => {
  return (
    <MatchDetailsMatchReportIncidentsController>
      {({ incidents }) => (
        <>
          {incidents.length === 0 && <EmptyListFeedback />}
          {incidents.length > 0 && <MatchDetailsMatchReportIncidents incidents={incidents} />}
        </>
      )}
    </MatchDetailsMatchReportIncidentsController>
  );
};
