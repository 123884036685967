import { isFunction } from 'lodash';
import { pipe } from 'utils/pipe';

import { TEAM_SCORE } from '../../../../constants';
import { BasketItem, OddsType, OddsTypePeriod } from '../../../../types';


const stringToTranslation = (text: string) => text.split('_').map(word => word.toUpperCase()).join('_');

const oddTypePeriodToTranslation = (time: OddsTypePeriod) => {
  if (time === OddsTypePeriod.Fulltime) {
    return 'full_time';
  }
  return time;
};

const oddTypeMap = new Map();
oddTypeMap.set(OddsType.Unknown, (item: BasketItem) => item.externalType);

export const handleDisplayOddType = (translate: (item: string) => string) => (item: BasketItem) => {
  const getOddTypeDisplayFunction = oddTypeMap.get(item.oddType);
  const formatOddType = pipe(
    stringToTranslation,
    translate,
  );

  const formatOddTypePeriod = pipe(
    oddTypePeriodToTranslation,
    stringToTranslation,
    translate,
  );

  const handleDisplayOddType = getOddTypeDisplayFunction ?
    getOddTypeDisplayFunction(item) :
    `${formatOddType(item.oddType)} (${formatOddTypePeriod(item.oddTypePeriod)})`;

  return handleDisplayOddType;
};


const betNameMap = new Map();
betNameMap.set(TEAM_SCORE.HOME, '1');
betNameMap.set(TEAM_SCORE.DRAW, 'X');
betNameMap.set(TEAM_SCORE.AWAY, '2');


const betNameMapByOddsType = new Map();
betNameMapByOddsType.set(OddsType.Outcome, (item: BasketItem) => betNameMap.get(item.odd.name));
betNameMapByOddsType.set(OddsType.Handicap3WX, (item: BasketItem) => betNameMap.get(item.odd.name));
betNameMapByOddsType.set(OddsType.Unknown, (item: BasketItem) => item.odd.name);


export const handleDisplayBetName = (item: BasketItem) => {
  const { name } = item.odd;
  const getBetNameByOddsTypePeriod = betNameMapByOddsType.get(item.oddType);
  const handleDisplayLabel = !isFunction(getBetNameByOddsTypePeriod) ? name : getBetNameByOddsTypePeriod(item);

  return handleDisplayLabel;
};
