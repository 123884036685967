import { useState, ReactElement, FC } from 'react';
import { MatchTime } from 'types';


type Props = {
  children(props: {
    activePredictionTab: MatchTime;
    setActivePredictionTab: (matchTime: MatchTime) => void;
    handleHighlightTab: (tab: MatchTime) => string;
  }): ReactElement;
}

const isTabActive = (activeTab: MatchTime) => (tab: MatchTime) => activeTab === tab ? 'match-details-side-bets__tab--active' : '';

export const MatchDetailsPredictionsSectionController: FC<Props> = ({ children }) => {
  const [activePredictionTab, setActivePredictionTab] = useState(MatchTime.RegularTime);
  const handleHighlightTab = isTabActive(activePredictionTab);

  const props = {
    activePredictionTab,
    handleHighlightTab,
    setActivePredictionTab,
  };

  return children(props);
};
