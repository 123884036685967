import React from 'react';
import { Cart } from 'components/Cart';


export const MobileNavigationLinkBasket = () => {
  return (
    <Cart
      isLabelVisible
      customTextClass="m-navigation__element-text"
    />
  );
};
