import React, { memo, FC } from 'react';
import { GameTeam, MatchStatusName, Event, GameTeamPosition } from 'types';

import { Badge } from '../../../shared/Badge/Badge';
import { handleMatchResult } from '../../../../shared/Match/matchServices';
import MatchResult from '../../../shared/Match/Result';

import './MatchTeam.scss';


type Props = {
  statusName: Event['statusName'];
  status: Event['status'];
  team: GameTeam;
  type: GameTeamPosition;
}

export const MobileMatchTeam: FC<Props> = memo(({ team, statusName, status, type }) => {
  const { teamDetails } = team;
  const { badgeUrl: badgeURL = '', name = '', displayedName = '', shortName = '' } = teamDetails || {};
  const handleTeam = type === GameTeamPosition.Home ? { home: team } : { away: team };
  const fieldResult = type === GameTeamPosition.Home ? 'homeResult' : 'awayResult';

  const {
    [fieldResult]: firstHalfScore,
  } = handleMatchResult(MatchStatusName['1stHalf'])(handleTeam);

  const secondHalfStatusName = MatchStatusName['1stHalf'] !== statusName ?
    statusName : MatchStatusName['2ndHalf'];

  const {
    [fieldResult]: regularScore,
  } = handleMatchResult(secondHalfStatusName)(handleTeam);


  return (
    <div className={`match-team match-team--${type}`}>
      <Badge
        customStyles={`match-team__badge match-team--${type}`}
        badgeURL={badgeURL}
        alt={`${name} badge`}
      />
      <span className={'match-team__short-name match-team--tournament-variant'}>
        {displayedName || shortName}
      </span>
      <MatchResult.Mobile
        firstHalfScore={firstHalfScore}
        regularTimeScore={regularScore}
        status={status}
      />

    </div>
  );
});
