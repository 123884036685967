/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';

import { MatchListFilterClearFilters } from '../MatchList/Filter/Clear';

import { SidebarAreaTournamentContinentList } from './ContinentList/SidebarAreaTournamentContinentList';
import { SidebarAreaTournamentListFavouriteList } from './FavouriteList/SidebarAreaTournamentListFavouriteList';

import './Sidebar.scss';


const Sidebar = () => {
  return (
    <aside className="sidebar">
      <MatchListFilterClearFilters />
      <section className="sidebar__section">
        <SidebarAreaTournamentListFavouriteList />
      </section>
      <section className="sidebar__section">
        <SidebarAreaTournamentContinentList />
      </section>
    </aside>
  );
};

export default Sidebar;
