const calendar = {
  'Mon': 'Mon',
  'Tue': 'Tue',
  'Wed': 'Wed',
  'Thu': 'Thu',
  'Fri': 'Fri',
  'Sat': 'Sat',
  'Sun': 'Sun',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  PREV_DAY: 'Previous day',
  NEXT_DAY: 'Next day',
  PREV_MONTH: 'Previous day',
  NEXT_MONTH: 'Next day',
  CALENDAR: 'Calendar',
};


export default calendar;
