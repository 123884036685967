import { CountryLanguage } from 'types';

import en from './translations/en';
import sv from './translations/sv';
import no from './translations/no';

const translations: Record<CountryLanguage, Record<string, string>> = {
  en,
  sv,
  no,
};

export default translations;
