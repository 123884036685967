import { useLiveTicker } from 'context/liveTicker/live-ticker-context';
import { FC, ReactElement } from 'react';
import { Noop } from 'types';


type Props = {
  children(props: {
    incrementDisplayLimit: Noop;
    decrementDisplayLimit: Noop;
    liveTickerDisplayLimit: number;
  }): ReactElement;
}


export const LiveContronTimeController: FC<Props> = ({ children }) => {
  const {
    incrementDisplayLimit,
    decrementDisplayLimit,
    liveTickerDisplayLimit,
  } = useLiveTicker();

  const props = {
    incrementDisplayLimit,
    decrementDisplayLimit,
    liveTickerDisplayLimit,
  };

  return children(props);
};
