import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ReactComponent as Setting } from 'assets/icons/setting.svg';
import { useTranslate } from 'context/translate-context';
import { Size } from 'types';
import { LIVE_DASHBOARD_ROUTING } from 'views/LiveDashboard/Routing/LiveDashboardRouting';

import { LiveControlPanel } from '../../Control/Panel/LiveControlPanel';

import './LiveTickerOverlay.scss';


type Props = {
  incidentsRemaining: number;
}

export const LiveTickerOverlay: FC<Props>  = ({ incidentsRemaining }) => {
  const { search } = useLocation();
  const { translate } = useTranslate();

  const link = `${LIVE_DASHBOARD_ROUTING.getDefaultLiveDashboardURL()}${search}`;

  return (
    <div className="live-ticker-overlay">
      <div className="live-ticker-overlay__container live-ticker-overlay__container--show-all">
        <Link
          className="live-ticker-overlay__link"
          to={link}
        >
          <Icon
            name="chevron up"
            size={Size.Small}
            className="live-match margin--unset"
          />
          <div className="live-ticker-overlay__text">
            {translate('SHOW_ALL')}
          </div>
          <div className="live-ticker-overlay__counter">
            {incidentsRemaining}
          </div>
        </Link>
        <LiveControlPanel
          canControlTime
          canMuteSound
          position="top"
        >
          {({ handleToggleExpand }) => {
            return (
              <button
                className="live-ticker-overlay__panel-btn"
                onClick={handleToggleExpand}
                aria-label={translate('LIVE_TICKER_SETTINGS')}
              >
                <Setting className="live-ticker-overlay__icon" />
              </button>
            );
          }}
        </LiveControlPanel>
      </div>
    </div>
  );
};
