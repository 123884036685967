const formFields = {
  ACTIVATION_CODE: 'Aktiviseringskode',
  BIRTH_DATE: 'Fødeselsdag',
  EMAIL: 'E-post',
  FIRST_NAME: 'Fornavn',
  LAST_NAME: 'Etternavn',
  PASSWORD: 'Passord',
  REPEATED_PASSWORD: 'Gjentatt passord',
  TERMS_AND_POLICY: 'Vilkår for bruk og personvernerklæring',
  CREATE_YOUR_ACCOUNT: 'Opprett kontoen din',
  FORGOT_YOUR_PASSWORD: 'Har du glemt passordet ditt?',
  LOGIN_WITH_FACEBOOK: 'Logg på med Facebook',
  LOGIN_WITH_GOOGLE: 'Logg inn med Google',
  SIGN_UP_WITH_FACEBOOK: 'Registrer deg med Facebook',
  SIGN_UP_WITH_GOOGLE: 'Registrer deg med Google',
  SIGN_UP_TO_UNLOCK: 'Registrer deg for å låse opp',
  LOGIN: 'Logg inn',
  OR: 'eller',
  SIGN_UP: 'Registrer deg',
  FORGOT_PASSWORD_TITLE: 'Glemt passordet ditt? Ikke bekymre deg! ',
  RESET_MESSAGE_CONFIRMATION: 'Det er sendt en melding til deg som e-post med instruksjoner om hvordan du tilbakestiller passordet.',
  RESET_MESSAGE_HINT: 'Skriv inn adressen som er knyttet til kontoen din. Vi sender deg instruksjoner på e-post for å tilbakestille passordet ditt. De kan ende opp i spam-mappen din, så sjekk det også. ',
  RETURN_TO_LOGIN: 'Gå tilbake til innlogging',
  RESET_PASSWORD: 'Tilbakestill passord',
  CHECK_YOUR_EMAIL: 'Sjekk e-posten vi nettopp sendte til ',
  FOR_ACTIVATION: ' for å aktivere kontoen din',
  CREATING_ACCOUNT: 'Å opprette en konto betyr at du har tilgang til vår',
  TERMS_OF_SERVICE: 'Vilkår for bruk',
  OUR: 'vårt',
  PRIVACY_POLICY: 'Personvernerklæring',
  USER_HAVE_AN_ACCOUNT: 'Har du allerede en konto?',
  NEW_PASSWORD: 'Nytt passord',
  REPEAT_PASSWORD: 'Gjenta passord',
  CHANGE_PASSWORD: 'Endre passord',
  ACTIVE_NOW: 'Kontoen din er aktiv nå',
  EMAIL_ADDRESS: 'E-postadresse',
  FEMALE: 'Kvinne',
  GENDER: 'Kjønn',
  LOGOUT: 'Logg ut',
  MALE: 'Mann',
  SAVE_CHANGES: 'Lagre endringer',
  UNSPECIFIED: 'Uspesifisert',
  DATA_CHANGED: 'Kontodata er endret!',
  OTHER: 'annet',
  GO_MAIN: 'Gå til startsiden',
  NO_DATA: 'Ingen data tilgjengelig',
};

export default formFields;
