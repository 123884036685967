
import { FC, ReactElement, useMemo, useEffect, useCallback } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import countBy from 'lodash/countBy';
import { useUpdateBasketWithLiveOddList } from 'context/basket/oddList/useUpdateBasketWithLiveOddList';
import { useUpdateBasketOddListOnOpen } from 'context/basket/oddList/useUpdateBasketOddListOnOpen/useUpdateBasketOddListOnOpen';
import { Basket, BasketItem, BasketMode, Noop } from 'types';

import { useBasket, Props as BasketContextProps } from '../../context/basket/basket-context';


type Props = {
  setValue: UseFormSetValue<Basket>;
  remove: (params: any) => void;
  reset: (params: any) => void;
  fields: Basket;
  children(props: {
    isOpen: boolean;
    onItemRemove: (item: BasketItem, index: number) => void;
    submitBasket: BasketContextProps['submitBasket'];
    clearItems: BasketContextProps['clearItems'];
    closeBasket: Noop;
    betPerEvent: Record<string, number>;
    isSystemBetsGenerated: boolean;
  }): ReactElement;
}

export const BasketController: FC<Props> = ({ children, remove, reset, fields, setValue }) => {
  const {
    isOpen,
    items: basketItems,
    generatedBets,
    clearItems,
    removeItem,
    submitBasket,
    clearGeneratedBets,
    closeBasket,
  } = useBasket();
  const memoSetValue = useCallback(setValue, [setValue]);

  useUpdateBasketOddListOnOpen({ setFormValue: memoSetValue });
  useUpdateBasketWithLiveOddList({ setFormValue: memoSetValue });


  const betPerEvent = useMemo(() => countBy(basketItems, 'eventId'), [basketItems]);

  const isSystemBetsGenerated = useMemo(() =>
    !!(fields.mode === BasketMode.System && generatedBets.length),
  [generatedBets, fields.mode]);

  const resetBasket = useCallback(({ mode, items, stakePerBet, stakePerCombo, totalStake, groupBetValues }) => {
    reset({
      mode,
      items,
      stakePerBet,
      stakePerCombo,
      totalStake,
      groupBetValues,
    });
  }, [reset]);

  const onItemRemove = useCallback((item, index) => {
    remove(index);
    removeItem(item.odd.id);
  }, [remove, removeItem]);

  useEffect(() => {
    if (basketItems.length !== fields.items.length) {
      resetBasket({
        ...fields,
        items: basketItems,
        groupBetValues: {},
      });
    }
  }, [basketItems, fields, resetBasket]);

  useEffect(() => {
    if(generatedBets.length) {
      if(fields.mode === BasketMode.System) {
        return;
      }
      window.open(generatedBets[0].link);
      clearGeneratedBets();
    }
  }, [generatedBets, fields.mode, clearGeneratedBets]);


  const props = {
    isOpen,
    onItemRemove,
    submitBasket,
    clearItems,
    closeBasket,
    betPerEvent,
    isSystemBetsGenerated,
  };

  return children(props);
};
