import React from 'react';
import { MatchSection } from 'types';
import { MatchDetailsAllSideBetsList } from 'views/Dashboard/shared/MatchDetails/SideBets/MatchDetailsAllSideBetsList';

import { MobileMatchDetailsSideBetsMatchResultSection } from '../MatchResult/MobileMatchDetailsSideBetsMatchResultSection';
import { MobileMatchDetailsSideBetsNumberOfGoalsSection } from '../NumberOfGoals/MobileMatchDetailsSideBetsNumberOfGoalsSection';
import { MobileMatchDetailsSideBetsThreeWayHandicap } from '../ThreeWayHandicap/MobileMatchDetailsSideBetsThreeWayHandicap';
import { MobileMatchDetailsSideBetsCorrectScoreSection } from '../CorrectScore/MobileMatchDetailsSideBetsCorrectScoreSection';
import { MatchDetailsSideBetsGenericDefault } from '../../../../MatchDetails/SideBets/Generic/Default/MatchDetailsSideBetsGenericDefault';

import './MobileMatchDetailsSideBetsAll.scss';


const SectionList = [
  {
    Component: MobileMatchDetailsSideBetsMatchResultSection,
    label: MatchSection.MatchResult,
  },
  {
    Component: MobileMatchDetailsSideBetsNumberOfGoalsSection,
    label: MatchSection.NumberOfGoals,
  },
  {
    Component: MobileMatchDetailsSideBetsThreeWayHandicap,
    label: MatchSection.ThreeWayHandicaps,
  },
  {
    Component: MobileMatchDetailsSideBetsCorrectScoreSection,
    label: MatchSection.CorrectScore,
  },
  {
    Component: MatchDetailsSideBetsGenericDefault,
  },
];

export const MobileMatchDetailsSideBetsAll = () => {
  return (
    <ul className="m-match-details-side-bets-all__grid">
      <MatchDetailsAllSideBetsList SectionList={SectionList} />
    </ul>
  );
};
