import React from 'react';
import { useTranslate } from 'context/translate-context';

const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const CalendarHeader = () => {
  const { translate } = useTranslate();

  return (
    <div className="calendar-header margin-bottom--size-s">
      {labels.map(label => (
        <div
          key={label}
          className="calendar-header__day"
        >
          {translate(label)}
        </div>
      ))}
    </div>
  );
};
