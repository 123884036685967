import apiErrors from './apiErrors';
import area from './area';
import basket from './basket';
import calendar from './calendar';
import common from './common';
import dashboardLayout from './dashboardLayout';
import dropdown from './dropdown';
import formFields from './formFields';
import liveTicker from './liveTicker';
import matchDetails from './matchDetails';
import tournamentDetails from './tournamentDetails';


const englishTranslations = {
  ...apiErrors,
  ...area,
  ...basket,
  ...calendar,
  ...common,
  ...dashboardLayout,
  ...dropdown,
  ...formFields,
  ...liveTicker,
  ...matchDetails,
  ...tournamentDetails,
};

export default englishTranslations;
