import { OddOdd } from 'types';
import { createMatchDetailsSideBetsRowHeaderWithKey, getSideBetsGridHeaders } from 'views/Dashboard/shared/MatchDetails/SideBets/matchDetailsSideBetsServices';

/*
  When there is more than one column, we want to hide label when user changes screen to smaller size.
  User needs number of labels equals to displayed columns.
  First index is always visible, other hidden by Media query.
*/

export const getColumnLabel = (columnVariant: number, firstOdd: OddOdd) => {
  const createRowWithCustomClass = createMatchDetailsSideBetsRowHeaderWithKey({
    key: `Section ${firstOdd.id} ${firstOdd.name}`,
  });

  const getStylingForColumnLabels = Array(columnVariant)
    .fill(columnVariant)
    .map((_, index) => {
      if (index === 0) {
        return createRowWithCustomClass({ isPredictionDisabled: true });
      }

      return createRowWithCustomClass({
        customStyle: 'match-details-prediction--from-tablet',
        isPredictionDisabled: true,
      });
    });

  return getSideBetsGridHeaders(getStylingForColumnLabels);
};
