import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslate } from 'context/translate-context';

import './TournamentFavouriteStar.scss';


type Props = {
  isFavourite: boolean;
  tournamentName: string;
  handleAddNewFavourite: () => Promise<void>;
  handleRemoveFavourite: () => Promise<void>;
};

export const TournamentFavouriteStar: FC<Props> = ({
  isFavourite,
  handleRemoveFavourite,
  handleAddNewFavourite,
  tournamentName,
}) => {
  const handleClickAction = isFavourite ? handleRemoveFavourite : handleAddNewFavourite;
  const { translate } = useTranslate();
  const ariaLabel = `${translate('FAVOURITE_BTN')} ${tournamentName}`;

  return (
    <button
      aria-label={ariaLabel}
      className="tournament-favourite__icon"
      onClick={handleClickAction}
    >
      {isFavourite ? (
        <Icon
          name="star"
          className="favourite favourite--selected margin--unset"
        />
      ) : (
        <Icon
          name="star outline"
          className="favourite margin--unset"
        />
      )}
    </button>
  );
};
