import { useTheme } from 'context/theme-context';


const themeWatermarks = new Map();
themeWatermarks.set('layout_saas-theme--dark', 'layout--dark');
themeWatermarks.set('layout_saas-theme--light', 'layout--light');

export const useThemeWaterMark = (className: string) => {
  const { theme } = useTheme();

  const classWithWatermark = themeWatermarks.get(`${className}_${theme}`);

  return classWithWatermark;
};
