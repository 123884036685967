export enum TOURNAMENT_ROUTE {
  DEFAULT = '/tournament/:tournamentId',
  MOBILE_DEFAULT = '/mobile/tournament/:tournamentId',
  MOBILE_STANDINGS = '/mobile/tournament/:tournamentId/standings',
  MOBILE_STATISTICS = '/mobile/tournament/:tournamentId/statistics',
  STANDINGS = '/tournament/:tournamentId/standings',
  STATISTICS = '/tournament/:tournamentId/statistics',
}

export enum TOURNAMENT_DETAILS {
  MOBILE = '/mobile',
  MOBILE_TOURNAMENT = '/mobile/tournament',
  TOURNAMENT = '/tournament',
  STANDINGS = '/standings',
  STATISTICS = '/statistics',
}
