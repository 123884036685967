import React, { FC, ReactElement } from 'react';
import { Odd, OddOdd, Match } from 'types';
import { useMatch } from 'context/match-context';
import { MatchDetailsSideBetsOddsController } from 'views/Dashboard/MatchList/MatchDetailsSideBetsOddsController ';


type Props = {
  oddType: Odd['type'][];
  children(props: {
    oddsQuantity: number;
    oddsByView: Odd[];
    selectedOdds: OddOdd[];
    handleToggleOddInBasket: ({ odd }: { odd?: Odd }) => ({ oddOdds }: { oddOdds: OddOdd }) => () => void;
    isOddInBasket: (odd: OddOdd) => boolean;
    match: Match;
  }): ReactElement;
}

export const MatchDetailsSideBetsOddsProviderContainer: FC<Props> = ({ oddType, children }) => {
  const { selectedMatch } = useMatch();

  if (!selectedMatch) {
    return null;
  }

  return (
    <MatchDetailsSideBetsOddsController
      match={selectedMatch}
      oddType={oddType}
    >
      {props => children(props)}
    </MatchDetailsSideBetsOddsController>
  );
};
