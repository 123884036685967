import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { createPortal } from 'react-dom';


export const Loading = () => {
  const layoutElement = document.querySelector('.layout');
  const rootElement = document.getElementById('root');

  if(layoutElement || rootElement) {
    return createPortal(
      <Dimmer active inverted>
        <Loader />
      </Dimmer>,
      layoutElement || rootElement,
    );
  }

  return null;
};

