import React from 'react';
import { Cart, Basket, MobileNavigation, Header } from 'components';
import { useThemeWaterMark } from 'hooks/useThemeWaterMark';
import { useTranslate } from 'context/translate-context';
import { BasketConfigController } from 'widgets/Basket/BasketConfigController';
import { basketAppConfig } from 'config/basketAppConfig';

import AuthRouter from './authRouter';
import { ScrollTop } from './shared/ScrollTop';
import ErrorBoundaryWithRollbar from './ErrorBoundaryWithRollbar';


const AppLayout = ({ children }) => {
  const { translate } = useTranslate();
  const watermarkClass = useThemeWaterMark('layout');

  return (
    <div className={`layout layout__page ${watermarkClass}`}>
      <Header>
        <Cart />
      </Header>
      <main className="layout__wrapper layout__main">
        <ScrollTop />
        <ErrorBoundaryWithRollbar
          FallbackComponent={
            <div>
              {translate('COULD_NOT_LOAD_CONTENT')}
            </div>
          }
        >
          {children}
        </ErrorBoundaryWithRollbar>
      </main>
      <BasketConfigController configuration={basketAppConfig}>
        <Basket />
      </BasketConfigController>
      <div className="layout--display-to-desktop">
        <MobileNavigation />
      </div>
    </div>
  );
};


const AuthApp = () => {
  return (
    <AppLayout>
      <AuthRouter />
    </AppLayout>
  );
};

export default AuthApp;
