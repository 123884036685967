export const APP_PREFIX = 'SPORTXPERT_SAAS';
export const BASKET_STORAGE_KEY = 'BASKET';
export const DASHBOARD_STORAGE_KEY = 'DASHBOARD';
export const CONFIG_STORAGE_KEY = 'CONFIG';
export const FILTERS_STORAGE_KEY = 'FILTERS';

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_CURRENCY = 'EUR';

export const DEFAULT_AREA = 'ENG';
