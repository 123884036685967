import { BasketMode, BasketItem, OddsProvider } from 'types';

import { checkIfEventIdIsDuplicated } from './checkIfEventIdIsDuplicated.validation';


type Props = {
  basketItemList: BasketItem[];
  betPerEvent: Record<string, number>;
  providerName?: OddsProvider;
}

export const basketValidationListByMode = ({ betPerEvent }: Props) => {
  return {
    [BasketMode.Single]: () => [
    ],
    [BasketMode.Combo]: () => [
      checkIfEventIdIsDuplicated(betPerEvent),
    ],
    [BasketMode.System]: () => [
      checkIfEventIdIsDuplicated(betPerEvent),
    ],
  };
};
