import React, { useContext, FC } from 'react';

import { Favourite, FavouriteInput } from '../../types/Favourite';
import { useFavouriteListHandlers } from '../../config/useFavouriteList';


type Props = {
  handleAdd: (favouriteInput: FavouriteInput) => () => Promise<void>;
  handleRemove: (favouriteInput: {tournamentId: string; seasonId: string}) => () => Promise<void>;
  isLoading: boolean;
  favourites: Favourite[];
}

const FavouriteListContext = React.createContext<Props>({
  isLoading: false,
  favourites: [],
  handleAdd: () => async() => undefined,
  handleRemove: () => async() => undefined,
});

const FavouriteListProvider: FC = props => {
  const initHandlers = useFavouriteListHandlers();

  const {
    favourites,
    handleAdd,
    handleRemove,
    isLoading,
  } = initHandlers();

  const values = {
    favourites,
    handleAdd,
    handleRemove,
    isLoading,
  };

  return (
    <FavouriteListContext.Provider
      value={values}
      {...props}
    />
  );
};


const useFavouriteList = () => {
  const context = useContext(FavouriteListContext);

  if(context === undefined) {
    throw new Error('useFavourite must be used within a FavouriteProvider');
  }

  return context;
};


export {
  FavouriteListProvider,
  useFavouriteList,
};
