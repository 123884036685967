const dashboardLayout = {
  CLEAR_TOURNAMENTS: 'Tøm turneringer',
  FAVOURITES: 'Favoritter',
  LIVE: 'Live',
  FAVOURITE_BTN: 'Legg til favoritt',
  ENDED: 'Slutt',
  ODDS_FILTER: 'Velg odds',
  POSTPONED: 'Utsatt',
  KICK_OFF: 'Avspark',
  'Kick off (first)': 'Avspark (første)',
  'Kick off (last)': 'Avspark (siste)',
  'Tournaments (first)': 'Turneringer (første)',
  'Tournaments (last)': 'Turneringer (siste)',
  'Bet ratio (ascending)': 'Spillfaktor (første)',
  'Bet ratio (descending)': 'Spillfaktor (siste)',
  SORT_EVENTS: 'Sorter hendelser etter',
  SHOW_XPERT: 'Vis Xpert som',
  PERSENT: 'Prosent',
  DECIMAL_FRACTION: 'Desimal',
  FILTERS_RESET: 'Filtre har blitt tilbakestilt til standardverdiene',
  TOURNAMENT_LIST_CLEARED: 'Turneringer ble slettet',
  FILTERS_NOT_CHANGED: 'Filtre er ikke endret',
  PREVIOUS_GAMEWEEK: 'Forrige serierunde',
  NEXT_GAMEWEEK: 'Neste serierunde',
  PERCENTAGE: 'Prosent',
  DECIMAL: 'Desimal',
  RATIO: 'Spillfaktor',
  SING_OUT: 'Syng ut',
  ACCOUNT: 'Konto',
  SIGN_OUT: 'LOGG UT',
  DISPLAY_SETTINGS: 'Skjerminnstillinger',
  BETS: 'Spill',
  HOME: 'Hjem',
  TOURNAMENTS: 'Turneringer',
  NO_MATCHES_AVAILABLE: 'Ingen treff tilgjengelig',
};

export default dashboardLayout;
