import { Area, Tournament, Maybe, SportsName } from 'types';
import { sortItemsAsc } from 'utils/sort';


type SetGivenAreaByProps = (props:
  {
    selectedArea: Maybe<Area>;
    setArea: (area: Maybe<Area>) => void;
  }) =>
  (area: Maybe<Area>) => void;

export const setGivenAreaBy: SetGivenAreaByProps = ({ selectedArea, setArea }) => area => {
  if (selectedArea?.id === area?.id) {
    return setArea(null);
  }

  return setArea(area);
};

export const getLabelToLowerCase = (continentName: string): string => {
  const normalizeContinentName = continentName.toLowerCase();

  return normalizeContinentName;
};

const continentOrder: Area['name'][] = [
  'europe',
  'north america',
  'south america',
  'africa',
  'asia',
  'oceania',
  'australia',
];

type SortProps = {
  continents: Area[];
};

const sortCollectionWith = (order: string[]) => (a: string, b: string) => {
  return order.indexOf(a.toLowerCase()) - order.indexOf(b.toLowerCase());
};

type OrderBySportName = {
  sortingOrder: string[];
  fieldName: keyof Pick<Area, 'name'>;
}

const orderBySportName: Record<SportsName, OrderBySportName> = {
  [SportsName.Soccer]: {
    sortingOrder: continentOrder,
    fieldName: 'name',
  },
  [SportsName.Hockey]: {
    sortingOrder: continentOrder,
    fieldName: 'name',
  },
};

export const sortParentAreaListInOrder = (sportName?: SportsName) => ({ continents }: SortProps): Area[] => {
  if (!sportName) {
    return continents.sort((a, b) => sortCollectionWith(continentOrder)(a.name, b.name));
  }

  const { sortingOrder, fieldName } = orderBySportName[sportName];
  const sortedCollection = continents.sort((a, b) => sortCollectionWith(sortingOrder)(a[fieldName], b[fieldName]));

  return sortedCollection;
};

export const sortTournamentsByType = (tournaments: Tournament[]) => {
  return tournaments.sort((firstTournament: Tournament, secondTournament: Tournament) => {
    const {
      name: firstTournamentName,
      areaSort: firstTournamentAreaSort,
    } = firstTournament;

    const {
      name: secondTournamentName,
      areaSort: secondTournamentAreaSort,
    } = secondTournament;


    return  sortItemsAsc(firstTournamentAreaSort, secondTournamentAreaSort) || sortItemsAsc(firstTournamentName, secondTournamentName);
  });
};
