import { useLiveTicker } from 'context/liveTicker/live-ticker-context';
import { FC, ReactElement } from 'react';
import { Maybe, MatchLiveIncident, Noop } from 'types';
import { getResultFromMatchIncident } from 'views/LiveDashboard/liveDashboardServices';

import { useLiveTickerQueue } from './useLiveTickerQueue';

type Props = {
  children(props: {
    incidentList: MatchLiveIncident[];
    incidentsRemaining: number;
    latestLiveMatch: Maybe<MatchLiveIncident>;
    dequeue: Noop;
    result: string;
    pauseTicker: () => void;
    resumeTicker: () => void;
  }): ReactElement;
};

export const LiveTickerController: FC<Props> = ({ children }) => {
  const { pauseTicker, resumeTicker } = useLiveTicker();
  const {
    incidentList,
    latestLiveMatch,
    incidentsRemaining,
    dequeue,
  } = useLiveTickerQueue();

  const result = latestLiveMatch ? getResultFromMatchIncident(latestLiveMatch) : '';

  const props = {
    latestLiveMatch,
    incidentList,
    incidentsRemaining,
    result,
    dequeue,
    pauseTicker,
    resumeTicker,
  };

  return children(props);
};


