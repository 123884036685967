import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';

import './TournamentCheckbox.scss';


type Props = {
  isSelected: boolean;
  handleClick: () => void;
  ariaLabel: string;
}

export const TournamentCheckbox: FC<Props> = ({
  isSelected,
  handleClick,
  ariaLabel,
}) => {
  return (
    <>
      {isSelected ? (
        <button
          type="button"
          className="tournament-checkbox__mark tournament-checkbox--selected"
          onClick={handleClick}
          aria-label={ariaLabel}
        >
          <Icon
            name="checkmark"
            className="primary margin--unset"
          />
        </button>
      ) : (
        <button
          type="button"
          className="tournament-checkbox__mark"
          onClick={handleClick}
          aria-label={ariaLabel}
        />
      )}
    </>
  );
};
