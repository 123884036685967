import React, { FC, ReactNode } from 'react';

import './MatchDetailsPredictionsColumnsGrid.scss';


type Props = {
  columnVariant: string;
  children: ReactNode;
}

const columnsMap = new Map();
columnsMap.set('1', 'match-details-prediction-columns-grid--column');
columnsMap.set('2', 'match-details-prediction-columns-grid--columns-2');
columnsMap.set('3', 'match-details-prediction-columns-grid--columns-3');
columnsMap.set('handicap', 'match-details-prediction-columns-grid--handicap');
columnsMap.set('three-way-handicap', 'match-details-prediction-columns-grid--three-way-handicap');


export const MatchDetailsPredictionsColumnsGrid: FC<Props> = ({ columnVariant, children }) => {
  return (
    <ul className={`match-details-prediction-columns-grid ${columnsMap.get(columnVariant) || ''}`}>
      {children}
    </ul>
  );
};
