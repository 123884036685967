import { FC, ReactElement, useCallback, useMemo } from 'react';
import min from 'lodash/min';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import Big from 'big.js';
import { Basket, BasketItem, BasketItemGroup, GroupBetValue } from 'types';
import { times } from 'utils';


type Props = {
  fields: Basket;
  children(props: {
    minPrize: string;
    maxPrize: string;
    totalOdds: string;
  }): ReactElement;
}

export const SystemModeFooterResultsController: FC<Props> = ({ children, fields }) => {
  const hasPossibleOdds = useMemo(() => fields.items.some(item => {
    return item.groups.length > 0;
  }), [fields.items]);

  const groupBets = useMemo(() => {
    const bets: Record<BasketItemGroup, BasketItem[]> = {
      A: [],
      B: [],
      C: [],
      S: [],
    };

    if (hasPossibleOdds) {
      Object.keys(BasketItemGroup).forEach(key => {
        bets[key as BasketItemGroup] = fields.items.reduce((acc: BasketItem[], curr) => {
          if (curr.groups.indexOf(key as BasketItemGroup) > -1) {
            return [...acc, curr];
          }

          return acc;
        }, []);
      });
    }

    return bets;
  }, [hasPossibleOdds, fields.items]);


  const bankerOdds = useMemo(() => {
    return groupBets.S.reduce((acc, curr) => acc.times(curr.odd.odds), new Big(1)) || 1;
  }, [groupBets.S]);

  const selectedBetValues = useMemo(() => {
    const betValues: Record<BasketItemGroup, GroupBetValue[]> = {
      A: [],
      B: [],
      C: [],
      S: [],
    };

    if (hasPossibleOdds) {
      Object.keys(BasketItemGroup).forEach(key => {
        betValues[key as BasketItemGroup] = fields.groupBetValues[key as BasketItemGroup]?.filter(betValue => betValue.selected) || [];
      });
    }

    return betValues;
  }, [hasPossibleOdds, fields.groupBetValues]);


  const getBetsByPredicate = useCallback((repeats: number, bets: BasketItem[], predicate: Function) => {
    const odds: BasketItem[] = [];
    const repeat = times(repeats);

    repeat(() => {
      const filtetedBets = bets.filter(bet => {
        return !odds.some(highBet => highBet.odd.id === bet.odd.id);
      });

      const foundOdd = predicate(filtetedBets, (item: BasketItem) => item.odd.odds);

      if (!foundOdd) {
        return;
      }

      odds.push(foundOdd);
    });

    return odds;
  }, []);


  const totalOdds = useMemo(() => {
    if(!hasPossibleOdds) {
      return 0;
    }

    const initialOddValue = new Big(1);

    const result = Object.values(groupBets).reduce((acc, curr) => {
      return acc.times(curr.reduce((acc, curr) => {
        return acc.times(curr.odd.odds);
      }, initialOddValue));
    }, initialOddValue);

    return result.toNumber();
  }, [hasPossibleOdds, groupBets]);


  const minPrize = useMemo(() => {
    const minGroupPrizes = Object.keys(BasketItemGroup)
      // Remove S from calculation
      .filter(key => key !== BasketItemGroup.S)
      .map(group => {
        const currentGroupBets = groupBets[group as BasketItemGroup];

        const minimumValues = selectedBetValues[group as BasketItemGroup].map(betValue => {
          const lowestBetOdds = getBetsByPredicate(betValue?.number || 0, currentGroupBets, minBy);

          const minOdd = lowestBetOdds.reduce((acc, curr) => {
            return acc.times(curr.odd.odds);
          }, new Big(1));

          // Multiply by odds from S group and stake for combination
          const result = minOdd.times(bankerOdds).times(betValue?.stakeForCombination || 0);

          return result.toNumber();
        });

        return min(minimumValues);
      });

    return min(minGroupPrizes) || 0;
  }, [groupBets, bankerOdds, selectedBetValues, getBetsByPredicate]);


  const maxPrize = useMemo(() => {
    return Object.keys(BasketItemGroup)

      // Remove S from calculation
      .filter(key => key !== BasketItemGroup.S)
      .reduce((accGroup, currGroup) => {
        const currentGroupBets = groupBets[currGroup as BasketItemGroup];

        const currGroupMaxPrize = selectedBetValues[currGroup as BasketItemGroup].reduce((accBetValue, currBetValue) => {
          const highestBetOdds = getBetsByPredicate(currBetValue.number, currentGroupBets, maxBy);

          const maxOdd = highestBetOdds.reduce((acc, curr) => {
            return acc.times(curr.odd.odds);
          }, new Big(1));

          // Multiply by odds from S group and stake for combination

          const result = maxOdd.times(bankerOdds).times(currBetValue?.stakeForCombination || 0);

          return accBetValue.add(result);
        }, new Big(0));


        return accGroup.add(currGroupMaxPrize);
      }, new Big(0)).toNumber();
  }, [groupBets, bankerOdds, selectedBetValues, getBetsByPredicate]);

  const props = {
    minPrize: minPrize.toFixed(2),
    maxPrize: maxPrize.toFixed(2),
    totalOdds: totalOdds.toFixed(2),
  };

  return children(props);
};
