import React, { FC } from 'react';
import { ReactComponent as SideBetsIcon } from 'assets/icons/side-bets.svg';
import { useTranslate } from 'context/translate-context';
import { Match, Prediction } from 'types';
import { isEmpty } from 'lodash';

import { MATCH_DETAILS_SECTION_KEY } from '../../../../constants';
import { MATCH_DETAILS_ROUTING } from '../Routing/MatchDetailsRouting';

import { MatchDetailsSidebarLinksSeparator } from './MatchDetailsSidebarLinksSeparator';
import { MatchDetailsSidebarLink } from './MatchDetailsSidebarLink';


type Props = {
  match: Match;
  predictions: Prediction;
}

export const MatchDetailsSidebarBetsLinks: FC<Props> = ({ match, predictions }) => {
  const {
    event: { id: eventId },
    season: { id: seasonId },
  } = match;
  const { translate } = useTranslate();

  return (
    <>
      <MatchDetailsSidebarLinksSeparator
        borderTop
        Icon={<SideBetsIcon color="var(--SaasText01)" stroke="var(--SaasText01)" />}
        label={translate(MATCH_DETAILS_SECTION_KEY.SIDE_BETS)}
      />
      <MatchDetailsSidebarLink
        label={translate(MATCH_DETAILS_SECTION_KEY.ALL_SIDE_BETS)}
        link={MATCH_DETAILS_ROUTING.getSideBetsURL({ eventId, seasonId })}
        disabled={isEmpty(predictions)}
      />
      <MatchDetailsSidebarLink
        label={translate(MATCH_DETAILS_SECTION_KEY.MATCH_RESULT)}
        link={MATCH_DETAILS_ROUTING.getMatchResultURL({ eventId, seasonId })}
        disabled={!Boolean(predictions?.prediction)}
      />
      <MatchDetailsSidebarLink
        label={translate(MATCH_DETAILS_SECTION_KEY.NUMBER_OF_GOALS)}
        link={MATCH_DETAILS_ROUTING.getNumberOfGoalsURL({ eventId, seasonId })}
        disabled={!Boolean(predictions?.goalsPredictions)}
      />
      <MatchDetailsSidebarLink
        label={translate(MATCH_DETAILS_SECTION_KEY.THREE_WAY_HANDICAPS)}
        link={MATCH_DETAILS_ROUTING.getThreeWayHandicapsURL({ eventId, seasonId })}
        disabled={!Boolean(predictions?.threeWayHandicap)}
      />
      <MatchDetailsSidebarLink
        label={translate(MATCH_DETAILS_SECTION_KEY.CORRECT_SCORE)}
        link={MATCH_DETAILS_ROUTING.getCorrectScoreURL({ eventId, seasonId })}
        disabled={!Boolean(predictions?.correctScore)}
      />
    </>
  );
};
