import React from 'react';
import { useLocation } from 'react-router';
import { TournamentProvider } from 'context/tournament-context';

import { TournamentDetailsController } from '../shared/TournamentDetailsController';

import { TournamentDetailsHeader } from './TournamentDetailsHeader';
import { TournamentDetailsTabs } from './TournamentDetailsTabs';
import { TournamentTabRouting, TOURNAMENT_TAB_ROUTING } from './Routing/TournamentTabRouting';
import { TournamentDetailsHistoricalSeasonsContainer } from './TournamentDetailsHistoricalSeasonsContainer';

import './TournamentDetails.scss';


export const TournamentDetails = () => {
  const { pathname } = useLocation();

  return (
    <TournamentProvider>
      <TournamentDetailsController>
        {tournamentProps => (
          <TournamentDetailsHeader {...tournamentProps} />
        )}
      </TournamentDetailsController>
      <TournamentDetailsTabs>
        {TOURNAMENT_TAB_ROUTING.isStandingsURL(pathname) && (
          <TournamentDetailsHistoricalSeasonsContainer />
        )}
      </TournamentDetailsTabs>
      <div className="tournament-details-page__container layout__grid">
        <div className="tournament-details-page__section-container">
          <div className="tournament-details-page__season-actions" />
          <TournamentTabRouting />
        </div>
      </div>
    </TournamentProvider>
  );
};
