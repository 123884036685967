import React from 'react';

import './Tabs.scss';


export const Tabs = ({ active, tabs = [], onTabClick }) => (
  <ul className="tabs">
    {tabs.map(tab => (
      <li
        key={tab.name}
        className={active === tab.name ? 'tabs__tab is-active' : 'tabs__tab'}
        onClick={() => onTabClick(tab.name)}
      >
        {tab.label}
      </li>
    ))}
  </ul>
);
