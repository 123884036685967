import { OddsProvider } from 'types';

import { updateBasketOddListByProviderHandlersMap, UseUpdateBasketOddListByProviderHandlersProps } from './updateBasketOddList/services/provider/updateBasketOddListByProviderHandlersMap';


export const handleGetUpdateList = (provider?: OddsProvider) => {
  if (provider) {
    const {
      getUpdatedList,
    } = updateBasketOddListByProviderHandlersMap.get(provider) as UseUpdateBasketOddListByProviderHandlersProps;

    return getUpdatedList;
  }
  return null;
};
