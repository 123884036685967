import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { TabLink } from 'views/shared/Routing/TabLink';

import './MobileCategoryLinkList.scss';


type Props = {
  categoryTabList: TabLink[];
}

export const MobileCategoryLinkList: FC<Props> = ({ categoryTabList }) => {
  return (
    <section className="m-category-link-list__section">
      <ul className="m-category-link-list__grid">
        {categoryTabList.map(({ text, to }) => (
          <li
            key={text}
            className="m-category-link-list__tab"
          >
            <NavLink
              className="m-category-link-list__tab-link"
              to={to}
            >
              {text}
            </NavLink>
          </li>
        ))}
      </ul>
    </section>
  );
};
