import React from 'react';
import { IncidentState } from 'types';

import { getCancelledIncidentsWithClassName, getConfirmedIncidentsWithClassName } from '../../shared/Incident/incidentCodesByStatus';


export const lineupPlayerConfirmedIncidentCodeMap = new Map();

const confirmedIncidents = getConfirmedIncidentsWithClassName('match-details-line-ups__incident-code');

confirmedIncidents.forEach(({ code, icon, className }) => (
  lineupPlayerConfirmedIncidentCodeMap.set(
    code,
    <div className={className}>
      {icon}
    </div>,
  )
));

export const lineupPlayerCancelledIncidentCodeMap = new Map();

const cancelledIncidents = getCancelledIncidentsWithClassName('match-details-line-ups__incident-code');

cancelledIncidents.forEach(({ code, icon, className }) => (
  lineupPlayerCancelledIncidentCodeMap.set(
    code,
    <div className={className}>
      {icon}
    </div>,
  )
));


export const lineupIncidentsMapsByStatus = new Map();

lineupIncidentsMapsByStatus.set(IncidentState.Tbd, lineupPlayerConfirmedIncidentCodeMap);
lineupIncidentsMapsByStatus.set(IncidentState.Confirmed, lineupPlayerConfirmedIncidentCodeMap);
lineupIncidentsMapsByStatus.set(IncidentState.Cancelled, lineupPlayerCancelledIncidentCodeMap);
