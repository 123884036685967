import React, { FC, ReactElement } from 'react';
import { Match, TeamScore, MatchStatus } from 'types';
import { useTranslate } from 'context/translate-context';
import { MatchPrediction } from 'views/Dashboard/shared/Match/Prediction/MatchPrediction';
import { MatchPredictionClassHandler } from 'views/Dashboard/shared/Match/Prediction/MatchPredictionClassHandler';

import { SIDE_BETS_PREDICTION_VARIANT } from '../../../../../constants';
import { DisplayPredictionRatioProps, DisplayPredictionRegularProps } from '../../matchListPredictionSectionServices';
import { isGoodBet } from '../../../shared/Match/Prediction/matchPredictionServices';

import { getValueWithDisplayHandler } from './matchPredictionsServices';

import './MatchPredictions.scss';


type Props = {
  matchStatus: MatchStatus;
  predictions: Match['prediction'];
  odds: Record<TeamScore, number>;
  displayPredictionHandler: DisplayPredictionRegularProps | DisplayPredictionRatioProps;
};

export const MatchPredictions: FC<Props> = ({
  predictions,
  odds,
  displayPredictionHandler,
  matchStatus,
}) => {
  const { translate } = useTranslate();
  const getValue = getValueWithDisplayHandler(displayPredictionHandler);

  const options = [
    {
      odd: odds.home,
      prediction: predictions?.home || 0,
    },
    {
      odd: odds.draw,
      prediction: predictions?.draw || 0,
    },
    {
      odd: odds.away,
      prediction: predictions?.away || 0,
    },
  ];

  return (
    <section className="match-predictions__grid">
      <div className="match-predictions--mobile">
        {translate('XPERT')}
      </div>
      {options.map(({ odd, prediction }, index) => {
        return (
          <MatchPredictionClassHandler
            key={`match-${index}-${odd}-${prediction}`}
            variant={SIDE_BETS_PREDICTION_VARIANT.XPERT}
            matchStatus={matchStatus}
            isRecommendedBet={isGoodBet(odd, prediction)}
          >
            {({ elementClassDisplayVariant, handleRecommendedBet }) => {
              return (
                <Prediction>
                  <MatchPrediction
                    value={getValue(odd, prediction)}
                    customClasses={handleRecommendedBet ? elementClassDisplayVariant(prediction) : ''}
                  />
                </Prediction>
              );
            }}
          </MatchPredictionClassHandler>
        );
      })}
      <div className="match-predictions--mobile">
        <div />
      </div>
    </section>
  );
};


type PredictionProps = {
  children: ReactElement;
};

const Prediction: FC<PredictionProps> = ({ children }) => {
  return (
    <div className="match-predictions__container match-predictions__container--column">
      <div className="match-predictions__prediction">
        {children}
      </div>
    </div>
  );
};
