import { MatchSort } from 'types';

import { MatchListMissingSelectedTournamentSortVariantTournament } from './MatchListMissingSelectedTournamentSortVariantTournament';
import { MatchListMissingSelectedTournamentSortVariantKickOff } from './MatchListMissingSelectedTournamentSortVariantKickOff';


export const matchSortVariantMap = new Map();

matchSortVariantMap.set(MatchSort.KICK_OFF_ASC, MatchListMissingSelectedTournamentSortVariantKickOff);
matchSortVariantMap.set(MatchSort.KICK_OFF_DESC, MatchListMissingSelectedTournamentSortVariantKickOff);
matchSortVariantMap.set(MatchSort.TOURNAMENT_ASC, MatchListMissingSelectedTournamentSortVariantTournament);
matchSortVariantMap.set(MatchSort.TOURNAMENT_DESC, MatchListMissingSelectedTournamentSortVariantTournament);
