export const CALENDAR_DISPLAY_DATE_FORMAT = 'iiii dd MMMM yyyy';
export const CALENDAR_DISPLAY_DATE_FORMAT_DAY_TEXT = 'iii';
export const CALENDAR_DISPLAY_DATE_FORMAT_DAY_MONTH = 'dd MMM';


export enum FormatDate {
  CALENDAR_DISPLAY_DATE_FORMAT = 'iiii dd MMMM yyyy',
  CALENDAR_DISPLAY_DATE_FORMAT_DAY_TEXT = 'iii',
  CALENDAR_DISPLAY_DATE_FORMAT_DAY_MONTH =  'dd MMM',
}
