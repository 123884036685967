import React, { FC } from 'react';
import { Noop, MatchQueryFilters, MatchSort } from 'types';
import { Dropdown, DefaultDropdownList } from 'components';
import { useTranslate } from 'context/translate-context';

import { MatchListSortController } from './MatchListSortController';


type Props = {
  isExpanded: boolean;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleToggleExpand: Noop;
  dropdownRef: React.MutableRefObject<null>;
  handleSetOrder: (sort: MatchSort) => void;
  filters: MatchQueryFilters;
};


export const MatchesFiltersSorting: FC<Props> = ({
  isExpanded,
  dropdownRef,
  handleClick,
  handleToggleExpand,
  handleSetOrder,
  filters,
}) => {
  const { translate } = useTranslate();

  return (
    <MatchListSortController filters={filters}>
      {({ availableLabelsInDropdown, currentLabel }) => {
        return (
          <Dropdown
            label={translate('SORT_EVENTS')}
            selectedElementLabel={currentLabel}
            isExpanded={isExpanded}
            handleClick={handleClick}
            handleToggleExpand={handleToggleExpand}
            dropdownRef={dropdownRef}
          >
            <DefaultDropdownList
              list={availableLabelsInDropdown}
              handleSelect={handleSetOrder as (item: string) => void}
            />
          </Dropdown>
        );
      }}
    </MatchListSortController>
  );
};
