import React, { FC, ReactElement, memo } from 'react';
import { OddsType, Maybe } from 'types';
import { isEmpty } from 'lodash';
import { useTranslate } from 'context/translate-context';
import { MatchPredictionClassHandler } from 'views/Dashboard/shared/Match/Prediction/MatchPredictionClassHandler';
import { MatchPrediction } from 'views/Dashboard/shared/Match/Prediction/MatchPrediction';
import { isGoodBet } from 'views/Dashboard/shared';
import { getPercent } from 'views/Dashboard/MatchList/matchListPredictionSectionServices';

import { MATCH_DETAILS_SECTION_KEY, SIDE_BETS_PREDICTION_VARIANT } from '../../../../../constants';
import { MatchPredictionSidebBetsOdds } from '../../../shared/Match/Prediction/MatchPredictionSidebBetsOdds';
import { XpertType, EmptyListFeedback } from '../../../../../components';
import { MatchDetailsPredictionController } from '../../Prediction/MatchDetailsPredictionController';
import { MatchDetailsSideBetsOddsLabel } from '../MatchDetailsSideBetsOddsLabel';
import { MatchDetailsPredictionDisplayController } from '../../Prediction/MatchDetailsPredictionDisplayController.js';
import { MatchDetailsPredictionsSection } from '../../Prediction/MatchDetailsPredictionsSection';
import { MatchDetailsSideBetsOddsProviderContainer } from '../MatchDetailsSideBetsOddsProviderContainer';
import { activeTabToOddsPeriod, findOddFromCorrectScore, createScoreName } from '../matchDetailsSideBetsServices';
import { handleDisplayGrid } from '../../Prediction/matchDetailsPredictionsGridService';
import { MatchDetailsPredictionsColumnsGrid } from '../../Prediction/MatchDetailsPredictionsColumnsGrid';

import { MatchDetailsSideBetsCorrectScoreController } from './MatchDetailsSideBetsCorrectScoreController';
import { goalsFactorToCorrectScore, isPreciseGoalEqualtoGoalsFactor } from './matchDetailsSideBetsServices';


const oneColumn = 1;
const defaultColumns = 3;

type Props = {
  ColumnsLabels: FC<{oddsLabel: Maybe<ReactElement>}>;
}

export const sectionOddType = OddsType.CorrectScore;

export const MatchDetailsSideBetsCorrectScore: FC<Props> = memo(({ ColumnsLabels }) => {
  const { translate } = useTranslate();

  return (
    <MatchDetailsPredictionDisplayController sectionNameToCheck={MATCH_DETAILS_SECTION_KEY.CORRECT_SCORE}>
      <MatchDetailsSideBetsOddsProviderContainer
        oddType={[sectionOddType]}
      >
        {({ isOddInBasket, oddsByView, handleToggleOddInBasket, match }) => {
          return (
            <MatchDetailsPredictionController>
              {({ predictions }) => {
                return (
                  <MatchDetailsPredictionsSection
                    tabsActive
                    label={translate(MATCH_DETAILS_SECTION_KEY.CORRECT_SCORE)}
                  >
                    {({ activePredictionTab }) => {
                      const goalsFactor = predictions?.goalsFactor?.[activePredictionTab] || {};
                      const visiblePredictions = predictions?.correctScore?.[activePredictionTab] || [];

                      const preciseGoalsFromGoalsFactor = goalsFactorToCorrectScore(goalsFactor);
                      const getOddsBy = findOddFromCorrectScore(oddsByView);

                      const sideBet = oddsByView
                        .find(odd => odd.typePeriod === activeTabToOddsPeriod.get(activePredictionTab));
                      const displayedOdds = sideBet?.odds || {};

                      const oddsLabel = !isEmpty(displayedOdds) ? <MatchDetailsSideBetsOddsLabel /> : null;

                      return (
                        <MatchDetailsSideBetsCorrectScoreController visiblePredictions={visiblePredictions}>
                          {({ predictions }) => (
                            <>
                              {predictions.length === 0 && (
                                <MatchDetailsPredictionsColumnsGrid
                                  columnVariant={String(oneColumn)}
                                >
                                  <EmptyListFeedback />
                                </MatchDetailsPredictionsColumnsGrid>
                              )}
                              {predictions.length > 0 && (
                                <MatchDetailsPredictionsColumnsGrid
                                  columnVariant={String(defaultColumns)}
                                >
                                  <ColumnsLabels
                                    oddsLabel={oddsLabel}
                                  />
                                  {predictions.map(({ home, away, prediction }) => {
                                    const label = `${home}:${away}`;
                                    const foundOdd = getOddsBy(
                                      activeTabToOddsPeriod.get(activePredictionTab),
                                      createScoreName({ home, away }),
                                    );

                                    const isGood = foundOdd && isGoodBet(foundOdd.odds, prediction);

                                    return (
                                      <li
                                        key={label}
                                        className={`match-details-prediction__prediction ${handleDisplayGrid(!Boolean(String(prediction)))}`}
                                      >
                                        {/* I column */}
                                        <div className="match-details-prediction__label">
                                          <div className="margin-right--size-s">
                                            {label}
                                          </div>
                                          {
                                            isPreciseGoalEqualtoGoalsFactor(
                                              { home, away },
                                              preciseGoalsFromGoalsFactor,
                                            ) && <XpertType />
                                          }
                                        </div>
                                        {/* II column */}
                                        {foundOdd && (
                                          <div className="match-details-prediction--second-column">
                                            <MatchPredictionSidebBetsOdds
                                              options={
                                                {
                                                  match,
                                                  scoreValue: foundOdd?.odds,
                                                  isSelected: isOddInBasket(foundOdd),
                                                  isGood,
                                                  handleClick: handleToggleOddInBasket({ odd: sideBet })({ oddOdds: foundOdd }),
                                                }
                                              }
                                            />
                                          </div>
                                        )}
                                        {/* III column */}
                                        <div className="match-details-prediction--third-column">
                                          <MatchPredictionClassHandler
                                            variant={SIDE_BETS_PREDICTION_VARIANT.XPERT}
                                            isRecommendedBet={isGood}
                                            matchStatus={match.event.status}
                                          >
                                            {({ elementClassDisplayVariant, handleRecommendedBet }) => (
                                              <MatchPrediction
                                                value={getPercent(prediction)}
                                                customClasses={handleRecommendedBet ? elementClassDisplayVariant(prediction) : ''}
                                              />
                                            )}
                                          </MatchPredictionClassHandler>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </MatchDetailsPredictionsColumnsGrid>
                              )}
                            </>
                          )}
                        </MatchDetailsSideBetsCorrectScoreController>
                      );
                    }}
                  </MatchDetailsPredictionsSection>
                );
              }}
            </MatchDetailsPredictionController>
          );
        }}
      </MatchDetailsSideBetsOddsProviderContainer>
    </MatchDetailsPredictionDisplayController>
  );
});
