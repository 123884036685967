import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { UserAccount } from './Account/UserAccount';


const USER_DEFAULT = '/user';

export const USER_ROUTE = {
  DEFAULT: USER_DEFAULT,
  DETAILS: `${USER_DEFAULT}/account`,
};

export const UserRouting = () => {
  return (
    <Switch>
      <Route exact path={USER_ROUTE.DETAILS} component={UserAccount} />
    </Switch>
  );
};

const getUserAccountUrl =  () => {
  return `${USER_ROUTE.DEFAULT}/account`;
};

export const USER_ROUTING = {
  getUserAccountUrl,
};
