import React, { FC } from 'react';
import { useTranslate } from 'context/translate-context';

import './MatchDetailsMatchReportIncidentBar.scss';


type Props = {
  label: string;
}

export const MatchDetailsMatchReportIncidentBar: FC<Props> = ({ label }) => {
  const { translate } = useTranslate();

  return (
    <li className="match-report-incident-bar__gradient">
      <div className="match-report-incident-bar__container">
        <div className="match-report-incident-bar__kick-off-label">
          {translate(label)}
        </div>
      </div>
    </li>
  );
};
