import React, { FC, useCallback, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { isEmpty, noop } from 'lodash';
import { BasketCheckoutItem, BasketItem, OddOdd, Basket, BasketErrorMessage } from 'types';
import BasketClient from 'api/BasketClient';
import useLocalStorage from 'hooks/useLocalStorage';

import { BASKET_STORAGE_KEY } from '../../constants/app';
import { DEFAULT_BET_VALUE } from '../../constants/basket';

import { useSendUpdatedState } from './useSendUpdatedState';
import { useSendInitializedState } from './useSendInitializedState';
import { useHandleReceivedOdd } from './useHandleReceivedOdd';


type SubmitProps = {
  mode: Basket['mode'];
  groupBetValues: Basket['groupBetValues'];
};

export type Props = {
  items: BasketItem[];
  generatedBets: BasketCheckoutItem[];
  setItems: (items: BasketItem[]) => void;
  addItem: (item: BasketItem) => void;
  updateItem: <T>(id: string, values: T) => void;
  removeItem: (id: string) => void;
  clearItems: () => void;
  isOddInBasket: (odd: OddOdd) => boolean;
  isOpen: boolean;
  openBasket: () => void;
  closeBasket: () => void;
  submitBasket: (values: SubmitProps) => Promise<SubmitProps> | void;
  clearGeneratedBets: () => void;
  setErrors: React.Dispatch<React.SetStateAction<BasketErrorMessage[]>>;
  errors: BasketErrorMessage[];
}

export const BasketContext = React.createContext<Props>({
  items: [],
  generatedBets: [],
  setItems: noop,
  addItem: noop,
  updateItem: noop,
  removeItem: noop,
  clearItems: noop,
  isOddInBasket: () => false,
  isOpen: false,
  openBasket: noop,
  closeBasket: noop,
  submitBasket: noop,
  clearGeneratedBets: noop,
  errors: [],
  setErrors: noop,
});

const BasketProvider: FC = props => {
  const [items, setItems] = useLocalStorage<BasketItem[]>(BASKET_STORAGE_KEY, []);
  const [generatedBets, setGeneratedBets] = useState<BasketCheckoutItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState<BasketErrorMessage[]>([]);
  const isOddInBasket = useCallback(odd => {
    const foundItem = items.find((i: BasketItem) => {
      return i.odd?.id === odd?.id;
    });

    const isExisting = Boolean(foundItem);

    return isExisting;
  }, [items]);

  const addItem = useCallback(item => {
    if (!isOddInBasket(item.odd)) {
      setItems([...items, {
        ...item,
        betValue: DEFAULT_BET_VALUE,
        groups: [],
      }]);
    }
  }, [items, setItems, isOddInBasket]);

  const updateItem = useCallback((id, values) => {
    const nItems = items.map((i: BasketItem) => {
      if (i.odd.id === id) {
        return {
          ...i,
          ...values,
        };
      }
      return i;
    });

    setItems(nItems);
  }, [items, setItems]);

  const removeItem = useCallback(id => {
    const nItems = items.filter((i: BasketItem) => {
      return i.odd.id !== id;
    });

    setItems(nItems);
  }, [items, setItems]);

  const clearItems = useCallback(() => {
    setItems([]);
  }, [setItems]);

  const clearGeneratedBets = useCallback(() => {
    setGeneratedBets([]);
  }, [setGeneratedBets]);

  const openBasket = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeBasket = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const { mutate: sendBasketItems } = useMutation(BasketClient.postBasket, {
    onSuccess: data => setGeneratedBets(data.data.basket),
    onError: ({ response: { data } }) => setErrors(data.errors as BasketErrorMessage[]),
  });

  const submitBasket = ({ mode, groupBetValues }: SubmitProps) => {
    return sendBasketItems({ mode, items, groupBetValues });
  };

  const memoSubmitBasket = useCallback(submitBasket, [items, sendBasketItems]);

  const handleBasketOddMessage = useCallback(item => {
    if (!isEmpty(item)) {
      if (isOddInBasket(item.odd)) {
        return removeItem(item.odd.id);
      }
      return addItem(item);
    }
  }, [addItem, isOddInBasket, removeItem]);

  const handleSentOdd = useCallback((item: CustomEventInit<BasketItem[]>) => {
    if (!isEmpty(item?.detail)) {
      return handleBasketOddMessage(item.detail);
    }
  }, [handleBasketOddMessage]);

  useSendInitializedState(items);
  useSendUpdatedState(items);
  useHandleReceivedOdd(handleSentOdd);

  const values = {
    items,
    errors,
    setErrors,
    generatedBets,
    setItems,
    addItem,
    updateItem,
    removeItem,
    clearItems,
    isOddInBasket,
    isOpen,
    openBasket,
    closeBasket,
    submitBasket: memoSubmitBasket,
    clearGeneratedBets,
  };

  return (
    <BasketContext.Provider value={values} {...props} />
  );
};


const useBasket = () => {
  const context = useContext(BasketContext);

  if(context === undefined) {
    throw new Error('useBasket must be used within a BasketProvider');
  }

  return context;
};


export {
  BasketProvider,
  useBasket,
};
