import { FC, ReactElement } from 'react';
import { useMatch } from 'context/match-context';

import { Maybe } from '../../../../types/Util';
import { EventStatistic } from '../../../../types/Match/Match';


type Props = {
  children(props: {
    areStatsLoading: boolean;
    statistics: Maybe<EventStatistic>;
  }): ReactElement;
}

export const MatchDetailsMatchStatsListController: FC<Props> = ({ children }) => {
  const {
    areStatsLoading,
    matchStatistics,
  } = useMatch();

  const props = {
    statistics: matchStatistics,
    areStatsLoading,
  };

  return children(props);
};
