import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { OddsProvider } from 'types';

import api from '../services/FetchService';

import { GetOddsList } from './types';
import * as query from './queries';


export class OddsClient {
  constructor() {
    this.getSideBetsOdds = this.getSideBetsOdds.bind(this);
  }

  getSideBetsOdds(params: { eventsIds: string[]; providers: OddsProvider[] }): AxiosPromise<GetOddsList> {
    const options: AxiosRequestConfig = {
      ...query.postOdds,
      data: {
        ...params,
      },
    };

    return api.request(options);
  }
}
