import { useState, useEffect, useRef } from 'react';
import { isAfter } from 'date-fns';
import { sortItemsAsc } from 'utils';
import { useLiveTicker } from 'context/liveTicker/live-ticker-context';
import { sortIncidentsByIncidentTimeInOrder } from 'views/shared/Incident/incidentServices';
import { useLiveMatchListContext } from 'context/liveTicker/live-match-list-context';

import { useHandleLiveTickerSound } from '../useHandleLiveTickerSound';


export const useLiveTickerQueue = () => {
  const { incidentList } = useLiveMatchListContext();

  const {
    incidentsDisplayQueue,
    setIncidentsDisplayQueue,
  } = useLiveTicker();

  const [dequeuedIncidentIdList, setDequeuedIncidentIdList] = useState<string[]>([]);
  const incidentsRemaining = incidentsDisplayQueue.length;
  const componentMountTime = useRef<number>(Date.now());

  const getIncidentListSinceComponentMount = incidentList.filter(incident => {
    const boundry = componentMountTime.current;
    const { createdAt } = incident;

    return isAfter(new Date(createdAt), boundry);
  });

  const [latestLiveMatch] = incidentsDisplayQueue;

  useHandleLiveTickerSound(latestLiveMatch);

  const dequeue = () => {
    setIncidentsDisplayQueue(([, ...restItemsInQueue]) => restItemsInQueue);

    setDequeuedIncidentIdList(prevState => ([...prevState, latestLiveMatch?.id]));
  };

  const uniqueLatestIncidents = getIncidentListSinceComponentMount.filter(({ id }) => !dequeuedIncidentIdList.includes(id));
  const boundryLength = uniqueLatestIncidents.length;

  const enqueue = () => {
    if (boundryLength > 0) {
      const sorted = uniqueLatestIncidents.sort(sortIncidentsByIncidentTimeInOrder(sortItemsAsc));

      setIncidentsDisplayQueue(sorted);
    }
  };

  useEffect(() => {
    enqueue();

    return () => {
      setIncidentsDisplayQueue([]);
      setDequeuedIncidentIdList(prevState => ([
        ...prevState,
        ...incidentsDisplayQueue.map(item=> item.id),
      ]));
    };
  }, [boundryLength]);

  return {
    latestLiveMatch,
    incidentList,
    incidentsRemaining,
    dequeue,
  };
};
