import React, { FC, ReactElement } from 'react';
import { Icon } from 'semantic-ui-react';

import { useTranslate } from '../../../../context/translate-context';
import { Noop } from '../../../../types';

import './TournamentCategory.scss';


type Props = {
  icon?: ReactElement;
  label: string;
  listLength?: number;
  handleClick: Noop;
  isOpen: boolean;
  handleSelect?: () => void;
};

export const TournamentCategory: FC<Props> = ({ icon = null, label, listLength = 0, handleClick, isOpen, handleSelect }) => {
  const { translate } = useTranslate();
  const translatedLabel = translate(label);

  return (
    <div
      className={`tournament-category__container ${isOpen ? 'active' : ''}`}
    >
      <div className="tournament-category__wrapper tournament-category--padding-top-bottom">
        {icon ? <div className="tournament-category__category-icon">{icon}</div> : null}
        <div
          className="tournament-category__wrapper"
        >
          <button
            className="tournament-category__name"
            onClick={handleSelect}
          >
            {`${translatedLabel} ${listLength > 0 ? `(${listLength})` : ''}`}
          </button>
          <button
            className="tournament-category__arrow"
            onClick={handleClick}
            aria-label={translatedLabel}
          >
            <Icon
              name="chevron down"
              className={`margin--unset ${isOpen ? 'active-rotate' : ''}`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};
