export const getHomeProps = ({ homeWinsPercentage, homeWins, label }) => {
  return {
    label,
    score: String(homeWins),
    percentage: String(homeWinsPercentage),
  };
};

export const getAwayProps = ({ awayWinsPercentage, awayWins, label }) => {
  return {
    label,
    score: String(awayWins),
    percentage: String(awayWinsPercentage),
  };
};

export const getDrawProps = ({ drawsPercentage, draws, label }) => {
  return {
    label,
    score: String(draws),
    percentage: String(drawsPercentage),
  };
};
