
import { FC, ReactElement, useMemo, useCallback, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import Big from 'big.js';

import { Maybe, Basket, BasketItemGroup, GroupBetValue } from '../../../../../types';
import { getTotalGroupCombinations } from '../../../utils';


type Props = {
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  children(props: {
    selectedCombinationsCount: number;
    onStakePerComboChange: (value: number | string) => void;
    onTotalStakeChange: (value: number | string) => void;
  }): ReactElement;
}

export const SystemModeFooterController: FC<Props> = ({ children, setValue, fields }) => {
  const selectedCombinationsCount = useMemo(() => {
    return Object.keys(fields.groupBetValues).reduce((value: number, group) => {
      const groupBetValues = fields.groupBetValues[group as BasketItemGroup];

      const groupCombinations = groupBetValues.reduce((value: number, curr: GroupBetValue) => {
        let result = value;
        if (curr.selected) {
          result = value + getTotalGroupCombinations(curr.number, groupBetValues.length);
        }
        return result;
      }, 0);

      if(groupCombinations) {
        if(!value) return groupCombinations;

        return new Big(value).times(new Big(groupCombinations)).toNumber();
      }
      return value;
    }, 0);
  }, [fields.groupBetValues]);

  const updateAllGroupBetValues = useCallback((betValue: number) => {
    // Update field inputs
    Object.keys(BasketItemGroup).forEach(key => {
      const groupBetValues = fields.groupBetValues[key as BasketItemGroup] || [];

      groupBetValues.forEach((groupBet: GroupBetValue, index) => {
        if (!groupBet.selected) {
          return;
        }
        setValue(`groupBetValues.${key}.${index}.stakeForCombination` as 'groupBetValues.A.0.stakeForCombination', betValue.toFixed(2) as never);

        const newStakeForGroup = Big(betValue).times(getTotalGroupCombinations(groupBet.number, groupBetValues.length)).toFixed(2);

        setValue(
          `groupBetValues.${key}.${index}.stakeForGroup` as 'groupBetValues.A.0.stakeForGroup',
          newStakeForGroup as never, // TODO: Needs fixing. Seems like react-hook-forms is not able to infer the type correctly
        );
      });
    });
  }, [fields.groupBetValues, setValue]);

  const stakePerCombo = useMemo(() => {
    let isSameEverywhere = true;
    let value: Maybe<number> = null;

    Object.keys(fields.groupBetValues).forEach(group => {
      const groupBetValues = fields.groupBetValues[group as BasketItemGroup];

      groupBetValues.forEach((groupBet: GroupBetValue) => {
        if (!groupBet.selected) {
          return;
        }
        const stakeForCombination = typeof groupBet.stakeForCombination === 'number' ? groupBet.stakeForCombination : parseFloat(groupBet.stakeForCombination);

        if (!value) {
          value = stakeForCombination;
        } else {
          if (value !== stakeForCombination) {
            isSameEverywhere = false;
          }
        }
      });
    });

    return isSameEverywhere ? (value ?? 0).toFixed(2) : '-';
  }, [fields.groupBetValues]);

  const totalStake = useMemo(() => {
    if(selectedCombinationsCount && stakePerCombo) {
      return new Big(selectedCombinationsCount).times(new Big(stakePerCombo)).toNumber();
    }

    return 0;
  }, [selectedCombinationsCount, stakePerCombo]);

  const onStakePerComboChange = useCallback((value = 0) => {
    if (value === undefined) {
      return;
    }
    const betValue = new Big(value || 0).abs();

    updateAllGroupBetValues(betValue.toNumber());

    // Remove - from input
    if (value < 0) {
      setValue('stakePerCombo', betValue.toFixed(2));
    }
  }, [setValue, updateAllGroupBetValues]);

  const onTotalStakeChange = useCallback((value = 0) => {
    if (value === undefined) {
      return;
    }

    const totalValue = new Big(value || 0).abs();
    let betValue = new Big(0);

    if (selectedCombinationsCount) {
      betValue =  totalValue.div(selectedCombinationsCount);

      onStakePerComboChange(betValue.toNumber());
    } else {
      setValue('totalStake', (0).toFixed(2));
    }

    setValue('stakePerBet', betValue.toFixed(2));

    // Remove - from input
    if (value < 0) {
      setValue('totalStake', totalValue.toFixed(2));
    }
  }, [setValue, selectedCombinationsCount, onStakePerComboChange]);

  useEffect(() => {
    setValue(
      'stakePerCombo',
      stakePerCombo,
    );
  }, [setValue, stakePerCombo]);

  useEffect(() => {
    setValue(
      'totalStake',
      totalStake.toFixed(2),
    );
  }, [setValue, totalStake]);

  const props = {
    selectedCombinationsCount,
    onStakePerComboChange,
    onTotalStakeChange,
  };

  return children(props);
};
