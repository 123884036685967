import { Match, MatchStatus, MatchStatusName, OddOdd } from 'types';


export const isGoodBet = (odds: OddOdd['odds'], prediction = 0) => {
  if (!odds) {
    return false;
  }
  return odds * prediction >= 1;
};

export const isMatchStatusEqualTo = ({ match, status }: { match: Match; status: MatchStatus }) => {
  const { status: matchStatus } = match.event;

  return matchStatus === status;
};

export const isMatchStatusBlockedByStatusNameList = ({ match, statusList }: { match: Match; statusList: MatchStatusName[] }) => {
  const { statusName: matchStatusName } = match.event;

  return statusList.includes(matchStatusName);
};


export const isMatchStatusBlockedByStatusList = ({ match, statusList }: { match: Match; statusList: MatchStatus[] }) => {
  const { status: matchStatus } = match.event;

  return statusList.includes(matchStatus);
};
