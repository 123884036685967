import { useMemo } from 'react';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useLocalStorage from 'hooks/useLocalStorage';
import { useConfig } from 'context/config-context';
import { AreaSort, FeatureStorage } from 'types';

import FavouriteClient from '../api/FavouriteClient';
import { GetFavouriteList } from '../api/FavouriteClient/types';
import { FavouriteInput, Favourite } from '../types/Favourite';


/*
  In the case of extracting DashboardWidget we lose:
    - info about user, if the system is not integrated with external app
    - with a user, we lose the functionality of assigning favourites to the user.
    In that case since favourites are directly assigned to the user we're modifying
    mechanism of handling favourite tournaments from an API to local storage.

    If Dashboard is extracted it will lose .envs with this information
    we can handle when the app should use an API or default mechanism.
*/

const useFavouriteListWithAPI = () => {
  const queryClient = useQueryClient();
  const {
    data,
    isLoading,
  } = useQuery('favourites', () => FavouriteClient.getFavourites(AreaSort.AREA_SORT_ASC));


  const { mutate: handleAddFavourite } = useMutation(FavouriteClient.createFavourite, {
    onSuccess: result => {
      const { favourite } = result?.data;

      queryClient.setQueryData(
        'favourites',
        previousState => {
          return ({ ...result, data: { favourites: [...(previousState as AxiosResponse<GetFavouriteList>).data.favourites, favourite] } });
        },
      );
    },
  });

  const { mutate: handleRemoveFavourite } = useMutation(FavouriteClient.removeFavourite, {
    onSuccess: (result, variables) => {
      const { seasonId, tournamentId } = variables;

      queryClient.setQueryData(
        'favourites',
        previousState => {
          const favourites = (previousState as AxiosResponse<GetFavouriteList>).data.favourites.filter(favourite => {
            return favourite.tournamentId !== tournamentId && favourite.seasonId !== seasonId;
          });

          return ({ ...result, data: { favourites } });
        },
      );
    },
  });


  const handleAdd = (favouriteInput: FavouriteInput) => async() => {
    if (favouriteInput) {
      return handleAddFavourite(favouriteInput);
    }
  };

  const handleRemove = (ids: { tournamentId: string; seasonId: string }) => async() => {
    if (ids) {
      return handleRemoveFavourite(ids);
    }
  };

  const favourites = useMemo(() => {
    return data?.data?.favourites || [];
  }, [data]);

  return {
    isLoading,
    handleAdd,
    handleRemove,
    favourites,
  };
};

const createFavouriteFromInputToLocalStorage = (favouriteInput: FavouriteInput): Favourite => {
  const defaultIdValue = 1;

  return {
    ...favouriteInput,
    userId: defaultIdValue,
    createdAt: Date.now().toString(),
    id: defaultIdValue,
  };
};

const useFavouriteListWithLocalStorage = () => {
  const initialFavouriteListValue: Favourite[] = [];
  const [storedValue, setValue] = useLocalStorage<Favourite[]>('favourites', initialFavouriteListValue);
  const isLoading = false;

  const handleAdd = (favouriteInput: FavouriteInput) => async() => {
    const favourite = createFavouriteFromInputToLocalStorage(favouriteInput);

    return setValue([...storedValue, favourite]);
  };

  const handleRemove = ({ tournamentId, seasonId }: { tournamentId: string; seasonId: string }) => async() => {
    const newFavouriteList = (storedValue as Favourite[]).filter(favourite => {
      return favourite.tournamentId !== tournamentId && favourite.seasonId !== seasonId;
    });

    return setValue(newFavouriteList);
  };

  const getFavouriteList = () => {
    return storedValue as Favourite[];
  };

  return {
    handleAdd,
    handleRemove,
    favourites: getFavouriteList(),
    isLoading,
  };
};

export const useFavouriteListHandlers = () => {
  const { configuration } = useConfig();

  return configuration?.features?.fallback === FeatureStorage.LocalStorage
    ? useFavouriteListWithLocalStorage
    : useFavouriteListWithAPI;
};
