export type BasketErrorMessage = {
  title?: string;
  message: BasketErrorMessageLabel;
  invalidEventIdList?: string[];
  invalidOddIdList?: string[];
};

export enum BasketErrorMessageLabel {
  CANT_BET = 'CANT_BET',
  CANT_MULTI_BET = 'CANT_MULTI_BET',
  OVERDUE_BET_LIST = 'OVERDUE_BET_LIST',
  INVALID_VALUE = 'INVALID_VALUE'
}
