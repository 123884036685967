import { AxiosRequestConfig } from 'axios';

const matchesUrl = `${window.__RUNTIME_CONFIG__.API_URL}/matches`;

export const getSeasonMatches: AxiosRequestConfig = { method: 'POST', url: `${matchesUrl}/events/seasons` };
export const getMatchDetails: AxiosRequestConfig = { method: 'GET', url: `${matchesUrl}/events` };

const urlStatistics = `${window.__RUNTIME_CONFIG__.API_URL}/statistics`;

export const getMatchStatistics: AxiosRequestConfig = { method: 'GET', url: `${urlStatistics}/event` };

const urlStandings = `${window.__RUNTIME_CONFIG__.API_URL}/standings`;

export const getStandings: AxiosRequestConfig = { method: 'GET', url: urlStandings };

export const postPredictionList: AxiosRequestConfig = { method: 'POST', url: `${matchesUrl}/predictions` };
