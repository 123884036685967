const area = {
  australia: 'Australia',
  africa: 'Africa',
  asia: 'Asia',
  europe: 'Europe',
  'north america': 'North and Central America',
  'south america': 'South America',
  oceania: 'Oceania',
  AFC: 'Asia (AFC)',
  CAF: 'Africa (CAF)',
  CONCACAF: 'North and Central America (CONCACAF)',
  CONMEBOL: 'South America (CONMEBOL)',
  OFC: 'Oceania (OFC)',
  UEFA: 'Europe (UEFA)',
};

export default area;
