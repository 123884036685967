import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { HeaderPublic } from './HeaderPublic';
import { HeaderPrivate } from './HeaderPrivate';

import './Header.scss';


const Header = props => {
  const { isAuthenticated, isLoading } = useAuth0();

  return !isLoading && (
    <>
      {isAuthenticated ? <HeaderPrivate {...props} /> : <HeaderPublic {...props} />}
    </>
  );
};

export default Header;
