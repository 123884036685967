import InputMask from 'react-input-mask';
import React from 'react';

import { DATE_MASK } from '../../../utils/inputMasks';
import ErrorMessage from '../../ErrorMessage';

import './FieldTextInput.scss';

export const FieldTextInputDate = ({
  labelText,
  ariaLabel,
  name,
  onChange,
  value,
  errors,
}) => {
  return (
    <div className="field-text-input__container">
      <label className="field-text-input__label">{labelText}</label>
      <InputMask mask={DATE_MASK} value={value} onChange={onChange}>
        {inputProps => (
          <input
            placeholder="MM/DD/YYYY"
            aria-label={ariaLabel}
            name={name}
            className="field-text-input__input"
            {...inputProps}
          />
        )}
      </InputMask>

      <ErrorMessage fieldName={name} errors={errors} />
    </div>
  );
};
