import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { MobileMatchListProviders } from 'context/AuthProviders';
import { NavigationHeader } from 'components';
import { parseQuery } from 'utils';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { Loader } from 'semantic-ui-react';

import { MatchListFilterSection } from '../MatchList/MatchListFilterSection';
import { SportsBarController } from '../SportsBar/SportsBarController';
import SportsBar from '../SportsBar';
import { MOBILE_DASHBOARD_ROUTING } from '../Routing/MobileDashboardRouting';
import { MediaQueriesRedirectController } from '../../shared/MediaQueriesRedirectController';
import { DASHBOARD_ROUTING } from '../Routing/DashboardRouting';
import { mediaQuery } from '../../../constants/mediaQuery';

import { MobileMatchListFilterModal } from './MatchList/FilterModal/MobileMatchListFilterModal';
import { MobileMatchListSection } from './MatchList/MobileMatchListSection';


export const MobileDashboard = () => {
  const { search } = useLocation();
  const isDesktop = useMediaQuery(mediaQuery.minDesktopSmall);
  const parsedQuery = parseQuery(search);
  const { season = [] } = parsedQuery;
  const seasonCasted = season as string[];

  return (
    <MobileMatchListProviders>
      <MediaQueriesRedirectController
        url={DASHBOARD_ROUTING.getDefaultDashboardURL()}
        isMediaQueryActive={isDesktop}
      >
        <div className="dashboard-page__container">
          <NavigationHeader
            isActive={Boolean(seasonCasted.length)}
            searchQuery={{ season: [] }}
            path={MOBILE_DASHBOARD_ROUTING.getTournamentsSelectURL()}
          />
          <div className="dashboard-page__sports-bar">
            <SportsBarController
              url={MOBILE_DASHBOARD_ROUTING.getDefaultDashboardURL()}
            >
              {props => (
                <Suspense
                  fallback={
                    <Loader active inline="centered" />
                  }
                >
                  <SportsBar {...props} />
                </Suspense>
              )}
            </SportsBarController>
          </div>
          <div className="dashboard-page__filters">
            <MatchListFilterSection />
          </div>
          <MobileMatchListFilterModal />
          <div className="layout__grid">
            <div className="dashboard-page__section-container">
              <MobileMatchListSection />
            </div>
          </div>
        </div>
      </MediaQueriesRedirectController>
    </MobileMatchListProviders>
  );
};
