import React, { FC, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';

import { Tournament } from '../types';
import TournamentClient  from '../api/TournamentClient';
import { useMatchesFilters } from '../views/Dashboard/MatchList/useMatchesFilters';
import { sortTournamentsByType } from '../views/Dashboard/Tournament/Area/tournamentAreaServices';

import { useArea } from './area-context';


type Props = {
  areTournamentsLoading: boolean;
  areContinentTournamentsLoading: boolean;
  continentTournaments: Tournament[];
  selectedTournaments: string[];
  tournaments: Tournament[];
};

const TournamentsContext = React.createContext<Props>({
  areTournamentsLoading: false,
  areContinentTournamentsLoading: false,
  continentTournaments: [],
  selectedTournaments: [],
  tournaments: [],
});

const TournamentsProvider: FC = props => {
  const { filters } = useMatchesFilters();
  const { selectedContinent, selectedCountry } = useArea();

  const { code: continentAreaCode = '' } = selectedContinent || {};
  const {
    isLoading: areContinentTournamentsLoading,
    data: continentTournamentsData,
  } = useQuery(['continentTournamentsList', continentAreaCode], () => TournamentClient.getTournaments({ areaCode: continentAreaCode }), {
    enabled: Boolean(continentAreaCode),
  });

  const continentTournaments: Tournament[] = useMemo(() => {
    const collection = continentTournamentsData?.data?.tournaments || [];
    const sortedTournamentList = sortTournamentsByType(collection);

    return sortedTournamentList;
  }, [continentTournamentsData]);

  const { code: areaCode = '' } = selectedCountry || {};
  const {
    isLoading: areTournamentsLoading,
    data: tournamentsData,
  } = useQuery(['countryTournamentsList', areaCode], () => TournamentClient.getTournaments({ areaCode }), {
    enabled: Boolean(areaCode),
  });

  const tournaments: Tournament[] = useMemo(() => {
    const collection = tournamentsData?.data?.tournaments || [];
    const sortedTournamentList = sortTournamentsByType(collection);

    return sortedTournamentList;
  }, [tournamentsData]);

  // selectors
  const selectedTournaments = filters.season || [];

  const values = {
    areContinentTournamentsLoading,
    areTournamentsLoading,
    continentTournaments,
    selectedTournaments,
    tournaments,
  };

  return (
    <TournamentsContext.Provider
      value={values}
      {...props}
    />
  );
};


const useTournaments = () => {
  const context = useContext(TournamentsContext);

  if(context === undefined) {
    throw new Error('useTournaments must be used within a TournamentsProvider');
  }

  return context;
};

export {
  TournamentsProvider,
  useTournaments,
};
