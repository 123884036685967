import { PLAYER_POSITION } from '../../../../constants';

export const transformLineupsToLineupsAndBench = selectedLineup => {
  const lineup = selectedLineup.filter(({ bench }) => !bench);
  const lineupsInOrder = getLineupOrderByPosition(lineup);

  const bench = selectedLineup.filter(({ bench }) => bench);
  const benchInOrder = getLineupOrderByPosition(bench);

  return {
    lineup: lineupsInOrder,
    bench: benchInOrder,
  };
};


export const getLineupOrderByPosition = lineup => {
  const goalkeeper = lineup.filter(({ position }) => position === PLAYER_POSITION.GOALKEEPER);
  const defenders = lineup.filter(({ position }) => position === PLAYER_POSITION.DEFENDER);
  const midfielder = lineup.filter(({ position }) => position === PLAYER_POSITION.MIDFIELDER);
  const atackers = lineup.filter(({ position }) => position === PLAYER_POSITION.ATTACKER);

  return [
    ...goalkeeper,
    ...defenders,
    ...midfielder,
    ...atackers,
  ];
};
