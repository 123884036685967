import React, { FC } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { Basket } from 'types';
import { useTranslate } from 'context/translate-context';

import { BasketFieldCurrencyInput } from '../../../..';

import { ComboModeFooterController } from './ComboModeFooterController';


type Props = {
  control: Control<Basket>;
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
  totalOdds: string;
}

export const ComboModeFooter: FC<Props> = ({
  control,
  setValue,
  fields,
  totalOdds,
}) => {
  const { translate } = useTranslate();

  return (
    <ComboModeFooterController
      setValue={setValue}
      fields={fields}
    >
      {({ onTotalStakeChange }) => (
        <div className="basket-footer__fields">
          <Controller
            control={control}
            name="totalStake"
            render={({
              field: { onBlur, onChange, value, name },
            }) => (
              <BasketFieldCurrencyInput
                labelText={translate('TOTAL_STAKE')}
                ariaLabel={translate('TOTAL_STAKE')}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={(value: number | string) => {
                  onChange(value);
                  onTotalStakeChange(value);
                }}
              />
            )}
          />
          <div className="basket-footer__odds">
            <span className="basket-footer__odds-title">{translate('TOTAL_ODDS')}</span>
            <span className="basket-footer__odds-value">
              {totalOdds}
            </span>
          </div>
        </div>
      )}
    </ComboModeFooterController>
  );
};
