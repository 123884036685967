import { Popup } from 'components';
import React, { memo, FC } from 'react';
import PlaceholderFlag from 'assets/images/placeholder-flag.svg';

import './PlacholderFlag.scss';


type Props = {
  label?: string;
  areaBadgeUrl?: string;
  customStylesClasses?: string;
  customStyles?: Record<string, string>;
};

export const PlacholderFlag: FC<Props> = memo(({ label, areaBadgeUrl, customStylesClasses = '', customStyles = {} }) => {
  const Flag = (
    <img
      alt="country flag"
      className={`placeholder-flag__container ${customStylesClasses}`}
      src={areaBadgeUrl || PlaceholderFlag}
      style={customStyles}
    />
  );

  return label ? (
    <Popup
      basic
      content={label}
      trigger={Flag}
      position="bottom right"
    />
  ) : Flag;
});
