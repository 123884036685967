import { useMatch } from '../../../context/match-context';


export const MatchDetailsSummaryWrapperTeamLineupsPlayerController = ({ children }) => {
  const { selectHomeIncidents, selectAwayIncidents } = useMatch();

  const incidents = [...selectHomeIncidents, ...selectAwayIncidents];

  const getIncidentsConnectedToPlayerName = playerName => {
    return incidents.filter(({ responsible }) => responsible === playerName);
  };

  const props = {
    incidents,
    getIncidentsConnectedToPlayerName,
  };

  return children(props);
};

