import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useMatch } from 'context/match-context';

import { MATCH_DETAILS } from '../../Dashboard/Routing/Routing';


type Props = {
  link: Record<string, Function>;
};

export const useMatchDetailsRedirect = ({ link: routingLink }: Props) => {
  const {
    selectedMatch,
    hasAnyLineup,
  } = useMatch();

  const { push } = useHistory();
  const { search, pathname } = useLocation();

  const handleRedirect = () => {
    if (selectedMatch && pathname.includes(MATCH_DETAILS.STATISTICS)) {
      return push({
        pathname: routingLink.getMatchReportURL({
          eventId: selectedMatch.event.id,
          seasonId: selectedMatch.season.id,
        }),
        search,
      });
    }
  };

  const memoHandleRedirect = useCallback(handleRedirect, [selectedMatch?.event.id]);


  useEffect(() => {
    if (!hasAnyLineup) {
      memoHandleRedirect();
    }
  }, [hasAnyLineup, memoHandleRedirect]);
};


