import { FindElementProps } from '../../../../FindElement';


export const findElementByExternalType: FindElementProps = (oddList, basketItem) => {
  const foundEvent = oddList.find(({ event }) => {
    return event.id === basketItem.eventId || event.name === basketItem.eventName;
  });

  const foundOdd = foundEvent?.odds
    .find(odd => odd.externalType === basketItem.externalType && odd.typePeriod === basketItem.oddTypePeriod);

  const foundOddOdd = foundOdd?.odds ? Object.values(foundOdd.odds) : [];

  const findItemByNames = foundOddOdd
    .find(item => {
      if (item.externalName && basketItem.odd?.externalName) {
        return item.externalName === basketItem?.odd?.externalName;
      }

      return item.name === basketItem?.odd.name;
    });

  const foundOddOddById = () => {
    if (!findItemByNames) {
      return foundOddOdd.find(item => {
        return item.id === basketItem?.odd.id;
      });
    }
    return findItemByNames;
  };

  return {
    foundEvent,
    foundOddOdd: foundOddOddById(),
  };
};
