import React, { FC } from 'react';
import { TeamScore, IncidentCode, MatchLiveIncident, IncidentState } from 'types';

import './LiveMatchResult.scss';


type Props = {
  result: string;
  teamOrigin: MatchLiveIncident['teamOrigin'];
  incidentCode: MatchLiveIncident['code'];
  confirmation?: MatchLiveIncident['confirmation'];
}

const formatResult = (result = '') => result.split(':').map(point => {
  const trimedPoint = point.trim();

  return trimedPoint.length > 0 ? parseInt(trimedPoint, 10) : 0;
});

const haveTeamScored = (teamOrigin: TeamScore, incidentCode: IncidentCode, confirmation?: MatchLiveIncident['confirmation']) =>
  (origin: TeamScore) => {
    const isConfirmed = confirmation === IncidentState.Confirmed;
    const isTeamEqual = origin === teamOrigin;
    const isMatchWithIncidentCodeList = [IncidentCode.Goal, IncidentCode.PenaltyGoal].includes(incidentCode);

    const isMatchToEveryCondition = [
      isConfirmed,
      isTeamEqual,
      isMatchWithIncidentCodeList,
    ];

    return isMatchToEveryCondition.every(Boolean)
      ? 'live-match-result__container--highlight' : '';
  };

export const LiveMatchResult: FC<Props> = ({ result, teamOrigin, incidentCode, confirmation }) => {
  const [homeResult = 0, awayResult = 0] = formatResult(result);
  const highlightMathResultFor = haveTeamScored(teamOrigin, incidentCode, confirmation);

  return (
    <>
      <div className={`live-match-result__container--home ${highlightMathResultFor(TeamScore.Home)}`}>
        {homeResult}
      </div>
      <div className="live-match-result__container--separator">
        :
      </div>
      <div className={`live-match-result__container--away ${highlightMathResultFor(TeamScore.Away)}`}>
        {awayResult}
      </div>
    </>
  );
};
