import { useConfig } from 'context/config-context';
import { useSocketsLiveOddList } from 'context/sockets/sockets-live-odd-list-context';
import { OddsProvider } from 'types';
import { useMatch } from 'context/match-context';
import { useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useMatchDetailsSideBetsOdds } from 'context/match-side-bets-odds-context';

import { updateMatchDetailsSideBetsByProviderMap } from './provider/updateMatchDetailsSideBetsByProviderMap';
import { Handlers } from './Handlers';


export const useUpdateMatchDetailsSideBets = () => {
  const { selectedMatch } = useMatch();
  const { liveOddListSocket } = useSocketsLiveOddList();
  const { matchDetailsOddList: matchDetailsGroupingOddList, setMatchDetailsOddList } = useMatchDetailsSideBetsOdds();
  const { configuration } = useConfig();
  const { provider: { name: providerName } } = configuration;
  const castedToProvider = providerName as OddsProvider;

  const {
    updateMatchDetailsOddGroupingTypeList,
    getEventGroupingTypeFromSockets,
    updateMatchDetailsOddList,
  } = updateMatchDetailsSideBetsByProviderMap.get(castedToProvider) as Handlers;

  const matchDetailsOddList = matchDetailsGroupingOddList[0]?.odds || [];

  const foundSocketGroupingType = getEventGroupingTypeFromSockets(selectedMatch?.event.id)(liveOddListSocket);
  const socketsGroupingOddOddList = foundSocketGroupingType?.odds || [];

  const updateListWithSockets = updateMatchDetailsOddList(socketsGroupingOddOddList);

  const syncedMatchDetailsOddList = useMemo(() => {
    return matchDetailsOddList.map(updateListWithSockets);
  }, [updateListWithSockets, matchDetailsOddList]);

  const shouldMatchDetailsOddListBeUpdated = useMemo(() => {
    return matchDetailsOddList.some((odd, idx) => {
      const notEqual = !isEqual(odd, syncedMatchDetailsOddList[idx]);

      return notEqual;
    });
  }, [matchDetailsOddList, syncedMatchDetailsOddList]);

  const syncedOddList = matchDetailsGroupingOddList.map(updateMatchDetailsOddGroupingTypeList(selectedMatch?.event.id || '', syncedMatchDetailsOddList));

  useEffect(() => {
    if (shouldMatchDetailsOddListBeUpdated) {
      setMatchDetailsOddList(syncedOddList);
    }
  }, [shouldMatchDetailsOddListBeUpdated, syncedOddList, setMatchDetailsOddList]);
};
