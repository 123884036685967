import React from 'react';
import { useTranslate } from 'context/translate-context';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { useMatchesFilters } from '../../useMatchesFilters';
import { MatchListFilterButton } from '../Button/MatchListFilterButton';
import { MatchListFilterClearTournamentListController } from '../MatchListFilterClearTournamentListController';

import './MatchListFilterClearFilters.scss';


export const MatchListFilterClearFilters = () => {
  const { filters } = useMatchesFilters();
  const { translate } = useTranslate();

  const { season } = filters;


  if (season?.length === 0) {
    return null;
  }

  return (
    <div className="match-list-filter-clear">
      <MatchListFilterClearTournamentListController>
        {({ handleResetTournamentList }) => (
          <MatchListFilterButton
            isSelected
            ariaLabel={translate('CLEAR_TOURNAMENTS')}
            callback={handleResetTournamentList}
          >
            <div className="match-list-filter-clear__container">
              <div className="match-list-filter-clear__close">
                <CloseIcon />
              </div>
              <span className="match-list-filter-clear__text">
                {translate('CLEAR_TOURNAMENTS')}
              </span>
            </div>
          </MatchListFilterButton>
        )
        }
      </MatchListFilterClearTournamentListController>
    </div>
  );
};
