import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { useTranslate } from '../../../context/translate-context';

import { TOURNAMENT_TAB_ROUTING } from './Routing/TournamentTabRouting';

import './TournamentDetailsTabs.scss';


export const TournamentDetailsTabs = ({ children }) => {
  const { translate } = useTranslate();
  const params = useParams();

  return (
    <div className="tournament-details-tabs">
      <div className="tournament-details-tabs__container">
        <div className="tournament-details-tabs__wrapper">
          <NavLink
            activeClassName="tournament-details-tabs__link--is-active"
            className="tournament-details-tabs__tab"
            to={TOURNAMENT_TAB_ROUTING.getTournamentStandingsTabURL(params)}
          >
            {translate('LEAGUE_TABLE')}
          </NavLink>
          <NavLink
            activeClassName="tournament-details-tabs__link--is-active"
            className="tournament-details-tabs__tab"
            to={TOURNAMENT_TAB_ROUTING.getTournamentStatisticsTabURL(params)}
          >
            {translate('STATISTICS')}
          </NavLink>
        </div>
        {children}
      </div>
    </div>
  );
};
