/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import ErrorBoundaryWithRollbar from 'views/ErrorBoundaryWithRollbar';
import { EmptyElementFeedback } from 'components';

import { TournamentListSection } from '../../Tournament/Area/TournamentListSection';
import { TournamentAreaContinentListSection } from '../../Tournament/Area/TournamentAreaContinentListSection';
import { TournamentList } from '../../Tournament/shared/TournamentList';
import Tournament from '../../Tournament/shared/Tournament';
import TournamentName  from '../../Tournament/shared/TournamentName';
import { TournamentAreaList } from '../../Tournament/Area/TournamentAreaList';
import { TournamentContinentListSectionVariantHandler } from '../../Tournament/Area/Variant/TournamentContinentListSectionVariantHandler';


export const SidebarAreaTournamentContinentList = () => {
  return (
    <ErrorBoundaryWithRollbar
      FallbackComponent={
        <div>
          <EmptyElementFeedback text="COULD_NOT_LOAD_CONTENT" />
        </div>
      }
    >
      <TournamentContinentListSectionVariantHandler>
        {props => (
          <>
            <TournamentAreaContinentListSection>
              {({ tournaments }) => (
                <TournamentList>
                  <>
                    {tournaments.map(tournament => {
                      const {
                        id,
                        name,
                        season: { id: seasonId },
                        area: { code: areaCode },
                        areaSort,
                      } = tournament;

                      return (
                        <Tournament.DefaultWithCheckbox
                          DisplayedContent={
                            <TournamentName.Default displayedName={name} />
                          }
                          key={id}
                          tournamentId={id}
                          tournamentName={name}
                          seasonId={seasonId}
                          areaCode={areaCode}
                          areaSort={areaSort}
                        />
                      );
                    })}
                  </>
                </TournamentList>
              )}
            </TournamentAreaContinentListSection>
            <TournamentAreaList {...props}>
              <TournamentListSection>
                {({ tournaments }) => (
                  <>
                    {tournaments.map(({
                      id,
                      name,
                      season: { id: seasonId },
                      area: { code: areaCode },
                      areaSort,
                    }) => (
                      <Tournament.DefaultWithCheckbox
                        key={id}
                        tournamentId={id}
                        tournamentName={name}
                        seasonId={seasonId}
                        areaCode={areaCode}
                        areaSort={areaSort}
                        DisplayedContent={
                          <TournamentName.Default displayedName={name} />
                        }
                      />
                    ))}
                  </>
                )}
              </TournamentListSection>
            </TournamentAreaList>
          </>
        )}
      </TournamentContinentListSectionVariantHandler>
    </ErrorBoundaryWithRollbar>
  );
};
