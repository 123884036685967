export const matchResultsToInt = (result: string) => {
  const [home = '0', away = '0'] = result.split(':');
  const parsedHome = Number(home);
  const parsedAway = Number(away);

  return {
    collection: [parsedHome, parsedAway],
    home: parsedHome,
    away: parsedAway,
  };
};
