import request, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as qs from 'query-string';
import { queryStringOptions } from 'utils';

export class FetchService {
  tokenGenerator: Function | null = null;

  serializeParams(params: URLSearchParams) {
    return qs.stringify(params, queryStringOptions);
  }

  async attachHeaders(options: AxiosRequestConfig) {
    const token = await this.getToken();

    return {
      // withCredentials: true,
      // This sends the query params in the correct format to API
      // We use arrays as param[]=1&param[]=2 in application
      // to know when a param should be an array or a simple value
      // without requiring some param mapper in the code
      paramsSerializer: (params: URLSearchParams) => this.serializeParams(params),
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
        ...options.headers,
      },
    };
  }

  async request(options: AxiosRequestConfig): Promise<AxiosResponse> {
    const optionsWithHeader = await this.attachHeaders(options);

    return request(optionsWithHeader);
  }

  setTokenGenerator(tokenGenerator: Function) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  getToken() {
    return this.tokenGenerator
      ? this.tokenGenerator({
        scope: 'read:all',
        audience: window.__RUNTIME_CONFIG__.AUTH0_AUDIENCE_SAAS,
      })
      : null;
  }
}

const instance = new FetchService();

export default instance;
