import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useMatch } from 'context/match-context';
import { isEmpty } from 'lodash';

import { DASHBOARD_ROUTE } from '../../Routing/Routing';
import { getMatchDetailsRouting } from '../../shared/MatchDetails/matchDetailsRoutingServices';
import { RouteParam } from '../../Mobile/MatchDetails/RouteParam';
import { MediaQueriesRedirectController } from '../../../shared/MediaQueriesRedirectController';
import { mediaQuery } from '../../../../constants/mediaQuery';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';

import { matchDetailsRouteList } from './routes';


export const MatchDetailsRouting = () => {
  const { selectedMatch } = useMatch();
  const params = selectedMatch && {
    eventId: selectedMatch.event.id,
    seasonId: selectedMatch.season.id,
  };
  const isMobile = useMediaQuery(mediaQuery.mobile);

  return (
    <Switch>
      {matchDetailsRouteList.map(({ path, redirectURL, Component, OptionalWrapper }, index) => {
        return (
          <Route exact key={`match-details ${index}`} path={path} render={() => (
            <MediaQueriesRedirectController
              isMediaQueryActive={Boolean(redirectURL) && isMobile}
              url={!isEmpty(params) ? redirectURL(params as RouteParam) : ''}
            >
              {OptionalWrapper ? (
                <OptionalWrapper>
                  <Component />
                </OptionalWrapper>
              ) : <Component />}
            </MediaQueriesRedirectController>
          )}
          />
        );
      })}
    </Switch>
  );
};

export const MATCH_DETAILS_ROUTING = getMatchDetailsRouting(DASHBOARD_ROUTE.DASHBOARD_MATCHES);
