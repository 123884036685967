import { FC, ReactElement } from 'react';
import { useLocation } from 'react-router';
import { useTranslate } from 'context/translate-context';
import { useMatch } from 'context/match-context';

import { MatchDetailsPartialProps, TabLink } from '../../../../shared/Routing/TabLink';


type LinksProps = (partialProps: MatchDetailsPartialProps) => TabLink[];

type Props = {
  getLinks: LinksProps;
  children(props: {
    linkList: TabLink[];
  }): ReactElement;
};

export const MobileMatchDetailsLinkListController: FC<Props> = ({ getLinks, children }) => {
  const { search } = useLocation();
  const { translate } = useTranslate();
  const { matchPredictions } = useMatch();

  const linkList = getLinks({ translate, search, prediction: matchPredictions });

  const props = {
    linkList,
  };

  return children(props);
};
