const area = {
  'australia': 'Australien',
  'africa': 'Afrika',
  'asia': 'Asien',
  'europe': 'Europa',
  'north america': 'Nord-och Centralamerika',
  'south america': 'Sydamerika',
  'oceania': 'Oceanien',
  AFC: 'Asien (AFC)',
  CAF: 'Afrika (CAF)',
  CONCACAF: 'Nord-och Centralamerika (CONCACAF)',
  CONMEBOL: 'Sydamerika (CONMEBOL)',
  OFC: 'Oceanien (OFC)',
  UEFA: 'Europa (UEFA)',
};

export default area;
