import { getMatchDetailsRoutes, getMatchDetailsRouting } from '../../shared/MatchDetails/matchDetailsRoutingServices';
import { MATCH_DETAILS, DASHBOARD_ROUTE } from '../../Routing/Routing';
import { MatchDetailsTeamFormation } from '../TeamFormation/MatchDetailsTeamFormation';
import { MatchDetailsMatchReport } from '../MatchReport/MatchDetailsMatchReport';
import { MatchDetailsMatchStats } from '../MatchStats/MatchDetailsMatchStats';
import { MatchDetailsSideBetsContainer } from '../SideBets/MatchDetailsSideBetsContainer';
import { MatchDetailsSideBetsMatchResultSection } from '../SideBets/MatchResult/MatchDetailsSideBetsMatchResultSection';
import { MatchDetailsSideBetsCorrectScoreSection } from '../SideBets/CorrectScore/MatchDetailsSideBetsCorrectScoreSection';
import { MatchDetailsSideBetsNumberOfGoalsSection } from '../SideBets/NumberOfGoals/MatchDetailsSideBetsNumberOfGoalsSection';
import { MatchDetailsSideBetsThreeWayHandicapSection } from '../SideBets/ThreeWayHandicap/MatchDetailsSideBetsThreeWayHandicapSection';
import { MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper } from '../SideBets/MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper';


export const MATCH_DETAILS_ROUTE = getMatchDetailsRoutes({
  pathname: MATCH_DETAILS.DEFAULT,
  dashboardPath: DASHBOARD_ROUTE.DASHBOARD_MATCHES,
});


const getMobileMatchDetailsURLByProps = getMatchDetailsRouting(DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES);

export const matchDetailsRouteList = [
  {
    path: MATCH_DETAILS_ROUTE.STATISTICS_TEAM_FORMATION,
    redirectURL: getMobileMatchDetailsURLByProps.getTeamFormationURL,
    Component: MatchDetailsTeamFormation,
  },
  {
    path: MATCH_DETAILS_ROUTE.STATISTICS_MATCH_REPORT,
    redirectURL: getMobileMatchDetailsURLByProps.getMatchReportURL,
    Component: MatchDetailsMatchReport,
  },
  {
    path: MATCH_DETAILS_ROUTE.STATISTICS_MATCH_STATISTICS,
    redirectURL: getMobileMatchDetailsURLByProps.getMatchStatsURL,
    Component: MatchDetailsMatchStats,
  },
  {
    path: MATCH_DETAILS_ROUTE.SIDE_BETS,
    redirectURL: getMobileMatchDetailsURLByProps.getSideBetsURL,
    Component: MatchDetailsSideBetsContainer,
  },
  {
    path: MATCH_DETAILS_ROUTE.SIDE_BETS_MATCH_RESULT,
    redirectURL: getMobileMatchDetailsURLByProps.getMatchResultURL,
    Component: MatchDetailsSideBetsMatchResultSection,
    OptionalWrapper: MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper,
  },
  {
    path: MATCH_DETAILS_ROUTE.SIDE_BETS_CORRECT_SCORE,
    redirectURL: getMobileMatchDetailsURLByProps.getCorrectScoreURL,
    Component: MatchDetailsSideBetsCorrectScoreSection,
    OptionalWrapper: MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper,
  },
  {
    path: MATCH_DETAILS_ROUTE.SIDE_BETS_THREE_WAY_HANDICAPS,
    redirectURL: getMobileMatchDetailsURLByProps.getThreeWayHandicapsURL,
    Component: MatchDetailsSideBetsThreeWayHandicapSection,
    OptionalWrapper: MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper,
  },
  {
    path: MATCH_DETAILS_ROUTE.SIDE_BETS_NUMBER_OF_GOALS,
    redirectURL: getMobileMatchDetailsURLByProps.getNumberOfGoalsURL,
    Component: MatchDetailsSideBetsNumberOfGoalsSection,
    OptionalWrapper: MatchDetailsSideBetsLoadingAndErrorBoundaryWrapper,
  },
];
