import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import './DequeueIncident.scss';


type Props = {
  dequeue: () => void;
};

export const DequeueIncident: FC<Props> = ({ dequeue }) => {
  return (
    <div className="dequeue-incident__container">
      <button className="dequeue-incident__button"
        onClick={dequeue}
      >
        <CloseIcon className="dequeue-incident__close" />
      </button>
    </div>
  );
};
