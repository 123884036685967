import { useMatch } from 'context/match-context';
import { ReactElement, FC } from 'react';
import { Match, OddsType } from 'types';

import { getUniqueOddTypes } from './services';


type Props = {
  children(props: {
    uniqueOddTypes: OddsType[];
    match: Match;
  }): ReactElement;
};

/*
  Remove OddsTypes which are already used by predictions to dont duplicate data in generic section.
*/

export const MatchDetailsSideBetsGenericSectionController: FC<Props> = ({ children }) => {
  const { matchPredictions, selectedMatch } = useMatch();
  const uniqueOddTypes = getUniqueOddTypes(matchPredictions);

  if (!selectedMatch || !matchPredictions) {
    return null;
  }

  const props = {
    uniqueOddTypes,
    match: selectedMatch,
  };

  return children(props);
};
