import { TabLinkListProps } from 'views/shared/Routing/TabLink';

import { RouteParam } from '../Routing/RouteParam';
import { TOURNAMENT_DETAILS } from '../Routing/Routing';
import { getTournamentDetailsRouting } from '../Routing/tournamentDetailsTabLinkListService';


const TOURNAMENT_DETAILS_ROUTING = getTournamentDetailsRouting(TOURNAMENT_DETAILS.MOBILE_TOURNAMENT);

type Props = TabLinkListProps<RouteParam>;

export const getMobileTournamentDetailsTabLinkList: Props = params => ({ translate, search }) => ([
  {
    text: translate('LEAGUE_TABLE'),
    to: `${TOURNAMENT_DETAILS_ROUTING.getTournamentStandingsTabURL(params)}${search}`,
    exact: true,
  },
  {
    text: translate('STATISTICS'),
    to: `${TOURNAMENT_DETAILS_ROUTING.getTournamentStatisticsTabURL(params)}${search}`,
    exact: true,
  },
]);
