import React from 'react';

import { formatDate } from '../../../utils';


export const CalendarBody = ({ weeks, handleOnClick, handleOnKeyDown, currentWeek, currentDate }) => {
  return (
    <div className="calendar-body">
      {weeks.map(props => {
        const { isInClickableRange, date, dateFormated } = props;
        const isSelectedDate = formatDate(currentDate) === dateFormated;

        const handleDisableCallbacks = callback => {
          return isInClickableRange ? callback : () => { };
        };

        return (
          <Row
            {...props}
            isSelectedDate={isSelectedDate}
            key={date}
            handleOnClick={handleDisableCallbacks(handleOnClick)}
            handleOnKeyDown={handleDisableCallbacks(handleOnKeyDown)}
            isDateInCurrentWeek={currentWeek.includes(dateFormated)}
            currentDate={currentDate}
          />
        );
      })}
    </div>
  );
};

const Row = ({
  isSelectedDate,
  date,
  day,
  handleOnClick,
  handleOnKeyDown,
  isCurrentMonth,
  isDateInCurrentWeek,
  isInClickableRange,
}) => {
  const handleCurrentMonth = isDateInCurrentWeek || isCurrentMonth ? '' : 'calendar-cell--disabled';
  const handleOutOfRangeClass = isInClickableRange ? '' : 'calendar-cell--out-of-range';
  const highlightSelectedDate = isSelectedDate ? 'calendar-cell--highlight' : '';
  const handleTabIndex = isInClickableRange ? { tabIndex: 0 } : {};

  return (
    <div
      className={`calendar-cell ${handleCurrentMonth} ${handleOutOfRangeClass} ${highlightSelectedDate}`}
      onClick={() => handleOnClick(date)}
      onKeyDown={event => handleOnKeyDown(event, date)}
      {...handleTabIndex}
    >
      {day}
    </div>
  );
};
