import React, { Fragment, useCallback } from 'react';

import { EmptyListFeedback, LoadingElement } from '../../../components';
import { TournamentDetailsStandingsProviderController } from '../shared/TournamentDetailsStandingsProviderController';
import { StandingList, StandingTableStylingVariant } from '../../../types';

import { TournamentDetailsTabStandingsTable } from './TournamentDetailsTabStandingsTable';


export const TournamentDetailsTabStandings = () => {
  const handleStylingVariant = useCallback((groupedStandings: StandingList[]): StandingTableStylingVariant => {
    if (groupedStandings[0]?.group.name.includes('Group')) {
      return StandingTableStylingVariant.Group;
    }
    return StandingTableStylingVariant.Virtual;
  }, []);

  return (
    <TournamentDetailsStandingsProviderController>
      {({ groupedStandings, isLoading }) => (
        <>
          {isLoading && <LoadingElement />}
          {!isLoading && groupedStandings.length === 0 && <EmptyListFeedback />}
          {!isLoading && groupedStandings.map(({ group: { id: groupId, name: groupName }, standings }) => {
            return (
              <Fragment key={`${groupId}`}>
                {standings.length > 0 ?
                  <TournamentDetailsTabStandingsTable
                    standings={standings}
                    stylingVariant={handleStylingVariant(groupedStandings)}
                    groupName={groupName}
                  /> :
                  <EmptyListFeedback key={`empty list feedback ${groupId}`} />
                }
              </Fragment>
            );
          })}
        </>
      )}
    </TournamentDetailsStandingsProviderController>
  );
};

