import { MatchLiveIncident, IncidentCode, IncidentState, TeamScore } from 'types';

import { handleMatchResult, transformResultToString } from '../shared/Match/matchServices';


export const getResultFromMatchIncident = (matchIncident: MatchLiveIncident): string => {
  const { match: { home, away, event: { statusName } } } = matchIncident;

  return transformResultToString(handleMatchResult(statusName)({ home, away }));
};

const getAllEventIncidents = (matchLiveIncidentList: MatchLiveIncident[]) =>
  (eventId: string) => (teamOrigin: TeamScore.Away | TeamScore.Home) => {
    const incidents = matchLiveIncidentList
      .filter(({ match: { event } }) => event.id === eventId);

    const teamOriginIncidents = incidents.filter(incident => incident.teamOrigin === teamOrigin);

    return teamOriginIncidents;
  };

const getIncidentByTimeAndGoal = (time: Date) => (incidents: MatchLiveIncident[]) => {
  return incidents.filter(incident => {
    const { createdAt, code, confirmation = IncidentState.Cancelled } = incident;
    const occuredAtToTime = new Date(createdAt);

    if (occuredAtToTime <= time) {
      return [IncidentState.Confirmed, IncidentState.Tbd].includes(confirmation) &&
        [IncidentCode.Goal, IncidentCode.PenaltyGoal, IncidentCode.OwnGoal].includes(code);
    }
    return null;
  });
};

export const getResultByCountingIncidents = (matchLiveIncidentList: MatchLiveIncident[]) => (incident: MatchLiveIncident) => {
  const {
    createdAt,
    match: { event: { id: eventId } },
  } = incident;
  const getMatchIncidents = getAllEventIncidents(matchLiveIncidentList)(eventId);

  const homeIncidents = getMatchIncidents(TeamScore.Home);
  const awayIncidents = getMatchIncidents(TeamScore.Away);

  const getIncidents = getIncidentByTimeAndGoal(new Date(createdAt));
  const homeScore = (homeIncidents ? getIncidents(homeIncidents) : []).length;
  const awayScore = (awayIncidents ? getIncidents(awayIncidents) : []).length;

  return `${homeScore} : ${awayScore}`;
};
