import React from 'react';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import { MatchStatus } from 'types';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { isEmpty } from 'lodash';
import { useMatch } from 'context/match-context';

import { RouteParam } from '../../RouteParam';
import { MediaQueriesRedirectController } from '../../../../../shared/MediaQueriesRedirectController';
import { mediaQuery } from '../../../../../../constants/mediaQuery';
import { getTeamFormationURL, getURLWithId } from '../../../../shared/MatchDetails/matchDetailsRoutingServices';
import { DASHBOARD_ROUTE } from '../../../../Routing/Routing';
import { MobileMatchDetailsSummaryWrapper } from '../MobileMatchDetailsSummaryWrapper';
import { MatchDetailsVenue } from '../../../../shared/MatchDetails/Venue/MatchDetailsVenue';

import { getMobileMatchDetailsStatisticRouteList } from './routes';


export const MobileMatchDetailsStatisticRouting = () => {
  const params = useParams<RouteParam>();
  const isDesktop = useMediaQuery(mediaQuery.minDesktopSmall);
  const { selectedMatch } = useMatch();

  return (
    <Switch>
      {getMobileMatchDetailsStatisticRouteList(params).map(({ path, redirectURL, Component }) => {
        const venue = selectedMatch?.venue || {};

        return (
          <Route exact key={redirectURL} path={path} render={() => (
            <MediaQueriesRedirectController
              isMediaQueryActive={isDesktop}
              url={redirectURL}
            >
              <MobileMatchDetailsSummaryWrapper
                displayStatus={[MatchStatus.Finished, MatchStatus.Live]}
              >
                <>
                  {!isEmpty(venue) && (
                    <div className="margin-top--size-s">
                      <MatchDetailsVenue venue={venue} />
                    </div>
                  )}
                  <Component />
                </>
              </MobileMatchDetailsSummaryWrapper>
            </MediaQueriesRedirectController>
          )}
          />
        );
      })}
      <Redirect to={getTeamFormationURL(getURLWithId(DASHBOARD_ROUTE.MOBILE_DASHBOARD_MATCHES))(params)} />
    </Switch>
  );
};

