import { FC, ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useTournaments } from '../../../../context/tournament-list-context';
import { addQuery } from '../../../../utils';
import { DASHBOARD_ROUTING } from '../../Routing/DashboardRouting';
import { MOBILE_DASHBOARD_ROUTING } from '../../Routing/MobileDashboardRouting';


type Props = {
  children(props: {
    handleSelectMultipleIds: (seasonIds: string[]) => void;
    selectedTournaments: string[];
    isIdSelected: (seasonId: string) => boolean;
    handleSelect: (id: string) => void;
    mobileHandleSelect: (id: string) => void;
  }): ReactElement;
}

export const TournamentController: FC<Props> = ({ children }) => {
  const {
    selectedTournaments,
  } = useTournaments();
  const { push } = useHistory();
  const { search } = useLocation();

  const removeSeasonId = (id: string) => selectedTournaments.filter(season => {
    return id !== season;
  });

  const isIdSelected = (seasonId: string) => {
    return selectedTournaments.includes(seasonId);
  };

  const toggleElements = (id: string) => {
    let seasonList = [];

    if (selectedTournaments.includes(id)) {
      seasonList = removeSeasonId(id);
    } else {
      seasonList = [...selectedTournaments, id];
    }

    return seasonList;
  };

  const mobileHandleSelect = (id: string) => {
    push({
      pathname: MOBILE_DASHBOARD_ROUTING.getDefaultDashboardURL(),
      search: addQuery(search,
        {
          season: toggleElements(id),
        }),
    });
  };

  const handleSelect = (id: string) => {
    push({
      pathname: DASHBOARD_ROUTING.getDefaultDashboardURL(),
      search: addQuery(search,
        {
          season: toggleElements(id),
        }),
    });
  };

  const handleSelectMultipleIds = (seasons: string[]) => {
    let seasonList = [];
    const areSomeSeasonIdsSelected = selectedTournaments.some(seasonId => seasons.includes(seasonId));

    if (areSomeSeasonIdsSelected) {
      seasonList = selectedTournaments.filter(season =>  !seasons.includes(season));
    } else {
      seasonList = seasons;
    }

    push({
      pathname: DASHBOARD_ROUTING.getDefaultDashboardURL(),
      search: addQuery(search, { season: seasonList }),
    });
  };

  const props = {
    handleSelectMultipleIds,
    selectedTournaments,
    isIdSelected,
    handleSelect,
    mobileHandleSelect,
  };

  return children(props);
};
