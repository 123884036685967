import { useEffect } from 'react';
import { useMatchList } from 'context/match-list-context';

import { useSocketMatchesData } from './hooks/useSocketMatchesData';


export const DashboardSocketsController = ({ children }) => {
  const { setMatches } = useMatchList();
  const { syncedMatches, shouldMatchesBeUpdated } = useSocketMatchesData();

  useEffect(() => {
    if(shouldMatchesBeUpdated) {
      setMatches(syncedMatches);
    }
  }, [syncedMatches, setMatches, shouldMatchesBeUpdated]);



  return children;
};
