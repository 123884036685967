import { FC, ReactElement, useCallback, useEffect } from 'react';
import { isUndefined } from 'lodash';
import { ProviderBasketAppConfig } from 'types';
import { useTranslate } from 'context/translate-context';

import { useBasketConfig } from '../../context/basket/basket-config-context';


type Props = {
  configuration?: ProviderBasketAppConfig;
  children: ReactElement;
}

export const BasketConfigController: FC<Props> = ({ configuration, children }) => {
  const { setConfiguration } = useBasketConfig();
  const { setLanguage } = useTranslate();

  const handleSetConfig = () => {
    if (configuration) {
      setConfiguration(configuration);

      if(configuration.lang) {
        setLanguage(configuration.lang);
      }
    }
  };

  const memoHandleSetConfig = useCallback(handleSetConfig, [!isUndefined(configuration)]);

  useEffect(() => {
    memoHandleSetConfig();
  }, [memoHandleSetConfig]);


  return children;
};
