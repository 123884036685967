import React, { FC, useMemo } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useTranslate } from 'context/translate-context';
import { Basket, BasketMode, BasketErrorMessageLabel } from 'types';
import { useBasket } from 'context/basket/basket-context';
import { isEqual } from 'lodash';
import { useBasketConfig } from 'context/basket/basket-config-context';

import { usePrevious } from '../../../../hooks/usePrevious';

import { SingleModeFooter } from './SingleModeFooter';
import { ComboModeFooter } from './ComboModeFooter';
import { SystemModeFooter } from './SystemModeFooter';
import { ModeFooterResults } from './ModeFooterResults';
import { BasketModeFooterErrorMessage } from './BasketModeFooterErrorMessage';

import './ModeFooter.scss';


type Props = {
  control: Control<Basket>;
  setValue: UseFormSetValue<Basket>;
  fields: Basket;
}

export const ModeFooter: FC<Props> = ({
  control,
  setValue,
  fields,
}) => {
  const { errors } = useBasket();
  const { translate } = useTranslate();
  const {
    configuration: {
      provider: { currency },
    },
  } = useBasketConfig();


  const isEmpty = fields.items.length === 0;
  const isBlocked = Boolean(errors.length);
  const displayError = errors
    .find(error => [BasketErrorMessageLabel.OVERDUE_BET_LIST, BasketErrorMessageLabel.INVALID_VALUE].includes(error.message));
  const groupsQuantity = fields.items
    .flatMap(({ groups }) => groups)
    .length;

  const handleBasketMode = () => {
    const controllers = {
      [BasketMode.Single]: SingleModeFooter,
      [BasketMode.Combo]: ComboModeFooter,
      [BasketMode.System]: SystemModeFooter,
    };

    return controllers[fields.mode];
  };
  const { groupBetValues } = fields;
  const previousGroupedList = usePrevious(groupBetValues);

  const shouldBeUpdade = !isEqual(previousGroupedList, groupBetValues);
  const ModeFields = useMemo(handleBasketMode, [fields.mode, groupsQuantity, shouldBeUpdade]);

  return (
    <ModeFooterResults fields={fields}>
      {({ totalOdds, minPrize, maxPrize }) => (
        <div className="basket-footer">
          <div className="basket-footer--padding">
            <ModeFields
              control={control}
              setValue={setValue}
              fields={fields}
              totalOdds={totalOdds}
              minPrize={minPrize}
              maxPrize={maxPrize}
            />
          </div>
          {displayError && (
            <BasketModeFooterErrorMessage
              message={translate(displayError.message)}
            />
          )}
          <div className="basket-footer--padding">
            <button
              form="basket-form"
              disabled={isEmpty || isBlocked}
              className="basket-submit"
              type="submit"
              data-testid="submitButton"
            >
              <span className="basket-submit__header">{translate('PLACE_BET')}</span>
              <span className="basket-submit__value">
                <NumberFormat
                  isNumericString
                  thousandSeparator
                  displayType="text"
                  value={fields.totalStake}
                  suffix={` ${currency}`}
                />
              </span>
            </button>
          </div>
        </div>
      )}
    </ModeFooterResults>
  );
};
