import React from 'react';

import { MatchDetailsSideBetsMatchResult } from '../../../../MatchDetails/SideBets/MatchResult/MatchDetailsSideBetsMatchResult';
import { getSideBetsGridHeaders, createMatchDetailsSideBetsRowHeaderWithKey } from '../../../../shared/MatchDetails/SideBets/matchDetailsSideBetsServices';


const createRowWithCustomClass = createMatchDetailsSideBetsRowHeaderWithKey({ key: 'match-result' });
const rows = [
  createRowWithCustomClass({}),
  createRowWithCustomClass({ customStyle: 'match-details-prediction--from-tablet' }),
  createRowWithCustomClass({ customStyle: 'match-details-prediction--from-tablet' }),
];

export const MobileMatchDetailsSideBetsMatchResultSection = () => {
  return (
    <MatchDetailsSideBetsMatchResult
      ColumnsLabels={getSideBetsGridHeaders(rows)}
    />
  );
};
