import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';


type Props = {
  text: string;
  to: string;
  exact?: boolean;
  isDisabled?: boolean;
}

export const MobileTab: FC<Props> = ({ text, to, exact, isDisabled = false }) => {
  return (
    <li
      key={text}
      className="m-tab-list__tab"
    >
      {isDisabled ? (
        <div className="m-tab-list__tab-link m-tab-list__tab-link--disabled">
          {text}
        </div>
      ) :
        (
          <NavLink
            className="m-tab-list__tab-link"
            to={to}
            exact={exact}
          >
            {text}
          </NavLink>
        )}
    </li>
  );
};
