import { usePrevious } from 'hooks/usePrevious';
import { useCallback, useEffect, useState } from 'react';


const TIMEOUT_TIME = 1000 * 60;

const isRecentScore = (prevValue: string, latestValue: string) => {
  return parseInt(prevValue, 10) !== parseInt(latestValue, 10);
};

type Props = {
  firstHalfScore: string;
  regularTimeScore: string;
}

export const useCompareScores = (props: Props) => {
  const previousProps = usePrevious(props);

  const [isScoreAtFH, setIsScoreAtFH] = useState<boolean>(false);
  const [isScoreAtRT, setIsScoreAtRT] = useState<boolean>(false);

  const compareStatesAndHandleNewScore = () => {
    if (isRecentScore(previousProps.firstHalfScore, props.firstHalfScore)) {
      setIsScoreAtFH(true);

      setTimeout(() => {
        setIsScoreAtFH(false);
      }, TIMEOUT_TIME);
    }

    if (isRecentScore(previousProps.regularTimeScore, props.regularTimeScore)) {
      setIsScoreAtRT(true);

      setTimeout(() => {
        setIsScoreAtRT(false);
      }, TIMEOUT_TIME);
    }
  };

  const memoCompareStatesAndHandleNewScore = useCallback(compareStatesAndHandleNewScore, [
    props.firstHalfScore,
    props.regularTimeScore,
  ]);

  useEffect(() => {
    memoCompareStatesAndHandleNewScore();
  }, [memoCompareStatesAndHandleNewScore]);

  return {
    isScoreAtFH,
    isScoreAtRT,
  };
};
