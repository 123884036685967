import React, { FC, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import { noop } from 'lodash';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import SeasonClient  from 'api/SeasonClient';

import { Tournament, StandingList, Season, StatisticSeason } from '../types';
import { Maybe, Noop } from '../types/Util';
import TournamentClient from '../api/TournamentClient';
import { RouteParam } from '../views/Tournament/Routing/RouteParam';
import MatchClient from '../api/MatchClient';


type Props = {
  areHistoricalSeasonsLoading: boolean;
  areStandingsLoading: boolean;
  areStatisticsLoading: boolean;
  handleNextSeason: Noop;
  handlePreviousSeason: Noop;
  historicalSeasons: Season[];
  indexOfDisplayedSeason: number;
  isTournamentDetailsLoading: boolean;
  selectedHistoricalSeason: Maybe<Season>;
  setIndexOfDisplayedSeason: (index: number) => void;
  setSelectedHistoricalSeason: (season: any) => void;
  standings: StandingList[];
  statistic: Maybe<StatisticSeason>;
  tournamentDetails: Maybe<Tournament>;
};


const TournamentsContext = React.createContext<Props>({
  areHistoricalSeasonsLoading: false,
  areStandingsLoading: false,
  areStatisticsLoading: false,
  handleNextSeason: noop,
  handlePreviousSeason: noop,
  historicalSeasons: [],
  indexOfDisplayedSeason: 0,
  isTournamentDetailsLoading: false,
  selectedHistoricalSeason: null,
  setIndexOfDisplayedSeason: noop,
  setSelectedHistoricalSeason: noop,
  standings: [],
  statistic: null,
  tournamentDetails: null,
});

const getLastIndex = (array: Season[]) => array.length - 1;


const TournamentProvider: FC = props => {
  const { tournamentId } = useParams<RouteParam>();

  // historical seasons
  const [selectedHistoricalSeason, setSelectedHistoricalSeason] = useState<Maybe<Season>>(null);
  const [indexOfDisplayedSeason, setIndexOfDisplayedSeason] = useState(0);

  const {
    data: tournamentData,
    isLoading: isTournamentDetailsLoading,
  } = useQuery(['tournamentDetails', tournamentId], () => TournamentClient.getTournament({ tournamentId }));

  const tournamentDetails = useMemo(() => {
    return tournamentData?.data?.tournament || null;
  }, [tournamentData]);


  const {
    data: historicalSeasonsData,
    isLoading: areHistoricalSeasonsLoading,
  } = useQuery(['historicalSeasons', tournamentId], () => SeasonClient.getSeasonList({ tournamentId }));


  const historicalSeasons = useMemo(() => {
    const [currentSeason, ...rest] = historicalSeasonsData?.data?.seasons || [];

    const seasons = [...rest.reverse(), currentSeason];
    setIndexOfDisplayedSeason(getLastIndex(seasons));
    setSelectedHistoricalSeason(currentSeason);

    return seasons;
  }, [historicalSeasonsData]);

  const handleDisplaySelectedHistoricalSeason = () => setSelectedHistoricalSeason(historicalSeasons[indexOfDisplayedSeason]);
  const memoUpdateDisplayOfHistoricalSeason = useCallback(handleDisplaySelectedHistoricalSeason, [indexOfDisplayedSeason]);

  useEffect(() => {
    memoUpdateDisplayOfHistoricalSeason();
  }, [indexOfDisplayedSeason, memoUpdateDisplayOfHistoricalSeason]);

  const handlePreviousSeason = () => {
    setIndexOfDisplayedSeason(prevState => {
      if (prevState === 0) {
        return 0;
      }
      return prevState - 1;
    });
  };

  const handleNextSeason = () => {
    setIndexOfDisplayedSeason(prevState => {
      if (prevState === getLastIndex(historicalSeasons)) {
        return getLastIndex(historicalSeasons);
      }
      return prevState + 1;
    });
  };

  // stats
  const seasonId = tournamentDetails?.season?.id || '';
  const {
    data: statisticsData,
    isLoading: areStatisticsLoading,
  } = useQuery(['tournamentStatistics', seasonId], () => SeasonClient.getSeasonStatistics({ seasonId }), {
    enabled: Boolean(seasonId),
  });

  const statistic = useMemo(() => {
    return statisticsData?.data?.statistics || null;
  }, [statisticsData]);

  // standings

  const selectedSeasonIds = selectedHistoricalSeason?.id || '';
  const {
    data: standingsData,
    isLoading: areStandingsLoading,
  } = useQuery(['tournamentStandings', selectedSeasonIds], () => MatchClient.getStandings({ seasonId: selectedSeasonIds }), {
    enabled: Boolean(selectedSeasonIds),
  });

  const standings = useMemo(() => {
    return standingsData?.data?.standings || [];
  }, [standingsData]);


  const values = {
    areStandingsLoading,
    areHistoricalSeasonsLoading,
    historicalSeasons,
    indexOfDisplayedSeason,
    isTournamentDetailsLoading,
    selectedHistoricalSeason,
    tournamentDetails,
    setIndexOfDisplayedSeason,
    setSelectedHistoricalSeason,
    areStatisticsLoading,
    standings,
    statistic,
    handlePreviousSeason,
    handleNextSeason,
  };

  return (
    <TournamentsContext.Provider
      value={values}
      {...props}
    />
  );
};


const useTournament = () => {
  const context = useContext(TournamentsContext);

  if(context === undefined) {
    throw new Error('useTournament must be used within a TournamentProvider');
  }

  return context;
};

export {
  TournamentProvider,
  useTournament,
};
