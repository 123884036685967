import { OddsType, OddsTypePeriod, OddListGroupingType, OddGroupingType } from 'types';


export const transformToDisplayedSideBetsOnMatchList = (oddList: OddGroupingType[]): OddListGroupingType[] => {
  return oddList.map(({ event, odds }) => {
    return {
      event,
      odds: odds.find(({ type, typePeriod }) => {
        return typePeriod === OddsTypePeriod.Fulltime && type === OddsType.Outcome;
      }),
      oddListLength: odds.length,
    };
  });
};
