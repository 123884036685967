const liveTicker = {
  ARIA_DECREMENT_TIME_BTN: 'Decrement time button',
  ARIA_INCREMENT_TIME_BTN: 'Increment time button',
  SET_DISPLAY_TIME: 'Set display time',
  MATCH_STATUS: 'Set display time',
  CARDS: 'Cards',
  INCIDENTS_IN_QUEUE: 'Incidents in queue',
  LIVE_TICKER_SETTINGS: 'Live ticker settings',
};

export default liveTicker;
