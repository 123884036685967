import { useTournament } from 'context/tournament-context';


export const TournamentDetailsHistoricalSeasonsController = ({ children }) => {
  const {
    selectedHistoricalSeason,
    historicalSeasons,
    indexOfDisplayedSeason,
    handlePreviousSeason,
    handleNextSeason,
    areHistoricalSeasonsLoading,
  } = useTournament();

  const buttonsState = {
    isPrevDisabled: indexOfDisplayedSeason === 0,
    isNextDisabled: indexOfDisplayedSeason === getLastIndex(historicalSeasons),
  };


  const props = {
    isLoading: areHistoricalSeasonsLoading,
    handlePreviousSeason,
    handleNextSeason,
    selectedHistoricalSeason,
    buttonsState,
  };


  return children(props);
};


const getLastIndex = array => array.length - 1;
