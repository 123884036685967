

import React, { memo } from 'react';
import { MatchSection } from 'types';
import { MatchDetailsAllSideBetsList } from 'views/Dashboard/shared/MatchDetails/SideBets/MatchDetailsAllSideBetsList';

import { useUpdateMatchDetailsSideBets } from '../../shared/hooks/updateMatchDetailsSideBets/useUpdateMatchDetailsSideBets';

import { MatchDetailsSideBetsThreeWayHandicapDefault } from './ThreeWayHandicap/Default/MatchDetailsSideBetsThreeWayHandicapDefault';
import { MatchDetailsSideBetsGenericDefault } from './Generic/Default/MatchDetailsSideBetsGenericDefault';
import { MatchDetailsSideBetsCorrectScoreDefault } from './CorrectScore/Default/MatchDetailsSideBetsCorrectScoreDefault';
import { MatchDetailsSideBetsMatchResultDefault } from './MatchResult/Default/MatchDetailsSideBetsMatchResultDefault';
import { MatchDetailsSideBetsNumberOfGoalsDefault } from './NumberOfGoals/Default/MatchDetailsSideBetsNumberOfGoalsDefault';

import './MatchDetailsSideBets.scss';


const SectionList = [
  {
    Component: MatchDetailsSideBetsMatchResultDefault,
    label: MatchSection.MatchResult,
  },
  {
    Component: MatchDetailsSideBetsNumberOfGoalsDefault,
    label: MatchSection.NumberOfGoals,
  },
  {
    Component: MatchDetailsSideBetsThreeWayHandicapDefault,
    label: MatchSection.ThreeWayHandicaps,
  },
  {
    Component: MatchDetailsSideBetsCorrectScoreDefault,
    label: MatchSection.CorrectScore,
  },
  {
    Component: MatchDetailsSideBetsGenericDefault,
  },
];


export const MatchDetailsSideBets = memo(() => {
  useUpdateMatchDetailsSideBets();

  return (
    <ul className="match-details-side-bets__grid-container">
      <MatchDetailsAllSideBetsList SectionList={SectionList} />
    </ul>
  );
});

