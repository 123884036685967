import { FC, ReactElement } from 'react';
import { Basket } from 'types';

import { useBasketError } from './useBasketError';


type Props = {
  fields: Basket;
  children: ReactElement;
}

export const BasketErrorsController: FC<Props> = ({ fields, children }) => {
  useBasketError({ fields });

  return children;
};
