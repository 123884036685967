import React, { memo } from 'react';
import { useUpdateMatchDetailsSideBets } from 'views/Dashboard/shared/hooks/updateMatchDetailsSideBets/useUpdateMatchDetailsSideBets';

import { MatchDetailsSideBetsThreeWayHandicapDefault } from '../Default/MatchDetailsSideBetsThreeWayHandicapDefault';


export const MatchDetailsSideBetsThreeWayHandicapLive = memo(() => {
  useUpdateMatchDetailsSideBets();

  return (
    <MatchDetailsSideBetsThreeWayHandicapDefault />
  );
});
